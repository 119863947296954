:root {
    // --toastify-icon-color-success: #fff !important;
    // --toastify-color-progress-success: #fff !important;
    // --toastify-color-light: #fe6f42 !important;
    // --toastify-icon-color-error: #fff !important;
    --P_color1: #fff;
    --P_color2: #fff;
    --P_color3: #fff;
    --P_color4: #fff;
    --P_color5: #fff;
    --P_color6: #fe6f42;
}

*,
:after,
:before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Euclid Circular A", sans-serif;
    list-style: none;
    text-decoration: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

html {
    font-size: 62.5%;

    @media (max-width: 600px) {
        font-size: 50%;
    }
}

.form-group {
    position: relative;
    grid-column: 4/8;

    @media (max-width: 1040px) {
        grid-column: 3/7;
    }

    @media (max-width: 768px) {
        grid-column: 1/-1;
    }

    .search-input {
        border: none;
        outline: none;
        background-color: rgba(#0c0d1e, 0.03);
        border-radius: 4px;
        height: 4.8rem;
        width: 100%;
        padding-left: 1.6rem;
        padding-right: 4rem;
        position: relative;
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 170%;
        color: rgba(#0c0d1e, 0.5);
        border: 1px solid rgba(#0c0d1e, 0.03);
        transition: all 0.3s;

        @media (max-width: 600px) {
            height: 6rem;
            font-size: 1.75rem;
        }

        &::placeholder {
            font-style: normal;
            font-weight: normal;
            font-size: 1.6rem;
            line-height: 170%;
            color: rgba(#0c0d1e, 0.5);

            @media (max-width: 600px) {
                height: 6rem;
                font-size: 1.75rem;
            }
        }

        &:focus {
            border: 1px solid #fe6f42;
            background: white;

            +.search-btn {
                background-color: #fe6f42;
                transition: all 0.3s;

                svg {
                    g {
                        opacity: 1;
                    }

                    path {
                        fill: #fff;
                    }
                }
            }
        }
    }

    /* clears the ‘X’ from Internet Explorer */
    input[type="search"]::-ms-clear {
        display: none;
        width: 0;
        height: 0;
    }

    input[type="search"]::-ms-reveal {
        display: none;
        width: 0;
        height: 0;
    }

    /* clears the ‘X’ from Chrome */
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
        display: none;
    }

    .search-btn {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        cursor: pointer;
        border: none;
        outline: none;
        background: none;
        height: 100%;
        width: 4.8rem;
        border-radius: 4px;
        transition: all 0.5s;

        @media (max-width: 600px) {
            width: 6rem;
        }
    }
}

.form-group.mobile {
    grid-column: 1/-1;
    display: none;
    width: 100%;
    margin: 1.6rem auto 0 auto;

    @media (max-width: 768px) {
        display: block;
    }

    @media (max-width: 600px) {
        grid-column: 1/-1;
        margin: 5px auto 0 auto;
    }
}

nav {
    width: 100%;
    border-bottom: 1px solid rgba(#0c0d1e, 0.05);

    @media (max-width: 768px) {
        width: 100%;
        padding: 0;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 9999999;
    }

    .nav-top-container {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-gap: 0 2.4rem;
        margin: 0 auto;
        position: relative;
        border-bottom: 1px solid rgba(#0c0d1e, 0.05);

        @media (max-width: 1128px) {
            width: 100%;
            //padding: 0 2.4rem;
        }

        @media (max-width: 768px) {
            display: none;
        }

        .navbar-top {
            width: 112.8rem;
            grid-column: 1/-1;
            //width: 100%;
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            grid-gap: 0 2.4rem;
            align-content: center;
            margin: 0 auto;
            height: 9.7rem;

            //border-bottom: 1px solid rgba(#0C0D1E, .05);
            @media (max-width: 1128px) {
                padding: 0 2.4rem;
                width: 100%;
            }

            @media (max-width: 768px) {
                display: none;
            }

            .main-links {
                display: flex;
                align-items: center;
            }

            .img-container {
                grid-column: 1/4;
                width: 13rem;
                height: 3.2rem;
                cursor: pointer;

                @media (max-width: 1040px) {
                    grid-column: 1/4;
                }

                @media (max-width: 768px) {
                    grid-column: 1/6;
                }

                img {
                    width: 13rem;
                    height: 3.2rem;
                    object-fit: contain;
                }
            }

            .nav-group {
                grid-column: 8/-1;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                @media (max-width: 1040px) {
                    grid-column: 7/-1;
                }

                @media (max-width: 768px) {
                    grid-column: 6/-1;
                    justify-self: end;
                    height: 6rem;
                }

                .account-btn {
                    outline: none;
                    border: none;
                    background-color: transparent;

                    .account {
                        position: relative;

                        .account-dropdown {
                            cursor: initial;
                            height: auto;
                            width: 27rem;
                            position: absolute;
                            top: 120%;
                            right: 0;
                            z-index: 9999;
                            background: #ffffff;
                            border: 1px solid rgba(5, 6, 30, 0.07);
                            box-sizing: border-box;
                            box-shadow: 0px 16px 32px rgba(5, 6, 30, 0.04);

                            .user-info {
                                width: 100%;
                                padding: 2.6rem 0 2.6rem 1.6rem;
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);

                                .img-container {
                                    width: 4rem;
                                    height: 4rem;
                                    border-radius: 50%;
                                    margin-right: 1.6rem;

                                    img {
                                        width: 4rem;
                                        height: 4rem;
                                        object-fit: cover;
                                        border-radius: 50%;
                                    }
                                }

                                .user-name {
                                    .name {
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 1.4rem;
                                        line-height: 170%;
                                        display: flex;
                                        align-items: center;
                                        color: #05061e;
                                    }

                                    .client-code {
                                        font-style: normal;
                                        font-weight: normal;
                                        font-size: 1.2rem;
                                        line-height: 170%;
                                        display: flex;
                                        align-items: center;
                                        color: rgba($color: #0c0d1e, $alpha: 0.5);
                                    }
                                }
                            }

                            .user-dropdown-links {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                justify-content: flex-start;
                                padding-left: 2.8rem;
                                padding-top: 2.6rem;
                                padding-bottom: 2.6rem;
                                border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);

                                a {
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 16px;
                                    line-height: 170%;
                                    display: flex;
                                    align-items: center;
                                    color: rgba($color: #05061e, $alpha: 0.5);
                                    padding-bottom: 2rem;

                                    &:last-child {
                                        padding-bottom: 0;
                                    }

                                    svg {
                                        width: 1.6rem;
                                        height: 1.6rem;
                                        margin-right: 2.8rem;
                                    }

                                    &:hover {
                                        color: #fe6f42;

                                        svg {
                                            g {
                                                opacity: 1;
                                            }

                                            path {
                                                stroke: #fe6f42;
                                            }
                                        }
                                    }
                                }
                            }

                            .logout-btn {
                                border: none;
                                outline: none;
                                background-color: transparent;
                                cursor: pointer;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 170%;
                                display: flex;
                                align-items: center;
                                color: rgba($color: #05061e, $alpha: 0.5);
                                padding: 2.2rem 0 2.2rem 2.8rem;

                                svg {
                                    width: 1.6rem;
                                    height: 1.6rem;
                                    margin-right: 2.8rem;
                                }

                                &:hover {
                                    color: #fe6f42;

                                    svg {
                                        g {
                                            opacity: 1;
                                        }

                                        path {
                                            stroke: #fe6f42;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .main-links {
                    &:after {
                        content: "";
                        width: 2px;
                        height: 2px;
                        background-color: rgba(#05061e, 0.75);
                        border-radius: 50%;
                        margin: 0 2.4rem;
                    }

                    &.open-store-link {
                        margin-right: 3.2rem;

                        .store-open {
                            svg {
                                width: 2.4rem;
                                height: 2.4rem;
                                display: none;

                                @media (max-width: 900px) {
                                    display: inline-block;
                                }
                            }

                            .store-text {
                                font-style: normal;
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 170%;
                                display: flex;
                                align-items: center;
                                color: rgba($color: #fe6f42, $alpha: 0.75);

                                @media (max-width: 900px) {
                                    display: none;
                                }
                            }
                        }

                        &::after {
                            display: none;
                        }
                    }
                }

                .account,
                .cart,
                .favorite {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    &:hover {
                        .icon-svg {
                            g {
                                opacity: 1;
                            }

                            path {
                                stroke: #fe6f42;
                            }
                        }

                        span {
                            color: #fe6f42;
                        }
                    }

                    svg {
                        height: 2.4rem;
                        width: 2.4rem;
                    }

                    span {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 1.6rem;
                        line-height: 170%;
                        display: flex;
                        align-items: center;
                        color: rgba(#05061e, 0.75);

                        @media (max-width: 768px) {
                            display: none;
                        }
                    }
                }

                .cart {
                    .cart-text {
                        margin-left: 1.6rem;
                    }

                    &:hover {
                        svg {
                            g {
                                opacity: 1;
                            }

                            path {
                                fill: #fe6f42;
                                stroke: unset;
                            }
                        }

                        span {
                            color: #fe6f42;
                        }
                    }
                }

                .cart,
                .favorite {

                    .cart-icon-container,
                    .favorite-icon-container {
                        position: relative;

                        .cart-count,
                        .favorite-count {
                            position: absolute;
                            top: -30%;
                            right: -50%;
                            display: inline-block;
                            width: 2rem;
                            height: 2rem;
                            background: #fe6f42;
                            color: #fff;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 1.2rem;
                            line-height: 170%;
                            border: 1px solid #fff;
                        }
                    }
                }

                .favorite {
                    .favorite-icon-container {
                        .favorite-count {
                            background-color: #fe6f42;
                        }
                    }
                }
            }
        }
    }

    .navbar-mobile {
        display: none;
        grid-column: 1/-1;
        width: 100%;
        height: 6rem;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(#0c0d1e, 0.05);
        background: #fff;
        z-index: 999999999999;

        @media (max-width: 768px) {
            display: flex;
            padding: 0 1.2rem;
        }

        @media (max-width: 600px) {
            height: 7.5rem;
            padding: 0 1.5rem;
        }

        .img-container {
            width: 8.2rem;
            height: 2rem;

            @media (max-width: 600px) {
                width: 13rem;
                height: 2rem;
            }

            img {
                width: 8.2rem;
                height: 2rem;

                @media (max-width: 600px) {
                    width: 13rem;
                    height: 2rem;
                }
            }
        }

        .nav-group {
            display: flex;
            align-items: center;

            .mobile-nav-search {
                display: flex;
                align-items: center;
                margin-right: 2.4rem;
                background-color: transparent;
                outline: 0;
                border: none;

                @media (max-width: 600px) {
                    margin-right: 3rem;
                }

                @media (max-width: 400px) {
                    margin-right: 2rem;
                }

                svg {
                    width: 2rem;
                    height: 2rem;

                    @media (max-width: 600px) {
                        width: 2.5rem;
                        height: 2.5rem;
                    }
                }
            }

            .account,
            .cart,
            .favorite,
            .menu {
                width: 2rem;
                height: 2rem;
                margin-right: 2.4rem;

                @media (max-width: 600px) {
                    width: 2.5rem;
                    height: 2.5rem;
                    margin-right: 3rem;
                }

                @media (max-width: 400px) {
                    margin-right: 2rem;
                }

                &:last-child {
                    margin-right: 0;
                }

                svg {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        #mobile-cart,
        #mobile-fav {
            position: relative;

            .mobile-cart-count,
            .mobile-fav-count {
                position: absolute;
                bottom: 100%;
                right: -30%;
                display: inline-block;
                width: 1.2rem;
                height: 1.2rem;
                background: #fe4242;
                color: #fff;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-style: normal;
                font-weight: 600;
                font-size: 0.8rem;
                line-height: 170%;
                border: 1px solid #fff;

                @media (max-width: 600px) {
                    width: 1.6rem;
                    height: 1.6rem;
                    font-size: 1rem;
                    top: -150%;
                }
            }
        }
    }
}

.account-mobile-menu {
    display: none;
    width: 100%;
    position: absolute;
    background: white;
    top: 60px;
    left: 0;
    height: calc(100vh - 60px);
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    z-index: 9999999999;

    @media (max-width: 768px) {
        display: flex;
    }

    .account-dropdown {
        height: auto;
        width: 100%;

        .user-info {
            width: 100%;
            padding: 2.6rem 1.5rem 2.6rem 1.5rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);

            .container {
                width: 100%;
                display: flex;

                .img-container {
                    width: 4rem;
                    height: 4rem;
                    border-radius: 50%;
                    margin-right: 1.6rem;

                    @media (max-width: 600px) {
                        width: 5rem;
                        height: 5rem;
                        margin-right: 1.6rem;
                    }

                    img {
                        width: 4rem;
                        height: 4rem;
                        object-fit: cover;
                        border-radius: 50%;

                        @media (max-width: 600px) {
                            width: 5rem;
                            height: 5rem;
                        }
                    }
                }

                .user-name {
                    .name {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 1.4rem;
                        line-height: 170%;
                        display: flex;
                        align-items: center;
                        color: #05061e;

                        @media (max-width: 600px) {
                            font-size: 1.75rem;
                        }
                    }

                    .client-code {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 1.2rem;
                        line-height: 170%;
                        display: flex;
                        align-items: center;
                        color: rgba($color: #0c0d1e, $alpha: 0.5);

                        @media (max-width: 600px) {
                            font-size: 1.5rem;
                        }
                    }
                }
            }

            .close-btn {
                border: none;
                outline: none;
                background-color: transparent;
                width: 2.5rem;
                height: 2.5rem;

                img {
                    width: 2.5rem;
                    height: 2.5rem;
                    object-fit: cover;
                }
            }
        }

        .user-dropdown-links {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            padding-left: 2.8rem;
            padding-top: 2.6rem;
            padding-bottom: 2.6rem;
            border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);

            @media (max-width: 600px) {
                padding-left: 3rem;
                padding-top: 3.25rem;
                padding-bottom: 3.25rem;
            }

            a {
                font-style: normal;
                font-weight: 500;
                font-size: 1.6rem;
                line-height: 170%;
                display: flex;
                align-items: center;
                color: rgba($color: #05061e, $alpha: 0.5);
                padding-bottom: 2rem;

                @media (max-width: 600px) {
                    font-size: 2rem;
                    padding-bottom: 2.5rem;
                }

                &:last-child {
                    padding-bottom: 0;
                }

                svg {
                    width: 1.6rem;
                    height: 1.6rem;
                    margin-right: 2.8rem;

                    @media (max-width: 600px) {
                        width: 2rem;
                        height: 2rem;
                        margin-right: 3.5rem;
                    }
                }

                &:hover {
                    color: #fe6f42;

                    svg {
                        g {
                            opacity: 1;
                        }

                        path {
                            stroke: #fe6f42;
                        }
                    }
                }
            }
        }

        .logout-btn {
            border: none;
            outline: none;
            background-color: transparent;
            cursor: pointer;
            font-style: normal;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            color: rgba($color: #05061e, $alpha: 0.5);
            padding: 2.2rem 0 2.2rem 2.8rem;

            @media (max-width: 600px) {
                padding: 2.75rem 0 2.75rem 3rem;
                font-size: 2rem;
            }

            svg {
                width: 1.6rem;
                height: 1.6rem;
                margin-right: 2.8rem;

                @media (max-width: 600px) {
                    width: 2rem;
                    height: 2rem;
                    margin-right: 3.5rem;
                }
            }

            &:hover {
                color: #fe6f42;

                svg {
                    g {
                        opacity: 1;
                    }

                    path {
                        stroke: #fe6f42;
                    }
                }
            }
        }
    }
}

.mobile-mega-accont {
    display: none;

    @media (max-width: 768px) {
        display: flex;
        justify-content: space-between;
        padding: 2.4rem 1.6rem;

        .user-dropdown-links {
            display: flex;
            flex-direction: column;

            a {
                margin-bottom: 1.2rem;
                display: flex;
                align-items: center;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 170%;
                display: flex;
                align-items: center;
                color: rgba(5, 6, 30, 0.5);

                svg {
                    margin-right: 1.2rem;
                }
            }
        }
    }
}

.category-mobile-menu {
    overflow: hidden;
    display: none;
    width: 60%;
    height: 100vh;
    position: absolute;
    background: white;
    top: 0;
    right: 0;
    flex-direction: column;
    justify-content: space-between;
    z-index: 9999999999;

    @media (max-width: 768px) {
        display: flex;
    }

    @media (max-width: 360px) {
        width: 70%;
    }

    .top-container {
        height: 100%;

        .header {
            font-style: normal;
            font-weight: 500;
            font-size: 2.5rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            color: rgba($color: #05061e, $alpha: 0.75);
            padding: 1.5rem 1.6rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);

            .img-container {
                width: 2.5rem;
                height: 2.5rem;

                img {
                    width: 2.5rem;
                    height: 2.5rem;
                    object-fit: cover;
                }
            }
        }

        .body {
            overflow-y: auto;
            height: calc(100% - 60px);

            .mobile-menu-links {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                width: 100%;
                padding: 8px 1.6rem 0 1.6rem;

                .navlist-item {
                    margin-bottom: 1.6rem;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    border-right: none;

                    @media (max-width: 600px) {
                        margin-bottom: 2rem;
                    }

                    a {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 1.4rem;
                        line-height: 170%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        padding: 0;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 170%;
                        display: flex;
                        align-items: center;
                        color: rgba(#05061e, 0.75);
                        width: 100%;
                        display: inline-block;

                        @media (max-width: 600px) {
                            font-size: 1.75rem;
                        }
                    }
                }
            }
        }
    }
}

.navlist {
    position: relative;
    width: 112.8rem;
    grid-column: 1/-1;
    height: 5.2rem;
    margin: 0 auto;
    //width: 100%;
    display: grid;
    grid-template-columns: repeat(7, auto);
    //border-bottom: 1px solid rgba(#0C0D1E, .05);
    border-left: 1px solid rgba($color: #05061e, $alpha: 0.07);
    border-right: 1px solid rgba($color: #05061e, $alpha: 0.07);

    @media (max-width: 1128px) {
        width: 100%;
        padding: 0 2.4rem;
    }

    //768 - 1024 elave olundu
    @media (max-width: 1024px) {
        display: none;
    }

    &-item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.1s;
        cursor: pointer;
        border-right: 1px solid rgba($color: #05061e, $alpha: 0.07);

        &:last-child {
            border-right: none;
        }

        // &:not(:last-child):after {
        //   content: '';
        //   width: 1px;
        //   height: 24px;
        //   background-color: rgba($color: #05061E, $alpha: .07);
        //   position: absolute;
        //   right: 0;

        // }

        a {
            font-style: normal;
            padding: 0 1rem;
            font-weight: 500;
            font-size: 16px;
            line-height: 170%;
            color: rgba(#0c0d1e, 0.75);
            cursor: pointer;
            text-transform: uppercase;
            height: 100%;
            display: flex;
            align-items: center;
        }

        .mega-menu {
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            grid-column: 0 2.4rem;
            justify-items: flex-start;
            width: 100%;
            // height: 36rem;
            position: absolute;
            top: 101%;
            left: -1px;
            margin: 0 auto;
            background-color: #fff;
            z-index: 99;
            padding: 3.2rem;
            visibility: hidden;
            visibility: hidden;
            opacity: 0;
            transition:
                visibility 0s,
                opacity 0s linear;

            &_body {
                width: 100%;
                //grid-column: 1/9;
                grid-column: 1/-1;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;

                h3 {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 2.4rem;
                    line-height: 170%;
                    display: flex;
                    align-items: center;
                    color: #05061e;
                    margin-bottom: 2rem;
                }

                .cat-list-container {
                    width: 100%;
                    // display: grid;
                    // grid-template-columns: repeat(4, 1fr);
                    // grid-gap: 0 4.2rem;
                    // align-items: flex-start;
                    // flex-wrap: wrap;
                    display: flex;
                    align-items: flex-start;
                    flex-wrap: wrap;
                    // justify-content: space-between;

                    .cat-list_item {
                        // min-width: 25%;
                        margin-right: 42px;

                        .header {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 1.6rem;
                            line-height: 170%;
                            display: flex;
                            align-items: center;
                            color: rgba($color: #1e0f34, $alpha: 0.75);
                            margin-bottom: 1.6rem;
                            text-align: left;
                            text-transform: unset;
                            padding-left: 0;
                            color: #fe6f42;

                            &:hover {
                                color: #fe6f42;
                            }
                        }

                        ul {
                            display: grid;
                            grid-template-columns: 1fr;
                            grid-template-rows: repeat(5, 1fr);
                            grid-auto-flow: column;
                            grid-gap: 0 3rem;
                            justify-items: flex-start;
                            margin-bottom: 40px;

                            li {
                                a {
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 1.6rem;
                                    line-height: 170%;
                                    display: flex;
                                    align-items: center;
                                    color: rgba($color: #05061e, $alpha: 0.75);
                                    text-transform: unset;
                                    transition: all 0.3s;
                                    text-transform: unset;
                                    padding-left: 0;
                                    white-space: nowrap;

                                    &:hover {
                                        color: #fe6f42;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &_img {
                display: none;
                grid-column: 9/-1;
                justify-self: flex-end;

                .img-container {
                    width: 29.6rem;
                    height: auto;
                    position: relative;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    a {
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 99;
                        text-transform: unset;
                        width: 100%;
                        display: flex;
                        padding: 0 2.4rem;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 1.6rem;
                        line-height: 170%;
                        display: flex;
                        align-items: flex-end;
                        justify-content: space-between;
                        color: #ffffff;
                        padding-bottom: 24px;
                    }
                }
            }
        }

        &:hover {
            .mega-menu {
                visibility: visible;
                opacity: 1;
                // transition-delay: 0.5s;
            }

            background-color: #fe6f42;

            a {
                color: #fff;
            }
        }
    }
}

.brand-container,
.brand-container-mobile {
    grid-column: 1/-1;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    //padding: 2.4rem 0;
    height: 12rem;
    border-bottom: 1px solid rgba($color: #0c0d1e, $alpha: 0.05);
    margin-bottom: 2.5rem;

    @media (max-width: 768px) {
        border-bottom: 1px solid rgba($color: #0c0d1e, $alpha: 0.05);
        margin-bottom: 1.2rem;
        height: 7.2rem;
    }

    @media (max-width: 600px) {
        height: 9rem;
        margin-bottom: 1.5rem;
    }

    .image-container {
        width: 7.2rem;
        height: 7.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;

        img {
            border: 2px solid rgba(12, 13, 30, 0.1);
            border-radius: 50%;
            width: 7.2rem;
            height: 7.2rem;
            object-fit: cover;
            transition: all 0.3s;

            &:hover {
                border: 2px solid #fe6f42;
                box-shadow: 0px 0px 25px rgba($color: #fe6f42, $alpha: 0.5);
            }
        }

        @media (max-width: 768px) {
            width: 4.8rem;
            height: 4.8rem;

            img {
                border-radius: 50%;
                width: 4.8rem;
                height: 4.8rem;
                object-fit: cover;
            }
        }

        @media (max-width: 600px) {
            width: 6rem;
            height: 6rem;

            img {
                border-radius: 50%;
                width: 6rem;
                height: 6rem;
                object-fit: cover;
            }
        }
    }
}

.brand-container-mobile {
    display: flex;
    width: auto;

    @media (max-width: 900px) {
        display: flex;
    }
}

.brand-container {
    grid-column: 1/-1;
    display: none;
    // @media  (max-width: 900px) {
    //     display: none;
    // }
}

#brand-carousel {
    width: 100%;
    position: relative;
    max-width: 1128px;
    height: 100%;

    .swiper-button-next {
        z-index: 1;
        display: block;
        background-image: url("../public/assets/img/chevron-back.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 24px;
        height: 24px;
        position: absolute;
        right: 0;
        top: 33%;
        cursor: pointer;
        transform: translateY(50%);

        &.swiper-button-disabled {
            opacity: 0;
        }

        &:after {
            display: none;
        }

        @media (max-width: 900px) {
            display: block;
        }
    }

    .swiper-button-prev {
        display: block;
        z-index: 1;
        background-image: url("../public/assets/img/chevron-back.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 33%;
        transform: scaleX(-1) translateY(50%);
        cursor: pointer;
        left: 0;

        &.swiper-button-disabled {
            opacity: 0;
        }

        &:after {
            display: none;
            background: #fe6f42;
        }

        @media (max-width: 768px) {
            display: block;
        }
    }
}

.swiper-slide {
    background: none;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.swiper-wrapper {
    padding-inline-start: 0;
    width: 100%;

}

#main {
    max-height: 648px;
    max-width: 460px;
    height: auto;
    width: 100%;
    margin-left: 0;

    @media (max-width: 768px) {
        width: 100%;
        height: auto;
        max-width: 100%;
    }

    .swiper-button-prev {
        display: none;
        background-image: url("../public/assets/img/chevron-back.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 16px;
        height: 16px;
        transform: rotate(-90deg);
        position: absolute;
        left: 10px !important;

        &:after {
            display: none;
        }

        @media (max-width: 768px) {
            display: block;
        }
    }

    .swiper-button-next {
        display: none;
        background-image: url("../public/assets/img/chevron-back.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 16px;
        height: 16px;
        transform: rotate(90deg);
        position: absolute;
        right: 0;

        &:after {
            display: none;
        }

        @media (max-width: 768px) {
            display: block;
        }
    }

    .swiper-slide {
        width: 100%;
        height: auto;

        @media (max-width: 768px) {
            width: 28.4rem;
            height: 60vh;
        }

        @media (max-width: 600px) {
            width: 100%;
            height: 80vh;
        }

        @media (max-width: 400px) {
            width: 100%;
            height: auto;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: top;

            @media (max-width: 768px) {
                height: 60vh;
                object-fit: contain;
                object-position: top;
            }

            @media (max-width: 600px) {
                height: 70vh;
                object-fit: contain;
                object-position: top;
            }

            @media (max-width: 400px) {
                height: auto;
                object-fit: cover;
                object-position: top;
            }
        }
    }
}

#thumbs {
    width: 72px;
    height: 100%;
    margin-right: 2.4rem;
    margin-left: 0;

    @media (max-width: 768px) {
        display: none;
    }

    .swiper-slide {
        height: 98px;
        width: 72px;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}

#carousel-custom {
    // grid-column: 1/7;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: 648px;
    padding-bottom: 10px;

    //height: 492px;
    @media (max-width: 768px) {
        // grid-column: 1/-1;
        height: auto;
    }

    .loader-container {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

// .magnifier {
//   position: unset;

//   .example-class {
//     width: 6000;
//     height: 60px;
//   }

//   .zoom {
//     width: 100px;
//     height: 100px;
//     position: absolute;
//   }

//   .preview {
//     position: absolute;
//     top: 40px;
//     left: 50px;
//     height: 100%;
//     width: 100%;
//   }
// }

#large-slide {
    display: block;

    @media (max-width: 768px) {
        display: none;
    }
}

#small-slide {
    display: none;

    @media (max-width: 768px) {
        display: block;
    }
}

.main-banner {
    grid-column: 1/9;

    @media (max-width: 768px) {
        grid-column: 1/-1;
    }

    .banner {
        display: block;
        width: 100%;
        height: auto;
        //border-radius: 0.8rem;
        margin-bottom: 2.4rem;
        cursor: pointer;

        @media (max-width: 768px) {
            height: auto;
            margin-bottom: 8px;
        }

        @media (max-width: 600px) {
            height: auto;
        }

        @media (max-width: 450px) {
            height: auto;
        }

        img {
            height: auto;
            width: 100%;
            max-height: 24rem;

            //background-size: cover;
            //border-radius: 0.8rem;
            @media (max-width: 768px) {
                height: auto;
            }

            @media (max-width: 600px) {
                height: auto;
            }

            @media (max-width: 450px) {
                height: auto;
            }
        }
    }
}

.home {
    width: 100%;
    grid-column: 1/-1;
    width: 112.8rem;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 0 2.4rem;
    margin: 0 auto;
    padding-bottom: 15rem;

    @media screen and (max-width: 1128px) {
        width: 100%;
        padding: 0 2.4rem;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        padding: 0 1.2rem;
    }

    @media screen and (max-width: 600px) {
        width: 100%;
        padding: 0 1.5rem;
    }
}

#category-carousel {
    grid-column: 1/-1;
    margin: 0 auto;
    display: none;
    height: 5.5rem;
    align-items: center;
    width: 100%;
    border-top: 1px solid rgba($color: #05061e, $alpha: 0.07);
    border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);
    border-left: 1px solid rgba($color: #05061e, $alpha: 0.07);
    margin-top: 1.2rem;

    @media screen and (max-width: 768px) {
        display: flex;
    }

    @media screen and (max-width: 600px) {
        height: 6rem;
        margin-top: 1.5rem;
    }

    .swiper-wrapper {
        display: flex;
        align-items: center;
        width: 100%;

        .swiper-slide {
            width: auto;
            height: auto;

            &:last-child {
                a {
                    height: 5.5rem;

                    @media screen and (max-width: 600px) {
                        height: 6rem;
                    }
                }
            }

            a {
                width: auto;
                font-style: normal;
                font-weight: 500;
                padding: 0 2.5rem;
                font-size: 1.4rem;
                line-height: 170%;
                display: flex;
                align-items: center;
                color: rgba($color: #05061e, $alpha: 0.75);
                text-transform: uppercase;
                border-right: 1px solid rgba($color: #05061e, $alpha: 0.07);

                @media screen and (max-width: 600px) {
                    font-size: 1.5rem;
                }
            }
        }
    }
}

// Yeni elave
#category-carousel2 {
    grid-column: 1/-1;
    margin: 0 auto;
    display: none;
    height: 5.5rem;
    align-items: center;
    width: 100%;
    border-top: 1px solid rgba($color: #05061e, $alpha: 0.07);
    border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);
    border-left: 1px solid rgba($color: #05061e, $alpha: 0.07);
    margin-top: 1.2rem;

    @media screen and (min-width: 768px) and (max-width: 1024px) {
        display: flex;
    }

    @media screen and (max-width: 600px) {
        height: 6rem;
        margin-top: 1.5rem;
    }

    .swiper-wrapper {
        display: flex;
        align-items: center;
        width: 100%;

        .swiper-slide {
            width: auto;
            height: auto;

            &:last-child {
                a {
                    border-right: none;
                }
            }

            a {
                width: auto;
                font-style: normal;
                font-weight: 500;
                padding: 0 2.5rem;
                font-size: 1.4rem;
                line-height: 170%;
                display: flex;
                align-items: center;
                color: rgba($color: #05061e, $alpha: 0.75);
                text-transform: uppercase;
                border-right: 1px solid rgba($color: #05061e, $alpha: 0.07);

                @media screen and (max-width: 600px) {
                    font-size: 1.5rem;
                }
            }

            .navlist-tablet {
                width: 100%;
            }

            .navlist-item {
                &:hover {
                    .mega-menu {
                        visibility: visible;
                    }

                    background-color: #fe6f42;

                    a {
                        color: #fff;
                    }
                }
            }
        }
    }
}

//yeni elave son

.banner-overlay {
    grid-column: 1/-1;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 9999999999999;
    padding: 0;
    background: rgba($color: #0c0d1e, $alpha: 0.5);

    @media screen and (max-width: 768px) {
        display: block;
    }

    .img-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        // img {
        // }
        button {
            position: absolute;
            top: 12px;
            right: 12px;
            border: none;
            outline: none;
            background: none;
            width: 20px;
            height: 20px;

            img {
                width: 20px;
                height: 20px;
                object-fit: cover;
            }
        }
    }
}

.main-banner {
    grid-column: 1/9;

    @media (max-width: 768px) {
        grid-column: 1/-1;
    }

    .banner {
        display: block;
        width: 100%;
        height: auto;
        //border-radius: 0.8rem;
        margin-bottom: 2.4rem;
        cursor: pointer;

        @media (max-width: 768px) {
            height: auto;
            margin-bottom: 8px;
        }

        @media (max-width: 600px) {
            height: auto;
        }

        @media (max-width: 450px) {
            height: auto;
        }

        img {
            height: auto;
            width: 100%;
            max-height: 24rem;

            //background-size: cover;
            //border-radius: 0.8rem;
            @media (max-width: 768px) {
                height: auto;
            }

            @media (max-width: 600px) {
                height: auto;
            }

            @media (max-width: 450px) {
                height: auto;
            }
        }
    }
}

.side-banner {
    grid-column: 9/-1;

    @media (max-width: 768px) {
        grid-column: 1/-1;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0 1rem;
    }

    .banner {
        display: block;
        width: 100%;
        height: auto;
        //border-radius: 0.8rem;
        margin-bottom: 2.4rem;
        cursor: pointer;

        @media (max-width: 768px) {
            height: auto;
            margin-bottom: 1rem;
        }

        @media (max-width: 450px) {
            height: auto;
        }

        img {
            height: auto;
            width: 100%;
            //object-fit: cover;
            //border-radius: 0.8rem;

            @media (max-width: 768px) {
                height: auto;
            }

            @media (max-width: 450px) {
                height: auto;
            }
        }
    }
}

.footer {
    width: 100%;
    height: 46.6rem;
    border-top: 1px solid rgba($color: #05061e, $alpha: 0.03);

    @media (max-width: 768px) {
        height: auto;
    }

    &-wrapper {
        width: 112.8rem;
        height: auto;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-gap: 0 2.4rem;
        margin: 0 auto;
        padding: 3.2rem 0;

        @media (max-width: 1128px) {
            padding: 3.2rem 2.4rem;
            width: 100%;
        }

        @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            padding-left: 1.2rem;
            padding-right: 1.2rem;
            padding-bottom: 0;
        }

        @media (max-width: 600px) {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }

        .footer-contact {
            width: 100%;
            grid-column: 1/3;

            @media (max-width: 768px) {
                padding-bottom: 3.2rem;
            }

            .logo-container {
                width: 13.1rem;
                height: 2rem;
                margin-bottom: 3.2rem;

                @media (max-width: 768px) {
                    margin-bottom: 8px;
                    width: 9.6rem;
                    height: 1.5rem;
                }

                @media (max-width: 600px) {
                    width: 13rem;
                    height: 1.875rem;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .content {
                font-style: normal;
                font-weight: normal;
                font-size: 1.6rem;
                line-height: 170%;
                display: flex;
                align-items: center;
                color: rgba($color: #05061e, $alpha: 0.5);
                margin-bottom: 3.2rem;

                @media (max-width: 600px) {
                    margin-bottom: 2rem;
                    font-size: 1.5rem;
                }
            }

            .contact-box {
                width: 100%;
                border: 1px dashed rgba($color: #05061e, $alpha: 0.2);
                border-radius: 4px;
                padding-top: 3.2rem;
                padding-bottom: 1.6rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;

                @media (max-width: 768px) {
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    padding: 1.4rem;
                }

                @media (max-width: 768px) {
                    padding: 1.75rem;
                }

                .top {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding-bottom: 2.4rem;

                    @media (max-width: 768px) {
                        flex-direction: row;
                        padding-bottom: 0;
                    }

                    .img-container {
                        width: 3.2rem;
                        height: 3.2rem;
                        margin-bottom: 1.6rem;

                        @media (max-width: 768px) {
                            width: 1.2rem;
                            height: 1.2rem;
                            margin-bottom: 0;
                            margin-right: 1.6rem;
                        }

                        @media (max-width: 600px) {
                            width: 1.5rem;
                            height: 1.5rem;
                            margin-right: 2rem;
                        }

                        svg {
                            width: 3.2rem;
                            height: 3.2rem;
                            object-fit: cover;

                            @media (max-width: 768px) {
                                width: 1.2rem;
                                height: 1.2rem;
                                object-fit: cover;
                            }

                            @media (max-width: 600px) {
                                width: 1.5rem;
                                height: 1.5rem;
                                object-fit: cover;
                            }
                        }
                    }

                    .phone-number {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 1.4rem;
                        line-height: 170%;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        color: rgba($color: #05061e, $alpha: 0.75);

                        @media (max-width: 600px) {
                            font-size: 1.5rem;
                        }
                    }
                }

                .bottom {
                    width: 100%;
                    border-top: 1px solid rgba($color: #05061e, $alpha: 0.07);
                    padding-top: 1.2rem;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 170%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    color: rgba($color: #05061e, $alpha: 0.5);
                    position: relative;

                    @media (max-width: 768px) {
                        border-top: none;
                        padding-top: 0;

                        &::before {
                            content: "";
                            width: 1px;
                            height: 24px;
                            background-color: rgba($color: #05061e, $alpha: 0.07);
                            position: absolute;
                            left: 0;
                        }
                    }

                    @media (max-width: 600px) {
                        font-size: 1.5rem;
                    }
                }
            }
        }

        .footer-categories {
            width: 100%;
            grid-column: 4/8;

            @media (max-width: 768px) {
                padding-bottom: 3.2rem;
            }

            .header {
                font-style: normal;
                font-weight: 600;
                font-size: 1.6rem;
                line-height: 170%;
                color: rgba($color: #05061e, $alpha: 0.75);
                margin-bottom: 3.2rem;

                @media (max-width: 600px) {
                    font-size: 2rem;
                    margin-bottom: 2.5rem;
                }
            }

            .footer-cat_list {
                display: flex;
                flex-wrap: wrap;

                li {
                    width: 50%;
                    margin-bottom: 2rem;

                    @media (max-width: 600px) {
                        width: 40%;
                    }

                    a {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 1.4rem;
                        line-height: 170%;
                        display: flex;
                        align-items: center;
                        color: rgba($color: #05061e, $alpha: 0.75);

                        @media (max-width: 600px) {
                            font-size: 1.75rem;
                        }
                    }
                }
            }
        }

        .footer-brands {
            width: 100%;
            grid-column: 8/11;

            @media (max-width: 768px) {
                padding-bottom: 3.2rem;
            }

            .header {
                font-style: normal;
                font-weight: 600;
                font-size: 1.6rem;
                line-height: 170%;
                color: rgba($color: #05061e, $alpha: 0.75);
                margin-bottom: 3.2rem;

                @media (max-width: 600px) {
                    font-size: 2rem;
                    margin-bottom: 2.5rem;
                }
            }

            .footer-brand_list {
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: repeat(7, 1fr);
                grid-auto-flow: column;
                grid-gap: 2rem 2.4rem;

                @media (max-width: 600px) {
                    grid-gap: 12px 8px;
                }

                li {
                    a {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 1.4rem;
                        line-height: 170%;
                        display: flex;
                        align-items: center;
                        color: rgba($color: #05061e, $alpha: 0.75);

                        @media (max-width: 600px) {
                            font-size: 1.75rem;
                        }
                    }
                }
            }
        }

        .footer-info {
            grid-column: 11/-1;
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;

            @media (max-width: 768px) {
                width: 100%;
                justify-content: center;
            }

            &_card {
                padding: 2.4rem;
                background: rgba(5, 6, 30, 0.03);
                border-radius: 4px;

                @media (max-width: 768px) {
                    width: 100%;
                }

                @media (max-width: 600px) {
                    padding: 3rem 2rem 3.5rem 2rem;
                }

                .header {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 1.6rem;
                    line-height: 170%;
                    color: rgba($color: #05061e, $alpha: 0.75);
                    margin-bottom: 3.2rem;

                    @media (max-width: 600px) {
                        font-size: 2rem;
                        margin-bottom: 2.5rem;
                    }
                }

                .footer_links {
                    @media (max-width: 768px) {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-template-rows: repeat(2, 1fr);
                        grid-auto-flow: column;
                        grid-gap: 12px 8px;
                    }

                    li {
                        margin-bottom: 2rem;

                        @media (max-width: 768px) {
                            margin-bottom: 0;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }

                        a {
                            display: inline-block;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 1.4rem;
                            line-height: 170%;
                            display: flex;
                            align-items: center;
                            color: rgba($color: #05061e, $alpha: 0.75);

                            @media (max-width: 600px) {
                                font-size: 1.75rem;
                            }
                        }
                    }
                }

                .send-message {
                    margin-top: 4.4rem;
                    background-color: #fff;
                    border-radius: 4px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 170%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: rgba($color: #05061e, $alpha: 0.75);
                    height: 4.8rem;
                    width: 14.4rem;
                    position: relative;
                    margin-bottom: 2.4rem;

                    @media (max-width: 768px) {
                        width: 100%;
                        font-size: 1.75rem;
                        height: 6rem;
                        margin-top: 4rem;
                        margin-bottom: 3rem;
                    }

                    svg {
                        width: 1.6rem;
                        height: 1.6rem;
                        object-fit: cover;
                        position: absolute;
                        top: 50%;
                        left: 4%;
                        transform: translateY(-50%);

                        @media (max-width: 600px) {
                            width: 2rem;
                            height: 2rem;
                        }
                    }
                }

                .social-icons {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    a {
                        display: flex;
                        align-items: center;

                        svg {
                            width: 2.4rem;
                            height: 2.4rem;
                            object-fit: cover;

                            @media (max-width: 600px) {
                                width: 3rem;
                                height: 3rem;
                            }
                        }

                        &:not(:last-child)::after {
                            content: "";
                            width: 1px;
                            height: 2.4rem;
                            background-color: rgba($color: #05061e, $alpha: 0.07);
                            margin: 0 12px;
                        }
                    }
                }
            }
        }
    }

    &-mini {
        width: 112.8rem;
        height: auto;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        align-content: center;
        grid-gap: 0 2.4rem;
        margin: 0 auto;
        padding: 3.2rem 0;
        border-top: 1px solid rgba($color: #05061e, $alpha: 0.07);

        @media (max-width: 1128px) {
            padding: 3.2rem 2.4rem;
            width: 100%;
        }

        @media (max-width: 768px) {
            padding: 2rem 1.2rem;
            grid-gap: 32px 0;
            border-top: none;
        }

        @media (max-width: 600px) {
            padding: 2.5rem 1.5rem;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }

        .footer-mini-copyright {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            grid-column: 1/4;
            font-style: normal;
            font-weight: normal;
            font-size: 1.2rem;
            line-height: 170%;
            color: rgba($color: #05061e, $alpha: 0.5);

            @media (max-width: 768px) {
                grid-column: 1/-1;
                order: 1;
                flex-direction: row;
                font-size: 1.5rem;
            }
        }

        .footer-mini-unitedskills {
            grid-column: 4/11;

            @media (max-width: 768px) {
                grid-column: 1/7;
            }
        }

        .footer-mini-visa {
            grid-column: 11/-1;
            justify-self: end;
            align-self: center;

            @media (max-width: 768px) {
                grid-column: 7/-1;
            }
        }
    }
}

.breadcrumb {
    width: 100%;
    grid-column: 1/-1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    position: relative;

    &.border {
        border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);
        height: 54px;
    }

    button {
        display: none;
    }

    @media (max-width: 1128px) {
        padding-left: 2.4rem;
        padding-right: 2.4rem;
    }

    @media (max-width: 768px) {
        padding-left: 1.2rem;
        padding-right: 1.2rem;
        justify-content: flex-end;

        button {
            display: block;
            position: absolute;
            left: 1.6rem;
            top: 0;
            bottom: 0;
            margin: auto;
            padding: 0;
            outline: 0;
            background-color: transparent;
            border: none;

            svg {
                width: 3.1rem;
                height: 2.2rem;
            }
        }
    }

    @media (max-width: 600px) {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .main-breadcrumb {
        font-style: normal;
        font-weight: normal;
        font-size: 1.4rem;
        line-height: 170%;
        display: flex;
        align-items: center;
        color: rgba($color: #05061e, $alpha: 0.75);
        margin-right: 4px;

        @media (max-width: 600px) {
            font-size: 1.2rem;
        }
    }

    .secondary-breadcrumb,
    .primary-breadcrumb {
        font-style: normal;
        font-weight: normal;
        font-size: 1.4rem;
        line-height: 170%;
        display: flex;
        align-items: center;
        color: rgba($color: #05061e, $alpha: 0.5);
        text-transform: capitalize;

        @media (max-width: 600px) {
            font-size: 1.2rem;
        }

        svg {
            width: 10px;
            height: 10px;
            margin-right: 4px;
        }
    }

    .primary-breadcrumb {
        color: rgba($color: #05061e, $alpha: 0.75);
        margin-right: 4px;
    }
}

@media (max-width: 767.9px) {
    .breadcrumb {
        position: sticky;
        left: 0;
        top: 60px;
        z-index: 9999;
        background: #fff;
    }
}

.product-list {
    width: 112.8rem;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 0 2.4rem;
    margin: 0 auto;
    padding-bottom: 15rem;

    @media screen and (max-width: 1128px) {
        width: 100%;
    }

    @media screen and (max-width: 768px) {
        .breadcrumb.border {
            border-bottom: none;
        }
    }
}

.product-list_top {
    grid-column: 1/-1;
    height: 9.4rem;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 0 2.4rem;
    margin: 0 auto;
    align-content: center;

    @media (max-width: 1128px) {
        padding: 0 2rem;
    }

    @media (max-width: 768px) {
        display: none;
    }

    .result {
        grid-column: 1/4;
        font-style: normal;
        font-weight: 500;
        font-size: 2.4rem;
        line-height: 170%;
        color: #fe6f42;
    }

    .result-category-name {
        grid-column: 4/-1;
        font-style: normal;
        font-weight: 500;
        font-size: 2.4rem;
        line-height: 170%;
        display: flex;
        align-items: center;
        color: #05061e;
        text-transform: capitalize;
    }
}

.product-list_top-mobile {
    grid-column: 1/-1;
    display: none;
    flex-direction: column;
    padding: 0 2rem;

    @media (max-width: 768px) {
        display: flex;
        padding: 0 1.2rem;
    }

    @media (max-width: 600px) {
        padding: 0 1.5rem;
    }

    .result {
        margin-bottom: 2.4rem;

        @media (max-width: 600px) {
            margin-bottom: 1rem;
        }

        h1 {
            font-style: normal;
            font-weight: 500;
            font-size: 2.5rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            color: #05061e;
            margin-bottom: 4px;
        }

        span {
            font-style: normal;
            font-weight: 500;
            font-size: 1.75rem;
            line-height: 170%;
            color: #fe6f42;
        }
    }
}

.question {
    width: 100%;
    border-top: 1px solid rgba($color: #0c0d1e, $alpha: 0.1);
    border-bottom: 1px solid rgba($color: #0c0d1e, $alpha: 0.1);
    cursor: pointer;
}

.question h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 170%;
    color: rgba($color: #0c0d1e, $alpha: 0.75);

    @media (max-width: 600px) {
        font-size: 1.75rem;
    }
}

.question .acc-cont {
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 170%;
    color: rgba($color: #0c0d1e, $alpha: 0.5);
    padding-bottom: 1.6rem;

    @media (max-width: 600px) {
        font-size: 1.5rem;
    }

    &::-webkit-scrollbar {
        width: 2.4rem;
    }

    &::-webkit-scrollbar-track {
        margin-bottom: 2.4rem;
        border-radius: 0.2rem;
        height: 100%;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba($color: #0c0d1e, $alpha: 0.1);
        border-radius: 1.6rem;
        border: 10px solid transparent;
        background-clip: padding-box;
    }

    &::-webkit-scrollbar-track-piece {
        background: rgba($color: #0c0d1e, $alpha: 0.1);
        background: transparent;
    }

    .popup-buy-as-guest {
        .container {
            .header {
                display: none;
            }

            .body {
                padding: 0;
                margin-top: 0;

                .fast-buy-inputs {
                    margin: 0;
                }
            }

            .footer-popup {
                position: relative;
                padding: 0;
            }
        }
    }
}

.question header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5.7rem;

    .header-wrapper {
        display: flex;
        align-items: center;

        svg {
            margin-right: 12px;
        }
    }
}

.question header h4 {
    margin-bottom: 0;
}

.btn {
    background: transparent;
    border-color: transparent;
    width: 1.6rem;
    height: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    align-self: center;
    transition: all 0.3s;
    outline: none;

    @media (max-width: 600px) {
        width: 2rem;
        height: 2rem;
    }

    svg {
        width: 1.6rem;
        height: 1.6rem;
        object-fit: cover;

        @media (max-width: 600px) {
            width: 2rem;
            height: 2rem;
        }
    }
}

.question.acc-side {
    width: 100%;
    border: none;
    padding: 0;
    border-bottom: 1px solid rgba($color: #0c0d1e, $alpha: 0.1);
    border-radius: 0;
    margin-bottom: 0;

    h4 {
        font-weight: 400;
    }

    p {
        max-height: 25rem;
        overflow-y: auto;
    }

    .btn {
        height: 2rem;
        width: 2rem;
    }

    .checkbox-group {
        padding-bottom: 1.2rem;

        &:last-child {
            padding-bottom: 0;
        }
    }
}

.product-body,
.product-body.store {
    grid-column: 1/-1;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 0 2.4rem;
    margin: 0 auto;

    @media (max-width: 1128px) {
        width: 100%;
        padding: 0 2rem;
    }

    @media (max-width: 768px) {
        padding: 0;
    }

    .side {
        grid-column: 1/4;
        height: auto;
        width: 100%;
        //border-top: 1px solid rgba($color: #0c0d1e, $alpha: 0.1);
        //border-bottom: 1px solid red;

        @media (max-width: 768px) {
            display: none;
        }
    }

    .main {
        grid-column: 4/-1;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(264px, 1fr));
        grid-gap: 0 2.4rem;
        align-content: flex-start;
        height: fit-content;

        @media (min-width: 769px) and (max-width: 950px) {
            grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        }

        @media (max-width: 768px) {
            grid-column: 1/-1;
            padding: 0 1.2rem;
            grid-gap: 0 8px;
        }

        @media (max-width: 600px) {
            grid-column: 1/-1;
            padding: 0 1.5rem;
            grid-template-columns: repeat(auto-fit, minmax(128px, 1fr));
        }

        .product-card-parent {
            .product-card {
                .product-img {
                    @media (max-width: 768px) {
                        height: 30rem;
                    }
                }
            }
        }

        .main_filters {
            grid-column: 1/-1;
            height: 6.7rem;
            width: 100%;
            margin-bottom: 2.4rem;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            align-content: center;
            border: 1px solid rgba($color: #05061e, $alpha: 0.07);
            padding: 1.8rem 1.6rem;

            @media (max-width: 768px) {
                display: none;
            }

            .checkbox-group {
                grid-column: 1/2;
                border-right: 1px solid rgba($color: #05061e, $alpha: 0.07);
                height: 3.2rem;
                align-self: center;
                display: flex;
                align-items: center;
            }

            .empty-grid {
                grid-column: 2/3;
                width: 100%;
                border-right: 1px solid rgba($color: #05061e, $alpha: 0.07);
            }
        }
    }

    .side-bar-menu_mobile {
        display: none;
        grid-column: 1/-1;
        width: 100%;
        justify-content: space-around;
        padding: 0 2rem;
        height: auto;
        background-color: #fff;
        height: 6.4rem;

        @media (max-width: 768px) {
            display: flex;
            padding: 1.2rem;
            top: 114px;
            position: sticky;
            z-index: 999999;
            border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);
        }

        @media (max-width: 600px) {
            display: flex;
            padding: 1.5rem;
            height: 8rem;
            align-items: center;
        }

        .mobile-arrange,
        .mobile-filter {
            background: #fff;
            border: none;
            outline: none;
            font-style: normal;
            width: 100%;
            padding: 0 1.2rem;
            height: 4rem;
            margin-bottom: 2.4rem;
            margin-bottom: 3rem;
            font-style: normal;
            font-weight: normal;
            font-size: 1.4rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-align: right;
            color: rgba($color: #05061e, $alpha: 0.75);
            border: 1px solid rgba($color: #05061e, $alpha: 0.07);

            @media (max-width: 768px) {
                border-left: none;
            }

            @media (max-width: 600px) {
                height: 5rem;
                margin-bottom: 3rem;
                font-style: normal;
                font-weight: normal;
                font-size: 1.75rem;
                line-height: 170%;
                display: flex;
                align-items: center;
                text-align: right;
                color: rgba($color: #05061e, $alpha: 0.75);
                margin-bottom: 0;
            }

            span,
            img {
                display: inline-block;
            }
        }

        // .mobile-filter {
        //     margin-right: 1.6rem;
        //     @media  (max-width: 600px) {
        //        margin-right: 2rem;
        //     }
        // }

        .menu-items-btn-container {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 1.2rem;
            border-top: 1px solid rgba($color: #05061e, $alpha: 0.07);
            background-color: #fff;

            @media (max-width: 600px) {
                padding: 1.5rem;
            }
        }

        .go-back {
            width: 100%;
            margin-bottom: 0;
            border: none;
            outline: none;
            height: 4.8rem;
            background: #fe6f42;
            color: #ffffff;
            border-radius: 8px;
            font-style: normal;
            font-weight: 600;
            font-size: 1.4rem;
            line-height: 170%;
            text-align: center;

            @media (max-width: 600px) {
                height: 6rem;
                font-size: 1.75rem;
            }
        }

        .title {
            width: 100%;
            font-style: normal;
            font-weight: normal;
            font-size: 2rem;
            line-height: 170%;
            color: #05061e;
            margin-bottom: 1.2rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 1.6rem;
            border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);

            @media (max-width: 600px) {
                font-size: 2.5rem;
                margin-bottom: 1.5rem;
            }

            span {
                display: inline-block;
            }

            button {
                display: inline-block;
                border: none;
                outline: none;
                background: none;
            }
        }

        .mobile-cont {
            grid-column: 1/-1;
            height: 100vh;
            width: 100%;
            background: white;
            z-index: 999999999999999999;
            position: fixed;
            overflow-y: scroll;
            top: 60px;
            left: 0;
        }

        .mobile-menu {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            padding: 16px 12px 12px 12px;
            overflow-y: scroll;

            .menu-items {
                width: 100%;
                padding-bottom: 45px;
            }

            .color-container,
            .size-container,
            .price-container,
            .brand-container-filter,
            .sex-cat-container {
                height: 100%;
                margin-bottom: 1.2rem;

                @media (max-width: 600px) {
                    margin-bottom: 1.5rem;
                }

                h1 {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1.4rem;
                    line-height: 170%;
                    color: rgba($color: #0c0d1e, $alpha: 0.5);
                    margin-bottom: 1.6rem;

                    @media (max-width: 600px) {
                        font-size: 1.75rem;
                        margin-bottom: 2rem;
                    }
                }
            }

            .question.acc-side {
                margin-bottom: 0;
            }

            .brand-container-filter {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                padding: 0;

                .checkbox-group {
                    margin-bottom: 12px;
                }
            }

            .price-container {
                .checkbox-group {
                    margin-bottom: 12px;
                }
            }

            .size-container {
                .size-label {
                    margin-bottom: 1.5rem;
                    margin-right: 1.5rem;
                }
            }
        }
    }
}

.select-group {
    grid-column: 3/-1;
    justify-self: flex-end;
    align-self: center;
    display: flex;
    align-self: center;

    @media (max-width: 768px) {
        width: 100%;
    }


    .react-select-filter {
        height: 3.2rem;
        width: 226px;

        @media (max-width: 768px) {
            height: 4rem;
            width: 100%;
        }

        @media (max-width: 600px) {
            height: 5rem;
            width: 100%;
        }

        .react-select__control {
            min-height: 3.2rem;
            height: 3.2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            border-radius: 0;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            cursor: pointer;

            @media (max-width: 768px) {
                height: 4rem;
                display: flex;
                align-items: center;
                border: 1px solid rgba($color: #05061e, $alpha: 0.07);
                border-radius: 0;
            }

            @media (max-width: 600px) {
                height: 5rem;
            }

            .react-select__indicators {
                min-height: 3.2rem;
                height: 3.2rem;

                @media (max-width: 768px) {
                    height: auto;
                }

                .react-select__indicator {
                    @media (max-width: 600px) {
                        padding: 4px;
                    }

                    svg {
                        transition: all 0.3s;

                        path {
                            stroke: rgba($color: #05061e, $alpha: 0.75);
                        }
                    }
                }
            }

            &.react-select__control--menu-is-open {
                .react-select__indicators {
                    .react-select__indicator {
                        svg {
                            transition: all 0.3s;
                            transform: rotate(180deg);

                            path {
                                stroke: rgba($color: #05061e, $alpha: 0.75);
                            }
                        }
                    }
                }
            }

            &--is-focused {
                //border: 1px solid rgba($color: #FE6F42, $alpha: 0.5);
                box-shadow: none;
            }

            .react-select__single-value {
                font-style: normal;
                font-weight: normal;
                font-size: 1.6rem;
                line-height: 170%;
                display: flex;
                align-items: center;
                color: rgba($color: #05061e, $alpha: 0.5);
                cursor: pointer;

                @media (max-width: 600px) {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 1.75rem;
                    line-height: 170%;
                    display: flex;
                    align-items: center;
                    text-align: right;
                    color: rgba($color: #05061e, $alpha: 0.75);
                }
            }
        }

        .react-select__placeholder {
            font-size: 16px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        .react-select__value-container {
            height: 3.2rem;

            @media (max-width: 600px) {
                height: auto;
            }
        }

        .react-select__input {
            font-size: 16px;
        }

        .react-select__menu {
            &-list {
                padding: 0;
            }

            .react-select__option {
                font-style: normal;
                font-weight: normal;
                font-size: 1.6rem;
                line-height: 170%;
                display: flex;
                align-items: center;
                color: rgba($color: #05061e, $alpha: 0.75);
                padding: 8px 12px;
                border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);
                cursor: pointer;

                @media (max-width: 600px) {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 10px;
                    line-height: 170%;
                    display: flex;
                    align-items: center;
                    text-align: left;
                    color: rgba($color: #05061e, $alpha: 0.75);
                    padding: 8px;
                    white-space: nowrap;
                }

                &:hover,
                &:active {
                    background: rgba($color: #05061e, $alpha: 0.03);
                }
            }

            .react-select__option--is-selected {
                background: rgba($color: #05061e, $alpha: 0.03);

                &:after {
                    content: "";
                    background: url("../public/assets/img/tick-small.png");
                    background-repeat: no-repeat;
                    background-position: center;
                    width: 12px;
                    height: 12px;
                    margin-left: 12px;
                }
            }
        }

        .css-b8ldur-Input {
            padding: 0;
            margin: 0;
        }

        .react-select__indicator-separator {
            display: none;
        }
    }
}

// .select-group.second {

//   .react-select__control {

//     .react-select__single-value  {
//       font-style: normal;
//       font-weight: normal;
//       font-size: 1.4rem;
//       line-height: 170%;
//       display: flex;
//       align-items: center;
//       text-align: right;
//       color: rgba($color: #05061e, $alpha: 0.75);
//     }
//   }

// }

.product-body.store {
    padding: 0;

    #mobile-filter {
        border: 1px solid rgba(5, 6, 30, 0.07);
    }
}

.question {
    width: 100%;
    border-top: 1px solid rgba($color: #0c0d1e, $alpha: 0.1);
    border-bottom: 1px solid rgba($color: #0c0d1e, $alpha: 0.1);
}

.question h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 2rem;
    line-height: 170%;
    display: flex;
    align-items: center;
    color: #05061e;
}

.question p.acc-content {
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 170%;
    color: rgba($color: #05061e, $alpha: 0.75);
    background: rgba($color: #05061e, $alpha: 0.03);
    padding: 2.4rem 1.6rem;

    &::-webkit-scrollbar {
        width: 2.4rem;
    }

    &::-webkit-scrollbar-track {
        margin-bottom: 2.4rem;
        border-radius: 0.2rem;
        height: 100%;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba($color: #0c0d1e, $alpha: 0.1);
        border-radius: 1.6rem;
        border: 10px solid transparent;
        background-clip: padding-box;
    }

    &::-webkit-scrollbar-track-piece {
        background: rgba($color: #0c0d1e, $alpha: 0.1);
        background: transparent;
    }
}

.question .acc-cont {
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 170%;
    color: rgba($color: #05061e, $alpha: 0.75);
    background: rgba($color: #05061e, $alpha: 0.03);
    padding: 2.4rem 1.6rem;
}

.question header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 6.6rem;
    padding: 0 1.6rem;
}

.question header h4 {
    margin-bottom: 0;
}

.btn {
    background: transparent;
    border-color: transparent;
    width: 1.6rem;
    height: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    align-self: center;
    transition: all 0.3s;
    outline: none;

    @media (max-width: 600px) {
        width: 2rem;
        height: 2rem;
    }

    svg {
        width: 1.6rem;
        height: 1.6rem;
        object-fit: cover;

        @media (max-width: 600px) {
            width: 2rem;
            height: 2rem;
        }
    }
}

.question.acc-side {
    width: 100%;
    border: none;
    padding: 0;
    border: 1px solid rgba($color: #05061e, $alpha: 0.07);
    border-radius: 0;
    margin-bottom: 2.4rem;

    header {
        padding: 1.6rem 2.4rem;
        border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);
    }

    .acc-cont {
        padding: 2.4rem 0 2.4rem 2.4rem;
        background: #fff;
    }

    h4 {
        font-weight: 400;
    }

    p {
        max-height: 25rem;
        overflow-y: auto;
    }

    .btn {
        height: 2rem;
        width: 2rem;
    }

    .checkbox-group {
        padding-bottom: 1.2rem;
    }
}

.question.combin {
    .acc-cont {
        padding: 0;
        background-color: #fff;
    }
}

.checkbox-group {
    display: block;
}

.checkbox {
    display: none;
}

.checkBoxLabel {
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 170%;
    display: flex;
    align-items: center;
    color: rgba($color: #05061e, $alpha: 0.5);

    &:before {
        content: "";
        display: inline-block;
        width: 16px;
        height: 16px;
        border: 1px solid rgba($color: #05061e, $alpha: 0.5);
        margin-right: 1.2rem;
        background: url("../public/assets/img/checked.svg") no-repeat center;
    }

    @media (max-width: 600px) {
        font-size: 1.75rem;
    }
}

.checkbox:checked+.checkBoxLabel:before {
    background-color: #05061e;
    border: 1px solid #05061e;
}

.checkbox:checked+.checkBoxLabel {
    color: #05061e;
}

/* radio */
.color-label {
    height: 3.2rem;
    width: 3.2rem;
    display: inline-block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: 1.5rem;

    @media (max-width: 600px) {
        height: 3rem;
        width: 3rem;
        margin-right: 2rem;
        margin-bottom: 1.6rem;
    }
}

.color-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.color-label input:checked~.checkmark {
    border: 1px solid #000;
}

.color-label input:checked~.checkmark:after {
    display: block;
}

.color-label .checkmark:after {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
}

.size-label {
    margin-right: 1.6rem;
    display: inline-block;
    margin-bottom: 1.6rem;

    @media (max-width: 768px) {
        margin-bottom: 0;
    }
}

.size-radio {
    display: none;
}

.size-button {
    cursor: pointer;
    background: #ffffff;
    border: 1px solid rgba($color: #05061e, $alpha: 0.1);
    box-sizing: border-box;
    padding: 0.4rem 0.9rem;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 170%;
    color: rgba($color: #05061e, $alpha: 0.5);
    min-width: 4.2rem;
    width: 100%;
    height: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 600px) {
        font-size: 1.75rem;
        min-width: 5.25rem;
        height: 4rem;
    }
}

.size-radio:checked+.size-button {
    border: 1px solid rgba(12, 13, 30, 0.75);
    box-sizing: border-box;
}

.size-radio:disabled+span {
    text-decoration: line-through;
    opacity: 0.5;
}

.product-card {
    display: block;
    margin-bottom: 3.2rem;

    @media (max-width: 600px) {
        margin-bottom: 8px;
    }
}

.product-card .product-img {
    width: 100%;
    height: 36.2rem;
    position: relative;

    @media (max-width: 768px) {
        height: 20.4rem;
    }

    @media (max-width: 600px) {
        height: 30.25rem;
    }
}

.product-card .product-img a {
    width: 100%;
    height: auto;
}

.product-card .product-img a img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}

.product-discount-rec {
    display: none;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 24px;
    position: absolute;
    background: url("../public/assets/img/mb2-discount-rectangle.svg");
    background-repeat: no-repeat;
    height: 4.8rem;
    width: 4.8rem;
    font-style: normal;
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 130%;
    color: #ffffff;

    @media (max-width: 600px) {
        font-size: 1rem;
        width: 4.5rem;
        height: 4.5rem;
        background: url("../public/assets/img/mb2-discount-rectangle-mob.svg");
        left: 8px;
        font-size: 1.5rem;
    }
}

.product-discount-rec.active {
    display: flex;
}

.product-card-parent {
    position: relative;
}

/* like button */
.product-heart {
    position: absolute;
    top: 1.2rem;
    right: 1.2rem;
    background: #ffffff;
    border: 1px solid rgba(12, 13, 30, 0.1);
    border-radius: 50%;
    width: 4.8rem;
    height: 4.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media (max-width: 600px) {
        width: 4.5rem;
        height: 4.5rem;
        top: 8px;
        right: 8px;
    }
}

.product-heart svg {
    width: 2.4rem;
    height: 2.4rem;
    /* opacity: .5; */

    @media (max-width: 600px) {
        width: 2.5rem;
        height: 2.5rem;
    }
}

.str0 {
    stroke: rgba(12, 13, 30, 0.5);
}

.like-button {
    display: none;
}

.fil0 {
    fill: #fff;
}

.like-button:checked+svg {
    g {
        opacity: 1;
    }

    .fil0 {
        fill: #ff2121;
        stroke: #ff2121;
    }
}

/* like button ///*/

.product-card-details {
    padding: 0 1.6rem;
    border: 1px solid rgba(18, 18, 29, 0.07);

    @media (max-width: 600px) {
        padding: 0 1rem;
    }

    .product-card_footer-info {
        padding-top: 1.2rem;
        padding-bottom: 1.6rem;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 600px) {
            padding: 1.5rem 0;
        }

        .free-delivery {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (max-width: 600px) {
                justify-content: flex-start;
            }

            .delivery-text {
                font-style: normal;
                font-weight: 500;
                font-size: 1.4rem;
                line-height: 130%;
                display: flex;
                align-items: center;
                color: #05b136;

                @media (max-width: 600px) {
                    font-size: 1.2rem;
                    order: 2;
                }
            }

            svg {
                width: 2.4rem;
                height: 2.4rem;
                object-fit: cover;

                @media (max-width: 600px) {
                    order: 1;
                    width: 1.5rem;
                    height: 1.5rem;
                    margin-right: 8px;
                }
            }
        }

        .out-of-stock {
            display: none;
            width: 100%;
            //display: flex;
            align-items: center;
            justify-content: center;
            font-style: normal;
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 170%;
            text-align: right;
            color: #ff2121;
        }
    }
}

.product-card-bottom {
    height: auto;
    padding-top: 1.2rem;
    padding-bottom: 1.6rem;
    border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.1);

    @media (max-width: 600px) {
        padding-top: 4px;
        padding-bottom: 8px;
    }
}

.product-card-details .brand-name {
    width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.6rem 0;
    border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.1);

    @media (max-width: 600px) {
        padding: 8px 0 6px 0;
    }

    .brand-name-text {
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 170%;
        display: flex;
        align-items: center;
        color: rgba($color: #05061e, $alpha: 0.75);

        @media (max-width: 600px) {
            font-size: 1.5rem;
            width: 10rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .other-details {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .color-sign {
            display: flex;
            align-items: center;

            &-text {
                font-style: normal;
                font-weight: normal;
                font-size: 1.4rem;
                line-height: 170%;
                text-align: right;
                color: rgba($color: #05061e, $alpha: 0.5);

                @media (max-width: 1024px) {
                    display: none;
                }
            }

            svg {
                margin-left: 8px;
                width: 16px;
                height: 16px;
                object-fit: cover;

                @media (max-width: 600px) {
                    width: 12px;
                    height: 12px;
                }
            }
        }

        .combin-sign {
            display: flex;
            align-items: center;

            svg {
                width: 24px;
                height: 24px;
                object-fit: cover;

                @media (max-width: 600px) {
                    width: 16px;
                    height: 16px;
                }
            }

            &:before {
                content: "";
                width: 1px;
                height: 16px;
                background: rgba($color: #05061e, $alpha: 0.07);
                margin-left: 4px;
                margin-right: 4px;

                @media (max-width: 600px) {
                    height: 12px;
                }
            }
        }
    }

    @media (max-width: 768px) {
        font-size: 1.5rem;
    }
}

.product-card-details .product-name {
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 170%;
    color: rgba($color: #05061e, $alpha: 0.75);
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    padding-top: 1.6rem;

    @media (max-width: 768px) {
        padding-top: 1rem;
        white-space: pre-wrap;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    @media (max-width: 768px) {
        font-size: 1.75rem;
        height: 6rem;
        white-space: pre-wrap;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}

.product-card-price {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;

    .price {
        font-style: normal;
        font-weight: 500;
        font-size: 2rem;
        line-height: 170%;
        color: #fe6f42;

        @media (max-width: 768px) {
            font-size: 1.75rem;
        }

        &.remove {
            color: #ff7222;
        }
    }

    .price-discount {
        font-style: normal;
        font-weight: normal;
        font-size: 1.4rem;
        line-height: 200%;
        text-decoration-line: line-through;
        color: rgba($color: #05061e, $alpha: 0.3);
        display: flex;
        align-items: flex-end;
        margin-left: 8px;

        @media (max-width: 600px) {
            font-size: 1.5rem;
            margin-left: 4px;
        }
    }
}

.product-detail {
    width: 112.8rem;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 0 2.4rem;
    margin: 0 auto;
    padding-bottom: 15rem;

    @media (max-width: 1128px) {
        width: 100%;
        padding: 0 2.4rem;

        .breadcrumb {
            padding-left: 0;
            padding-right: 0;
        }
    }

    @media (max-width: 768px) {
        width: 100%;
        padding: 0 0;

        .breadcrumb {
            padding-left: 1.2rem;
            padding-right: 1.2rem;
        }
    }

    @media (max-width: 600px) {
        .breadcrumb {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    }
}

.detail {
    grid-column: 7/-1;
    position: relative;
    padding-bottom: 4.8rem;

    @media (max-width: 768px) {
        grid-column: 1/-1;
        padding: 0 1.2rem;
    }
}

.desktop-none {
    display: none;
}

.product-views {
    padding: 100px 0;
}

.product-views-details {
    padding: 3.2rem 0;
}

.product-view-details-child {
    padding-bottom: 3.2rem;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
        padding-left: 0;
        padding-bottom: 3rem;
    }
}

.product-views-price {
    display: flex;

    align-items: center;
    height: 7.2rem;

    @media (max-width: 768px) {
        padding-top: 1.5rem;
        border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);
    }

    @media (max-width: 600px) {
        height: 9rem;
    }

    .discount {
        height: 5.6rem;
        width: 5.6rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 1.6rem;
        background: url("../public/assets/img/mb2-discount-rectangle.svg");
        background-repeat: no-repeat;
        background-size: cover;
        font-style: normal;
        font-weight: bold;
        font-size: 1.6rem;
        line-height: 130%;
        display: flex;
        align-items: center;
        text-align: center;
        color: #ffffff;

        p {
            font-style: normal;
            font-weight: bold;
            font-size: 1rem;
            line-height: 130%;
            display: flex;
            align-items: center;
            text-align: center;
            color: #ffffff;
        }
    }
}

.product-views-price .price {
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 170%;
    text-decoration-line: line-through;
    color: #0c0d1e;
    opacity: 0.3;
    padding-right: 2.4rem;

    @media (max-width: 768px) {
        height: 4.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba($color: #0c0d1e, $alpha: 0.2);
        padding-right: 0;
        width: 12.9rem;
        margin-right: 1.5rem;
    }

    @media (max-width: 600px) {
        height: 6rem;
        width: 16.12rem;
        margin-right: 1.8rem;
    }
}

.product-views-discount {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.product-views-discount p {
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 130%;
    text-decoration-line: line-through;
    color: rgba($color: #05061e, $alpha: 0.3);
    margin-bottom: 4px;

    @media (max-width: 600px) {
        font-size: 1.75rem;
        margin-bottom: 0;
    }
}

.product-views-discount span {
    font-style: normal;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 130%;
    color: #fe6f42;

    @media (max-width: 600px) {
        font-size: 2.5rem;
    }
}

.product-views-name {
    h4 {
        font-style: normal;
        font-weight: 500;
        font-size: 2.8rem;
        line-height: 170%;
        color: rgba($color: #05061e, $alpha: 0.75);

        @media (max-width: 600px) {
            font-size: 2.5rem;
        }
    }
}

.product-views-store {
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 170%;
    display: flex;
    align-items: center;
    color: rgba(5, 6, 30, 0.75);
    opacity: 0.75;

    a {
        color: #fe6f42;
    }
}

.product-view-details-child .brand {
    padding-top: 2.4rem;
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 170%;
    display: flex;
    align-items: center;
    color: rgba($color: #05061e, $alpha: 0.75);

    @media (max-width: 600px) {
        padding-top: 2rem;
        font-size: 1.75rem;
    }

    .brand-det-container {
        padding: 0;
        width: 3.2rem;
        height: 3.2rem;
        border-radius: 50%;
        margin-right: 1.2rem;
        border: 1px solid rgba($color: #0c0d1e, $alpha: 0.1);

        img {
            border-radius: 50%;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.product-colors {
    display: flex;
    flex-direction: column;
    margin: 1.6rem 0 2.4rem 0;

    @media (max-width: 768px) {
        margin-top: 2rem;
        margin-bottom: 3rem;
    }

    h6 {
        padding-bottom: 1.6rem;
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 170%;
        color: rgba($color: #05061e, $alpha: 0.5);

        @media (max-width: 600px) {
            font-size: 1.75rem;
        }
    }
}

.product-colors-row {
    display: flex;
    align-items: center;

    .combine-image {
        width: 5.2rem;
        height: 7.2rem;
        margin-right: 1.6rem;

        @media (max-width: 600px) {
            width: 6.5rem;
            height: 9rem;
        }

        img {
            width: 5.2rem;
            height: 7.2rem;
            object-fit: cover;

            &.active {
                border: 1px solid #fe6f42;
            }

            @media (max-width: 600px) {
                width: 6.5rem;
                height: 9rem;
            }
        }
    }
}

/* radio */
.radio-parent {
    display: block;
    position: relative;
    padding-left: 3.2rem;
    margin-bottom: 2.4rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.radio-parent input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 2.4rem;
    width: 2.4rem;
    border-radius: 50%;
    border: 1px solid rgba(12, 13, 30, 0.1);

    @media (max-width: 600px) {
        height: 3rem;
        width: 3rem;
    }
}

.radio-parent input:checked~.checkmark {
    border: 1px solid rgba(12, 13, 30, 0.75);
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.radio-parent input:checked~.checkmark:after {
    display: block;
}

.radio-parent .checkmark:after {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
}

.checkmark::before {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    margin: auto;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    content: "";
    display: block;

    @media (max-width: 600px) {
        height: 2rem;
        width: 2rem;
    }
}

.color-1::before {
    background: #ff6480;
}

.color-2::before {
    background: #00d67c;
}

.color-3::before {
    background: #54acfd;
}

.color-4:before {
    background: #ff9162;
}

.product-size {
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
        flex-direction: column;
        border: none;
    }
}

.product-details-comment-card .rating-star-card {
    span {
        margin-right: 4px !important;
    }
}

.product-size .size {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    .title {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 170%;
        color: rgba($color: #05061e, $alpha: 0.5);
        padding-bottom: 1.6rem;

        @media (max-width: 600px) {
            font-size: 1.75rem;
            padding-bottom: 1.5rem;
        }

        .find-your-size {
            margin-left: 12px;
            display: flex;
            align-items: center;
            font-style: normal;
            font-weight: normal;
            font-size: 1.6rem;
            line-height: 170%;
            color: #fe6f42;
            cursor: pointer;

            @media (max-width: 600px) {
                margin-left: 8px;
                font-size: 1.75rem;
            }

            svg {
                width: 8px;
                height: 8px;
                margin-left: 4px;

                path {
                    stroke: #fe6f42;
                }
            }
        }
    }

    @media (max-width: 768px) {
        padding: 0;
    }
}

.product-size-radio {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: 600px) {
        margin-bottom: 2.5rem;
        overflow-x: scroll;
        width: 100%;
    }
}

.product-size-radio label {
    margin-right: 1.6rem;

    @media (max-width: 600px) {
        margin-right: 2rem;
    }
}

.product-size-radio .size-radio {
    display: none;
}

.size {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 170%;
    color: #0c0d1e;
    color: rgba(12, 13, 30, 0.5);
}

.size-radio:checked+.size-button {
    border: 1px solid #05061e;
    background-color: #05061e;
    box-sizing: border-box;
    color: #fff;
}

.size-radio:disabled+.size-button {
    border: 1px solid rgba(12, 13, 30, 0.1);
}

.product-has-combin {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 170%;
    color: rgba($color: #05061e, $alpha: 0.5);

    @media (max-width: 600px) {
        margin-top: 0;
        font-size: 1.5rem;
    }

    svg {
        margin-right: 8px;

        @media (max-width: 600px) {
            width: 2rem;
            height: 2rem;
            object-fit: cover;
        }
    }
}

.product-buttons {
    display: flex;
    align-items: center;
    margin-top: 2.4rem;

    @media (max-width: 768px) {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: #fff;
        height: 7.2rem;
        z-index: 9999998;
        width: 100%;
        margin: 0;
        padding: 0 1.2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid rgba($color: #0c0d1e, $alpha: 0.05);
    }

    @media (max-width: 600px) {
        height: 9rem;
        padding: 0 1.5rem;
    }

    .whatsapp-button {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 4.8rem;
        height: 4.8rem;
        background-color: #25d366;
        border-radius: 4px;
        margin-left: 8px;

        @media (max-width: 600px) {
            width: 6rem;
            height: 6rem;
            min-width: 6rem;
        }

        svg {
            width: 2.4rem;
            height: 2.4rem;

            @media (max-width: 600px) {
                width: 3rem;
                height: 3rem;
            }
        }
    }
}

.buy-fast-button {
    border: none;
    outline: none;
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 170%;
    text-align: center;
    color: #ffffff;
    background: #05b136;
    border-radius: 4px;
    width: 100%;
    height: 4.8rem;
    //margin-right: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media (max-width: 768px) {
        width: 100%;
        margin: 0;
    }

    @media (max-width: 600px) {
        height: 6rem;
        font-size: 1.75rem;
    }
}

.buy-fast-button.disabled {
    cursor: not-allowed;
    background: rgba($color: #05b136, $alpha: 0.5);
}

.basket-button {
    position: relative;
    border: none;
    outline: none;
    cursor: pointer;
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 170%;
    text-align: center;
    color: #ffffff;
    background: #fe6f42;
    border-radius: 4px;
    width: 100%;
    height: 4.8rem;
    margin-right: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        width: 100%;
        margin: 0;
        margin-right: 8px;
    }

    @media (max-width: 600px) {
        height: 6rem;
        font-size: 1.75rem;
    }
}

.basket-button.disabled {
    background: rgba($color: #fe6f42, $alpha: 0.5);
    cursor: not-allowed;
}

/* like button */
.product-hearth {
    position: absolute;
    top: 0;
    right: 0;
    background: #ffffff;
    border: 1px solid rgba(12, 13, 30, 0.1);
    box-sizing: border-box;
    border-radius: 50%;
    width: 4.8rem;
    height: 4.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media (max-width: 768px) {
        right: 12px;
    }

    @media (max-width: 600px) {
        top: 12px;
        width: 6rem;
        height: 6rem;
    }
}

.product-hearth svg {
    width: 2.4rem;
    height: 2.4rem;

    @media (max-width: 600px) {
        width: 3rem;
        height: 3rem;
    }
}

.str0 {
    stroke: rgba(12, 13, 30, 0.5);
}

.like-button {
    display: none;
}

.fil0 {
    fill: #fff;
}

.like-button:checked+svg {
    g {
        opacity: 1;
    }

    .fil0 {
        fill: #ff2121;
        stroke: #ff2121;
    }
}

.product-views-delivery {
    padding: 1.2rem 1.6rem;
    border: 1px dashed rgba($color: #05b136, $alpha: 0.5);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3.2rem;

    @media (max-width: 600px) {
        padding: 1.5rem;
        margin-top: 2rem;
    }

    .delivery-desc {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-style: normal;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 130%;
        color: #05b136;

        @media (max-width: 600px) {
            font-size: 1.5rem;
        }

        svg {
            margin-right: 1.6rem;

            @media (max-width: 600px) {
                width: 4rem;
                height: 4rem;
                object-fit: cover;
            }
        }

        .delivery-content {
            display: flex;

            @media (max-width: 600px) {
                flex-direction: column;
            }

            .inline {
                span {
                    font-weight: 600;
                    margin: 0 4px;
                }
            }
        }
    }

    .remaining-time {
        //font-variant-numeric: tabular-nums;
        font-family: "Euclid Circular A";
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 130%;
        display: flex;
        align-items: center;
        text-align: right;
        color: #05b136;

        span {
            margin-right: 4px;
        }

        @media (max-width: 600px) {
            font-size: 1.5rem;
            flex-direction: column;
            align-items: flex-end;

            span {
                margin-right: 0;
            }
        }
    }
}

#zoom {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999999999999;
}

.product-detail-right {
    width: 100%;
    grid-column: 1/7;
    grid-gap: 0 2.4rem;

    @media (max-width: 768px) {
        grid-column: 1/-1;
        height: auto;

        #carousel-custom {
            overflow: hidden;
            display: flex;
            flex-direction: row;
        }
    }
}

.comment-product-detail {
    margin-top: 5.5rem;
    background: #ffffff;
    border: 0.1rem solid rgba(5, 6, 30, 0.1);
    box-sizing: border-box;

    .comment-product-detail-header {
        padding: 2.1rem 2.4rem 2.1rem 1.6rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 0.1rem solid rgba(5, 6, 30, 0.07);

        h6 {
            font-style: normal;
            font-weight: 500;
            font-size: 2rem;
            line-height: 100%;
            display: flex;
            align-items: center;
            color: #05061e;
            opacity: 0.75;
        }

        span {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 100%;
            color: #05061e;
            opacity: 0.75;
        }
    }

    .comment-product-detail-body {
        padding: 3.2rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        svg,
        img {
            width: 7.2rem;
            height: 7.2rem;
        }

        h6 {
            font-style: normal;
            font-weight: 500;
            font-size: 2rem;
            line-height: 170%;
            color: #05061e;
            margin: 0.8rem 0 !important;
        }

        p {
            font-style: normal;
            font-weight: normal;
            font-size: 1.4rem;
            line-height: 170%;
            color: rgba(5, 6, 30, 0.5);
        }
    }

    .rating-star-card {
        display: flex;
        align-items: center;

        span {
            margin-right: 4px;
        }

        svg {
            margin-right: 0.4rem !important;
            width: 1.4rem !important;
            height: 1.3rem !important;

            path {
                fill: rgba(126, 127, 139, 0.5);
            }
        }
    }

    .comment-product-detail-body-active {
        padding: 0 1.6rem 2.4rem;
        border-bottom: 0;
        border-left: 0;
        border-right: 0;

        .product-d-c-c-head {
            display: flex;
            align-items: center;
            padding-top: 3.2rem;

            svg,
            img {
                margin-right: 1.6rem;
                width: 3.2rem;
                border-radius: 50%;
                height: 3.2rem;
            }

            span {
                font-style: normal;
                font-weight: normal;
                font-size: 1.4rem;
                line-height: 100%;
                color: #05061e;
                opacity: 0.75;
                display: block;
                margin-right: 2.4rem;
            }
        }

        .product-d-c-c-body {
            margin-top: 1.2rem;
            padding-left: 4.8rem;
            padding-bottom: 2.4rem;
            border-bottom: 0.1rem solid rgba(5, 6, 30, 0.03);

            P {
                font-style: normal;
                font-weight: normal;
                font-size: 1.6rem;
                line-height: 170%;
                color: #05061e;
                opacity: 0.75;
            }
        }

        .product-details-comment-card {
            &:last-child {
                .product-d-c-c-body {
                    border-bottom: 0;
                }
            }
        }
    }
}

.comment-product-detail:nth-child(3) {
    display: none;
}

@media (max-width: 768px) {
    .desktop-none {
        display: block;
    }

    .resp-none {
        display: none;
    }

    .accordion-comment {
        .acc-cont {
            background-color: #fff;
            padding: 0 1rem;

            .comment-product-detail:nth-child(2) {
                display: none;
                border: none !important;
            }
        }

        .comment-product-detail {
            border-top: 0.1rem solid rgba(5, 6, 30, 0.05);

            .react-stars {
                span {
                    width: 14px;
                    font-size: 14px !important;
                    height: 14px;
                }
            }
        }
    }

    .comment-product-detail {
        grid-column: 1/-1;
        margin: 0;
        border-top: 0;
        border-right: 0;
        border-left: 0;

        .comment-product-detail-header {
            padding: 12px 1.6rem 16px;
        }

        .comment-product-detail-header {
            h6 {
                display: none;
            }
        }

        .product-d-c-c-head {
            padding-top: 1.2rem !important;
        }

        .product-d-c-c-body {
            padding-bottom: 1.2rem !important;
        }

        .comment-product-detail-body-active {
            padding: 0 1.6rem;
        }
    }
}

.combined-product {
    background-color: #fff;
    height: 23rem;
    width: 100%;
    border: 1px solid rgba($color: #05061e, $alpha: 0.07);
    display: flex;
    margin-bottom: 2.4rem;

    @media (max-width: 600px) {
        height: 24.5rem;
    }

    .img-container {
        position: relative;
        height: 100%;
        width: 16.8rem;

        @media (max-width: 600px) {
            width: 18rem;
        }

        img {
            height: 100%;
            width: 16.8rem;
            object-fit: cover;

            @media (max-width: 600px) {
                width: 18rem;
            }
        }

        /* like button */
        .product-heart {
            position: absolute;
            top: 16px;
            right: 16px;
            background: #ffffff;
            border: 1px solid rgba(12, 13, 30, 0.1);
            border-radius: 50%;
            width: 3.2rem;
            height: 3.2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            @media (max-width: 600px) {
                width: 4.5rem;
                height: 4.5rem;
                top: 8px;
                right: 8px;
            }
        }

        .product-heart svg {
            width: 1.6rem;
            height: 1.6rem;
            /* opacity: .5; */

            @media (max-width: 600px) {
                width: 2.5rem;
                height: 2.5rem;
            }
        }

        .str0 {
            stroke: rgba(12, 13, 30, 0.5);
        }

        .like-button {
            display: none;
        }

        .fil0 {
            fill: #fff;
        }

        .like-button:checked+svg {
            g {
                opacity: 1;
            }

            .fil0 {
                fill: #ff2121;
                stroke: #ff2121;
            }
        }

        /* like button ///*/
    }

    .product-detail {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 2.4rem;
        overflow: hidden;

        @media (max-width: 900px) {
            padding: 1.6rem;
        }

        @media (max-width: 600px) {
            padding: 8px 12px 12px 12px;
        }

        .top-section {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 1.6rem;
            border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.1);

            @media (max-width: 600px) {
                padding-bottom: 6px;
            }

            .brand {
                font-style: normal;
                font-weight: 500;
                font-size: 1.4rem;
                line-height: 170%;
                display: flex;
                align-items: center;
                color: rgba($color: #05061e, $alpha: 0.75);

                @media (max-width: 600px) {
                    font-size: 1.5rem;
                }
            }

            .colors {
                display: flex;
                align-items: center;

                .color-text-card {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 1.4rem;
                    line-height: 170%;
                    text-align: right;
                    color: rgba($color: #05061e, $alpha: 0.5);

                    @media (max-width: 768px) {
                        display: none;
                    }
                }

                svg {
                    width: 1.6rem;
                    height: 1.6rem;
                    margin-left: 8px;
                }
            }
        }

        .middle-section {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 1.6rem 0;
            border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.1);

            @media (max-width: 600px) {
                padding-top: 3.5rem;
                padding-bottom: 3rem;
            }

            .product-name {
                font-style: normal;
                font-weight: normal;
                font-size: 1.6rem;
                line-height: 170%;
                color: rgba($color: #05061e, $alpha: 0.75);
                margin-bottom: 1.2rem;
                display: -webkit-box;
                white-space: pre-wrap;
                width: auto;
                overflow: hidden !important;
                text-overflow: ellipsis;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;

                @media (max-width: 600px) {
                    font-size: 1.75rem;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    white-space: pre-wrap;
                    margin-bottom: 4px;
                }
            }

            .price {
                display: flex;
                align-items: center;

                .new-price {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 2rem;
                    line-height: 170%;
                    color: #fe6f42;
                    margin-right: 8px;

                    @media (max-width: 600px) {
                        font-size: 1.75rem;
                    }
                }

                .old-price {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 1.4rem;
                    line-height: 170%;
                    text-decoration-line: line-through;
                    color: rgba($color: #05061e, $alpha: 0.3);

                    @media (max-width: 600px) {
                        font-size: 11px;
                    }
                }
            }
        }

        .bottom-section {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 1.6rem;

            @media (max-width: 600px) {
                padding-bottom: 0;
                padding-top: 1.5rem;
                justify-content: flex-start;
            }

            .text {
                font-style: normal;
                font-weight: 500;
                font-size: 1.4rem;
                line-height: 130%;
                display: flex;
                align-items: center;
                color: #05b136;

                @media (max-width: 600px) {
                    font-size: 1.25rem;
                    order: 1;
                }
            }

            svg {
                @media (max-width: 600px) {
                    width: 12px;
                    height: 12px;
                    order: 0;
                    margin-right: 8px;
                }
            }
        }
    }
}

.detail {
    grid-column: 7/-1;
    position: relative;
    padding-bottom: 4.8rem;

    @media (max-width: 768px) {
        grid-column: 1/-1;
        padding: 0 1.2rem;
    }
}

.desktop-none {
    display: none;
}

.product-views {
    padding: 100px 0;
}

.product-views-details {
    padding: 3.2rem 0;
}

.product-view-details-child {
    padding-bottom: 3.2rem;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
        padding-left: 0;
        padding-bottom: 3rem;
    }
}

.product-views-price {
    display: flex;

    align-items: center;
    height: 7.2rem;

    @media (max-width: 768px) {
        padding-top: 1.5rem;
        border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);
    }

    @media (max-width: 600px) {
        height: 9rem;
    }

    .discount {
        height: 5.6rem;
        width: 5.6rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 1.6rem;
        background: url("../public/assets/img/mb2-discount-rectangle.svg");
        background-repeat: no-repeat;
        background-size: cover;
        font-style: normal;
        font-weight: bold;
        font-size: 1.6rem;
        line-height: 130%;
        display: flex;
        align-items: center;
        text-align: center;
        color: #ffffff;

        p {
            font-style: normal;
            font-weight: bold;
            font-size: 1rem;
            line-height: 130%;
            display: flex;
            align-items: center;
            text-align: center;
            color: #ffffff;
        }
    }
}

.product-views-price .price {
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 170%;
    text-decoration-line: line-through;
    color: #0c0d1e;
    opacity: 0.3;
    padding-right: 2.4rem;

    @media (max-width: 768px) {
        height: 4.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba($color: #0c0d1e, $alpha: 0.2);
        padding-right: 0;
        width: 12.9rem;
        margin-right: 1.5rem;
    }

    @media (max-width: 600px) {
        height: 6rem;
        width: 16.12rem;
        margin-right: 1.8rem;
    }
}

.product-views-discount {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.product-views-discount p {
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 130%;
    text-decoration-line: line-through;
    color: rgba($color: #05061e, $alpha: 0.3);
    margin-bottom: 4px;

    @media (max-width: 600px) {
        font-size: 1.75rem;
        margin-bottom: 0;
    }
}

.product-views-discount span {
    font-style: normal;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 130%;
    color: #fe6f42;

    @media (max-width: 600px) {
        font-size: 2.5rem;
    }
}

.product-views-name {
    h4 {
        font-style: normal;
        font-weight: 500;
        font-size: 2.8rem;
        line-height: 170%;
        color: rgba($color: #05061e, $alpha: 0.75);

        @media (max-width: 600px) {
            font-size: 2.5rem;
        }
    }
}

.product-views-store {
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 170%;
    display: flex;
    align-items: center;
    color: rgba(5, 6, 30, 0.75);
    opacity: 0.75;

    a {
        color: #fe6f42;
    }
}

.product-view-details-child .brand {
    padding-top: 2.4rem;
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 170%;
    display: flex;
    align-items: center;
    color: rgba($color: #05061e, $alpha: 0.75);

    @media (max-width: 600px) {
        padding-top: 2rem;
        font-size: 1.75rem;
    }

    .brand-det-container {
        padding: 0;
        width: 3.2rem;
        height: 3.2rem;
        border-radius: 50%;
        margin-right: 1.2rem;
        border: 1px solid rgba($color: #0c0d1e, $alpha: 0.1);

        img {
            border-radius: 50%;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.product-colors {
    display: flex;
    flex-direction: column;
    margin: 1.6rem 0 2.4rem 0;

    @media (max-width: 768px) {
        margin-top: 2rem;
        margin-bottom: 3rem;
    }

    h6 {
        padding-bottom: 1.6rem;
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 170%;
        color: rgba($color: #05061e, $alpha: 0.5);

        @media (max-width: 600px) {
            font-size: 1.75rem;
        }
    }
}

.product-colors-row {
    display: flex;
    align-items: center;

    .combine-image {
        width: 5.2rem;
        height: 7.2rem;
        margin-right: 1.6rem;

        @media (max-width: 600px) {
            width: 6.5rem;
            height: 9rem;
        }

        img {
            width: 5.2rem;
            height: 7.2rem;
            object-fit: cover;

            &.active {
                border: 1px solid #fe6f42;
            }

            @media (max-width: 600px) {
                width: 6.5rem;
                height: 9rem;
            }
        }
    }
}

/* radio */
.radio-parent {
    display: block;
    position: relative;
    padding-left: 3.2rem;
    margin-bottom: 2.4rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.radio-parent input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 2.4rem;
    width: 2.4rem;
    border-radius: 50%;
    border: 1px solid rgba(12, 13, 30, 0.1);

    @media (max-width: 600px) {
        height: 3rem;
        width: 3rem;
    }
}

.radio-parent input:checked~.checkmark {
    border: 1px solid rgba(12, 13, 30, 0.75);
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.radio-parent input:checked~.checkmark:after {
    display: block;
}

.radio-parent .checkmark:after {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
}

.checkmark::before {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    margin: auto;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    content: "";
    display: block;

    @media (max-width: 600px) {
        height: 2rem;
        width: 2rem;
    }
}

.color-1::before {
    background: #ff6480;
}

.color-2::before {
    background: #00d67c;
}

.color-3::before {
    background: #54acfd;
}

.color-4:before {
    background: #ff9162;
}

.product-size {
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
        flex-direction: column;
        border: none;
    }
}

.product-size .size {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    .title {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 170%;
        color: rgba($color: #05061e, $alpha: 0.5);
        padding-bottom: 1.6rem;

        @media (max-width: 600px) {
            font-size: 1.75rem;
            padding-bottom: 1.5rem;
        }

        .find-your-size {
            margin-left: 12px;
            display: flex;
            align-items: center;
            font-style: normal;
            font-weight: normal;
            font-size: 1.6rem;
            line-height: 170%;
            color: #fe6f42;
            cursor: pointer;

            @media (max-width: 600px) {
                margin-left: 8px;
                font-size: 1.75rem;
            }

            svg {
                width: 8px;
                height: 8px;
                margin-left: 4px;

                path {
                    stroke: #fe6f42;
                }
            }
        }
    }

    @media (max-width: 768px) {
        padding: 0;
    }
}

.product-size-radio {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: 600px) {
        margin-bottom: 2.5rem;
    }
}

.product-size-radio label {
    margin-right: 1.6rem;

    @media (max-width: 600px) {
        margin-right: 2rem;
    }
}

.product-size-radio .size-radio {
    display: none;
}

.size {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 170%;
    color: #0c0d1e;
    color: rgba(12, 13, 30, 0.5);
}

.size-radio:checked+.size-button {
    border: 1px solid #05061e;
    background-color: #05061e;
    box-sizing: border-box;
    color: #fff;
}

.size-radio:disabled+.size-button {
    border: 1px solid rgba(12, 13, 30, 0.1);
}

.product-has-combin {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 170%;
    color: rgba($color: #05061e, $alpha: 0.5);

    @media (max-width: 600px) {
        margin-top: 0;
        font-size: 1.5rem;
    }

    svg {
        margin-right: 8px;

        @media (max-width: 600px) {
            width: 2rem;
            height: 2rem;
            object-fit: cover;
        }
    }
}

.product-buttons {
    display: flex;
    align-items: center;
    margin-top: 2.4rem;

    @media (max-width: 768px) {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: #fff;
        height: 7.2rem;
        z-index: 9999998;
        width: 100%;
        margin: 0;
        padding: 0 1.2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid rgba($color: #0c0d1e, $alpha: 0.05);
    }

    @media (max-width: 600px) {
        height: 9rem;
        padding: 0 1.5rem;
    }

    .whatsapp-button {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 4.8rem;
        height: 4.8rem;
        background-color: #25d366;
        border-radius: 4px;
        margin-left: 8px;

        @media (max-width: 600px) {
            width: 6rem;
            height: 6rem;
            min-width: 6rem;
        }

        svg {
            width: 2.4rem;
            height: 2.4rem;

            @media (max-width: 600px) {
                width: 3rem;
                height: 3rem;
            }
        }
    }
}

.buy-fast-button {
    border: none;
    outline: none;
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 170%;
    text-align: center;
    color: #ffffff;
    background: #05b136;
    border-radius: 4px;
    width: 100%;
    height: 4.8rem;
    //margin-right: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media (max-width: 768px) {
        width: 100%;
        margin: 0;
    }

    @media (max-width: 600px) {
        height: 6rem;
        font-size: 1.75rem;
    }
}

.buy-fast-button.disabled {
    cursor: not-allowed;
    background: rgba($color: #05b136, $alpha: 0.5);
}

.basket-button {
    border: none;
    outline: none;
    cursor: pointer;
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 170%;
    text-align: center;
    color: #ffffff;
    background: #fe6f42;
    border-radius: 4px;
    width: 100%;
    height: 4.8rem;
    margin-right: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        width: 100%;
        margin: 0;
        margin-right: 8px;
    }

    @media (max-width: 600px) {
        height: 6rem;
        font-size: 1.75rem;
    }
}

.basket-button.disabled {
    background: rgba($color: #fe6f42, $alpha: 0.5);
    cursor: not-allowed;
}

/* like button */
.product-hearth {
    position: absolute;
    top: 0;
    right: 0;
    background: #ffffff;
    border: 1px solid rgba(12, 13, 30, 0.1);
    box-sizing: border-box;
    border-radius: 50%;
    width: 4.8rem;
    height: 4.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media (max-width: 768px) {
        right: 12px;
    }

    @media (max-width: 600px) {
        top: 12px;
        width: 6rem;
        height: 6rem;
    }
}

.product-hearth svg {
    width: 2.4rem;
    height: 2.4rem;

    @media (max-width: 600px) {
        width: 3rem;
        height: 3rem;
    }
}

.str0 {
    stroke: rgba(12, 13, 30, 0.5);
}

.like-button {
    display: none;
}

.fil0 {
    fill: #fff;
}

.like-button:checked+svg {
    g {
        opacity: 1;
    }

    .fil0 {
        fill: #ff2121;
        stroke: #ff2121;
    }
}

.product-views-delivery {
    padding: 1.2rem 1.6rem;
    border: 1px dashed rgba($color: #05b136, $alpha: 0.5);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3.2rem;

    @media (max-width: 600px) {
        padding: 1.5rem;
        margin-top: 2rem;
    }

    .delivery-desc {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-style: normal;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 130%;
        color: #05b136;

        @media (max-width: 600px) {
            font-size: 1.5rem;
        }

        svg {
            margin-right: 1.6rem;

            @media (max-width: 600px) {
                width: 4rem;
                height: 4rem;
                object-fit: cover;
            }
        }

        .delivery-content {
            display: flex;

            @media (max-width: 600px) {
                flex-direction: column;
            }

            .inline {
                span {
                    font-weight: 600;
                    margin: 0 4px;
                }
            }
        }
    }

    .remaining-time {
        //font-variant-numeric: tabular-nums;
        font-family: "Euclid Circular A";
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 130%;
        display: flex;
        align-items: center;
        text-align: right;
        color: #05b136;

        span {
            margin-right: 4px;
        }

        @media (max-width: 600px) {
            font-size: 1.5rem;
            flex-direction: column;
            align-items: flex-end;

            span {
                margin-right: 0;
            }
        }
    }
}

#zoom {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999999999999;
}

.product-detail-right {
    width: 100%;
    grid-column: 1/7;
    grid-gap: 0 2.4rem;

    @media (max-width: 768px) {
        grid-column: 1/-1;
        height: auto;

        #carousel-custom {
            overflow: hidden;
            display: flex;
            flex-direction: row;
        }
    }
}

.comment-product-detail {
    margin-top: 5.5rem;
    background: #ffffff;
    border: 0.1rem solid rgba(5, 6, 30, 0.1);
    box-sizing: border-box;

    .comment-product-detail-header {
        padding: 2.1rem 2.4rem 2.1rem 1.6rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 0.1rem solid rgba(5, 6, 30, 0.07);

        h6 {
            font-style: normal;
            font-weight: 500;
            font-size: 2rem;
            line-height: 100%;
            display: flex;
            align-items: center;
            color: #05061e;
            opacity: 0.75;
        }

        span {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 100%;
            color: #05061e;
            opacity: 0.75;
        }
    }

    .comment-product-detail-body {
        padding: 3.2rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        svg {
            width: 7.2rem;
            height: 7.2rem;
        }

        h6 {
            font-style: normal;
            font-weight: 500;
            font-size: 2rem;
            line-height: 170%;
            color: #05061e;
            margin: 0.8rem 0 !important;
        }

        p {
            font-style: normal;
            font-weight: normal;
            font-size: 1.4rem;
            line-height: 170%;
            color: rgba(5, 6, 30, 0.5);
        }
    }

    .rating-star-card {
        display: flex;
        align-items: center;

        svg {
            margin-right: 0.4rem !important;
            width: 1.4rem !important;
            height: 1.3rem !important;

            path {
                fill: rgba(126, 127, 139, 0.5);
            }
        }
    }

    .comment-product-detail-body-active {
        padding: 2.4rem 1.6rem;
        border-bottom: 0;
        border-left: 0;
        border-right: 0;

        .product-d-c-c-head {
            display: flex;
            align-items: center;
            padding-top: 3.2rem;

            svg {
                margin-right: 1.6rem;
                width: 3.2rem;
                border-radius: 50%;
                height: 3.2rem;
            }

            span {
                font-style: normal;
                font-weight: normal;
                font-size: 1.4rem;
                line-height: 100%;
                color: #05061e;
                opacity: 0.75;
                display: block;
                margin-right: 2.4rem;
            }
        }

        .product-d-c-c-body {
            margin-top: 1.2rem;
            padding-left: 4.8rem;
            padding-bottom: 2.4rem;
            border-bottom: 0.1rem solid rgba(5, 6, 30, 0.03);

            P {
                font-style: normal;
                font-weight: normal;
                font-size: 1.6rem;
                line-height: 170%;
                color: #05061e;
                opacity: 0.75;
            }
        }

        .product-details-comment-card {
            &:last-child {
                .product-d-c-c-body {
                    border-bottom: 0;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .desktop-none {
        display: block;
    }

    .resp-none {
        display: none;
    }

    .comment-product-detail {
        grid-column: 1/-1;
        margin: 0 1.2rem;
        border-top: 0;
        border-right: 0;
        border-left: 0;

        .comment-product-detail-header {
            padding: 12px 1.6rem 16px;
        }

        .product-d-c-c-head {
            padding-top: 1.2rem !important;
        }

        .product-d-c-c-body {
            padding-bottom: 1.2rem !important;
        }

        .comment-product-detail-body-active {
            padding: 0 1.6rem;
        }
    }
}

.combined-product {
    background-color: #fff;
    height: 23rem;
    width: 100%;
    border: 1px solid rgba($color: #05061e, $alpha: 0.07);
    display: flex;
    margin-bottom: 2.4rem;

    @media (max-width: 600px) {
        height: 24.5rem;
    }

    .img-container {
        position: relative;
        height: 100%;
        width: 16.8rem;

        @media (max-width: 600px) {
            width: 18rem;
        }

        img {
            height: 100%;
            width: 16.8rem;
            object-fit: cover;

            @media (max-width: 600px) {
                width: 18rem;
            }
        }

        /* like button */
        .product-heart {
            position: absolute;
            top: 16px;
            right: 16px;
            background: #ffffff;
            border: 1px solid rgba(12, 13, 30, 0.1);
            border-radius: 50%;
            width: 3.2rem;
            height: 3.2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            @media (max-width: 600px) {
                width: 4.5rem;
                height: 4.5rem;
                top: 8px;
                right: 8px;
            }
        }

        .product-heart svg {
            width: 1.6rem;
            height: 1.6rem;
            /* opacity: .5; */

            @media (max-width: 600px) {
                width: 2.5rem;
                height: 2.5rem;
            }
        }

        .str0 {
            stroke: rgba(12, 13, 30, 0.5);
        }

        .like-button {
            display: none;
        }

        .fil0 {
            fill: #fff;
        }

        .like-button:checked+svg {
            g {
                opacity: 1;
            }

            .fil0 {
                fill: #ff2121;
                stroke: #ff2121;
            }
        }

        /* like button ///*/
    }

    .product-detail {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 2.4rem;
        overflow: hidden;

        @media (max-width: 900px) {
            padding: 1.6rem;
        }

        @media (max-width: 600px) {
            padding: 8px 12px 12px 12px;
        }

        .top-section {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 1.6rem;
            border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.1);

            @media (max-width: 600px) {
                padding-bottom: 6px;
            }

            .brand {
                font-style: normal;
                font-weight: 500;
                font-size: 1.4rem;
                line-height: 170%;
                display: flex;
                align-items: center;
                color: rgba($color: #05061e, $alpha: 0.75);

                @media (max-width: 600px) {
                    font-size: 1.5rem;
                }
            }

            .colors {
                display: flex;
                align-items: center;

                .color-text-card {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 1.4rem;
                    line-height: 170%;
                    text-align: right;
                    color: rgba($color: #05061e, $alpha: 0.5);

                    @media (max-width: 768px) {
                        display: none;
                    }
                }

                svg {
                    width: 1.6rem;
                    height: 1.6rem;
                    margin-left: 8px;
                }
            }
        }

        .middle-section {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 1.6rem 0;
            border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.1);

            @media (max-width: 600px) {
                padding-top: 3.5rem;
                padding-bottom: 3rem;
            }

            .product-name {
                font-style: normal;
                font-weight: normal;
                font-size: 1.6rem;
                line-height: 170%;
                color: rgba($color: #05061e, $alpha: 0.75);
                margin-bottom: 1.2rem;
                display: -webkit-box;
                white-space: pre-wrap;
                width: auto;
                overflow: hidden !important;
                text-overflow: ellipsis;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;

                @media (max-width: 600px) {
                    font-size: 1.75rem;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    white-space: pre-wrap;
                    margin-bottom: 4px;
                }
            }

            .price {
                display: flex;
                align-items: center;

                .new-price {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 2rem;
                    line-height: 170%;
                    color: #fe6f42;
                    margin-right: 8px;

                    @media (max-width: 600px) {
                        font-size: 1.75rem;
                    }
                }

                .old-price {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 1.4rem;
                    line-height: 170%;
                    text-decoration-line: line-through;
                    color: rgba($color: #05061e, $alpha: 0.3);

                    @media (max-width: 600px) {
                        font-size: 11px;
                    }
                }
            }
        }

        .bottom-section {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 1.6rem;

            @media (max-width: 600px) {
                padding-bottom: 0;
                padding-top: 1.5rem;
                justify-content: flex-start;
            }

            .text {
                font-style: normal;
                font-weight: 500;
                font-size: 1.4rem;
                line-height: 130%;
                display: flex;
                align-items: center;
                color: #05b136;

                @media (max-width: 600px) {
                    font-size: 1.25rem;
                    order: 1;
                }
            }

            svg {
                @media (max-width: 600px) {
                    width: 12px;
                    height: 12px;
                    order: 0;
                    margin-right: 8px;
                }
            }
        }
    }
}

.borderNone {
    border-top: none;
}

.cart-wrapper {
    background: #fff;
    width: 100%;
    //position: relative;

    .notification {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: white;
        padding: 4.8rem 2.4rem 2.4rem 2.4rem;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        z-index: 999999999999999999;

        @media (max-width: 600px) {
            padding-top: 2rem;
        }

        .img-container {
            width: 6.4rem;
            height: 6.4rem;
            margin-bottom: 2.4rem;

            @media (max-width: 600px) {
                width: 8rem;
                height: 8rem;
                margin-bottom: 3rem;
            }

            img {
                width: 6.4rem;
                height: 6.4rem;
                object-fit: cover;

                @media (max-width: 600px) {
                    width: 8rem;
                    height: 8rem;
                }
            }
        }

        .title {
            font-style: normal;
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 170%;
            color: rgba($color: #0c0d1e, $alpha: 0.75);

            @media (max-width: 600px) {
                font-size: 1.75rem;
            }
        }

        .subtitle {
            font-style: normal;
            font-weight: normal;
            font-size: 1.2rem;
            line-height: 170%;
            text-align: center;
            color: rgba($color: #0c0d1e, $alpha: 0.5);

            @media (max-width: 600px) {
                font-size: 1.5rem;
            }
        }

        .addInfo,
        .addInfo-mobile {
            display: inline-block;
            width: 36rem;
            height: 5rem;
            border: none;
            outline: none;
            cursor: pointer;
            background: #fe4242;
            border-radius: 8px;
            font-style: normal;
            font-weight: 600;
            font-size: 1.4rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            text-align: center;
            margin-top: 3.2rem;

            @media (max-width: 600px) {
                width: 30rem;
            }
        }

        .addInfo-mobile {
            display: none;

            @media (max-width: 768px) {
                display: block;
            }
        }

        .addInfo {
            display: block;

            @media (max-width: 768px) {
                display: none;
            }
        }

        .cross-close {
            border: none;
            outline: none;
            cursor: pointer;
            background: none;
            position: absolute;
            top: 10%;
            right: 10%;
        }
    }
}

.empty-cart {
    width: 112.8rem;
    margin: 0 auto;
    height: 48rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 5rem;

    @media (max-width: 1128px) {
        width: 100%;
        padding: 0 2rem;

        .breadcrumb {
            padding-left: 0;
            padding-right: 0;
        }
    }

    @media (max-width: 768px) {
        position: relative;
        padding: 0 1.2rem;
    }

    @media (max-width: 600px) {
        position: relative;
        padding: 0 1.5rem;
    }

    .empty-cart-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: rgba($color: #05061e, $alpha: 0.03);
        border-radius: 4px;

        @media (max-width: 768px) {
            padding: 0 1.2rem;
        }

        @media (max-width: 600px) {
            padding: 0 1.5rem;
        }

        .logo-container {
            width: 7.2rem;
            height: 7.2rem;
            margin-bottom: 1.6rem;

            @media (max-width: 600px) {
                width: 9rem;
                height: 9rem;
                margin-bottom: 1rem;
            }

            svg {
                width: 7.2rem;
                height: 7.2rem;
                object-fit: cover;

                @media (max-width: 600px) {
                    width: 9rem;
                    height: 9rem;
                }
            }
        }

        .title {
            font-style: normal;
            font-weight: 500;
            font-size: 2.4rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            color: #05061e;
            margin-bottom: 8px;

            @media (max-width: 600px) {
                font-size: 2.5rem;
            }
        }

        .subtitle {
            font-style: normal;
            font-weight: normal;
            font-size: 1.6rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            text-align: center;
            color: rgba($color: #05061e, $alpha: 0.5);
            margin-bottom: 3.2rem;

            @media (max-width: 600px) {
                font-size: 1.75rem;
                margin-bottom: 2.75rem;
            }
        }

        a {
            font-style: normal;
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: #ffffff;
            background: #fe6f42;
            border-radius: 4px;
            height: 4.8rem;
            width: 16.8rem;

            @media (max-width: 600px) {
                font-size: 1.75rem;
                height: 6rem;
                width: 100%;
            }
        }
    }
}

.cart-container {
    width: 112.8rem;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 0 2.4rem;
    margin: 0 auto;
    padding-bottom: 24.8rem;

    @media (max-width: 1128px) {
        width: 100%;
        padding: 0 2rem;
    }

    @media (min-width: 768px) {
        .breadcrumb.border {
            padding-left: 0;
            padding-right: 0;
        }
    }

    @media (max-width: 768px) {
        position: relative;
        padding: 0;

        .breadcrumb.border {
            border-bottom: none;
        }
    }
}

.cart-banner {
    grid-column: 1/-1;
    height: 14.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 4rem;

    @media (max-width: 768px) {
        height: auto;
        padding: 0 2rem;
        padding-top: 0.8rem;
        padding-bottom: 1.2rem;
        border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);
        margin-bottom: 0;
    }

    @media (max-width: 600px) {
        height: auto;
        padding-top: 1rem;
        padding-bottom: 1.5rem;
        border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);
    }

    .title {
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 170%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #05061e;
        margin-bottom: 8px;

        @media (max-width: 768px) {
            font-size: 2.4rem;
        }

        @media (max-width: 600px) {
            font-size: 3rem;
        }

        a {
            font-style: normal;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: rgba($color: #05061e, $alpha: 0.5);
            height: 4.8rem;
            border: 1px solid rgba($color: #05061e, $alpha: 0.07);
            border-radius: 4px;
            width: 19.2rem;

            svg {
                margin-right: 1.6rem;
            }
        }
    }

    .subtitle {
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 170%;
        color: rgba($color: #05061e, $alpha: 0.5);

        @media (max-width: 768px) {
            display: none;
        }
    }
}

.cart-table-wrapper {
    grid-column: 1/9;
    height: fit-content;

    @media (max-width: 768px) {
        grid-column: 1/-1;
        padding: 0 2rem;
    }

    .cart-table-header {
        background-color: rgba($color: #05061e, $alpha: 0.03);
        padding: 2rem 2.4rem;
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 170%;
        display: flex;
        align-items: center;
        color: rgba($color: #05061e, $alpha: 0.75);
        margin-bottom: 2.4rem;

        @media (max-width: 768px) {
            padding: 0;
            padding-top: 1.2rem;
            padding-bottom: 1.6rem;
            background-color: #fff;
        }

        @media (max-width: 600px) {
            padding: 0;
            padding-top: 1.5rem;
            padding-bottom: 2rem;
            background-color: #fff;
            font-size: 2rem;
        }
    }
}

.cart-sidebar {
    height: fit-content;
    grid-column: 9/-1;

    @media (max-width: 768px) {
        width: 100%;
        height: 7.2rem;
        grid-column: 1/-1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        bottom: -1%;
        left: 0;
        border-radius: 0;
        padding: 0 2rem;
        z-index: 9999998;
        background-color: #fff;
        border-top: 1px solid rgba($color: #0c0d1e, $alpha: 0.05);
    }

    @media (max-width: 768px) {
        height: 12.5rem;
        padding: 0;
    }

    @media (max-width: 768px) {
        height: 15.625rem;
    }

    .cart-content-mobile {
        display: none;
        flex-direction: column;
        height: auto;

        @media (max-width: 768px) {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
        }

        .total-content {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding-bottom: 1.2rem;
            border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);
            position: relative;

            @media (max-width: 768px) {
                padding-left: 1.2rem;
                padding-right: 1.2rem;
            }

            @media (max-width: 600px) {
                padding: 1.5rem;
                padding-top: 0;
            }

            .cart-content-popup {
                position: absolute;
                bottom: 0;
                left: 0;
                //visibility: hidden;
                height: 100vh;
                width: 100vw;
                background-color: transparent;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                transform: translateY(100%);
                transition: all 0.5s;

                .cart-detail-close-btn {
                    width: 4rem;
                    height: 4rem;
                    margin-bottom: 4px;

                    @media (max-width: 600px) {
                        width: 5rem;
                        height: 5rem;
                    }

                    svg {
                        width: 4rem;
                        height: 4rem;
                        object-fit: cover;

                        @media (max-width: 600px) {
                            width: 5rem;
                            height: 5rem;
                        }
                    }
                }

                &.visible {
                    visibility: visible;
                    transform: translateY(0);
                    background-color: transparent;
                    animation-name: cart;
                    animation-duration: 0.3s;
                    //animation-delay: .3s;
                    animation-iteration-count: 1;
                    animation-fill-mode: both;
                }

                @keyframes cart {
                    from {
                        background-color: transparent;
                    }

                    to {
                        background-color: rgba($color: #05061e, $alpha: 0.5);
                    }
                }

                .cart-detail-popup-container {
                    height: auto;
                    width: 100%;
                    background-color: #fff;
                    padding-bottom: 2.4rem;

                    .side-heading {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 2.4rem;
                        line-height: 170%;
                        display: flex;
                        align-items: center;
                        color: #05061e;
                        background-color: rgba($color: #05061e, $alpha: 0.07);
                        height: 8.4rem;
                        padding-left: 2.4rem;
                        margin-bottom: 2.4rem;

                        @media (max-width: 600px) {
                            font-weight: 500;
                            font-size: 2.5rem;
                            padding: 0;
                            padding-left: 1.5rem;
                            margin-bottom: 4rem;
                        }
                    }

                    .amount,
                    .discount {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 1.6rem;
                        line-height: 170%;
                        color: rgba($color: #05061e, $alpha: 0.75);
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 0 2.4rem;

                        @media (max-width: 600px) {
                            font-size: 1.75rem;
                            padding: 0 1.5rem;
                        }

                        .amount-content,
                        .discount-content {
                            color: rgba($color: #05061e, $alpha: 0.75);

                            @media (max-width: 600px) {
                                font-size: 1.75rem;
                            }
                        }

                        .discount-price {
                            font-weight: 500;
                            color: #ff2121;

                            @media (max-width: 600px) {
                                font-size: 1.75rem;
                            }
                        }

                        .amount-price {
                            font-weight: 500;
                            color: #05061e;

                            @media (max-width: 600px) {
                                font-size: 1.75rem;
                            }
                        }
                    }

                    .amount {
                        margin-bottom: 1.6rem;

                        @media (max-width: 600px) {
                            margin-bottom: 2rem;
                        }
                    }

                    .discount {
                        padding-bottom: 3.2rem;
                        border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);

                        @media (max-width: 600px) {
                            padding-bottom: 4rem;
                        }
                    }

                    .cart-total {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 2rem;
                        line-height: 170%;
                        color: rgba($color: #05061e, $alpha: 0.75);
                        margin-top: 3.2rem;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 0 2.4rem;

                        @media (max-width: 600px) {
                            padding: 0 1.5rem;
                            margin-top: 3rem;
                        }

                        .price {
                            color: #fe6f42;
                            font-weight: 500;
                        }
                    }

                    .discount-coupon {
                        margin-top: 3.2rem;
                        padding: 0 2.4rem;

                        @media (max-width: 600px) {
                            margin-top: 3rem;
                            padding: 0 1.5rem;
                        }

                        .container {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            height: 4.8rem;
                            position: relative;

                            @media (max-width: 600px) {
                                height: 6rem;
                            }

                            input {
                                border: 1px solid rgba(5, 6, 30, 0.07);
                                box-sizing: border-box;
                                border-radius: 4px;
                                height: 100%;
                                width: 100%;
                                padding-left: 1.2rem;
                                padding-right: 4rem;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 1.6rem;
                                line-height: 170%;
                                display: flex;
                                align-items: center;
                                color: rgba($color: #05061e, $alpha: 0.75);
                                outline: none;
                                transition: all 0.3s;

                                @media (max-width: 600px) {
                                    font-size: 1.75rem;
                                }

                                &::placeholder {
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: 1.6rem;
                                    line-height: 170%;
                                    display: flex;
                                    align-items: center;
                                    color: rgba($color: #05061e, $alpha: 0.5);

                                    @media (max-width: 600px) {
                                        font-size: 1.75rem;
                                    }
                                }

                                &:focus {
                                    border: 1px solid #fe6f42;

                                    &+button {
                                        background-color: rgba($color: #fe6f42, $alpha: 0.1);
                                        color: #fe6f42;
                                    }
                                }
                            }

                            button {
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                right: 0;
                                background: rgba($color: #05061e, $alpha: 0.03);
                                border-radius: 4px;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 1.4rem;
                                line-height: 170%;
                                display: flex;
                                align-items: center;
                                text-align: center;
                                color: rgba($color: #05061e, $alpha: 0.75);
                                padding: 8px 10px;
                                margin-right: 4px;
                                height: 4rem;
                                transition: all 0.3s;

                                @media (max-width: 600px) {
                                    font-size: 1.75rem;
                                    height: 5rem;
                                }
                            }
                        }
                    }
                }
            }

            .text {
                display: flex;
                align-items: center;
                font-style: normal;
                font-weight: 500;
                font-size: 1.6rem;
                line-height: 170%;
                color: rgba($color: #05061e, $alpha: 0.75);

                @media (max-width: 768px) {
                    font-size: 1.5rem;
                }

                @media (max-width: 600px) {
                    font-size: 2rem;
                }

                svg {
                    margin-left: 4px;
                }
            }

            .price {
                font-style: normal;
                font-weight: 500;
                font-size: 1.6rem;
                line-height: 170%;
                text-align: right;
                color: #fe6f42;

                @media (max-width: 600px) {
                    font-size: 2rem;
                }
            }
        }

        .button-container {
            display: flex;
            width: 100%;
            background: #fff;
            z-index: 9;

            @media (max-width: 768px) {
                padding: 1.2rem;
            }

            @media (max-width: 768px) {
                padding: 1.5rem;
            }
        }
    }

    .order-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: none;
        outline: none;
        width: 100%;
        height: 5rem;
        background: #fe6f42;
        color: #fff;
        border-radius: 4px;
        font-style: normal;
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 170%;
        margin-top: 3.2rem;

        @media (max-width: 768px) {
            height: 4.8rem;
            margin-top: 0;
        }

        @media (max-width: 600px) {
            font-size: 1.75rem;
            height: 6rem;
        }
    }

    .order-as-guest {
        cursor: pointer;
        border: none;
        outline: none;
        width: 100%;
        height: 5rem;
        color: #fff;
        background: #05b136;
        border-radius: 4px;
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 170%;
        margin-top: 3.2rem;
        margin-right: 1.6rem;

        @media (max-width: 768px) {
            height: 4.8rem;
            margin-top: 0;
        }

        @media (max-width: 600px) {
            font-size: 1.75rem;
            height: 6rem;
            margin-right: 2rem;
        }
    }

    .cart-sidebar_main {
        .cart-content {
            margin-bottom: 1.6rem;
            background: white;
            //padding: 2.4rem 2.4rem 3.2rem 2.4rem;
            border: 1px solid rgba(5, 6, 30, 0.07);
            padding-bottom: 2.4rem;

            @media (max-width: 768px) {
                display: none;
            }

            .side-heading {
                font-style: normal;
                font-weight: normal;
                font-size: 2.4rem;
                line-height: 170%;
                display: flex;
                align-items: center;
                color: #05061e;
                background-color: rgba($color: #05061e, $alpha: 0.07);
                height: 8.4rem;
                padding-left: 2.4rem;
                margin-bottom: 2.4rem;
            }

            .amount,
            .discount {
                font-style: normal;
                font-weight: normal;
                font-size: 1.6rem;
                line-height: 170%;
                color: rgba($color: #05061e, $alpha: 0.75);
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 2.4rem;

                .amount-content,
                .discount-content {
                    color: rgba($color: #05061e, $alpha: 0.75);
                }

                .discount-price {
                    font-weight: 500;
                    color: #ff2121;
                }

                .amount-price {
                    font-weight: 500;
                    color: #05061e;
                }
            }

            .amount {
                margin-bottom: 1.6rem;
            }

            .discount {
                padding-bottom: 3.2rem;
                border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);
            }

            .cart-total {
                font-style: normal;
                font-weight: normal;
                font-size: 2rem;
                line-height: 170%;
                color: rgba($color: #05061e, $alpha: 0.75);
                margin-top: 3.2rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 2.4rem;

                .price {
                    color: #fe6f42;
                    font-weight: 500;
                }
            }

            .discount-coupon {
                margin-top: 3.2rem;
                padding: 0 2.4rem;

                .container {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 4.8rem;
                    position: relative;

                    input {
                        border: 1px solid rgba(5, 6, 30, 0.07);
                        box-sizing: border-box;
                        border-radius: 4px;
                        height: 100%;
                        width: 100%;
                        padding-left: 1.2rem;
                        padding-right: 4rem;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 1.6rem;
                        line-height: 170%;
                        display: flex;
                        align-items: center;
                        color: rgba($color: #05061e, $alpha: 0.75);
                        outline: none;
                        transition: all 0.3s;

                        &::placeholder {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 1.6rem;
                            line-height: 170%;
                            display: flex;
                            align-items: center;
                            color: rgba($color: #05061e, $alpha: 0.5);
                        }

                        &:focus {
                            border: 1px solid #fe6f42;

                            &+button {
                                background-color: rgba($color: #fe6f42, $alpha: 0.1);
                                color: #fe6f42;
                            }
                        }
                    }

                    button {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 0;
                        background: rgba($color: #05061e, $alpha: 0.03);
                        border-radius: 4px;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 1.4rem;
                        line-height: 170%;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        color: rgba($color: #05061e, $alpha: 0.75);
                        padding: 8px 10px;
                        margin-right: 4px;
                        height: 4rem;
                        transition: all 0.3s;
                    }
                }
            }

            .buttons-container {
                padding: 0 2.4rem;

                .order-btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    border: none;
                    outline: none;
                    width: 100%;
                    height: 5rem;
                    background: #fe6f42;
                    color: #fff;
                    border-radius: 4px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 1.4rem;
                    line-height: 170%;
                    margin-top: 3.2rem;

                    @media (max-width: 768px) {
                        height: 4.8rem;
                        width: 12.8rem;
                        margin-top: 0;
                    }

                    @media (max-width: 600px) {
                        font-size: 1.75rem;
                        height: 6rem;
                        width: 16rem;
                    }
                }
            }
        }

        .order-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border: none;
            outline: none;
            width: 100%;
            height: 5rem;
            background: #fe6f42;
            color: #fff;
            border-radius: 4px;
            font-style: normal;
            font-weight: 600;
            font-size: 1.4rem;
            line-height: 170%;
            margin-top: 3.2rem;

            @media (max-width: 768px) {
                height: 4.8rem;
                width: 12.8rem;
                margin-top: 0;
            }

            @media (max-width: 600px) {
                font-size: 1.75rem;
                height: 6rem;
                width: 16rem;
            }
        }

        .need-register {
            font-style: normal;
            font-weight: normal;
            font-size: 1.4rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba($color: #05061e, $alpha: 0.5);
            padding-top: 1.6rem;

            @media (max-width: 768px) {
                padding-top: 0;
            }
        }

        .cart-sidebar-accordion {
            margin-top: 1.6rem;
            max-height: fit-content;

            @media (max-width: 768px) {
                display: none;
            }

            article {
                background-color: #fff;

                .acc-cont {
                    padding: 2.4rem;
                }

                header {
                    background: rgba(5, 6, 30, 0.03);
                    height: 8rem;

                    button {
                        height: 2.4rem;
                        width: 2.4rem;

                        svg {
                            width: 2.4rem;
                            height: 2.4rem;
                            object-fit: cover;
                        }
                    }
                }
            }
        }

        .cart-sidebar-second {
            grid-column: 9/-1;
            height: auto;

            @media (max-width: 768px) {
                display: none;
            }

            .cart-sidebar_address {
                background-color: #fff;
                border-radius: 8px;
                padding: 1.6rem 2.4rem 2.4rem 2.4rem;

                .header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 1.2rem;

                    p {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 2rem;
                        line-height: 170%;
                        color: rgba($color: #0c0d1e, $alpha: 0.75);
                    }

                    button {
                        border: none;
                        outline: none;
                        cursor: pointer;
                        background: none;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 1.2rem;
                        line-height: 170%;
                        text-align: center;
                        color: #fe4242;
                    }
                }

                .address-container {
                    display: flex;
                    flex-direction: column;

                    .address-form-group {
                        display: flex;
                        width: 100%;
                        margin-bottom: 1.6rem;

                        input {
                            display: none;

                            &:checked+label {
                                border: 1px solid #fe4242;

                                .title {
                                    color: #fe4242;
                                }
                            }
                        }

                        label {
                            border: 1px solid rgba($color: #0c0d1e, $alpha: 0.1);
                            border-radius: 8px;
                            padding: 1.2rem;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 1.2rem;
                            line-height: 170%;
                            width: 100%;
                            cursor: pointer;

                            .title {
                                font-style: normal;
                                font-weight: 600;
                                font-size: 1.4rem;
                                line-height: 170%;
                                color: rgba($color: #0c0d1e, $alpha: 0.75);
                            }

                            .address {
                                color: rgba($color: #0c0d1e, $alpha: 0.5);
                                padding-bottom: 0.8rem;
                            }

                            .mobile {
                                color: rgba($color: #0c0d1e, $alpha: 0.75);
                            }
                        }
                    }
                }
            }
        }
    }
}

.address-notification {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 8.2rem 2.4rem 2.4rem 2.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 999999999999999999;
    max-width: 40.8rem;
    min-width: 29.6rem;

    @media (max-width: 600px) {
        padding: 6rem 3rem 3rem 3rem;
        max-width: 51rem;
        min-width: 37rem;
    }

    .img-container {
        width: 7.2rem;
        height: 7.2rem;
        margin-bottom: 1.6rem;

        @media (max-width: 600px) {
            width: 9rem;
            height: 9rem;
            margin-bottom: 1rem;
        }

        svg {
            width: 7.2rem;
            height: 7.2rem;
            object-fit: cover;

            @media (max-width: 600px) {
                width: 9rem;
                height: 9rem;
            }
        }
    }

    .title {
        font-style: normal;
        font-weight: 500;
        font-size: 2.4rem;
        line-height: 170%;
        display: flex;
        align-items: center;
        text-align: center;
        color: #05061e;
        margin-bottom: 6px;

        @media (max-width: 600px) {
            font-size: 2.5rem;
        }
    }

    .subtitle {
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 170%;
        text-align: center;
        color: rgba($color: #05061e, $alpha: 0.5);

        @media (max-width: 600px) {
            font-size: 1.75rem;
        }

        .bold-text {
            font-weight: 500;
        }
    }

    .warning {
        font-style: normal;
        font-weight: normal;
        font-size: 1.4rem;
        line-height: 170%;
        text-align: center;
        color: #fe4242;

        .mobile:after {
            display: inline-block;
            content: "";
            width: 3px;
            height: 3px;
            background: #fe4242;
            border-radius: 50%;
            margin: 0 1.2rem;
            vertical-align: middle;
        }
    }

    .addInfo,
    .addInfo-mobile {
        display: inline-block;
        width: 36rem;
        height: 5rem;
        border: none;
        outline: none;
        cursor: pointer;
        background: #fe4242;
        border-radius: 8px;
        font-style: normal;
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 170%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        text-align: center;
        margin-top: 3.2rem;

        @media (max-width: 600px) {
            width: 30rem;
        }
    }

    .add-to-cart {
        width: 100%;
        height: 4.8rem;
        background: #fe6f42;
        border-radius: 4px;
        font-style: normal;
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 170%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #ffffff;
        margin-top: 3.2rem;

        @media (max-width: 600px) {
            height: 6rem;
            font-size: 1.75rem;
            margin-top: 3rem;
        }
    }

    .cross-close {
        border: none;
        outline: none;
        cursor: pointer;
        background: none;
        position: absolute;
        top: 24px;
        right: 24px;
        width: 2.4rem;
        height: 2.4rem;

        @media (max-width: 600px) {
            width: 2.5rem;
            height: 2.5rem;
        }

        img {
            width: 2.4rem;
            height: 2.4rem;
            object-fit: cover;

            @media (max-width: 600px) {
                width: 2.5rem;
                height: 2.5rem;
            }
        }
    }
}

.size-table {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 2.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 999999999999999999;
    max-width: 76rem;
    min-width: 29.6rem;

    &__img {
        max-width: 100%;
        max-height: 80vh;
    }

    @media (max-width: 767px) {
        min-width: 90vw;

        &__img {
            max-height: 90vh;
        }
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba($color: #05061e, $alpha: 0.75);
    width: 100vw;
    height: 100vh;
    z-index: 999999999999999;
}

.overlay.search {
    top: 60px;

    .search-input_container {
        background: #fff;
        z-index: 999;
        height: 7.2rem;
        padding: 1.2rem;

        @media (max-width: 600px) {
            height: 9rem;
            padding: 1.5rem;
        }
    }
}

.mobile-address {
    width: 100%;
    display: none;
    grid-column: 1/-1;
    padding: 0 2rem;
    margin-top: 8px;
    flex-direction: column;

    @media (max-width: 768px) {
        display: flex;
    }

    .header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.2rem;

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 2rem;
            line-height: 170%;
            color: rgba($color: #0c0d1e, $alpha: 0.75);

            @media (max-width: 600px) {
                font-size: 1.75rem;
            }
        }

        button {
            border: none;
            outline: none;
            cursor: pointer;
            background: none;
            font-style: normal;
            font-weight: normal;
            font-size: 1.2rem;
            line-height: 170%;
            text-align: center;
            color: #fe4242;

            @media (max-width: 600px) {
                font-size: 1.5rem;
            }
        }
    }

    .address-container {
        display: flex;
        flex-direction: column;

        .address-form-group {
            display: flex;
            width: 100%;
            margin-bottom: 1.6rem;

            input {
                display: none;

                &:checked+label {
                    border: 1px solid #fe4242;

                    .title {
                        color: #fe4242;
                    }
                }
            }

            label {
                border: 1px solid rgba($color: #0c0d1e, $alpha: 0.1);
                border-radius: 8px;
                padding: 1.2rem;
                font-style: normal;
                font-weight: normal;
                font-size: 1.2rem;
                line-height: 170%;
                width: 100%;
                cursor: pointer;
                background: #fff;

                @media (max-width: 600px) {
                    padding: 1.5rem;
                    height: auto;
                }

                .title {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 1.4rem;
                    line-height: 170%;
                    color: rgba($color: #0c0d1e, $alpha: 0.75);

                    @media (max-width: 600px) {
                        font-size: 1.75rem;
                        line-height: 170%;
                    }
                }

                .address {
                    color: rgba($color: #0c0d1e, $alpha: 0.5);
                    padding-bottom: 0.8rem;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    width: 100%;

                    @media (max-width: 600px) {
                        font-size: 1.5rem;
                        padding-bottom: 1rem;
                        line-height: 170%;
                    }
                }

                .mobile {
                    color: rgba($color: #0c0d1e, $alpha: 0.75);

                    @media (max-width: 600px) {
                        font-size: 1.5rem;
                        line-height: 170%;
                    }
                }
            }
        }
    }
}

.popupfast {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: #fff;
    z-index: 9999998;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 768px) {
        background: #fff;
        top: 30px;
        left: 0;
        height: 100vh;
        overflow-y: scroll;
    }
}

.popup_inner.fast {
    h1.title {
        color: rgba($color: #0c0d1e, $alpha: 0.75);
    }

    h3.subtitle {
        color: rgba($color: #0c0d1e, $alpha: 0.4);
    }

    form {
        button.confirm-button {
            color: #ffffff;
            background: #00d67c;
        }
    }
}

.empty-address {
    background-color: #f3f3f4;
    border-radius: 8px;
    padding: 1.8rem 0 2.2rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 600px) {
        padding: 2rem 0 2rem 0;
    }

    &.red-border {
        border: 1px solid #fe4242;
    }

    p {
        font-style: normal;
        font-weight: normal;
        font-size: 1.2rem;
        line-height: 170%;
        text-align: center;
        color: rgba($color: #0c0d1e, $alpha: 0.5);
        margin-bottom: 4px;

        @media (max-width: 600px) {
            font-size: 1.5rem;
        }
    }

    button {
        border: none;
        outline: none;
        background: none;
        cursor: pointer;
        font-style: normal;
        font-weight: normal;
        font-size: 1.2rem;
        line-height: 170%;
        text-align: center;
        color: #fe4242;

        @media (max-width: 600px) {
            font-size: 1.5rem;
        }
    }

    &.white {
        background: #fff;
    }

    &.white.red-border {
        border: 1px solid #fe4242;
    }
}

.white {
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    width: 100%;
    height: 100%;
    z-index: 9999999999;
}

.popup.address {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999999;
}

.cart-wrapper {
    background: #fff;
    width: 100%;
    //position: relative;

    .notification {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: white;
        padding: 4.8rem 2.4rem 2.4rem 2.4rem;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        z-index: 999999999999999999;

        @media (max-width: 600px) {
            padding-top: 2rem;
        }

        .img-container {
            width: 6.4rem;
            height: 6.4rem;
            margin-bottom: 2.4rem;

            @media (max-width: 600px) {
                width: 8rem;
                height: 8rem;
                margin-bottom: 3rem;
            }

            img {
                width: 6.4rem;
                height: 6.4rem;
                object-fit: cover;

                @media (max-width: 600px) {
                    width: 8rem;
                    height: 8rem;
                }
            }
        }

        .title {
            font-style: normal;
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 170%;
            color: rgba($color: #0c0d1e, $alpha: 0.75);

            @media (max-width: 600px) {
                font-size: 1.75rem;
            }
        }

        .subtitle {
            font-style: normal;
            font-weight: normal;
            font-size: 1.2rem;
            line-height: 170%;
            text-align: center;
            color: rgba($color: #0c0d1e, $alpha: 0.5);

            @media (max-width: 600px) {
                font-size: 1.5rem;
            }
        }

        .addInfo,
        .addInfo-mobile {
            display: inline-block;
            width: 36rem;
            height: 5rem;
            border: none;
            outline: none;
            cursor: pointer;
            background: #fe4242;
            border-radius: 8px;
            font-style: normal;
            font-weight: 600;
            font-size: 1.4rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            text-align: center;
            margin-top: 3.2rem;

            @media (max-width: 600px) {
                width: 30rem;
            }
        }

        .addInfo-mobile {
            display: none;

            @media (max-width: 768px) {
                display: block;
            }
        }

        .addInfo {
            display: block;

            @media (max-width: 768px) {
                display: none;
            }
        }

        .cross-close {
            border: none;
            outline: none;
            cursor: pointer;
            background: none;
            position: absolute;
            top: 10%;
            right: 10%;
        }
    }
}

.empty-cart {
    width: 112.8rem;
    margin: 0 auto;
    height: 48rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 5rem;

    @media (max-width: 1128px) {
        width: 100%;
        padding: 0 2rem;

        .breadcrumb {
            padding-left: 0;
            padding-right: 0;
        }
    }

    @media (max-width: 768px) {
        position: relative;
        padding: 0 1.2rem;
    }

    @media (max-width: 600px) {
        position: relative;
        padding: 0 1.5rem;
    }

    .empty-cart-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: rgba($color: #05061e, $alpha: 0.03);
        border-radius: 4px;

        @media (max-width: 768px) {
            padding: 0 1.2rem;
        }

        @media (max-width: 600px) {
            padding: 0 1.5rem;
        }

        .logo-container {
            width: 7.2rem;
            height: 7.2rem;
            margin-bottom: 1.6rem;

            @media (max-width: 600px) {
                width: 9rem;
                height: 9rem;
                margin-bottom: 1rem;
            }

            svg {
                width: 7.2rem;
                height: 7.2rem;
                object-fit: cover;

                @media (max-width: 600px) {
                    width: 9rem;
                    height: 9rem;
                }
            }
        }

        .title {
            font-style: normal;
            font-weight: 500;
            font-size: 2.4rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            color: #05061e;
            margin-bottom: 8px;

            @media (max-width: 600px) {
                font-size: 2.5rem;
            }
        }

        .subtitle {
            font-style: normal;
            font-weight: normal;
            font-size: 1.6rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            text-align: center;
            color: rgba($color: #05061e, $alpha: 0.5);
            margin-bottom: 3.2rem;

            @media (max-width: 600px) {
                font-size: 1.75rem;
                margin-bottom: 2.75rem;
            }
        }

        a {
            font-style: normal;
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: #ffffff;
            background: #fe6f42;
            border-radius: 4px;
            height: 4.8rem;
            width: 16.8rem;

            @media (max-width: 600px) {
                font-size: 1.75rem;
                height: 6rem;
                width: 100%;
            }
        }
    }
}

.cart-container {
    width: 112.8rem;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 0 2.4rem;
    margin: 0 auto;
    padding-bottom: 24.8rem;

    @media (max-width: 1128px) {
        width: 100%;
        padding: 0 2rem;
    }

    @media (min-width: 768px) {
        .breadcrumb.border {
            padding-left: 0;
            padding-right: 0;
        }
    }

    @media (max-width: 768px) {
        position: relative;
        padding: 0;

        .breadcrumb.border {
            border-bottom: none;
        }
    }
}

.cart-banner {
    grid-column: 1/-1;
    height: 14.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 4rem;

    @media (max-width: 768px) {
        height: auto;
        padding: 0 2rem;
        padding-top: 0.8rem;
        padding-bottom: 1.2rem;
        border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);
        margin-bottom: 0;
    }

    @media (max-width: 600px) {
        height: auto;
        padding-top: 1rem;
        padding-bottom: 1.5rem;
        border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);
    }

    .title {
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 170%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #05061e;
        margin-bottom: 8px;

        @media (max-width: 768px) {
            font-size: 2.4rem;
        }

        @media (max-width: 600px) {
            font-size: 3rem;
        }

        a {
            font-style: normal;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: rgba($color: #05061e, $alpha: 0.5);
            height: 4.8rem;
            border: 1px solid rgba($color: #05061e, $alpha: 0.07);
            border-radius: 4px;
            width: 19.2rem;

            svg {
                margin-right: 1.6rem;
            }
        }
    }

    .subtitle {
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 170%;
        color: rgba($color: #05061e, $alpha: 0.5);

        @media (max-width: 768px) {
            display: none;
        }
    }
}

.cart-table-wrapper {
    grid-column: 1/9;
    height: fit-content;

    @media (max-width: 768px) {
        grid-column: 1/-1;
        padding: 0 2rem;
    }

    .cart-table-header {
        background-color: rgba($color: #05061e, $alpha: 0.03);
        padding: 2rem 2.4rem;
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 170%;
        display: flex;
        align-items: center;
        color: rgba($color: #05061e, $alpha: 0.75);
        margin-bottom: 2.4rem;

        @media (max-width: 768px) {
            padding: 0;
            padding-top: 1.2rem;
            padding-bottom: 1.6rem;
            background-color: #fff;
        }

        @media (max-width: 600px) {
            padding: 0;
            padding-top: 1.5rem;
            padding-bottom: 2rem;
            background-color: #fff;
            font-size: 2rem;
        }
    }
}

.cart-sidebar {
    height: fit-content;
    grid-column: 9/-1;

    @media (max-width: 768px) {
        width: 100%;
        height: 7.2rem;
        grid-column: 1/-1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        bottom: -1%;
        left: 0;
        border-radius: 0;
        padding: 0 2rem;
        z-index: 9999998;
        background-color: #fff;
        border-top: 1px solid rgba($color: #0c0d1e, $alpha: 0.05);
    }

    @media (max-width: 768px) {
        height: 12.5rem;
        padding: 0;
    }

    @media (max-width: 768px) {
        height: 15.625rem;
    }

    .cart-content-mobile {
        display: none;
        flex-direction: column;
        height: auto;

        @media (max-width: 768px) {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
        }

        .total-content {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding-bottom: 1.2rem;
            border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);
            position: relative;

            @media (max-width: 768px) {
                padding-left: 1.2rem;
                padding-right: 1.2rem;
            }

            @media (max-width: 600px) {
                padding: 1.5rem;
                padding-top: 0;
            }

            .cart-content-popup {
                position: absolute;
                bottom: 0;
                left: 0;
                //visibility: hidden;
                height: 100vh;
                width: 100vw;
                background-color: transparent;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                transform: translateY(100%);
                transition: all 0.5s;

                .cart-detail-close-btn {
                    width: 4rem;
                    height: 4rem;
                    margin-bottom: 4px;

                    @media (max-width: 600px) {
                        width: 5rem;
                        height: 5rem;
                    }

                    svg {
                        width: 4rem;
                        height: 4rem;
                        object-fit: cover;

                        @media (max-width: 600px) {
                            width: 5rem;
                            height: 5rem;
                        }
                    }
                }

                &.visible {
                    visibility: visible;
                    transform: translateY(0);
                    background-color: transparent;
                    animation-name: cart;
                    animation-duration: 0.3s;
                    //animation-delay: .3s;
                    animation-iteration-count: 1;
                    animation-fill-mode: both;
                }

                @keyframes cart {
                    from {
                        background-color: transparent;
                    }

                    to {
                        background-color: rgba($color: #05061e, $alpha: 0.5);
                    }
                }

                .cart-detail-popup-container {
                    height: auto;
                    width: 100%;
                    background-color: #fff;
                    padding-bottom: 2.4rem;

                    .side-heading {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 2.4rem;
                        line-height: 170%;
                        display: flex;
                        align-items: center;
                        color: #05061e;
                        background-color: rgba($color: #05061e, $alpha: 0.07);
                        height: 8.4rem;
                        padding-left: 2.4rem;
                        margin-bottom: 2.4rem;

                        @media (max-width: 600px) {
                            font-weight: 500;
                            font-size: 2.5rem;
                            padding: 0;
                            padding-left: 1.5rem;
                            margin-bottom: 4rem;
                        }
                    }

                    .amount,
                    .discount {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 1.6rem;
                        line-height: 170%;
                        color: rgba($color: #05061e, $alpha: 0.75);
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 0 2.4rem;

                        @media (max-width: 600px) {
                            font-size: 1.75rem;
                            padding: 0 1.5rem;
                        }

                        .amount-content,
                        .discount-content {
                            color: rgba($color: #05061e, $alpha: 0.75);

                            @media (max-width: 600px) {
                                font-size: 1.75rem;
                            }
                        }

                        .discount-price {
                            font-weight: 500;
                            color: #ff2121;

                            @media (max-width: 600px) {
                                font-size: 1.75rem;
                            }
                        }

                        .amount-price {
                            font-weight: 500;
                            color: #05061e;

                            @media (max-width: 600px) {
                                font-size: 1.75rem;
                            }
                        }
                    }

                    .amount {
                        margin-bottom: 1.6rem;

                        @media (max-width: 600px) {
                            margin-bottom: 2rem;
                        }
                    }

                    .discount {
                        padding-bottom: 3.2rem;
                        border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);

                        @media (max-width: 600px) {
                            padding-bottom: 4rem;
                        }
                    }

                    .cart-total {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 2rem;
                        line-height: 170%;
                        color: rgba($color: #05061e, $alpha: 0.75);
                        margin-top: 3.2rem;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 0 2.4rem;

                        @media (max-width: 600px) {
                            padding: 0 1.5rem;
                            margin-top: 3rem;
                        }

                        .price {
                            color: #fe6f42;
                            font-weight: 500;
                        }
                    }

                    .discount-coupon {
                        margin-top: 3.2rem;
                        padding: 0 2.4rem;

                        @media (max-width: 600px) {
                            margin-top: 3rem;
                            padding: 0 1.5rem;
                        }

                        .container {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            height: 4.8rem;
                            position: relative;

                            @media (max-width: 600px) {
                                height: 6rem;
                            }

                            input {
                                border: 1px solid rgba(5, 6, 30, 0.07);
                                box-sizing: border-box;
                                border-radius: 4px;
                                height: 100%;
                                width: 100%;
                                padding-left: 1.2rem;
                                padding-right: 4rem;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 1.6rem;
                                line-height: 170%;
                                display: flex;
                                align-items: center;
                                color: rgba($color: #05061e, $alpha: 0.75);
                                outline: none;
                                transition: all 0.3s;

                                @media (max-width: 600px) {
                                    font-size: 1.75rem;
                                }

                                &::placeholder {
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: 1.6rem;
                                    line-height: 170%;
                                    display: flex;
                                    align-items: center;
                                    color: rgba($color: #05061e, $alpha: 0.5);

                                    @media (max-width: 600px) {
                                        font-size: 1.75rem;
                                    }
                                }

                                &:focus {
                                    border: 1px solid #fe6f42;

                                    &+button {
                                        background-color: rgba($color: #fe6f42, $alpha: 0.1);
                                        color: #fe6f42;
                                    }
                                }
                            }

                            button {
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                right: 0;
                                background: rgba($color: #05061e, $alpha: 0.03);
                                border-radius: 4px;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 1.4rem;
                                line-height: 170%;
                                display: flex;
                                align-items: center;
                                text-align: center;
                                color: rgba($color: #05061e, $alpha: 0.75);
                                padding: 8px 10px;
                                margin-right: 4px;
                                height: 4rem;
                                transition: all 0.3s;

                                @media (max-width: 600px) {
                                    font-size: 1.75rem;
                                    height: 5rem;
                                }
                            }
                        }
                    }
                }
            }

            .text {
                display: flex;
                align-items: center;
                font-style: normal;
                font-weight: 500;
                font-size: 1.6rem;
                line-height: 170%;
                color: rgba($color: #05061e, $alpha: 0.75);

                @media (max-width: 768px) {
                    font-size: 1.5rem;
                }

                @media (max-width: 600px) {
                    font-size: 2rem;
                }

                svg {
                    margin-left: 4px;
                }
            }

            .price {
                font-style: normal;
                font-weight: 500;
                font-size: 1.6rem;
                line-height: 170%;
                text-align: right;
                color: #fe6f42;

                @media (max-width: 600px) {
                    font-size: 2rem;
                }
            }
        }

        .button-container {
            display: flex;
            width: 100%;
            background: #fff;
            z-index: 9;

            @media (max-width: 768px) {
                padding: 1.2rem;
            }

            @media (max-width: 768px) {
                padding: 1.5rem;
            }
        }
    }

    .order-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: none;
        outline: none;
        width: 100%;
        height: 5rem;
        background: #fe6f42;
        color: #fff;
        border-radius: 4px;
        font-style: normal;
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 170%;
        margin-top: 3.2rem;

        @media (max-width: 768px) {
            height: 4.8rem;
            margin-top: 0;
        }

        @media (max-width: 600px) {
            font-size: 1.75rem;
            height: 6rem;
        }
    }

    .order-as-guest {
        cursor: pointer;
        border: none;
        outline: none;
        width: 100%;
        height: 5rem;
        color: #fff;
        background: #05b136;
        border-radius: 4px;
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 170%;
        margin-top: 3.2rem;
        margin-right: 1.6rem;

        @media (max-width: 768px) {
            height: 4.8rem;
            margin-top: 0;
        }

        @media (max-width: 600px) {
            font-size: 1.75rem;
            height: 6rem;
            margin-right: 2rem;
        }
    }

    .cart-sidebar_main {
        .cart-content {
            margin-bottom: 1.6rem;
            background: white;
            //padding: 2.4rem 2.4rem 3.2rem 2.4rem;
            border: 1px solid rgba(5, 6, 30, 0.07);
            padding-bottom: 2.4rem;

            @media (max-width: 768px) {
                display: none;
            }

            .side-heading {
                font-style: normal;
                font-weight: normal;
                font-size: 2.4rem;
                line-height: 170%;
                display: flex;
                align-items: center;
                color: #05061e;
                background-color: rgba($color: #05061e, $alpha: 0.07);
                height: 8.4rem;
                padding-left: 2.4rem;
                margin-bottom: 2.4rem;
            }

            .amount,
            .discount {
                font-style: normal;
                font-weight: normal;
                font-size: 1.6rem;
                line-height: 170%;
                color: rgba($color: #05061e, $alpha: 0.75);
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 2.4rem;

                .amount-content,
                .discount-content {
                    color: rgba($color: #05061e, $alpha: 0.75);
                }

                .discount-price {
                    font-weight: 500;
                    color: #ff2121;
                }

                .amount-price {
                    font-weight: 500;
                    color: #05061e;
                }
            }

            .amount {
                margin-bottom: 1.6rem;
            }

            .discount {
                padding-bottom: 3.2rem;
                border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);
            }

            .cart-total {
                font-style: normal;
                font-weight: normal;
                font-size: 2rem;
                line-height: 170%;
                color: rgba($color: #05061e, $alpha: 0.75);
                margin-top: 3.2rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 2.4rem;

                .price {
                    color: #fe6f42;
                    font-weight: 500;
                }
            }

            .discount-coupon {
                margin-top: 3.2rem;
                padding: 0 2.4rem;

                .container {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 4.8rem;
                    position: relative;

                    input {
                        border: 1px solid rgba(5, 6, 30, 0.07);
                        box-sizing: border-box;
                        border-radius: 4px;
                        height: 100%;
                        width: 100%;
                        padding-left: 1.2rem;
                        padding-right: 4rem;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 1.6rem;
                        line-height: 170%;
                        display: flex;
                        align-items: center;
                        color: rgba($color: #05061e, $alpha: 0.75);
                        outline: none;
                        transition: all 0.3s;

                        &::placeholder {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 1.6rem;
                            line-height: 170%;
                            display: flex;
                            align-items: center;
                            color: rgba($color: #05061e, $alpha: 0.5);
                        }

                        &:focus {
                            border: 1px solid #fe6f42;

                            &+button {
                                background-color: rgba($color: #fe6f42, $alpha: 0.1);
                                color: #fe6f42;
                            }
                        }
                    }

                    button {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 0;
                        background: rgba($color: #05061e, $alpha: 0.03);
                        border-radius: 4px;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 1.4rem;
                        line-height: 170%;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        color: rgba($color: #05061e, $alpha: 0.75);
                        padding: 8px 10px;
                        margin-right: 4px;
                        height: 4rem;
                        transition: all 0.3s;
                    }
                }
            }

            .buttons-container {
                padding: 0 2.4rem;

                .order-btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    border: none;
                    outline: none;
                    width: 100%;
                    height: 5rem;
                    background: #fe6f42;
                    color: #fff;
                    border-radius: 4px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 1.4rem;
                    line-height: 170%;
                    margin-top: 3.2rem;

                    @media (max-width: 768px) {
                        height: 4.8rem;
                        width: 12.8rem;
                        margin-top: 0;
                    }

                    @media (max-width: 600px) {
                        font-size: 1.75rem;
                        height: 6rem;
                        width: 16rem;
                    }
                }
            }
        }

        .order-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border: none;
            outline: none;
            width: 100%;
            height: 5rem;
            background: #fe6f42;
            color: #fff;
            border-radius: 4px;
            font-style: normal;
            font-weight: 600;
            font-size: 1.4rem;
            line-height: 170%;
            margin-top: 3.2rem;

            @media (max-width: 768px) {
                height: 4.8rem;
                width: 12.8rem;
                margin-top: 0;
            }

            @media (max-width: 600px) {
                font-size: 1.75rem;
                height: 6rem;
                width: 16rem;
            }
        }

        .need-register {
            font-style: normal;
            font-weight: normal;
            font-size: 1.4rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba($color: #05061e, $alpha: 0.5);
            padding-top: 1.6rem;

            @media (max-width: 768px) {
                padding-top: 0;
            }
        }

        .cart-sidebar-accordion {
            margin-top: 1.6rem;
            max-height: fit-content;

            @media (max-width: 768px) {
                display: none;
            }

            article {
                background-color: #fff;

                .acc-cont {
                    padding: 2.4rem;
                }

                header {
                    background: rgba(5, 6, 30, 0.03);
                    height: 8rem;

                    button {
                        height: 2.4rem;
                        width: 2.4rem;

                        svg {
                            width: 2.4rem;
                            height: 2.4rem;
                            object-fit: cover;
                        }
                    }
                }
            }
        }

        .cart-sidebar-second {
            grid-column: 9/-1;
            height: auto;

            @media (max-width: 768px) {
                display: none;
            }

            .cart-sidebar_address {
                background-color: #fff;
                border-radius: 8px;
                padding: 1.6rem 2.4rem 2.4rem 2.4rem;

                .header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 1.2rem;

                    p {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 2rem;
                        line-height: 170%;
                        color: rgba($color: #0c0d1e, $alpha: 0.75);
                    }

                    button {
                        border: none;
                        outline: none;
                        cursor: pointer;
                        background: none;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 1.2rem;
                        line-height: 170%;
                        text-align: center;
                        color: #fe4242;
                    }
                }

                .address-container {
                    display: flex;
                    flex-direction: column;

                    .address-form-group {
                        display: flex;
                        width: 100%;
                        margin-bottom: 1.6rem;

                        input {
                            display: none;

                            &:checked+label {
                                border: 1px solid #fe4242;

                                .title {
                                    color: #fe4242;
                                }
                            }
                        }

                        label {
                            border: 1px solid rgba($color: #0c0d1e, $alpha: 0.1);
                            border-radius: 8px;
                            padding: 1.2rem;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 1.2rem;
                            line-height: 170%;
                            width: 100%;
                            cursor: pointer;

                            .title {
                                font-style: normal;
                                font-weight: 600;
                                font-size: 1.4rem;
                                line-height: 170%;
                                color: rgba($color: #0c0d1e, $alpha: 0.75);
                            }

                            .address {
                                color: rgba($color: #0c0d1e, $alpha: 0.5);
                                padding-bottom: 0.8rem;
                            }

                            .mobile {
                                color: rgba($color: #0c0d1e, $alpha: 0.75);
                            }
                        }
                    }
                }
            }
        }
    }
}

.address-notification {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 8.2rem 2.4rem 2.4rem 2.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 999999999999999999;
    max-width: 40.8rem;
    min-width: 29.6rem;

    @media (max-width: 600px) {
        padding: 6rem 3rem 3rem 3rem;
        max-width: 51rem;
        min-width: 37rem;
    }

    .img-container {
        width: 7.2rem;
        height: 7.2rem;
        margin-bottom: 1.6rem;

        @media (max-width: 600px) {
            width: 9rem;
            height: 9rem;
            margin-bottom: 1rem;
        }

        svg {
            width: 7.2rem;
            height: 7.2rem;
            object-fit: cover;

            @media (max-width: 600px) {
                width: 9rem;
                height: 9rem;
            }
        }
    }

    .title {
        font-style: normal;
        font-weight: 500;
        font-size: 2.4rem;
        line-height: 170%;
        display: flex;
        align-items: center;
        text-align: center;
        color: #05061e;
        margin-bottom: 6px;

        @media (max-width: 600px) {
            font-size: 2.5rem;
        }
    }

    .subtitle {
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 170%;
        text-align: center;
        color: rgba($color: #05061e, $alpha: 0.5);

        @media (max-width: 600px) {
            font-size: 1.75rem;
        }

        .bold-text {
            font-weight: 500;
        }
    }

    .warning {
        font-style: normal;
        font-weight: normal;
        font-size: 1.4rem;
        line-height: 170%;
        text-align: center;
        color: #fe4242;

        .mobile:after {
            display: inline-block;
            content: "";
            width: 3px;
            height: 3px;
            background: #fe4242;
            border-radius: 50%;
            margin: 0 1.2rem;
            vertical-align: middle;
        }
    }

    .addInfo,
    .addInfo-mobile {
        display: inline-block;
        width: 36rem;
        height: 5rem;
        border: none;
        outline: none;
        cursor: pointer;
        background: #fe4242;
        border-radius: 8px;
        font-style: normal;
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 170%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        text-align: center;
        margin-top: 3.2rem;

        @media (max-width: 600px) {
            width: 30rem;
        }
    }

    .add-to-cart {
        width: 100%;
        height: 4.8rem;
        background: #fe6f42;
        border-radius: 4px;
        font-style: normal;
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 170%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #ffffff;
        margin-top: 3.2rem;

        @media (max-width: 600px) {
            height: 6rem;
            font-size: 1.75rem;
            margin-top: 3rem;
        }
    }

    .cross-close {
        border: none;
        outline: none;
        cursor: pointer;
        background: none;
        position: absolute;
        top: 24px;
        right: 24px;
        width: 2.4rem;
        height: 2.4rem;

        @media (max-width: 600px) {
            width: 2.5rem;
            height: 2.5rem;
        }

        img {
            width: 2.4rem;
            height: 2.4rem;
            object-fit: cover;

            @media (max-width: 600px) {
                width: 2.5rem;
                height: 2.5rem;
            }
        }
    }
}

.size-table {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 2.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 999999999999999999;
    max-width: 76rem;
    min-width: 29.6rem;

    &__img {
        max-width: 100%;
        max-height: 80vh;
    }

    @media (max-width: 767px) {
        min-width: 90vw;

        &__img {
            max-height: 90vh;
        }
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba($color: #05061e, $alpha: 0.75);
    width: 100vw;
    height: 100vh;
    z-index: 999999999999999;
}

.overlay.search {
    top: 60px;

    .search-input_container {
        background: #fff;
        z-index: 999;
        height: 7.2rem;
        padding: 1.2rem;

        @media (max-width: 600px) {
            height: 9rem;
            padding: 1.5rem;
        }
    }
}

.mobile-address {
    width: 100%;
    display: none;
    grid-column: 1/-1;
    padding: 0 2rem;
    margin-top: 8px;
    flex-direction: column;

    @media (max-width: 768px) {
        display: flex;
    }

    .header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.2rem;

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 2rem;
            line-height: 170%;
            color: rgba($color: #0c0d1e, $alpha: 0.75);

            @media (max-width: 600px) {
                font-size: 1.75rem;
            }
        }

        button {
            border: none;
            outline: none;
            cursor: pointer;
            background: none;
            font-style: normal;
            font-weight: normal;
            font-size: 1.2rem;
            line-height: 170%;
            text-align: center;
            color: #fe4242;

            @media (max-width: 600px) {
                font-size: 1.5rem;
            }
        }
    }

    .address-container {
        display: flex;
        flex-direction: column;

        .address-form-group {
            display: flex;
            width: 100%;
            margin-bottom: 1.6rem;

            input {
                display: none;

                &:checked+label {
                    border: 1px solid #fe4242;

                    .title {
                        color: #fe4242;
                    }
                }
            }

            label {
                border: 1px solid rgba($color: #0c0d1e, $alpha: 0.1);
                border-radius: 8px;
                padding: 1.2rem;
                font-style: normal;
                font-weight: normal;
                font-size: 1.2rem;
                line-height: 170%;
                width: 100%;
                cursor: pointer;
                background: #fff;

                @media (max-width: 600px) {
                    padding: 1.5rem;
                    height: auto;
                }

                .title {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 1.4rem;
                    line-height: 170%;
                    color: rgba($color: #0c0d1e, $alpha: 0.75);

                    @media (max-width: 600px) {
                        font-size: 1.75rem;
                        line-height: 170%;
                    }
                }

                .address {
                    color: rgba($color: #0c0d1e, $alpha: 0.5);
                    padding-bottom: 0.8rem;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    width: 100%;

                    @media (max-width: 600px) {
                        font-size: 1.5rem;
                        padding-bottom: 1rem;
                        line-height: 170%;
                    }
                }

                .mobile {
                    color: rgba($color: #0c0d1e, $alpha: 0.75);

                    @media (max-width: 600px) {
                        font-size: 1.5rem;
                        line-height: 170%;
                    }
                }
            }
        }
    }
}

.popupfast {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: #fff;
    z-index: 9999998;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 768px) {
        background: #fff;
        top: 30px;
        left: 0;
        height: 100vh;
        overflow-y: scroll;
    }
}

.popup_inner.fast {
    h1.title {
        color: rgba($color: #0c0d1e, $alpha: 0.75);
    }

    h3.subtitle {
        color: rgba($color: #0c0d1e, $alpha: 0.4);
    }

    form {
        button.confirm-button {
            color: #ffffff;
            background: #00d67c;
        }
    }
}

.empty-address {
    background-color: #f3f3f4;
    border-radius: 8px;
    padding: 1.8rem 0 2.2rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 600px) {
        padding: 2rem 0 2rem 0;
    }

    &.red-border {
        border: 1px solid #fe4242;
    }

    p {
        font-style: normal;
        font-weight: normal;
        font-size: 1.2rem;
        line-height: 170%;
        text-align: center;
        color: rgba($color: #0c0d1e, $alpha: 0.5);
        margin-bottom: 4px;

        @media (max-width: 600px) {
            font-size: 1.5rem;
        }
    }

    button {
        border: none;
        outline: none;
        background: none;
        cursor: pointer;
        font-style: normal;
        font-weight: normal;
        font-size: 1.2rem;
        line-height: 170%;
        text-align: center;
        color: #fe4242;

        @media (max-width: 600px) {
            font-size: 1.5rem;
        }
    }

    &.white {
        background: #fff;
    }

    &.white.red-border {
        border: 1px solid #fe4242;
    }
}

.white {
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    width: 100%;
    height: 100%;
    z-index: 9999999999;
}

.popup.address {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999999;
}

.cart-table-row {
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    //padding: 1.6rem 2rem 1.6rem 1.6rem;
    width: 100%;
    margin-bottom: 2.4rem;
    position: relative;
    height: auto;
    border: 1px solid rgba(5, 6, 30, 0.07);

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
    }

    .table-info {
        position: relative;
        width: 100%;
        display: flex;

        @media (max-width: 768px) {
            grid-gap: 8px 12px;
            height: auto;
            border-bottom: 1px solid rgba($color: #0c0d1e, $alpha: 0.05);
            width: 100%;
        }

        .img-container {
            width: 11rem;
            margin-right: 2rem;

            @media (max-width: 768px) {
                width: 8.5rem;
                max-height: 150px;
            }

            img {
                width: 11rem;
                height: 100%;
                object-fit: cover;

                @media (max-width: 600px) {
                    max-height: 150px;
                }
            }
        }

        .table-info-content {
            grid-column: 2/-1;
            grid-row: 1/2;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;

            .content {
                font-style: normal;
                font-weight: 500;
                font-size: 1.6rem;
                line-height: 170%;
                color: rgba($color: #05061e, $alpha: 0.75);
                padding-top: 1.2rem;

                @media (max-width: 768px) {
                    display: flex;
                    flex-direction: column;
                }

                @media (max-width: 768px) {
                    font-size: 1.5rem;
                }

                .brand {
                    margin-bottom: 4px;
                }

                .name {
                    font-weight: normal;
                    margin-bottom: 4px;

                    @media (max-width: 768px) {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: wrap;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }

                .size {
                    font-weight: normal;
                    font-size: 1.4rem;

                    span:first-child {
                        display: inline-block;
                        margin-right: 12px;
                    }
                }
            }
        }

        .table-functions {
            position: relative;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            width: 100%;
            height: 100%;
            padding-top: 8px;
            padding-right: 1.6rem;

            .quantity {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                border: 1px solid rgba(5, 6, 30, 0.07);
                border-bottom: none;
                height: 4.8rem;

                button {
                    border: none;
                }

                @media(max-width: 768px) {
                    order: 2;
                    border: none;
                    border-left: 1px solid rgba(5, 6, 30, 0.07);
                }

                .count {
                    display: inline-block;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1.6rem;
                    line-height: 170%;
                    color: rgba($color: #05061e, $alpha: 0.75);
                    margin: 0 0.8rem;
                    display: flex;
                    align-items: center;
                    text-align: center;
                }

                button {
                    width: 3.2rem;
                    height: 100%;
                    background: none;
                    cursor: pointer;
                    outline: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;

                    svg {
                        width: 1.2rem;
                        height: 1.2rem;
                    }

                    &:hover {
                        background: rgba($color: #0c0d1e, $alpha: 0.05);

                        svg {
                            g {
                                opacity: 1;
                            }
                        }
                    }
                }

                &.red {
                    border: 1px solid #ff2121;

                    .inc.inc-btn {
                        position: relative;

                        svg {
                            path {
                                stroke: rgba($color: #ff2121, $alpha: 0.5);
                            }
                        }
                    }
                }
            }

            @media (max-width: 768px) {
                padding: 0 0;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-top: 1px solid rgba(18, 18, 29, 0.07);

                .quantity {
                    padding-top: 0;
                }
            }

            .table-price {
                font-style: normal;
                font-weight: 500;
                font-size: 1.6rem;
                line-height: 170%;
                display: flex;
                align-items: center;
                text-align: right;
                color: #fe6f42;

                @media (max-width: 768px) {
                    display: flex;
                    align-items: center;
                    padding-right: 4rem;
                }

                .normal-price {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1.6rem;
                    line-height: 170%;
                    color: #fe6f42;

                    &.line-through {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 1.4rem;
                        line-height: 170%;
                        color: rgba($color: #05061e, $alpha: 0.3);
                        text-decoration: line-through;
                    }
                }

                .after-discount {
                    margin-left: 8px;
                }
            }


        }
    }

    .delete-btn {
        position: absolute;
        right: 9px;
        top: 9px;
        outline: none;
        border: 1px solid rgba($color: #0c0d1e, $alpha: 0.05);
        background: none;
        width: 3.2rem;
        height: 3.2rem;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: absolute;

        @media (max-width: 768px) {
            top: 4px;
            right: 7px;
        }

        &:hover {
            background: rgba($color: #0c0d1e, $alpha: 0.05);

            svg {
                g {
                    opacity: 1;
                }
            }
        }
    }
}

.max-quantity {
    width: 26.6rem;
    height: 4.8rem;
    background: rgba($color: #ff2121, $alpha: 0.05);
    padding: 1.6rem;
    position: absolute;
    top: 0%;
    left: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1111;

    @media (max-width: 768px) {
        left: -116px;
        top: -104%;
        width: 14.8rem;
        height: 5rem;
        font-size: 1rem;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 8px;
    }

    @media (max-width: 600px) {
        height: 6.25rem;
        width: 18.5rem;
        top: -130%;
        left: -122px;
    }

    .icon {
        margin-right: 4px;
        display: flex;
        align-items: center;
    }

    .subtitle {
        font-style: normal;
        font-weight: normal;
        font-size: 1.2rem;
        line-height: 170%;
        display: flex;
        align-items: center;
        color: #ff2121;

        @media (max-width: 768px) {
            font-size: 1rem;
        }

        @media (max-width: 600px) {
            font-size: 1.2rem;
        }
    }
}

.max-quantity.higher {
    z-index: 1112;
}

.login-container {
    width: 100%;
    background: #f7f8f8;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 15rem;
    padding-top: 6.8rem;

    @media (max-width: 768px) {
        width: 100%;
        padding-top: 0;
        background: #fff;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    }

    @media(max-width: 600px) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .login-card {
        width: 40.8rem;
        height: auto;
        background: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 3.2rem 2.4rem 2.4rem 2.4rem;
        border-radius: 4px;

        @media (max-width: 768px) {
            width: 100%;
            padding: 3.2rem 0 2.4rem 0;
        }
    }
}

.login {
    width: 100%;

    .top {
        @media (max-width: 768px) {
            background: url("../public/assets/img/register-back-mobile.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            padding-top: 2.4rem;
            padding-bottom: 2rem;
            padding-left: 1.2rem;
            padding-right: 1.2rem;
        }
    }

    .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 2.4rem;

        @media (max-width: 600px) {
            margin-bottom: 2.5rem;
        }

        .title {
            font-style: normal;
            font-weight: 500;
            font-size: 2.4rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            text-align: center;
            color: rgba($color: #05061e, $alpha: 0.75);
            margin-bottom: 4px;

            @media (max-width: 768px) {
                color: #fff;
            }

            @media (max-width: 600px) {
                font-size: 2.5rem;
            }
        }

        .subtitle {
            font-style: normal;
            font-weight: normal;
            font-size: 1.4rem;
            line-height: 170%;
            text-align: center;
            color: rgba($color: #05061e, $alpha: 0.5);

            @media (max-width: 768px) {
                color: #fff;
            }

            @media (max-width: 600px) {
                font-size: 1.5rem;
            }
        }
    }

    .tabs-titles {
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(5, 6, 30, 0.03);
        margin-bottom: 3.2rem;
        height: 4.8rem;
        padding: 0 4px;
        border-radius: 4px;
        width: 100%;

        @media (max-width: 768px) {
            width: 100%;
            margin-bottom: 0;
            background: transparent;
        }

        .tab-title {
            font-style: normal;
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            color: rgba($color: #05061e, $alpha: 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 4rem;
            transition: all 0.3s;
            border-radius: 4px;
            cursor: pointer;

            @media (max-width: 768px) {
                width: 100%;
                color: #ffffff;
                background-color: rgba($color: #ffffff, $alpha: 0.2);

                &:first-child {
                    margin-right: 8px;
                }
            }

            @media (max-width: 600px) {
                font-size: 1.75rem;
                height: 6rem;
            }

            &.tab-title--active {
                color: #fe6f42;
                background: #fff;
            }
        }
    }
}

.form-control {
    width: 100%;
    display: flex;
    flex-direction: column;

    input {
        width: 100%;
        border: 1px solid rgba($color: #05061e, $alpha: 0.1);
        border-radius: 4px;
        outline: none;
        height: 4.8rem;
        padding: 0 1.6rem;
        transition: all 0.3s;
        color: #0c0d1e;
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;

        @media (max-width: 600px) {
            height: 6rem;
            font-size: 1.75rem;
        }

        &:focus {
            border: 1px solid rgba($color: #fe6f42, $alpha: 0.5);
        }

        &::placeholder {
            font-style: normal;
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 170%;
            color: rgba($color: #0c0d1e, $alpha: 0.5);

            @media (max-width: 600px) {
                font-size: 1.75rem;
                color: rgba($color: #0c0d1e, $alpha: 0.75);
            }
        }
    }

    label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 170%;
        color: rgba($color: #05061e, $alpha: 0.75);
        margin-bottom: 8px;

        @media (max-width: 600px) {
            font-size: 1.75rem;
            color: rgba($color: #0c0d1e, $alpha: 0.75);
        }

        a {
            font-weight: normal;
            color: rgba($color: #05061e, $alpha: 0.5);
        }

        .label-note {
            color: rgba($color: #05061e, $alpha: 0.3);
            font-weight: normal;
        }
    }

    small {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 170%;
        color: rgba($color: #05061e, $alpha: 0.5);

        @media (max-width: 600px) {
            font-size: 1.5rem;
            margin-top: 1rem;
        }

        margin-bottom: 15px;
    }

    .number-input {
        display: flex;
        border: 1px solid rgba($color: #05061e, $alpha: 0.07);
        border-radius: 4px;

        input {
            border: none;

            &:focus {
                border: 1px solid rgba($color: #fe6f42, $alpha: 0.5);
            }
        }
    }

    .react-select-container {
        height: 4.8rem;

        @media (max-width: 600px) {
            height: 6rem;
        }

        .react-select__menu {
            .react-select__menu-list {
                max-height: 130px;
                overflow-y: auto;
                z-index: -1;

                &::-webkit-scrollbar {
                    width: 6px;
                }

                &::-webkit-scrollbar-track {
                    margin-bottom: 0.4rem;
                    border-radius: 0.2rem;
                    height: 100%;
                }

                &::-webkit-scrollbar-thumb {
                    background: rgba($color: #0c0d1e, $alpha: 0.1);
                    border-radius: 1.6rem;
                    border: 2px solid transparent;
                    background-clip: padding-box;
                }

                &::-webkit-scrollbar-track-piece {
                    background: rgba($color: #0c0d1e, $alpha: 0.1);
                    background: transparent;
                }
            }
        }
    }

    .react-select__control {
        height: 4.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        border-right: 1px solid rgba(44, 20, 79, 0.1);
        width: 77px;
        border-radius: 0;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        cursor: pointer;

        @media (max-width: 600px) {
            height: 6rem;
        }

        &--is-focused {
            border: 1px solid rgba($color: #fe6f42, $alpha: 0.5);
            box-shadow: none;
        }

        &:hover,
        &:active {
            border-color: rgba($color: #fe6f42, $alpha: 0.5);
        }

        .react-select__single-value {
            font-style: normal;
            font-weight: normal;
            font-size: 1.6rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            color: rgba($color: #05061e, $alpha: 0.75);
            cursor: pointer;

            @media (max-width: 600px) {
                font-size: 2rem;
            }
        }
    }

    .react-select__placeholder {
        font-size: 16px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .react-select__value-container {
        height: 4.8rem;
    }

    .react-select__input {
        font-size: 16px;
    }

    .react-select__menu {
        width: 77px;

        &-list {
            padding: 0;
        }

        .react-select__option {
            font-style: normal;
            font-weight: normal;
            font-size: 1.6rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            color: rgba($color: #05061e, $alpha: 0.75);
            padding: 8px 12px;
            border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);
            cursor: pointer;

            @media (max-width: 600px) {
                font-size: 2rem;
            }

            &:hover,
            &:active {
                background: rgba($color: #05061e, $alpha: 0.03);
            }
        }

        .react-select__option--is-selected {
            background: rgba($color: #05061e, $alpha: 0.03);

            &:after {
                content: "";
                background: url("../public/assets/img/tick-small.png");
                background-repeat: no-repeat;
                background-position: center;
                width: 12px;
                height: 12px;
                margin-left: 12px;
            }
        }
    }

    .css-b8ldur-Input {
        padding: 0;
        margin: 0;
    }

    .react-select__indicator-separator {
        display: none;
    }
}

.password-container {
    position: relative;

    .eye {
        position: absolute;
        right: 1.6rem;
        transform: translate(-50%, -50%);
        top: 50%;
        height: 1.6rem;
        width: 1.6rem;
        cursor: pointer;

        img {
            height: 1.6rem;
            width: 1.6rem;
            object-fit: cover;
        }
    }
}

.submit-btn {
    cursor: pointer;
    border: none;
    outline: none;
    width: 100%;
    height: 5rem;
    background: #fe6f42;
    color: #fff;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 170%;
    margin-top: 1.7rem;
    position: relative;

    @media (max-width: 600px) {
        font-size: 1.75rem;
    }

    svg {
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);

        path {
            stroke: #fff;
        }
    }
}

.login-form {
    width: 100%;

    @media (max-width: 768px) {
        padding-left: 1.2rem;
        padding-right: 1.2rem;
        padding-top: 2rem;
    }

    @media (max-width: 600px) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .social-btn-container {
        padding-top: 2.4rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .seperator {
            font-style: normal;
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 170%;
            text-align: center;
            color: rgba($color: #05061e, $alpha: 0.3);
            margin-bottom: 2.4rem;
        }

        .buttons {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .facebook,
            .google {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 4.8rem;
                font-style: normal;
                font-weight: normal;
                font-size: 1.4rem;
                line-height: 130%;
                border-radius: 4px;

                span {
                    font-weight: 500;
                    margin-right: 4px;
                }
            }

            .facebook {
                background: #1778f2;
                color: #fff;

                svg {
                    width: 2.4rem;
                    height: 2.4rem;
                    object-fit: cover;
                    margin-right: 2rem;
                }
            }

            .google {
                border: 1px solid rgba($color: #05061e, $alpha: 0.07);
                color: rgba($color: #05061e, $alpha: 0.5);
                margin-right: 2.4rem;

                svg {
                    width: 2.4rem;
                    height: 2.4rem;
                    margin-right: 3.2rem;
                }
            }
        }
    }

    small {
        color: #ff2121;
        display: flex;
        align-items: center;

        svg {
            margin-right: 4px;
        }
    }
}

.react-select-container-cities {
    height: 4.8rem;

    @media (max-width: 600px) {
        height: 6rem;
    }

    .react-select-cities__single-value {
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 170%;
        display: flex;
        align-items: center;
        color: rgba($color: #05061e, $alpha: 0.5);

        @media (max-width: 600px) {
            font-size: 1.75rem;
        }
    }

    .react-select-cities__control {
        border: 1px solid rgba($color: #05061e, $alpha: 0.07);
        height: 4.8rem;

        @media (max-width: 600px) {
            height: 6rem;
        }

        &:hover,
        &:active {
            border-color: rgba($color: #fe6f42, $alpha: 0.5);
        }

        &--is-focused {
            border: 1px solid rgba($color: #fe6f42, $alpha: 0.5);
            box-shadow: none;
        }
    }

    .react-select-cities__value-container {
        height: 4.8rem;

        @media (max-width: 600px) {
            height: 6rem;
        }
    }

    .react-select-cities__indicators {
        span.react-select-cities__indicator-separator {
            display: none;
        }
    }

    .react-select-cities__menu-list {
        max-height: 130px;
        overflow-y: scroll;
        z-index: -1;

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            margin-bottom: 0.4rem;
            border-radius: 0.2rem;
            height: 100%;
        }

        &::-webkit-scrollbar-thumb {
            background: rgba($color: #0c0d1e, $alpha: 0.1);
            border-radius: 1.6rem;
            border: 2px solid transparent;
            background-clip: padding-box;
        }

        &::-webkit-scrollbar-track-piece {
            background: rgba($color: #0c0d1e, $alpha: 0.1);
            background: transparent;
        }

        .react-select-cities__option {
            font-style: normal;
            font-weight: normal;
            font-size: 1.6rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            color: rgba($color: #05061e, $alpha: 0.75);
            padding: 4px 8px;
            outline: none;

            &:hover,
            &:active {
                background: rgba($color: #05061e, $alpha: 0.03);
            }
        }

        .react-select-cities__option--is-focused {
            background-color: transparent;
        }

        .react-select-cities__control--menu-is-open {
            box-shadow: 0 0 0 1px rgba($color: #05061e, $alpha: 0.07);
        }

        .react-select-cities__control--menu-is-focused {
            box-shadow: none;
            border: none;

            &.css-1pahdxg-control {
                box-shadow: 0 0 0 1px rgba($color: #05061e, $alpha: 0.07);
                border: none;
            }
        }

        .react-select-cities__option--is-selected {
            background: rgba($color: #05061e, $alpha: 0.03);
        }
    }
}

.popup-buy-as-guest {
    width: auto;
    height: auto;
    background-color: #fff;
    z-index: 999999999999;
    overflow-y: auto;
    position: relative;
    max-width: 52rem;
    max-height: 80%;

    @media (max-width: 768px) {
        width: 100%;
        height: 100%;
        position: unset;
        max-width: 100%;
        max-height: 100%;
    }

    &::-webkit-scrollbar {
        width: 2px;
    }

    &::-webkit-scrollbar-track {
        margin-bottom: 2.4rem;
        border-radius: 0.2rem;
        height: 100%;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba($color: #05061e, $alpha: 0.07);
        border-radius: 1.6rem;
        border: 10px solid rgba($color: #05061e, $alpha: 0.75);
        background-clip: padding-box;
    }

    &::-webkit-scrollbar-track-piece {
        background: rgba($color: #05061e, $alpha: 0.07);
    }

    .container {
        position: relative;
        height: auto;

        .header {
            position: sticky;
            top: 0;
            left: 0;
            height: 8rem;
            background-color: #fff;
            width: 100%;
            padding: 0 1.5rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 1.5rem;
            border-bottom: 1px solid rgba(5, 6, 30, 0.07);
            z-index: 9;

            h3 {
                font-style: normal;
                font-weight: 500;
                font-size: 2rem;
                line-height: 170%;
                display: flex;
                align-items: center;
                color: #05061e;
            }

            button {
                width: 1.6rem;
                height: 1.6rem;
                cursor: pointer;

                @media (max-width: 600px) {
                    width: 2rem;
                    height: 2rem;
                }

                svg {
                    width: 1.6rem;
                    height: 1.6rem;

                    @media (max-width: 600px) {
                        width: 2rem;
                        height: 2rem;
                    }
                }
            }
        }

        .body {
            font-size: 54px;
            padding: 0 1.5rem;
            background-color: #fff;
            height: 100%;
            width: 100%;

            .fast-buy-inputs {
                height: 100%;
                margin-bottom: 2rem;
                margin-top: 2.4rem;

                small {
                    color: #fe4242;
                }

                .form-group {
                    display: flex;

                    .form-control {
                        &:first-child {
                            margin-right: 2.4rem;
                        }
                    }
                }

                .delivery-container {
                    .title {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 2rem;
                        line-height: 170%;
                        display: flex;
                        align-items: center;
                        color: #05061e;
                        margin-bottom: 1.6rem;

                        @media (max-width: 600px) {
                            font-size: 1.75rem;
                            margin-bottom: 2rem;
                        }
                    }

                    .delivery-form-group {
                        display: flex;
                        width: 100%;
                        margin-bottom: 2.4rem;
                        height: 88px;

                        input {
                            display: none;

                            &:checked+label {
                                border: 1px solid #fe6f42;
                                background-color: rgba($color: #fe6f42, $alpha: 0.05);

                                .logo-container {
                                    svg {
                                        g {
                                            opacity: 1;
                                        }

                                        path {
                                            stroke: #fe6f42;

                                            &.star {
                                                fill: #fe6f42;
                                            }
                                        }
                                    }
                                }

                                .content {
                                    .content-title {
                                        color: #fe6f42;
                                    }

                                    .content-info {
                                        color: #fe6f42;
                                    }
                                }
                            }

                            &:checked+label#free {
                                border: 1px solid #05b136;
                                background-color: rgba($color: #05b136, $alpha: 0.05);

                                .logo-container {
                                    display: flex;

                                    svg {
                                        g {
                                            opacity: 1;
                                        }

                                        path {
                                            stroke: #05b136;

                                            &.star {
                                                fill: #05b136;
                                            }
                                        }
                                    }
                                }

                                .content {
                                    .content-title {
                                        color: #05b136;
                                    }

                                    .content-info {
                                        color: #05b136;
                                    }
                                }
                            }
                        }

                        label {
                            border: 1px solid rgba($color: #0c0d1e, $alpha: 0.1);
                            border-radius: 4px;
                            width: 100%;
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 1.6rem 2.4rem;

                            @media (max-width: 600px) {
                                padding: 1.5rem 0 1.5rem 3rem;
                            }

                            .logo-container {
                                margin-right: 2.4rem;
                                display: flex;
                                align-items: center;
                            }

                            .content {
                                .content-title {
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 1.6rem;
                                    line-height: 170%;
                                    display: flex;
                                    align-items: center;
                                    color: rgba($color: #05061e, $alpha: 0.75);
                                    margin-bottom: 4px;

                                    @media (max-width: 600px) {
                                        font-size: 1.75rem;
                                        margin-bottom: 0;
                                    }
                                }

                                .content-info {
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 1.2rem;
                                    line-height: 170%;
                                    color: rgba($color: #05061e, $alpha: 0.5);

                                    @media (max-width: 600px) {
                                        font-size: 1.5rem;
                                    }

                                    span {
                                        display: inline-block;
                                        font-weight: 500;
                                    }
                                }
                            }
                        }
                    }
                }

                .cart-sidebar_bottom {
                    margin-bottom: 2.4rem;
                }

                .checkout-sidebar_bottom {
                    .title {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 2rem;
                        line-height: 170%;
                        display: flex;
                        align-items: center;
                        color: #05061e;
                        margin-bottom: 1.6rem;

                        @media (max-width: 600px) {
                            font-size: 1.75rem;
                            margin-bottom: 2rem;
                        }
                    }

                    .payment-method {
                        display: flex;

                        @media (min-width: 769px) and (max-width: 1024px) {
                            flex-direction: column;

                            .payment-form-group {
                                &:first-child {
                                    margin-bottom: 2.4rem;
                                }
                            }
                        }

                        .payment-form-group {
                            display: flex;
                            width: 100%;
                            margin-right: 2.4rem;

                            &:last-child {
                                margin-right: 0;
                            }

                            input {
                                display: none;

                                &:checked+label {
                                    border: 1px solid #fe6f42;

                                    .logo-container {
                                        svg {
                                            g {
                                                opacity: 1;
                                            }

                                            path {
                                                stroke: #fe6f42;
                                            }

                                            rect {
                                                stroke: #fe6f42;
                                            }
                                        }
                                    }

                                    .content {
                                        .content-title {
                                            color: #fe6f42;
                                        }
                                    }
                                }
                            }

                            label {
                                border: 1px solid rgba($color: #0c0d1e, $alpha: 0.1);
                                border-radius: 4px;
                                width: 100%;
                                cursor: pointer;
                                display: flex;
                                //align-items: center;
                                justify-content: flex-start;
                                padding: 2rem;
                                height: 6.4rem;

                                @media (max-width: 600px) {
                                    padding: 2.5rem;
                                    height: 8rem;
                                }

                                .logo-container {
                                    display: flex;
                                    align-items: center;
                                    margin-right: 2.4rem;
                                    width: 2.4rem;
                                    height: 2.4rem;

                                    @media (max-width: 600px) {
                                        width: 3rem;
                                        height: 3rem;
                                    }

                                    svg {
                                        width: 2.4rem;
                                        height: 2.4rem;
                                        object-fit: cover;

                                        @media (max-width: 600px) {
                                            width: 3rem;
                                            height: 3rem;
                                        }
                                    }
                                }

                                .content {
                                    .content-title {
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 1.4rem;
                                        line-height: 170%;
                                        display: flex;
                                        align-items: center;
                                        color: rgba($color: #05061e, $alpha: 0.5);

                                        @media (max-width: 600px) {
                                            font-size: 1.75rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .footer-popup {
            position: sticky;
            bottom: 0;
            left: 0;
            height: 6rem;
            background-color: #fff;
            width: 100%;
            // border-top: 1px solid rgba(5, 6, 30, 0.07)
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 1.5rem;

            button {
                background-color: #05b136;
                border-radius: 4px;
                font-style: normal;
                font-weight: 600;
                font-size: 1.4rem;
                line-height: 170%;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                color: #ffffff;
                height: 4.8rem;
                width: 100%;
                cursor: pointer;
                border: none;

                @media (max-width: 600px) {
                    height: 6rem;
                    font-size: 1.75rem;
                }

                &.confirm-button {
                    &.save {
                        background-color: #fe6f42;
                    }

                    &:disabled {
                        opacity: 0.7;
                    }
                }
            }
        }
    }
}

.textFormGuest {
    width: 100%;
    border: 1px solid rgba(5, 6, 30, 0.1);
    border-radius: 4px;
    outline: none;
    height: 150px;
    transition: all 0.3s;
    color: #0c0d1e;
    font-weight: 500;
    font-size: 1.6rem;
    padding: 10px 15px;
    resize: none;
}

.google-btn {
    background: rgba(66, 133, 244, 0.1);
    border: 1px solid rgba(26, 115, 232, 0.2);
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    height: 4.8rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media (max-width: 600px) {
        height: 6rem;
    }

    .img-container-logo {
        width: 2rem;
        height: 2rem;
        position: absolute;
        top: 50%;
        left: 5%;
        transform: translateY(-50%);

        @media (max-width: 600px) {
            height: 2.5rem;
            width: 2.5rem;
        }

        img {
            width: 2rem;
            height: 2rem;
            object-fit: cover;

            @media (max-width: 600px) {
                height: 2.5rem;
                width: 2.5rem;
            }
        }
    }

    .img-container-text {
        width: 97px;
        height: 2rem;

        @media (max-width: 600px) {
            height: 2.5rem;
        }

        img {
            width: 97px;
            height: 2rem;
            object-fit: cover;

            @media (max-width: 600px) {
                height: 2.5rem;
            }
        }
    }
}

.google-map-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: auto;
    background-color: #fff;

    .header-map {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 6.4rem;
        padding: 0 1.2rem;

        @media (max-width: 600px) {
            padding: 0 1.5rem;
        }

        .title {
            font-style: normal;
            font-weight: 500;
            font-size: 2.4rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            color: #05061e;

            @media (max-width: 600px) {
                font-size: 2.5rem;
            }
        }

        button {
            height: 1.6rem;
            width: 1.6rem;

            @media (max-width: 600px) {
                height: 2rem;
                width: 2rem;
            }

            svg {
                height: 1.6rem;
                width: 1.6rem;
                cursor: pointer;

                @media (max-width: 600px) {
                    height: 2rem;
                    width: 2rem;
                }
            }
        }
    }

    .footer-map {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 7.2rem;
        padding: 1.6rem 1.2rem;

        @media (max-width: 600px) {
            height: 9rem;
            padding: 2rem 1.5rem;
        }

        .title {
            font-size: 2rem;
        }

        button {
            font-style: normal;
            font-weight: 600;
            font-size: 1.4rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: #ffffff;
            background: #05b136;
            border-radius: 4px;
            height: 4.8rem;
            width: 19.2rem;
            cursor: pointer;

            @media (max-width: 768px) {
                width: 100%;
            }

            @media (max-width: 600px) {
                height: 6rem;
                font-size: 1.75rem;
            }
        }
    }
}

.mega-menu_body {
    h3 {
        display: none !important;
    }
}

.register-container {
    width: 100%;
    height: 92.4rem;
    background: #f7f8f8;
    background: url("../public/assets/img/register-back.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-bottom: 15rem;
    padding-top: 6.8rem;

    @media (max-width: 768px) {
        width: 100%;
        height: 100%;
        padding-top: 0;
        background: #fff;
        padding-left: 0;
        padding-right: 0;
    }

    @media (max-width: 600px) {
        padding-left: 0;
        padding-right: 0;
    }

    .register-card {
        width: 40.8rem;
        height: auto;
        background: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 3.2rem 2.4rem 2.4rem 2.4rem;
        border-radius: 4px;

        @media (max-width: 768px) {
            width: 100%;
            padding: 0 0 2.4rem 0;
        }
    }
}

.register {
    width: 100%;

    .top {
        @media (max-width: 768px) {
            background: url("../public/assets/img/register-back-mobile.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            padding-top: 2.4rem;
            padding-left: 2rem;
            padding-right: 2rem;
            padding-bottom: 2rem;
        }

        .header {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 2.4rem;

            @media (max-width: 600px) {
                margin-bottom: 2.5rem;
            }

            .title {
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 170%;
                display: flex;
                align-items: center;
                text-align: center;
                color: rgba($color: #05061e, $alpha: 0.75);
                margin-bottom: 4px;

                @media (max-width: 768px) {
                    color: #fff;
                }

                @media (max-width: 600px) {
                    font-size: 2.5rem;
                }
            }

            .subtitle {
                font-style: normal;
                font-weight: normal;
                font-size: 1.4rem;
                line-height: 170%;
                text-align: center;
                color: rgba($color: #05061e, $alpha: 0.5);

                @media (max-width: 768px) {
                    color: #fff;
                }

                @media (max-width: 600px) {
                    font-size: 1.5rem;
                }
            }
        }

        .tabs-titles {
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(5, 6, 30, 0.03);
            margin-bottom: 3.2rem;
            height: 4.8rem;
            padding: 0 4px;
            border-radius: 4px;
            width: 100%;

            @media (max-width: 768px) {
                width: 100%;
                margin-bottom: 0;
                background: transparent;
                padding: 0;
            }

            .tab-title {
                font-style: normal;
                font-weight: 500;
                font-size: 1.4rem;
                line-height: 170%;
                display: flex;
                align-items: center;
                color: rgba($color: #05061e, $alpha: 0.5);
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 4rem;
                transition: all 0.3s;
                border-radius: 4px;
                cursor: pointer;

                @media (max-width: 768px) {
                    width: 100%;
                    color: #ffffff;
                    background-color: rgba($color: #ffffff, $alpha: 0.2);

                    &:first-child {
                        margin-right: 8px;
                    }
                }

                @media (max-width: 600px) {
                    font-size: 1.75rem;
                    height: 6rem;
                }

                &.tab-title--active {
                    color: #fe6f42;
                    background: #fff;
                }
            }
        }
    }
}

#password-warn {
    color: #fe4242;
}

.submit-code {
    width: 100%;

    @media (max-width: 768px) {
        padding: 3.2rem 0 2.4rem 0;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    }

    @media (max-width: 600px) {
        padding: 3.2rem 0 2.4rem 0;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .steps-header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding-bottom: 2rem;
        border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);
        margin-bottom: 2.4rem;

        .title {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 170%;
            display: flex;
            align-items: center;
            text-align: center;
            color: rgba($color: #05061e, $alpha: 0.75);
            margin-bottom: 4px;
        }

        .subtitle {
            font-style: normal;
            font-weight: normal;
            font-size: 1.4rem;
            line-height: 170%;
            text-align: center;
            color: rgba($color: #05061e, $alpha: 0.5);
        }
    }

    .form-control {
        small {
            color: #ff2121;
            display: flex;
            align-items: center;

            svg {
                margin-right: 4px;
            }
        }

        .note {
            color: rgba(5, 6, 30, 0.5);
        }
    }

    .submit-code_form {
        width: 100%;
    }
}

.code-input {
    width: 100% !important;

    div {
        width: 100%;
        display: flex;

        input {
            font-family: "Euclid Circular A";
            margin-right: 12px;
            border: 1px solid rgba($color: #05061e, $alpha: 0.07);
            border-radius: 0;
            width: 5rem;
            height: 4.8rem;
            font-style: normal;
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 170%;
            color: rgba($color: #05061e, $alpha: 0.75);
            border-radius: 4px;
            width: 100% !important;
            height: 4.8rem !important;

            @media (max-width: 600px) {
                height: 6rem !important;
            }

            &:first-child {
                border-radius: 0;
                border-radius: 4px;
            }

            &:last-child {
                border-radius: 0;
                border-right: none;
                border: 1px solid rgba($color: #05061e, $alpha: 0.07);
                margin-right: 0;
                border-radius: 4px;
            }

            &:focus {
                border: 1px solid #fe6f42;
                caret-color: unset;

                &+input {
                    border-left: 1px solid rgba($color: #05061e, $alpha: 0.07);
                }
            }
        }
    }
}

.loader-container {
    display: flex;
}

.scroll-content {
    min-height: 25px;
    max-height: 400px;
}

.scrollbar-track-y {
    width: 4px !important;
    right: 5px !important;

    .scrollbar-thumb {
        background-color: #fe6f42;
        width: 4px;
    }
}

.scrollbar-track {
    opacity: 1 !important;
    z-index: 0 !important;
}

.range-slider {
    form {
        display: flex;
        flex-direction: column;
        padding-right: 2.4rem;

        .range {
            margin-bottom: 2.4rem;
        }

        .input-container {
            display: flex;
            align-items: center;
            justify-content: space-between;

            input {
                width: 100%;
                height: 4.8rem;
                border: 1px solid rgba($color: #05061e, $alpha: 0.1);
                box-sizing: border-box;
                border-radius: 4px;
                font-style: normal;
                font-weight: 500;
                font-size: 1.4rem;
                line-height: 170%;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                color: rgba($color: #05061e, $alpha: 0.5);
                outline: none;

                @media (max-width: 600px) {
                    font-size: 1.75rem;
                }

                &:first-child {
                    margin-right: 1.6rem;
                }

                &:focus {
                    border: 1px solid rgba($color: #fe6f42, $alpha: 0.5);
                }
            }
        }
    }
}

.Toastify__progress-bar {
    opacity: 1 !important;
}

.Toastify__close-button--light {
    color: #fff !important;
    opacity: 1 !important;
}

.Toastify__toast-icon {
    width: 24px !important;
}

.cart-table-wrapper {
    .acc-cont {
        background-color: #fff;
    }
}

.checkout-wrapper {
    background: #fff;
    width: 100%;
    position: relative;

    @media (max-width: 600px) {
        padding-bottom: 10rem;
    }

    .checkout-container {
        width: 112.8rem;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-gap: 0 2.4rem;
        margin: 0 auto;
        padding-bottom: 20rem;

        @media (max-width: 1128px) {
            width: 100%;
            padding: 0 2rem;
        }

        @media (min-width: 768px) {
            .breadcrumb.border {
                padding-left: 0;
                padding-right: 0;
            }
        }

        @media (max-width: 768px) {
            position: relative;
            padding: 0;

            .breadcrumb.border {
                border-bottom: none;
            }
        }

        .checkout-banner {
            grid-column: 1/-1;
            height: 14.5rem;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-bottom: 4rem;

            @media (max-width: 768px) {
                height: auto;
                padding: 0 2rem;
                padding-top: 0.8rem;
                padding-bottom: 1.2rem;
                border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);
                margin-bottom: 0;
            }

            @media (max-width: 600px) {
                height: auto;
                padding-top: 1rem;
                padding-bottom: 1.5rem;
                border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);
            }

            .title {
                font-style: normal;
                font-weight: 500;
                font-size: 32px;
                line-height: 170%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #05061e;
                margin-bottom: 8px;

                @media (max-width: 768px) {
                    font-size: 2.4rem;
                }

                @media (max-width: 600px) {
                    font-size: 3rem;
                }

                a {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1.6rem;
                    line-height: 170%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    color: rgba($color: #05061e, $alpha: 0.5);
                    height: 4.8rem;
                    border: 1px solid rgba($color: #05061e, $alpha: 0.07);
                    border-radius: 4px;
                    width: 19.2rem;

                    svg {
                        margin-right: 1.6rem;
                    }
                }
            }

            .subtitle {
                font-style: normal;
                font-weight: normal;
                font-size: 1.6rem;
                line-height: 170%;
                color: rgba($color: #05061e, $alpha: 0.5);

                @media (max-width: 768px) {
                    display: none;
                }
            }
        }

        .checkout-main {
            width: 100%;
            height: max-content;
            grid-column: 1/9;
            border: 1px solid rgba($color: #05061e, $alpha: 0.07);

            @media (max-width: 768px) {
                grid-column: 1/-1;
            }

            .checkout-address_banner {
                font-style: normal;
                font-weight: normal;
                font-size: 2.4rem;
                line-height: 170%;
                display: flex;
                align-items: center;
                color: #05061e;
                padding: 2rem 2.4rem 2.4rem 2.4rem;
                border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);

                @media (max-width: 768px) {
                    padding: 2rem 1.2rem;
                }

                @media (max-width: 600px) {
                    font-size: 2rem;
                    padding: 2.5rem 1.5rem;
                }
            }

            .checkout-address_body {
                padding: 2.4rem;

                @media (max-width: 768px) {
                    padding: 1.2rem;
                }

                @media (max-width: 600px) {
                    padding: 1.5rem;
                }

                form {
                    .form-group {
                        display: flex;

                        @media (max-width: 768px) {

                            &:nth-child(2),
                            &:nth-child(3) {
                                flex-direction: column;
                            }
                        }

                        .form-control {
                            &:first-child {
                                margin-right: 2.4rem;
                            }

                            small {
                                color: #fe4242;
                            }
                        }
                    }

                    .submit-btn {
                        background-color: rgba($color: #fe6f42, $alpha: 0.1);
                        color: #fe6f42;
                    }
                }

                .checkout-addresses-container {
                    width: 100%;
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-template-rows: 21rem;
                    grid-gap: 2.4rem;

                    @media (max-width: 604px) {
                        grid-template-columns: auto;
                    }

                    .address-card {
                        .address-text {
                            height: auto;
                        }
                    }

                    .add-new-address {
                        border: 1px dashed #fe6f42;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 1.6rem;
                        line-height: 170%;
                        display: flex;
                        align-items: center;
                        color: #fe6f42;
                        cursor: pointer;
                        min-height: 9.6rem;
                        border-radius: 4px;

                        @media (max-width: 600px) {
                            min-height: 12rem;
                            font-size: 1.75rem;
                        }

                        svg {
                            width: 2.4rem;
                            height: 2.4rem;

                            @media (max-width: 600px) {
                                width: 3rem;
                                height: 3rem;
                            }

                            g {
                                opacity: 1;
                            }

                            path {
                                stroke: #fe6f42;
                            }
                        }
                    }
                }

                .checkout-carousel {
                    display: none;

                    @media (max-width: 768px) {
                        display: block;
                    }

                    #address-carousel {
                        .swiper-wrapper {
                            width: 272px;
                            height: 144px;
                            margin-left: 0;

                            .swiper-slide {
                                width: 272px;
                                height: 144px;

                                .address-card {
                                    width: 100%;
                                    height: 100%;

                                    .address-text {
                                        height: auto;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .checkout-sidebar {
            width: 100%;
            grid-column: 9/-1;

            @media (max-width: 768px) {
                grid-column: 1/-1;
            }

            .checkout-sidebar_top {
                border: 1px solid rgba($color: #05061e, $alpha: 0.07);
                margin-bottom: 2.4rem;

                @media (max-width: 768px) {
                    border: 0;
                    margin-bottom: 0;
                }

                .header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 2rem 2.4rem 2.4rem 2.4rem;
                    border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);

                    .text {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 2.4rem;
                        line-height: 170%;
                        display: flex;
                        align-items: center;
                        color: #05061e;
                    }

                    .total {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 2.4rem;
                        line-height: 170%;
                        display: flex;
                        align-items: center;
                        text-align: right;
                        color: #fe6f42;
                    }

                    @media (max-width: 768px) {
                        display: none;
                    }
                }

                .body {
                    padding: 2.4rem;

                    @media (max-width: 768px) {
                        padding: 1.2rem;
                    }

                    @media (max-width: 600px) {
                        padding: 1.5rem;
                    }

                    .title {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 2rem;
                        line-height: 170%;
                        display: flex;
                        align-items: center;
                        color: #05061e;
                        margin-bottom: 1.6rem;

                        @media (max-width: 600px) {
                            font-size: 1.75rem;
                            margin-bottom: 2rem;
                        }
                    }
                }
            }

            .delivery-container {
                .delivery-form-group {
                    display: flex;
                    width: 100%;
                    margin-bottom: 2.4rem;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    input {
                        display: none;

                        &:checked+label {
                            border: 1px solid #fe6f42;
                            background-color: rgba($color: #fe6f42, $alpha: 0.05);

                            .logo-container {
                                svg {
                                    g {
                                        opacity: 1;
                                    }

                                    path {
                                        stroke: #fe6f42;

                                        &.star {
                                            fill: #fe6f42;
                                        }
                                    }
                                }
                            }

                            .content {
                                .content-title {
                                    color: #fe6f42;
                                }

                                .content-info {
                                    color: #fe6f42;
                                }
                            }
                        }

                        &:checked+label#free {
                            border: 1px solid #05b136;
                            background-color: rgba($color: #05b136, $alpha: 0.05);

                            .logo-container {
                                svg {
                                    g {
                                        opacity: 1;
                                    }

                                    path {
                                        stroke: #05b136;

                                        &.star {
                                            fill: #05b136;
                                        }
                                    }
                                }
                            }

                            .content {
                                .content-title {
                                    color: #05b136;
                                }

                                .content-info {
                                    color: #05b136;
                                }
                            }
                        }
                    }

                    label {
                        border: 1px solid rgba($color: #0c0d1e, $alpha: 0.1);
                        border-radius: 4px;
                        width: 100%;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        padding: 1.6rem 2.4rem;

                        @media (max-width: 600px) {
                            padding: 1.5rem 0 1.5rem 3rem;
                        }

                        .logo-container {
                            margin-right: 2.4rem;
                            // svg {

                            // }
                        }

                        .content {
                            .content-title {
                                font-style: normal;
                                font-weight: 500;
                                font-size: 1.6rem;
                                line-height: 170%;
                                display: flex;
                                align-items: center;
                                color: rgba($color: #05061e, $alpha: 0.75);
                                margin-bottom: 4px;

                                @media (max-width: 600px) {
                                    font-size: 1.75rem;
                                    margin-bottom: 0;
                                }
                            }

                            .content-info {
                                font-style: normal;
                                font-weight: 400;
                                font-size: 1.2rem;
                                line-height: 170%;
                                color: rgba($color: #05061e, $alpha: 0.5);

                                @media (max-width: 600px) {
                                    font-size: 1.5rem;
                                }

                                span {
                                    display: inline-block;
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                }
            }

            .cart-sidebar_bottom {
                margin-bottom: 2.4rem;
            }

            .checkout-sidebar_bottom {
                border: 1px solid rgba($color: #05061e, $alpha: 0.07);
                padding: 2.4rem;

                @media (max-width: 768px) {
                    border: 0;
                    padding: 1.2rem;
                }

                @media (max-width: 600px) {
                    padding: 1.5rem;
                }

                .title {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 2rem;
                    line-height: 170%;
                    display: flex;
                    align-items: center;
                    color: #05061e;
                    margin-bottom: 1.6rem;

                    @media (max-width: 600px) {
                        font-size: 1.75rem;
                        margin-bottom: 2rem;
                    }
                }

                .payment-method {
                    display: flex;

                    @media (max-width: 767.9px) {
                        margin-bottom: 2rem !important;
                    }

                    @media (min-width: 769px) and (max-width: 1024px) {
                        flex-direction: column;

                        .payment-form-group {
                            &:first-child {
                                margin-bottom: 2.4rem;
                            }
                        }
                    }

                    .payment-form-group {
                        display: flex;
                        width: 100%;
                        margin-right: 2.4rem;

                        &:last-child {
                            margin-right: 0;
                        }

                        input {
                            display: none;

                            &:checked+label {
                                border: 1px solid #fe6f42;

                                .logo-container {
                                    svg {
                                        g {
                                            opacity: 1;
                                        }

                                        path {
                                            stroke: #fe6f42;
                                        }

                                        rect {
                                            stroke: #fe6f42;
                                        }
                                    }
                                }

                                .content {
                                    .content-title {
                                        color: #fe6f42;
                                    }
                                }
                            }
                        }

                        label {
                            border: 1px solid rgba($color: #0c0d1e, $alpha: 0.1);
                            border-radius: 4px;
                            width: 100%;
                            cursor: pointer;
                            display: flex;
                            //align-items: center;
                            justify-content: flex-start;
                            padding: 2rem;
                            height: 6.4rem;

                            @media (max-width: 600px) {
                                padding: 2.5rem;
                                height: 8rem;
                            }

                            .logo-container {
                                margin-right: 2.4rem;
                                width: 2.4rem;
                                height: 2.4rem;

                                @media (max-width: 600px) {
                                    width: 3rem;
                                    height: 3rem;
                                }

                                svg {
                                    width: 2.4rem;
                                    height: 2.4rem;
                                    object-fit: cover;

                                    @media (max-width: 600px) {
                                        width: 3rem;
                                        height: 3rem;
                                    }
                                }
                            }

                            .content {
                                .content-title {
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 1.4rem;
                                    line-height: 170%;
                                    display: flex;
                                    align-items: center;
                                    color: rgba($color: #05061e, $alpha: 0.5);

                                    @media (max-width: 600px) {
                                        font-size: 1.75rem;
                                    }
                                }
                            }
                        }
                    }
                }

                .submit-btn.checkout {
                    margin-top: 1.2rem;
                    margin-bottom: 2.4rem;

                    @media (max-width: 768px) {
                        display: none;
                    }
                }
            }
        }
    }
}

.mobile-fixed-confirm {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 999999;
    height: 12.5rem;
    border-top: 1px solid rgba($color: #05061e, $alpha: 0.07);
    padding: 1.2rem 0;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }

    @media (max-width: 600px) {
        padding: 1.5rem 0;
        height: 15.62rem;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);
        padding: 0 1.2rem 1.2rem 1.2rem;

        @media (max-width: 600px) {
            padding: 0 1.5rem 1.5rem 1.5rem;
        }

        .text {
            font-style: normal;
            font-weight: normal;
            font-size: 1.6rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            color: #05061e;

            @media (max-width: 600px) {
                font-size: 2rem;
            }
        }

        .total {
            font-style: normal;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            text-align: right;
            color: #fe6f42;

            @media (max-width: 600px) {
                font-size: 2rem;
            }
        }
    }

    @media (max-width: 768px) {
        .btn-container-fixed {
            padding: 0 1.2rem;

            .submit-btn {
                margin-top: 1.2rem;
                height: 4.8rem;
            }
        }
    }

    @media (max-width: 600px) {
        .btn-container-fixed {
            padding: 0 1.5rem;

            .submit-btn {
                margin-top: 1.5rem;
                height: 6rem;
            }
        }
    }
}

.address-card {
    background: #fff;
    padding: 1.6rem 2rem 2rem;
    border-radius: 4px;
    border: 1px solid rgba($color: #05061e, $alpha: 0.07);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 600px) {
        padding: 1.5rem;
    }

    input[type="radio"] {
        display: none;

        // &:checked + label {

        //   .header {
        //     .user-name {
        //       color: #FE6F42;
        //     }
        //   }
        // }
    }

    &.checked {
        border: 1px solid #fe6f42;

        .header {
            .user-name {
                color: #fe6f42;
            }
        }
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 1.2rem;

        @media (max-width: 600px) {
            padding-bottom: 1rem;
        }

        .user-name {
            font-style: normal;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            color: rgba($color: #05061e, $alpha: 0.75);

            @media (max-width: 600px) {
                font-size: 1.75rem;
            }
        }

        .open-dropdown {
            outline: 0;
            border: none;
            background-color: transparent;
            width: 1.2rem;
            height: 1.2rem;
            object-fit: cover;
            cursor: pointer;
            align-self: flex-start;
            position: relative;

            @media (max-width: 600px) {
                width: 1.5rem;
                height: 1.5rem;
            }

            .open-options {
                position: absolute;
                top: 20px;
                right: 0;
                background: #ffffff;
                border: 1px solid rgba(5, 6, 30, 0.07);
                box-sizing: border-box;
                box-shadow: 0px 16px 32px rgba(5, 6, 30, 0.04);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                p {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 1.4rem;
                    line-height: 170%;
                    display: flex;
                    align-items: center;
                    color: rgba($color: #05061e, $alpha: 0.5);
                    padding: 12px 0;
                    width: 14.7rem;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding-left: 1.6rem;
                    transition: all 0.3s;

                    @media (max-width: 600px) {
                        font-size: 1.75rem;
                    }

                    svg {
                        width: 1.6rem;
                        height: 1.6rem;
                        margin-right: 1.6rem;
                        object-fit: cover;

                        @media (max-width: 600px) {
                            width: 2rem;
                            height: 2rem;
                        }
                    }

                    &.edit {
                        border-bottom: 1px solid rgba(5, 6, 30, 0.07);
                    }

                    &:hover {
                        color: rgba($color: #05061e, $alpha: 0.75);

                        svg {
                            g {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }

    .address-text {
        font-style: normal;
        font-weight: normal;
        font-size: 1.4rem;
        line-height: 170%;
        display: flex;
        align-items: center;
        color: rgba($color: #05061e, $alpha: 0.5);
        margin-bottom: 1.2rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: wrap;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        height: 30px;

        @media (max-width: 600px) {
            margin-bottom: 1rem;
            font-size: 1.75rem;
        }
    }

    .footer-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .google-pin {
            width: 3.2rem;
            height: 3.2rem;
            border: 1px solid rgba(26, 115, 232, 0.2);
            box-sizing: border-box;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 8px;

            @media (max-width: 600px) {
                height: 4rem;
                width: 4rem;
            }

            svg {
                width: 1.6rem;
                height: 1.6rem;
                object-fit: cover;

                @media (max-width: 600px) {
                    height: 2rem;
                    width: 2rem;
                }
            }
        }

        .phone-number {
            border: 1px solid rgba($color: #05061e, $alpha: 0.07);
            box-sizing: border-box;
            border-radius: 4px;
            padding: 4px 12px;
            font-style: normal;
            font-weight: normal;
            font-size: 1.4rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba($color: #05061e, $alpha: 0.75);
            height: 3.2rem;

            @media (max-width: 600px) {
                height: 4rem;
                font-size: 1.75rem;
            }

            svg {
                margin-right: 8px;
                width: 1.2rem;
                height: 1.2rem;
                object-fit: cover;

                @media (max-width: 600px) {
                    height: 1.5rem;
                    width: 1.5rem;
                }
            }
        }
    }
}

.popup,
.popup.fast {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(#0c0d1e, 0.5);
    z-index: 9999999;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        background: rgba(#0c0d1e, 0.5);
        top: 0;
        left: 0;
        height: 100vh;
        overflow-y: scroll;
    }

    &.white {
        background: #ffffff;

        @media (min-width: 768px) {
            background: rgba(#0c0d1e, 0.5);
        }
    }

    .notification {
        background: #fff;
        position: relative;
        margin: auto;
        background: white;
        width: 40.8rem;
        height: auto;
        border-radius: 0.8rem;
        padding: 3.2rem 2.4rem 4.8rem 2.4rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        button {
            border: none;
            outline: none;
            cursor: pointer;
            background: #fff;
            position: absolute;
            top: 5%;
            right: 5%;
        }
    }
}

.popup.fast {
    background-color: white;
}

.popup_inner,
.popup_inner.fast {
    position: relative;
    margin: auto;
    background: white;
    width: 40.8rem;
    height: auto;
    border-radius: 0.8rem;
    padding: 3.2rem 2.4rem 3rem 2.4rem;

    @media (max-width: 768px) {
        width: 100%;
        z-index: 9999999999999999999999999999999999999;
        height: 100%;
        margin-top: 55px;
    }

    small {
        color: #fe4242;
    }

    .title {
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 170%;
        display: flex;
        align-items: center;
        color: #fe4242;
        margin-bottom: 0.8rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 600px) {
            font-size: 1.75rem;
            margin-bottom: 1rem;
        }

        .close-popup {
            border: none;
            outline: none;
            cursor: pointer;
            background: none;
            color: #fff;
        }
    }

    .subtitle {
        font-style: normal;
        font-weight: normal;
        font-size: 1.2rem;
        line-height: 170%;
        color: rgba($color: #0c0d1e, $alpha: 1);
        margin-bottom: 2.4rem;

        @media (max-width: 600px) {
            font-size: 1.5rem;
        }
    }

    form {
        @media (max-width: 768px) {
            padding-bottom: 2rem;
        }

        small {
            color: #fe4242;
        }

        .form-group {
            display: flex;

            .form-control {
                &:first-child {
                    margin-right: 2.4rem;
                }
            }
        }

        .confirm-button {
            border: none;
            outline: none;
            cursor: pointer;
            font-style: normal;
            font-weight: 600;
            font-size: 1.4rem;
            line-height: 170%;
            text-align: center;
            color: #ffffff;
            background: #fe4242;
            border-radius: 0.8rem;
            width: 100%;
            height: 4.8rem;
            display: flex;
            align-items: center;
            justify-content: center;

            //margin-top: 2.4rem;
            @media (max-width: 600px) {
                height: 6.25rem;
                margin-bottom: 2rem;
                font-size: 1.75rem;
            }
        }
    }
}

.popup.comment {
    .popup-comment {
        background: #ffffff;
        border: 0.1rem solid rgba(5, 6, 30, 0.07);
        box-sizing: border-box;
        border-radius: 0.4rem;
        width: 40.8rem;
        position: relative;

        .popup-comment-inner-padding {
            padding: 2rem 2.4rem 2.4rem;
            border-bottom: 0.1rem solid rgba(5, 6, 30, 0.07);

            h5.title {
                font-style: normal;
                font-weight: 500;
                font-size: 2.4rem;
                line-height: 170%;
                color: #05061e;
                opacity: 0.75;
                margin-bottom: 4px;
            }

            p {
                font-style: normal;
                font-weight: normal;
                font-size: 1.4rem;
                line-height: 170%;
                color: #05061e;
                opacity: 0.5;
            }

            .subtitle {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                color: #05061e;
                opacity: 0.75;
                margin-bottom: 1.6rem;
            }

            .rating-parent {
                margin-bottom: 32px;

                span {
                    margin-right: 6px;
                }

                svg {
                    width: 26px;
                    height: 26px;
                }
            }
        }

        .textarea-comment {
            border: 1px solid rgba(5, 6, 30, 0.1);
            box-sizing: border-box;
            border-radius: 4px;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 170%;
            color: rgba(5, 6, 30, 0.8);
            outline: none;
            resize: none;
            padding: 1.2rem;
            width: 100%;
            height: 14.4rem;

            &::placeholder {
                color: rgba(5, 6, 30, 0.3);
            }
        }

        .close-button {
            position: absolute;
            top: 28px;
            right: 24px;
            width: fit-content;
            cursor: pointer;

            svg {
                width: 2.8rem;
                height: 2.8rem;
                margin-bottom: 0;
            }
        }

        @media (max-width: 767.7px) {
            width: 94vw;

            h5.title,
            p {
                text-align: center;
            }
        }
    }

    .popup-comment-success {
        position: relative;
        width: 40.8rem;
        background: #ffffff;
        border: 1px solid rgba(5, 6, 30, 0.07);
        box-sizing: border-box;
        padding-top: 8.2rem;
        padding-bottom: 2.4rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        svg {
            width: 7.2rem;
            height: 7.2rem;
            margin-bottom: 1.6rem;
        }

        h6 {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 170%;
            color: #05061e;
            margin-bottom: 6px;
        }

        p {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 170%;
            text-align: center;
            color: #05061e;
            opacity: 0.5;
            width: 70%;
            margin-bottom: 3.2rem;
        }

        a,
        button {
            width: 90%;
        }

        .close-button {
            position: absolute;
            top: 28px;
            right: 24px;
            width: fit-content;
            cursor: pointer;

            svg {
                width: 2.8rem;
                height: 2.8rem;
                margin-bottom: 0;
            }
        }

        @media (max-width: 767.7px) {
            width: 94vw;
            padding-top: 4.8rem;

            p {
                width: 100%;
                margin-bottom: 1.2rem;
            }

            h5 {
                margin-bottom: 0.8rem;
            }
        }
    }
}

.appstore-overlay {
    width: 100%;
    // height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    display: none;

    .popup.appstore {
        display: none;
        width: auto;
        height: 122px;
        background: linear-gradient(90.55deg, #ffd1d1 5.72%, #ffebc0 71.28%);
        border-radius: 4px;
        z-index: 99;
        bottom: 6px;
        top: auto;
        margin: 0 auto;
        left: 6px;
        right: 6px;
        // background-image: url('../public/assets/img/');
        background-size: cover;

        .appstore-content {
            padding: 16px 26px;

            h4 {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 135%;
                color: #ffffff;
                margin-bottom: 16px;
            }

            .cross-close {
                position: absolute;
                right: 25px;
                top: 20px;
                opacity: 0.25;
            }

            .appstore-content-child {
                display: flex;
                align-items: center;
            }

            .playstore {
                display: block;
                width: 50%;
                height: 36px;

                img {
                    width: 100%;
                    height: 100%;
                }

                &:first-child {
                    margin-right: 24px;
                }
            }
        }
    }

    @media (max-width: 991.9px) {
        .popup.appstore {
            display: block;
        }

        display: block;
    }
}

.addressModal_close {
    background-color: transparent;
    outline: 0;
    border: none;
}

.account-container {
    background: #fff;
    width: 100%;
}

.account-wrapper {
    width: 112.8rem;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 0 2.4rem;
    margin: 0 auto;
    align-content: center;
    justify-items: center;
    padding-bottom: 24.8rem;

    @media (max-width: 1128px) {
        width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    @media (max-width: 768px) {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 8rem;
    }

    .tabs {
        width: 100%;
        grid-column: 1/4;
        background: #fff;
        border-radius: 0.8rem;
        height: fit-content;

        @media (max-width: 768px) {
            grid-column: 1/-1;
        }

        .account-user-container {
            border: 1px solid rgba($color: #05061e, $alpha: 0.07);
            margin-bottom: 2.4rem;

            @media (max-width: 768px) {
                display: none;
            }

            .account-user-info {
                padding-top: 4.8rem;
                padding-bottom: 3.2rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .user-image {
                    width: 7.2rem;
                    height: 7.2rem;
                    border-radius: 50%;
                    margin-bottom: 2.4rem;

                    img {
                        width: 7.2rem;
                        height: 7.2rem;
                        object-fit: cover;
                        border-radius: 50%;
                    }
                }

                .user-name {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 2rem;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    color: #05061e;
                    margin-bottom: 8px;
                    display: inline-block;
                    width: 235px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .user-phone {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 1.4rem;
                    line-height: 170%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: rgba($color: #05061e, $alpha: 0.75);
                    border: 1px solid rgba($color: #05061e, $alpha: 0.07);
                    box-sizing: border-box;
                    border-radius: 4px;
                    padding: 4px 12px;

                    svg {
                        margin-right: 8px;
                    }
                }
            }

            .user-code-container {
                width: 100%;
                background-color: rgba($color: #05061e, $alpha: 0.03);
                padding: 12px 0;
                display: flex;
                align-items: center;
                justify-content: center;

                .client-code {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 1.4rem;
                    line-height: 170%;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    color: rgba($color: #05061e, $alpha: 0.75);
                }
            }
        }

        .tabs-titles {
            padding-top: 0;
            padding-bottom: 0;
            border: 1px solid rgba($color: #05061e, $alpha: 0.07);

            @media (max-width: 768px) {
                display: grid;
                grid-template-columns: repeat(4, auto);
                margin: 0 1.2rem;
                margin-bottom: 1.2rem;
            }

            @media (max-width: 600px) {
                margin: 0 1.5rem;
                margin-bottom: 1.5rem;
            }

            .tab-title {
                font-style: normal;
                font-weight: normal;
                font-size: 1.4rem;
                line-height: 170%;
                cursor: pointer;
                transition: all 0.3s;
                border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);
                height: 5.6rem;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-left: 2rem;

                &:last-child {
                    border-bottom: none;
                }

                // &:last-child {
                //     display: none;
                // }

                @media (max-width: 768px) {
                    padding-left: 0;
                    border-bottom: none;
                }

                a {
                    width: 100%;
                    color: rgba($color: #0c0d1e, $alpha: 0.5);
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    @media (max-width: 768px) {
                        justify-content: center;
                    }

                    p {
                        width: 100%;
                        position: relative;

                        svg {
                            display: none;
                        }
                    }

                    @media (max-width: 768px) {
                        p {
                            display: none;

                            svg {
                                display: none;
                            }
                        }
                    }
                }

                &:hover,
                &.tab-title--active {
                    background: rgba($color: #fe6f42, $alpha: 0.07);

                    a {
                        color: #fe6f42;

                        p {
                            svg {
                                display: inline-block;
                                position: absolute;
                                top: 50%;
                                right: 20px;
                                transform: translateY(-50%);
                            }
                        }
                    }

                    .logo-container {
                        svg {
                            g {
                                opacity: 1;
                            }

                            path {
                                stroke: #fe6f42;
                            }
                        }
                    }
                }

                .logo-container {
                    width: 1.6rem;
                    height: 1.6rem;
                    margin-right: 1.2rem;

                    @media (max-width: 768px) {
                        margin-right: 0;
                    }

                    @media (max-width: 600px) {
                        width: 2rem;
                        height: 2rem;
                    }

                    svg {
                        width: 1.6rem;
                        height: 1.6rem;
                        object-fit: cover;

                        @media (max-width: 600px) {
                            width: 2rem;
                            height: 2rem;
                        }
                    }
                }
            }
        }
    }

    .tab-content {
        width: 100%;
        grid-column: 4/-1;
        height: fit-content;

        @media (max-width: 768px) {
            grid-column: 1/-1;
        }
    }

    @media screen and (max-width: 768px) {
        .tabs-titles {
            // display: flex !important;
            // align-items: center;
            // width: fit-content;
            // overflow: auto;
            grid-template-columns: repeat(5, auto) !important;
            // .tab-title a{
            //   width: ;
            // }
        }
    }
}

.account-header {
    h1 {
        font-style: normal;
        font-weight: 500;
        font-size: 2.4rem;
        line-height: 170%;
        display: flex;
        align-items: center;
        color: #05061e;
        margin-bottom: 8px;

        @media (max-width: 600px) {
            font-size: 2.5rem;
            margin-bottom: 0;
        }
    }

    p {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 170%;
        display: flex;
        align-items: center;
        color: rgba($color: #05061e, $alpha: 0.5);

        @media (max-width: 600px) {
            font-size: 1.5rem;
        }
    }
}

table {
    width: 100%;
    background: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 170%;
    border: 1px solid rgba($color: #05061e, $alpha: 0.07);

    @media (max-width: 768px) {
        display: none;
    }

    thead {
        tr {
            display: grid;
            grid-template-columns: 2fr 2fr 2fr 2fr 1fr;
            border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);
            padding: 2.4rem;

            th {
                text-align: left;
                font-size: 1.6rem;
                font-weight: 500;
                color: rgba($color: #05061e, $alpha: 0.5);
            }
        }
    }

    tbody {
        tr {
            display: grid;
            grid-template-columns: 2fr 2fr 2fr 2fr 1fr;
            padding: 2.4rem;
            border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);

            &:last-child {
                border-bottom: none;
            }

            td {
                text-align: left;
                color: rgba($color: #05061e, $alpha: 0.75);

                .show-products {
                    border: none;
                    outline: none;
                    background: #fff;
                    cursor: pointer;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 170%;
                    color: rgba($color: #05061e, $alpha: 0.5);
                    border: 1px solid rgba($color: #05061e, $alpha: 0.07);
                    border-radius: 4px;
                    height: 3.2rem;
                    width: 7.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all 0.3s;

                    svg {
                        margin-left: 12px;

                        g {
                            opacity: 0.5;
                            transition: all 0.3s;
                        }

                        path {
                            stroke: #05061e;
                            transition: all 0.3s;
                        }
                    }

                    &:hover {
                        color: #fff;
                        background: #fe6f42;
                        border: 1px solid #fe6f42;

                        svg {
                            g {
                                opacity: 1;
                            }

                            path {
                                stroke: #ffffff;
                            }
                        }
                    }
                }
            }

            td.ordered-img {
                .avatars {
                    display: inline-flex;
                    flex-direction: row;

                    .avatar {
                        position: relative;
                        border: 2px solid #fff;
                        border-radius: 50%;
                        overflow: hidden;
                        width: 24px;
                        height: 24px;
                        margin-left: -8px;
                    }

                    .avatar img {
                        width: 24px;
                        height: 24px;
                        display: block;
                        border-radius: 50%;
                        object-fit: cover;
                    }

                    .avatar.text {
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #ffe0d6;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 1.2rem;
                        line-height: 170%;
                        display: flex;
                        align-items: center;
                        color: #fe6f42;
                        border: none;
                    }
                }
            }
        }
    }
}

.order-container {
    display: none;
    width: 100%;
    padding-bottom: 8rem;

    @media (max-width: 768px) {
        display: block;
        padding: 0 1.2rem;
    }

    @media (max-width: 600px) {
        padding: 0 1.5rem;
    }

    .order-card {
        display: none;
        padding: 1.6rem 1.2rem 1.2rem 1.2rem;
        border: 1px solid rgba($color: #05061e, $alpha: 0.07);
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2.4rem 2.4rem;
        margin-bottom: 1.2rem;

        @media (max-width: 600px) {
            grid-gap: 3rem 3rem;
            padding: 2rem 1.5rem 1.5rem 1.5rem;
            margin-bottom: 1.5rem;
        }

        .order-products,
        .order-num,
        .order-date,
        .order-total,
        .order-status {
            .title {
                font-size: 1.2rem;
                font-style: normal;
                line-height: 170%;
                font-weight: 500;
                color: rgba($color: #05061e, $alpha: 0.5);
                margin-bottom: 8px;

                @media (max-width: 600px) {
                    font-size: 1.5rem;
                }
            }

            .subtitle {
                font-size: 1.4rem;
                line-height: 170%;
                font-weight: normal;
                font-style: normal;
                color: rgba($color: #05061e, $alpha: 0.75);

                @media (max-width: 600px) {
                    font-size: 1.75rem;
                }
            }
        }

        .order-products {
            grid-column: 1/-1;

            .ordered-images {
                padding-left: 8px;

                .avatars {
                    display: inline-flex;
                    flex-direction: row;

                    .avatar {
                        position: relative;
                        border: 2px solid #fff;
                        border-radius: 50%;
                        overflow: hidden;
                        width: 24px;
                        height: 24px;
                        margin-left: -8px;
                    }

                    .avatar img {
                        width: 24px;
                        height: 24px;
                        display: block;
                        border-radius: 50%;
                        object-fit: cover;
                    }

                    .avatar.text {
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #ffe0d6;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 1.2rem;
                        line-height: 170%;
                        display: flex;
                        align-items: center;
                        color: #fe6f42;
                        border: none;
                    }
                }
            }
        }

        .order-button {
            grid-column: 1/-1;
            margin-top: 0.8rem;

            @media (max-width: 600px) {
                margin-top: 1rem;
            }

            .order-link {
                border: none;
                outline: none;
                cursor: pointer;
                background: #fe6f42;
                font-style: normal;
                font-weight: 600;
                font-size: 1.4rem;
                line-height: 170%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #ffffff;
                width: 100%;
                height: 4.8rem;
                border-radius: 4px;

                @media (max-width: 600px) {
                    font-size: 1.75rem;
                    height: 6rem;
                }
            }
        }

        .order-total {
            .subtitle {
                font-weight: 500;
            }
        }

        .order-status {
            .subtitle {
                font-weight: 500;
                color: #fe4242;
            }
        }

        .order-status.done {
            .subtitle {
                font-weight: 500;
                color: #00d67c;
            }
        }
    }
}

.overlay-2 {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba($color: #05061e, $alpha: 0.75);
    width: 100vw;
    height: 100vh;
    z-index: 999999999999999;

    @media (max-width: 768px) {
        background: white;
        top: 60px;
    }

    .notification {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: white;
        padding: 3.2rem;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        z-index: 999999999999999999;
        width: 74.4rem;
        height: 500px;

        @media (max-width: 768px) {
            width: 100%;
            height: 100%;
            border-radius: 0;
            padding: 0 2rem;
            top: 60%;
        }

        @media (max-width: 600px) {
            padding-top: 0;
            top: 60%;
        }

        @media (max-width: 320px) {
            padding-top: 0;
            top: 63%;
        }

        .header {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 1.6rem;
            border-bottom: 1px solid rgba($color: #0c0d1e, $alpha: 0.05);
            margin-bottom: 2rem;

            .title {
                font-style: normal;
                font-weight: 500;
                font-size: 2rem;
                line-height: 170%;
                color: rgba($color: #0c0d1e, $alpha: 0.75);
            }

            .cross-close {
                border: none;
                outline: none;
                cursor: pointer;
                background: none;
            }
        }

        .body {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                width: 2.4rem;

                @media (max-width: 768px) {
                    //width: 0;
                }
            }

            &::-webkit-scrollbar-track {
                margin-bottom: 2.4rem;
                border-radius: 0.2rem;
                height: 100%;

                @media (max-width: 768px) {
                    margin-bottom: 8rem;
                }
            }

            &::-webkit-scrollbar-thumb {
                background: rgba($color: #0c0d1e, $alpha: 0.1);
                border-radius: 1.6rem;
                border: 10px solid transparent;
                background-clip: padding-box;
            }

            &::-webkit-scrollbar-track-piece {
                background: rgba($color: #0c0d1e, $alpha: 0.1);
                background: transparent;
            }

            .order-row {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 3fr 1fr 1fr;
                margin-bottom: 2rem;
                padding-bottom: 2rem;
                border-bottom: 1px solid rgba($color: #0c0d1e, $alpha: 0.05);

                @media (max-width: 768px) {
                    grid-template-columns: repeat(4, 1fr);
                    grid-gap: 0 2rem;
                    padding-bottom: 1rem;
                    margin-bottom: 3rem;
                }

                &:last-child {
                    margin-bottom: 0;

                    @media (max-width: 768px) {
                        margin-bottom: 8rem;
                    }
                }

                .img-container {
                    width: 8rem;
                    height: 11rem;

                    @media (max-width: 768px) {
                        grid-column: 1/2;
                    }

                    @media (max-width: 600px) {
                        width: 5rem;
                        height: 6.75rem;
                    }

                    img {
                        width: 8rem;
                        height: 11rem;
                        object-fit: cover;
                        border-radius: 4px;

                        @media (max-width: 600px) {
                            width: 5rem;
                            height: 6.75rem;
                        }
                    }
                }

                .details {
                    @media (max-width: 768px) {
                        grid-column: 2/5;
                        padding-bottom: 1.4rem;
                        //border-bottom: 1px solid rgba($color: #0C0D1E, $alpha: .05);
                    }

                    @media (max-width: 600px) {
                        padding-bottom: 1.75rem;
                    }

                    .brand {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 1.4rem;
                        line-height: 170%;
                        color: rgba($color: #0c0d1e, $alpha: 0.75);
                        margin-bottom: 4px;

                        @media (max-width: 600px) {
                            font-size: 1.5rem;
                            margin-bottom: 1rem;
                        }
                    }

                    .name,
                    .size,
                    .quantity {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 1.4rem;
                        line-height: 170%;
                        color: rgba($color: #0c0d1e, $alpha: 0.75);
                        margin-bottom: 4px;

                        @media (max-width: 600px) {
                            font-size: 1.5rem;
                            margin-bottom: 2px;
                        }
                    }

                    .quantity {
                        margin-bottom: 0;
                    }
                }

                .price {
                    @media (max-width: 768px) {
                        grid-column: 2/3;
                    }

                    .title {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 1.4rem;
                        line-height: 170%;
                        color: rgba($color: #0c0d1e, $alpha: 0.5);
                        margin-bottom: 4px;

                        @media (max-width: 600px) {
                            font-size: 1.75rem;
                        }
                    }

                    .actual-price {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 1.4rem;
                        line-height: 170%;
                        display: flex;
                        align-items: center;
                        color: #fe4242;

                        @media (max-width: 600px) {
                            font-size: 1.75rem;
                        }
                    }
                }

                .status {
                    @media (max-width: 768px) {
                        grid-column: 4/5;
                    }

                    .title {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 1.4rem;
                        line-height: 170%;
                        color: rgba($color: #0c0d1e, $alpha: 0.5);
                        margin-bottom: 4px;

                        @media (max-width: 600px) {
                            font-size: 1.75rem;
                        }
                    }

                    .actual-status {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 1.4rem;
                        line-height: 170%;
                        display: flex;
                        align-items: center;
                        color: #00d67c;

                        @media (max-width: 600px) {
                            font-size: 1.75rem;
                        }
                    }
                }
            }
        }
    }
}

.react-select-cities__single-value {
    color: #0c0d1e !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 1.6rem !important;
}

.contact-list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(264px, 1fr));
    grid-gap: 2.4rem 2.4rem;
    padding-bottom: 10rem;

    @media (max-width: 1128px) {
        padding: 0 2rem;
        padding-bottom: 10rem;
    }

    @media (max-width: 768px) {
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    }

    @media (max-width: 600px) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        grid-gap: 1.5rem;
        padding-bottom: 0;
    }

    .add-new-address {
        border: 1px dashed #fe6f42;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 170%;
        display: flex;
        align-items: center;
        color: #fe6f42;
        cursor: pointer;
        min-height: 9.6rem;
        border-radius: 4px;

        @media (max-width: 600px) {
            min-height: 12rem;
            font-size: 1.75rem;
        }

        svg {
            width: 2.4rem;
            height: 2.4rem;

            @media (max-width: 600px) {
                width: 3rem;
                height: 3rem;
            }

            g {
                opacity: 1;
            }

            path {
                stroke: #fe6f42;
            }
        }
    }

    input:checked~span {
        .address-card {
            color: red;
        }
    }
}

.order-details {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-gap: 0 2.4rem;

    @media (max-width: 768px) {
        padding-bottom: 10rem;
    }

    .order-header {
        grid-column: 1/-1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2.4rem 0;
        border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);
        margin-bottom: 3.2rem;

        @media (max-width: 768px) {
            grid-column: 1/-1;
        }

        @media (max-width: 600px) {
            margin-bottom: 3rem;
            padding: 1rem 0 1.5rem 0;
            margin-bottom: 1.5rem;
        }

        .order-header_text {
            font-style: normal;
            font-weight: 500;
            font-size: 2.4rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            color: #05061e;

            @media (max-width: 768px) {
                padding-left: 1.2rem;
            }

            @media (max-width: 600px) {
                font-size: 2.5rem;
                padding-left: 1.5rem;
            }
        }

        .order-header_code {
            font-style: normal;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            text-align: right;
            color: rgba($color: #05061e, $alpha: 0.5);

            @media (max-width: 768px) {
                padding-right: 1.2rem;
            }

            @media (max-width: 600px) {
                font-size: 1.5rem;
                padding-right: 1.5rem;
            }
        }
    }

    .order-address {
        grid-column: 1/6;

        @media (max-width: 768px) {
            grid-column: 1/-1;
            padding: 0 1.2rem;
        }

        @media (max-width: 600px) {
            padding: 0 1.5rem;
        }

        .order-address_title {
            width: 100%;
            font-style: normal;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 170%;
            color: rgba($color: #05061e, $alpha: 0.5);
            margin-bottom: 1.6rem;

            @media (max-width: 600px) {
                font-size: 1.75rem;
                margin-bottom: 2rem;
            }
        }

        .order-address_card {
            background: #ffffff;
            border: 1px solid rgba(5, 6, 30, 0.07);
            box-sizing: border-box;
            border-radius: 4px;

            @media (max-width: 768px) {
                margin-bottom: 3.2rem;
            }

            @media (max-width: 768px) {
                margin-bottom: 4rem;
            }

            .top-container {
                padding: 1.6rem 2rem 2rem 2rem;
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
                border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);

                @media (max-width: 768px) {
                    padding: 1.5rem;
                }

                .address-user-name {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1.6rem;
                    line-height: 170%;
                    display: flex;
                    align-items: center;
                    color: rgba($color: #05061e, $alpha: 0.75);
                    margin-bottom: 1.2rem;

                    @media (max-width: 768px) {
                        font-size: 1.75rem;
                        margin-bottom: 1rem;
                    }
                }

                .address-user-info {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 1.4rem;
                    line-height: 170%;
                    display: flex;
                    align-items: center;
                    color: rgba($color: #05061e, $alpha: 0.5);
                    margin-bottom: 1.2rem;

                    @media (max-width: 768px) {
                        font-size: 1.75rem;
                        margin-bottom: 1rem;
                    }
                }

                .address-other-details {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    .google-pin {
                        width: 3.2rem;
                        height: 3.2rem;
                        border: 1px solid rgba(26, 115, 232, 0.2);
                        box-sizing: border-box;
                        border-radius: 4px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 8px;

                        @media (max-width: 600px) {
                            height: 4rem;
                            width: 4rem;
                        }

                        svg {
                            width: 1.6rem;
                            height: 1.6rem;
                            object-fit: cover;

                            @media (max-width: 600px) {
                                height: 2rem;
                                width: 2rem;
                            }
                        }
                    }

                    .phone-number {
                        border: 1px solid rgba($color: #05061e, $alpha: 0.07);
                        box-sizing: border-box;
                        border-radius: 4px;
                        padding: 4px 12px;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 1.4rem;
                        line-height: 170%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: rgba($color: #05061e, $alpha: 0.75);
                        height: 3.2rem;

                        @media (max-width: 600px) {
                            height: 4rem;
                            font-size: 1.75rem;
                        }

                        svg {
                            margin-right: 8px;
                            width: 1.2rem;
                            height: 1.2rem;
                            object-fit: cover;

                            @media (max-width: 600px) {
                                height: 1.5rem;
                                width: 1.5rem;
                            }
                        }
                    }
                }
            }

            .address-card_delivery {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 1.4rem 2rem;

                @media (max-width: 768px) {
                    padding: 1.5rem;
                }

                .delivery-text {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1.4rem;
                    line-height: 170%;
                    color: rgba($color: #05061e, $alpha: 0.5);

                    @media (max-width: 600px) {
                        font-size: 1.5rem;
                    }
                }

                .delivery-status {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1.4rem;
                    line-height: 170%;
                    display: flex;
                    align-items: center;
                    text-align: right;
                    color: #05b136;

                    @media (max-width: 600px) {
                        font-size: 1.5rem;
                    }
                }
            }
        }
    }

    .order-payment {
        grid-column: 6/-1;

        @media (max-width: 768px) {
            grid-column: 1/-1;
            padding: 0 1.2rem;
        }

        @media (max-width: 600px) {
            padding: 0 1.5rem;
        }

        .order-payment_title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-style: normal;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 170%;
            margin-bottom: 1.6rem;

            @media (max-width: 600px) {
                font-size: 1.75rem;
                margin-bottom: 2rem;
            }

            &-text {
                color: rgba($color: #05061e, $alpha: 0.5);
            }

            &-price {
                color: #fe6f42;
            }
        }

        .order-payment-card {
            background: #ffffff;
            border: 1px solid rgba(5, 6, 30, 0.07);
            box-sizing: border-box;

            &_top {
                padding: 2.4rem 2.4rem 2rem 2.4rem;

                @media (max-width: 600px) {
                    padding: 1.5rem;
                }

                .order-payment_details {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 1.2rem;

                    @media (max-width: 600px) {
                        margin-bottom: 1.5rem;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .text {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 1.4rem;
                        line-height: 170%;
                        color: rgba($color: #05061e, $alpha: 0.75);

                        @media (max-width: 600px) {
                            font-size: 1.75rem;
                        }
                    }

                    .price {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 1.4rem;
                        line-height: 170%;
                        color: #05061e;

                        @media (max-width: 600px) {
                            font-size: 1.75rem;
                        }
                    }
                }
            }

            &_bottom {
                padding: 1.4rem 2.4rem;
                border-top: 1px solid rgba($color: #05061e, $alpha: 0.07);
                display: flex;
                align-items: center;
                justify-content: space-between;

                @media (max-width: 600px) {
                    padding: 1.75 1.5rem;
                }

                .payment-title {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1.4rem;
                    line-height: 170%;
                    color: rgba($color: #05061e, $alpha: 0.5);

                    @media (max-width: 600px) {
                        font-size: 1.5rem;
                    }
                }

                .payment-type {
                    display: flex;
                    align-items: center;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1.4rem;
                    line-height: 170%;
                    color: rgba($color: #05061e, $alpha: 0.5);

                    @media (max-width: 600px) {
                        font-size: 1.5rem;
                    }

                    svg {
                        width: 24px;
                        height: 24px;
                        margin-left: 1.2rem;

                        @media (max-width: 600px) {
                            margin-left: 1.5rem;
                        }
                    }
                }
            }
        }
    }

    .order-products {
        grid-column: 1/-1;
        margin-top: 2.4rem;

        @media (max-width: 768px) {
            grid-column: 1/-1;
            padding: 0 1.2rem;
        }

        @media (max-width: 600px) {
            padding: 0 1.5rem;
            margin-top: 4rem;
        }

        .order-products_header {
            font-style: normal;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 170%;
            color: rgba($color: #05061e, $alpha: 0.5);
            margin-bottom: 1.6rem;

            @media (max-width: 600px) {
                font-size: 1.75rem;
                margin-bottom: 1.5rem;
            }
        }
    }
}

.order-details {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-gap: 0 2.4rem;

    @media (max-width: 768px) {
        padding-bottom: 10rem;
    }

    .order-header {
        grid-column: 1/-1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2.4rem 0;
        border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);
        margin-bottom: 3.2rem;

        @media (max-width: 768px) {
            grid-column: 1/-1;
        }

        @media (max-width: 600px) {
            margin-bottom: 3rem;
            padding: 1rem 0 1.5rem 0;
            margin-bottom: 1.5rem;
        }

        .order-header_text {
            font-style: normal;
            font-weight: 500;
            font-size: 2.4rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            color: #05061e;

            @media (max-width: 768px) {
                padding-left: 1.2rem;
            }

            @media (max-width: 600px) {
                font-size: 2.5rem;
                padding-left: 1.5rem;
            }
        }

        .order-header_code {
            font-style: normal;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            text-align: right;
            color: rgba($color: #05061e, $alpha: 0.5);

            @media (max-width: 768px) {
                padding-right: 1.2rem;
            }

            @media (max-width: 600px) {
                font-size: 1.5rem;
                padding-right: 1.5rem;
            }
        }
    }

    .order-address {
        grid-column: 1/6;

        @media (max-width: 768px) {
            grid-column: 1/-1;
            padding: 0 1.2rem;
        }

        @media (max-width: 600px) {
            padding: 0 1.5rem;
        }

        .order-address_title {
            width: 100%;
            font-style: normal;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 170%;
            color: rgba($color: #05061e, $alpha: 0.5);
            margin-bottom: 1.6rem;

            @media (max-width: 600px) {
                font-size: 1.75rem;
                margin-bottom: 2rem;
            }
        }

        .order-address_card {
            background: #ffffff;
            border: 1px solid rgba(5, 6, 30, 0.07);
            box-sizing: border-box;
            border-radius: 4px;

            @media (max-width: 768px) {
                margin-bottom: 3.2rem;
            }

            @media (max-width: 768px) {
                margin-bottom: 4rem;
            }

            .top-container {
                padding: 1.6rem 2rem 2rem 2rem;
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
                border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);

                @media (max-width: 768px) {
                    padding: 1.5rem;
                }

                .address-user-name {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1.6rem;
                    line-height: 170%;
                    display: flex;
                    align-items: center;
                    color: rgba($color: #05061e, $alpha: 0.75);
                    margin-bottom: 1.2rem;

                    @media (max-width: 768px) {
                        font-size: 1.75rem;
                        margin-bottom: 1rem;
                    }
                }

                .address-user-info {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 1.4rem;
                    line-height: 170%;
                    display: flex;
                    align-items: center;
                    color: rgba($color: #05061e, $alpha: 0.5);
                    margin-bottom: 1.2rem;

                    @media (max-width: 768px) {
                        font-size: 1.75rem;
                        margin-bottom: 1rem;
                    }
                }

                .address-other-details {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    .google-pin {
                        width: 3.2rem;
                        height: 3.2rem;
                        border: 1px solid rgba(26, 115, 232, 0.2);
                        box-sizing: border-box;
                        border-radius: 4px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 8px;

                        @media (max-width: 600px) {
                            height: 4rem;
                            width: 4rem;
                        }

                        svg {
                            width: 1.6rem;
                            height: 1.6rem;
                            object-fit: cover;

                            @media (max-width: 600px) {
                                height: 2rem;
                                width: 2rem;
                            }
                        }
                    }

                    .phone-number {
                        border: 1px solid rgba($color: #05061e, $alpha: 0.07);
                        box-sizing: border-box;
                        border-radius: 4px;
                        padding: 4px 12px;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 1.4rem;
                        line-height: 170%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: rgba($color: #05061e, $alpha: 0.75);
                        height: 3.2rem;

                        @media (max-width: 600px) {
                            height: 4rem;
                            font-size: 1.75rem;
                        }

                        svg {
                            margin-right: 8px;
                            width: 1.2rem;
                            height: 1.2rem;
                            object-fit: cover;

                            @media (max-width: 600px) {
                                height: 1.5rem;
                                width: 1.5rem;
                            }
                        }
                    }
                }
            }

            .address-card_delivery {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 1.4rem 2rem;

                @media (max-width: 768px) {
                    padding: 1.5rem;
                }

                .delivery-text {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1.4rem;
                    line-height: 170%;
                    color: rgba($color: #05061e, $alpha: 0.5);

                    @media (max-width: 600px) {
                        font-size: 1.5rem;
                    }
                }

                .delivery-status {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1.4rem;
                    line-height: 170%;
                    display: flex;
                    align-items: center;
                    text-align: right;
                    color: #05b136;

                    @media (max-width: 600px) {
                        font-size: 1.5rem;
                    }
                }
            }
        }
    }

    .order-payment {
        grid-column: 6/-1;

        @media (max-width: 768px) {
            grid-column: 1/-1;
            padding: 0 1.2rem;
        }

        @media (max-width: 600px) {
            padding: 0 1.5rem;
        }

        .order-payment_title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-style: normal;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 170%;
            margin-bottom: 1.6rem;

            @media (max-width: 600px) {
                font-size: 1.75rem;
                margin-bottom: 2rem;
            }

            &-text {
                color: rgba($color: #05061e, $alpha: 0.5);
            }

            &-price {
                color: #fe6f42;
            }
        }

        .order-payment-card {
            background: #ffffff;
            border: 1px solid rgba(5, 6, 30, 0.07);
            box-sizing: border-box;

            &_top {
                padding: 2.4rem 2.4rem 2rem 2.4rem;

                @media (max-width: 600px) {
                    padding: 1.5rem;
                }

                .order-payment_details {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 1.2rem;

                    @media (max-width: 600px) {
                        margin-bottom: 1.5rem;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .text {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 1.4rem;
                        line-height: 170%;
                        color: rgba($color: #05061e, $alpha: 0.75);

                        @media (max-width: 600px) {
                            font-size: 1.75rem;
                        }
                    }

                    .price {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 1.4rem;
                        line-height: 170%;
                        color: #05061e;

                        @media (max-width: 600px) {
                            font-size: 1.75rem;
                        }
                    }
                }
            }

            &_bottom {
                padding: 1.4rem 2.4rem;
                border-top: 1px solid rgba($color: #05061e, $alpha: 0.07);
                display: flex;
                align-items: center;
                justify-content: space-between;

                @media (max-width: 600px) {
                    padding: 1.75 1.5rem;
                }

                .payment-title {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1.4rem;
                    line-height: 170%;
                    color: rgba($color: #05061e, $alpha: 0.5);

                    @media (max-width: 600px) {
                        font-size: 1.5rem;
                    }
                }

                .payment-type {
                    display: flex;
                    align-items: center;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1.4rem;
                    line-height: 170%;
                    color: rgba($color: #05061e, $alpha: 0.5);

                    @media (max-width: 600px) {
                        font-size: 1.5rem;
                    }

                    svg {
                        width: 24px;
                        height: 24px;
                        margin-left: 1.2rem;

                        @media (max-width: 600px) {
                            margin-left: 1.5rem;
                        }
                    }
                }
            }
        }
    }

    .order-products {
        grid-column: 1/-1;
        margin-top: 2.4rem;

        @media (max-width: 768px) {
            grid-column: 1/-1;
            padding: 0 1.2rem;
        }

        @media (max-width: 600px) {
            padding: 0 1.5rem;
            margin-top: 4rem;
        }

        .order-products_header {
            font-style: normal;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 170%;
            color: rgba($color: #05061e, $alpha: 0.5);
            margin-bottom: 1.6rem;

            @media (max-width: 600px) {
                font-size: 1.75rem;
                margin-bottom: 1.5rem;
            }
        }
    }
}

.order-detail-row {
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    //padding: 1.6rem 2rem 1.6rem 1.6rem;
    width: 100%;
    max-height: 15.2rem;
    margin-bottom: 2.4rem;
    position: relative;
    height: auto;
    border: 1px solid rgba(5, 6, 30, 0.07);

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        max-height: 100%;
        height: auto;
    }

    .order-detail-card-info {
        width: 100%;
        display: grid;
        grid-template-columns: 11rem auto;
        grid-gap: 0 2rem;
        grid-template-rows: auto auto;

        @media (max-width: 768px) {
            grid-gap: 8px 12px;
            height: auto;
            border-bottom: 1px solid rgba($color: #0c0d1e, $alpha: 0.05);
            width: 100%;
            grid-template-columns: 8.5rem auto;
        }

        .noComment {
            background-color: transparent !important;
            color: rgba(5, 6, 30, 0.5) !important;

            svg {
                path {
                    stroke: #82838f !important;
                }
            }
        }

        .img-container {
            grid-column: 1/2;
            grid-row: 1/-1;
            width: 11rem;
            height: 15.2rem;
            margin-right: 2rem;

            @media (max-width: 768px) {
                grid-row: 1/2;
                width: 8.5rem;
                height: 11.5rem;
                margin-right: 0;
            }

            img {
                width: 11rem;
                height: 100%;
                object-fit: cover;

                @media (max-width: 768px) {
                    width: 8.5rem;
                    height: 11.5rem;
                    padding: 1rem 0 0 1rem;
                }

                @media (max-width: 600px) {
                    width: 8.5rem;
                    height: 11.5rem;
                }
            }
        }

        .order-detail-card-content {
            position: relative;
            grid-column: 2/-1;
            grid-row: 1/2;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;

            .content {
                width: 100%;
                font-style: normal;
                font-weight: 500;
                font-size: 1.6rem;
                line-height: 170%;
                color: rgba($color: #05061e, $alpha: 0.75);
                padding-top: 1.2rem;

                @media (max-width: 768px) {
                    width: 100%;
                }

                @media (max-width: 768px) {
                    display: flex;
                    flex-direction: column;
                }

                @media (max-width: 600px) {
                    font-size: 1.5rem;
                }

                .brand {
                    margin-bottom: 4px;

                    @media (max-width: 600px) {
                        margin-bottom: 2px;
                        font-size: 1.5rem;
                    }
                }

                .name {
                    font-weight: normal;
                    margin-bottom: 4px;
                    width: 60%;

                    @media (max-width: 768px) {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: wrap;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }

                    @media (max-width: 600px) {
                        font-size: 1.75rem;
                        margin-bottom: 2px;
                    }
                }

                .size {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    font-weight: normal;
                    font-size: 1.4rem;

                    :first-child {
                        display: inline-block;
                        margin-right: 12px;
                    }

                    @media (max-width: 600px) {
                        font-size: 1.5rem;
                    }
                }
            }

            .order-status {
                position: absolute;
                top: 84px;
                right: 16px;
                font-style: normal;
                font-weight: 500;
                font-size: 1.4rem;
                line-height: 170%;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: right;
                color: #05b136;
                background-color: rgba($color: #05b136, $alpha: 0.1);
                width: 119px;
                height: 4rem;
                border-radius: 4px;

                @media (max-width: 600px) {
                    height: 3rem;
                    padding: 2px 8px;
                    font-size: 1.25rem;
                    top: 60px;
                    right: 12px;
                    width: 10rem;
                }
            }

            .order-comment {
                position: absolute;
                right: 16px;
                top: 28px;
                border: 1px solid rgba(5, 6, 30, 0.07);
                box-sizing: border-box;
                border-radius: 4px;
                height: 40px;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 170%;
                color: rgba(5, 6, 30, 0.5);
                display: flex;
                align-items: center;
                justify-content: center;
                width: 119px;
                transition: 0.3s;
                cursor: pointer;
                white-space: nowrap;

                svg {
                    margin-right: 1.2rem;

                    g {
                        transition: 0.3s;
                    }

                    path {
                        transition: 0.3s;
                    }
                }

                &:hover {
                    background: #fe6f42;
                    color: #ffffff;

                    svg {
                        g {
                            opacity: 1;
                        }

                        path {
                            stroke: #fff;
                        }
                    }
                }

                &.deactive {
                    border: 0.1rem solid rgba(5, 6, 30, 0.07);
                    color: rgba(5, 6, 30, 0.07);
                    pointer-events: none;

                    svg {
                        g {
                            opacity: 1;
                        }

                        path {
                            stroke: rgba(5, 6, 30, 0.07);
                        }
                    }
                }

                @media (max-width: 600px) {
                    height: 3rem;
                    padding: 2px 8px;
                    font-size: 1.25rem;
                    width: 10rem;
                    top: 26px;
                    right: 12px;
                }
            }
        }

        .order-detail-card-footer {
            grid-column: 2/-1;
            grid-row: 2/-1;
            display: flex;
            align-items: flex-end;

            @media (max-width: 768px) {
                grid-column: 1/-1;
                justify-content: flex-end;
                border-top: 1px solid rgba($color: #05061e, $alpha: 0.07);
                padding: 4px 1.2rem;
            }

            @media (max-width: 768px) {
                padding: 4px 1.5rem;
            }

            .quantity {
                font-style: normal;
                font-weight: 500;
                font-size: 1.6rem;
                line-height: 170%;
                display: flex;
                align-items: center;
                color: rgba($color: #05061e, $alpha: 0.75);
                height: 4.8rem;
                padding: 0 1.6rem;
                border: 1px solid rgba($color: #05061e, $alpha: 0.07);
                border-bottom: none;
                margin-right: 1.6rem;

                @media (max-width: 768px) {
                    border: none;
                    order: 1;
                    height: auto;
                    margin-right: 0;
                    padding: 0;
                    flex: 1;
                    justify-content: flex-end;
                }

                @media (max-width: 600px) {
                    font-size: 1.75rem;
                }
            }

            .payed {
                font-size: 1.6rem;
                font-style: normal;
                font-weight: 500;
                line-height: 170%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                color: #fe6f42;
                padding-bottom: 1.2rem;

                @media (max-width: 768px) {
                    padding-bottom: 0;
                    flex: 1;
                    justify-content: flex-end;
                }

                @media (max-width: 600px) {
                    font-size: 1.75rem;
                }
            }
        }
    }
}

.account-mobile {
    button {
        outline: 0;
        border: none;
        background-color: transparent;
    }
}

:root {
    --bg: #242526;
    --bg-accent: #484a4d;
    --text-color: black;
    --nav-size: 60px;
    --speed: 500ms;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

/* Dropdown Menu */

.dropdown {
    //position: absolute;
    //top: 0;
    width: 100%;
    background-color: #fff;
    overflow: hidden;
}

.menu {
    width: 100%;

    .select-all {
        font-size: 1.6rem;
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0 1rem;
        border-bottom: 1px solid #e6e6e6;
        color: #fe6f42;
        text-decoration: none;
    }

    .menu-item,
    .clickable-links {
        background-color: none;
        width: 100%;
        color: unset;
        text-decoration: none;
    }
}

.menu-item,
.clickable-links {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    border-bottom: 1px solid #e6e6e6;
    font-size: 1.6rem;
}

.menu-item .icon-button {
    margin-right: 0.5rem;
}

.menu-item .icon-button:hover {
    filter: none;
}

.icon-right {
    margin-left: auto;
}

/* CSSTransition classes  */
.menu-primary-enter {
    position: absolute;
    transform: translateX(-110%);
}

.menu-primary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
}

.menu-primary-exit {
    position: absolute;
}

.menu-primary-exit-active {
    transform: translateX(-110%);
    transition: all var(--speed) ease;
}

.menu-secondary-enter {
    position: absolute;
    transform: translateX(-110%);
}

.menu-secondary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
}

.menu-secondary-exit {
    position: absolute;
}

.menu-secondary-exit-active {
    transform: translateX(-110%);
    transition: all var(--speed) ease;
}

.menu-third-enter {
    position: absolute;
    transform: translateX(-110%);
}

.menu-third-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
}

.menu-third-exit {
    position: absolute;
}

.menu-third-exit-active {
    transform: translateX(-110%);
    transition: all var(--speed) ease;
}

.cart-wrapper {
    background: #fff;
    width: 100%;
    //position: relative;

    .acc-cont {
        padding: 0px !important;
    }

    .question {
        header {
            padding: 0 !important;
        }
    }

    .notification {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: white;
        padding: 4.8rem 2.4rem 2.4rem 2.4rem;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        z-index: 999999999999999999;

        @media (max-width: 600px) {
            padding-top: 2rem;
        }

        .img-container {
            width: 6.4rem;
            height: 6.4rem;
            margin-bottom: 2.4rem;

            @media (max-width: 600px) {
                width: 8rem;
                height: 8rem;
                margin-bottom: 3rem;
            }

            img {
                width: 6.4rem;
                height: 6.4rem;
                object-fit: cover;

                @media (max-width: 600px) {
                    width: 8rem;
                    height: 8rem;
                }
            }
        }

        .title {
            font-style: normal;
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 170%;
            color: rgba($color: #0c0d1e, $alpha: 0.75);

            @media (max-width: 600px) {
                font-size: 1.75rem;
            }
        }

        .subtitle {
            font-style: normal;
            font-weight: normal;
            font-size: 1.2rem;
            line-height: 170%;
            text-align: center;
            color: rgba($color: #0c0d1e, $alpha: 0.5);

            @media (max-width: 600px) {
                font-size: 1.5rem;
            }
        }

        .addInfo,
        .addInfo-mobile {
            display: inline-block;
            width: 36rem;
            height: 5rem;
            border: none;
            outline: none;
            cursor: pointer;
            background: #fe4242;
            border-radius: 8px;
            font-style: normal;
            font-weight: 600;
            font-size: 1.4rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            text-align: center;
            margin-top: 3.2rem;

            @media (max-width: 600px) {
                width: 30rem;
            }
        }

        .addInfo-mobile {
            display: none;

            @media (max-width: 768px) {
                display: block;
            }
        }

        .addInfo {
            display: block;

            @media (max-width: 768px) {
                display: none;
            }
        }

        .cross-close {
            border: none;
            outline: none;
            cursor: pointer;
            background: none;
            position: absolute;
            top: 10%;
            right: 10%;
        }
    }
}

.empty-cart {
    width: 112.8rem;
    margin: 0 auto;
    height: 48rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 5rem;

    @media (max-width: 1128px) {
        width: 100%;
        padding: 0 2rem;

        .breadcrumb {
            padding-left: 0;
            padding-right: 0;
        }
    }

    @media (max-width: 768px) {
        position: relative;
        padding: 0 1.2rem;
    }

    @media (max-width: 600px) {
        position: relative;
        padding: 0 1.5rem;
    }

    .empty-cart-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: rgba($color: #05061e, $alpha: 0.03);
        border-radius: 4px;

        @media (max-width: 768px) {
            padding: 0 1.2rem;
        }

        @media (max-width: 600px) {
            padding: 0 1.5rem;
        }

        .logo-container {
            width: 7.2rem;
            height: 7.2rem;
            margin-bottom: 1.6rem;

            @media (max-width: 600px) {
                width: 9rem;
                height: 9rem;
                margin-bottom: 1rem;
            }

            svg {
                width: 7.2rem;
                height: 7.2rem;
                object-fit: cover;

                @media (max-width: 600px) {
                    width: 9rem;
                    height: 9rem;
                }
            }
        }

        .title {
            font-style: normal;
            font-weight: 500;
            font-size: 2.4rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            color: #05061e;
            margin-bottom: 8px;

            @media (max-width: 600px) {
                font-size: 2.5rem;
            }
        }

        .subtitle {
            font-style: normal;
            font-weight: normal;
            font-size: 1.6rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            text-align: center;
            color: rgba($color: #05061e, $alpha: 0.5);
            margin-bottom: 3.2rem;

            @media (max-width: 600px) {
                font-size: 1.75rem;
                margin-bottom: 2.75rem;
            }
        }

        a {
            font-style: normal;
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: #ffffff;
            background: #fe6f42;
            border-radius: 4px;
            height: 4.8rem;
            width: 16.8rem;

            @media (max-width: 600px) {
                font-size: 1.75rem;
                height: 6rem;
                width: 100%;
            }
        }
    }
}

.cart-container {
    width: 112.8rem;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 0 2.4rem;
    margin: 0 auto;
    padding-bottom: 24.8rem;

    @media (max-width: 1128px) {
        width: 100%;
        padding: 0 2rem;
    }

    @media (min-width: 768px) {
        .breadcrumb.border {
            padding-left: 0;
            padding-right: 0;
        }
    }

    @media (max-width: 768px) {
        position: relative;
        padding: 0;

        .breadcrumb.border {
            border-bottom: none;
        }
    }
}

.cart-banner {
    grid-column: 1/-1;
    height: 14.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 4rem;

    @media (max-width: 768px) {
        height: auto;
        padding: 0 2rem;
        padding-top: 0.8rem;
        padding-bottom: 1.2rem;
        border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);
        margin-bottom: 0;
    }

    @media (max-width: 600px) {
        height: auto;
        padding-top: 1rem;
        padding-bottom: 1.5rem;
        border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);
    }

    .title {
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 170%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #05061e;
        margin-bottom: 8px;

        @media (max-width: 768px) {
            font-size: 2.4rem;
        }

        @media (max-width: 600px) {
            font-size: 3rem;
        }

        a {
            font-style: normal;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: rgba($color: #05061e, $alpha: 0.5);
            height: 4.8rem;
            border: 1px solid rgba($color: #05061e, $alpha: 0.07);
            border-radius: 4px;
            width: 19.2rem;

            svg {
                margin-right: 1.6rem;
            }
        }
    }

    .subtitle {
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 170%;
        color: rgba($color: #05061e, $alpha: 0.5);

        @media (max-width: 768px) {
            display: none;
        }
    }
}

.cart-table-wrapper {
    grid-column: 1/9;
    height: fit-content;

    @media (max-width: 768px) {
        grid-column: 1/-1;
        padding: 0 2rem;
    }

    .cart-table-header {
        background-color: rgba($color: #05061e, $alpha: 0.03);
        padding: 2rem 2.4rem;
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 170%;
        display: flex;
        align-items: center;
        color: rgba($color: #05061e, $alpha: 0.75);
        margin-bottom: 2.4rem;

        @media (max-width: 768px) {
            padding: 0;
            padding-top: 1.2rem;
            padding-bottom: 1.6rem;
            background-color: #fff;
        }

        @media (max-width: 600px) {
            padding: 0;
            padding-top: 1.5rem;
            padding-bottom: 2rem;
            background-color: #fff;
            font-size: 2rem;
        }
    }
}

.cart-sidebar {
    height: fit-content;
    grid-column: 9/-1;

    @media (max-width: 768px) {
        width: 100%;
        height: 7.2rem;
        grid-column: 1/-1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        bottom: -1%;
        left: 0;
        border-radius: 0;
        padding: 0 2rem;
        z-index: 9999998;
        background-color: #fff;
        border-top: 1px solid rgba($color: #0c0d1e, $alpha: 0.05);
    }

    @media (max-width: 768px) {
        height: 12.5rem;
        padding: 0;
    }

    @media (max-width: 768px) {
        height: 15.625rem;
    }

    .cart-content-mobile {
        display: none;
        flex-direction: column;
        height: auto;

        @media (max-width: 768px) {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
        }

        .total-content {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding-bottom: 1.2rem;
            border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);
            position: relative;

            @media (max-width: 768px) {
                padding-left: 1.2rem;
                padding-right: 1.2rem;
            }

            @media (max-width: 600px) {
                padding: 1.5rem;
                padding-top: 0;
            }

            .cart-content-popup {
                position: absolute;
                bottom: 0;
                left: 0;
                //visibility: hidden;
                height: 100vh;
                width: 100vw;
                background-color: transparent;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                transform: translateY(100%);
                transition: all 0.5s;

                .cart-detail-close-btn {
                    width: 4rem;
                    height: 4rem;
                    margin-bottom: 4px;

                    @media (max-width: 600px) {
                        width: 5rem;
                        height: 5rem;
                    }

                    svg {
                        width: 4rem;
                        height: 4rem;
                        object-fit: cover;

                        @media (max-width: 600px) {
                            width: 5rem;
                            height: 5rem;
                        }
                    }
                }

                &.visible {
                    visibility: visible;
                    transform: translateY(0);
                    background-color: transparent;
                    animation-name: cart;
                    animation-duration: 0.3s;
                    //animation-delay: .3s;
                    animation-iteration-count: 1;
                    animation-fill-mode: both;
                }

                @keyframes cart {
                    from {
                        background-color: transparent;
                    }

                    to {
                        background-color: rgba($color: #05061e, $alpha: 0.5);
                    }
                }

                .cart-detail-popup-container {
                    height: auto;
                    width: 100%;
                    background-color: #fff;
                    padding-bottom: 2.4rem;

                    .side-heading {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 2.4rem;
                        line-height: 170%;
                        display: flex;
                        align-items: center;
                        color: #05061e;
                        background-color: rgba($color: #05061e, $alpha: 0.07);
                        height: 8.4rem;
                        padding-left: 2.4rem;
                        margin-bottom: 2.4rem;

                        @media (max-width: 600px) {
                            font-weight: 500;
                            font-size: 2.5rem;
                            padding: 0;
                            padding-left: 1.5rem;
                            margin-bottom: 4rem;
                        }
                    }

                    .amount,
                    .discount {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 1.6rem;
                        line-height: 170%;
                        color: rgba($color: #05061e, $alpha: 0.75);
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 0 2.4rem;

                        @media (max-width: 600px) {
                            font-size: 1.75rem;
                            padding: 0 1.5rem;
                        }

                        .amount-content,
                        .discount-content {
                            color: rgba($color: #05061e, $alpha: 0.75);

                            @media (max-width: 600px) {
                                font-size: 1.75rem;
                            }
                        }

                        .discount-price {
                            font-weight: 500;
                            color: #ff2121;

                            @media (max-width: 600px) {
                                font-size: 1.75rem;
                            }
                        }

                        .amount-price {
                            font-weight: 500;
                            color: #05061e;

                            @media (max-width: 600px) {
                                font-size: 1.75rem;
                            }
                        }
                    }

                    .amount {
                        margin-bottom: 1.6rem;

                        @media (max-width: 600px) {
                            margin-bottom: 2rem;
                        }
                    }

                    .discount {
                        padding-bottom: 3.2rem;
                        border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);

                        @media (max-width: 600px) {
                            padding-bottom: 4rem;
                        }
                    }

                    .cart-total {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 2rem;
                        line-height: 170%;
                        color: rgba($color: #05061e, $alpha: 0.75);
                        margin-top: 3.2rem;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 0 2.4rem;

                        @media (max-width: 600px) {
                            padding: 0 1.5rem;
                            margin-top: 3rem;
                        }

                        .price {
                            color: #fe6f42;
                            font-weight: 500;
                        }
                    }

                    .discount-coupon {
                        margin-top: 3.2rem;
                        padding: 0 2.4rem;

                        @media (max-width: 600px) {
                            margin-top: 3rem;
                            padding: 0 1.5rem;
                        }

                        .container {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            height: 4.8rem;
                            position: relative;

                            @media (max-width: 600px) {
                                height: 6rem;
                            }

                            input {
                                border: 1px solid rgba(5, 6, 30, 0.07);
                                box-sizing: border-box;
                                border-radius: 4px;
                                height: 100%;
                                width: 100%;
                                padding-left: 1.2rem;
                                padding-right: 4rem;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 1.6rem;
                                line-height: 170%;
                                display: flex;
                                align-items: center;
                                color: rgba($color: #05061e, $alpha: 0.75);
                                outline: none;
                                transition: all 0.3s;

                                @media (max-width: 600px) {
                                    font-size: 1.75rem;
                                }

                                &::placeholder {
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: 1.6rem;
                                    line-height: 170%;
                                    display: flex;
                                    align-items: center;
                                    color: rgba($color: #05061e, $alpha: 0.5);

                                    @media (max-width: 600px) {
                                        font-size: 1.75rem;
                                    }
                                }

                                &:focus {
                                    border: 1px solid #fe6f42;

                                    &+button {
                                        background-color: rgba($color: #fe6f42, $alpha: 0.1);
                                        color: #fe6f42;
                                    }
                                }
                            }

                            button {
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                right: 0;
                                background: rgba($color: #05061e, $alpha: 0.03);
                                border-radius: 4px;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 1.4rem;
                                line-height: 170%;
                                display: flex;
                                align-items: center;
                                text-align: center;
                                color: rgba($color: #05061e, $alpha: 0.75);
                                padding: 8px 10px;
                                margin-right: 4px;
                                height: 4rem;
                                transition: all 0.3s;

                                @media (max-width: 600px) {
                                    font-size: 1.75rem;
                                    height: 5rem;
                                }
                            }
                        }
                    }
                }
            }

            .text {
                display: flex;
                align-items: center;
                font-style: normal;
                font-weight: 500;
                font-size: 1.6rem;
                line-height: 170%;
                color: rgba($color: #05061e, $alpha: 0.75);

                @media (max-width: 768px) {
                    font-size: 1.5rem;
                }

                @media (max-width: 600px) {
                    font-size: 2rem;
                }

                svg {
                    margin-left: 4px;
                }
            }

            .price {
                font-style: normal;
                font-weight: 500;
                font-size: 1.6rem;
                line-height: 170%;
                text-align: right;
                color: #fe6f42;

                @media (max-width: 600px) {
                    font-size: 2rem;
                }
            }
        }

        .button-container {
            display: flex;
            width: 100%;
            background: #fff;
            z-index: 9;

            @media (max-width: 768px) {
                padding: 1.2rem;
            }

            @media (max-width: 768px) {
                padding: 1.5rem;
            }
        }
    }

    .order-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: none;
        outline: none;
        width: 100%;
        height: 5rem;
        background: #fe6f42;
        color: #fff;
        border-radius: 4px;
        font-style: normal;
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 170%;
        margin-top: 3.2rem;

        @media (max-width: 768px) {
            height: 4.8rem;
            margin-top: 0;
        }

        @media (max-width: 600px) {
            font-size: 1.75rem;
            height: 6rem;
        }
    }

    .order-as-guest {
        cursor: pointer;
        border: none;
        outline: none;
        width: 100%;
        height: 5rem;
        color: #fff;
        background: #05b136;
        border-radius: 4px;
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 170%;
        margin-top: 3.2rem;
        margin-right: 1.6rem;

        @media (max-width: 768px) {
            height: 4.8rem;
            margin-top: 0;
        }

        @media (max-width: 600px) {
            font-size: 1.75rem;
            height: 6rem;
            margin-right: 2rem;
        }
    }

    .cart-sidebar_main {
        .cart-content {
            margin-bottom: 1.6rem;
            background: white;
            //padding: 2.4rem 2.4rem 3.2rem 2.4rem;
            border: 1px solid rgba(5, 6, 30, 0.07);
            padding-bottom: 2.4rem;

            @media (max-width: 768px) {
                display: none;
            }

            .side-heading {
                font-style: normal;
                font-weight: normal;
                font-size: 2.4rem;
                line-height: 170%;
                display: flex;
                align-items: center;
                color: #05061e;
                background-color: rgba($color: #05061e, $alpha: 0.07);
                height: 8.4rem;
                padding-left: 2.4rem;
                margin-bottom: 2.4rem;
            }

            .amount,
            .discount {
                font-style: normal;
                font-weight: normal;
                font-size: 1.6rem;
                line-height: 170%;
                color: rgba($color: #05061e, $alpha: 0.75);
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 2.4rem;

                .amount-content,
                .discount-content {
                    color: rgba($color: #05061e, $alpha: 0.75);
                }

                .discount-price {
                    font-weight: 500;
                    color: #ff2121;
                }

                .amount-price {
                    font-weight: 500;
                    color: #05061e;
                }
            }

            .amount {
                margin-bottom: 1.6rem;
            }

            .discount {
                padding-bottom: 3.2rem;
                border-bottom: 1px solid rgba($color: #05061e, $alpha: 0.07);
            }

            .cart-total {
                font-style: normal;
                font-weight: normal;
                font-size: 2rem;
                line-height: 170%;
                color: rgba($color: #05061e, $alpha: 0.75);
                margin-top: 3.2rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 2.4rem;

                .price {
                    color: #fe6f42;
                    font-weight: 500;
                }
            }

            .discount-coupon {
                margin-top: 3.2rem;
                padding: 0 2.4rem;

                .container {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 4.8rem;
                    position: relative;

                    input {
                        border: 1px solid rgba(5, 6, 30, 0.07);
                        box-sizing: border-box;
                        border-radius: 4px;
                        height: 100%;
                        width: 100%;
                        padding-left: 1.2rem;
                        padding-right: 4rem;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 1.6rem;
                        line-height: 170%;
                        display: flex;
                        align-items: center;
                        color: rgba($color: #05061e, $alpha: 0.75);
                        outline: none;
                        transition: all 0.3s;

                        &::placeholder {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 1.6rem;
                            line-height: 170%;
                            display: flex;
                            align-items: center;
                            color: rgba($color: #05061e, $alpha: 0.5);
                        }

                        &:focus {
                            border: 1px solid #fe6f42;

                            &+button {
                                background-color: rgba($color: #fe6f42, $alpha: 0.1);
                                color: #fe6f42;
                            }
                        }
                    }

                    button {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 0;
                        background: rgba($color: #05061e, $alpha: 0.03);
                        border-radius: 4px;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 1.4rem;
                        line-height: 170%;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        color: rgba($color: #05061e, $alpha: 0.75);
                        padding: 8px 10px;
                        margin-right: 4px;
                        height: 4rem;
                        transition: all 0.3s;
                    }
                }
            }

            .buttons-container {
                padding: 0 2.4rem;

                .order-btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    border: none;
                    outline: none;
                    width: 100%;
                    height: 5rem;
                    background: #fe6f42;
                    color: #fff;
                    border-radius: 4px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 1.4rem;
                    line-height: 170%;
                    margin-top: 3.2rem;

                    @media (max-width: 768px) {
                        height: 4.8rem;
                        width: 12.8rem;
                        margin-top: 0;
                    }

                    @media (max-width: 600px) {
                        font-size: 1.75rem;
                        height: 6rem;
                        width: 16rem;
                    }
                }
            }
        }

        .order-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border: none;
            outline: none;
            width: 100%;
            height: 5rem;
            background: #fe6f42;
            color: #fff;
            border-radius: 4px;
            font-style: normal;
            font-weight: 600;
            font-size: 1.4rem;
            line-height: 170%;
            margin-top: 3.2rem;

            @media (max-width: 768px) {
                height: 4.8rem;
                width: 12.8rem;
                margin-top: 0;
            }

            @media (max-width: 600px) {
                font-size: 1.75rem;
                height: 6rem;
                width: 16rem;
            }
        }

        .need-register {
            font-style: normal;
            font-weight: normal;
            font-size: 1.4rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba($color: #05061e, $alpha: 0.5);
            padding-top: 1.6rem;

            @media (max-width: 768px) {
                padding-top: 0;
            }
        }

        .cart-sidebar-accordion {
            margin-top: 1.6rem;
            max-height: fit-content;

            @media (max-width: 768px) {
                display: none;
            }

            article {
                background-color: #fff;

                .acc-cont {
                    padding: 2.4rem;
                }

                header {
                    background: rgba(5, 6, 30, 0.03);
                    height: 8rem;

                    button {
                        height: 2.4rem;
                        width: 2.4rem;

                        svg {
                            width: 2.4rem;
                            height: 2.4rem;
                            object-fit: cover;
                        }
                    }
                }
            }
        }

        .cart-sidebar-second {
            grid-column: 9/-1;
            height: auto;

            @media (max-width: 768px) {
                display: none;
            }

            .cart-sidebar_address {
                background-color: #fff;
                border-radius: 8px;
                padding: 1.6rem 2.4rem 2.4rem 2.4rem;

                .header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 1.2rem;

                    p {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 2rem;
                        line-height: 170%;
                        color: rgba($color: #0c0d1e, $alpha: 0.75);
                    }

                    button {
                        border: none;
                        outline: none;
                        cursor: pointer;
                        background: none;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 1.2rem;
                        line-height: 170%;
                        text-align: center;
                        color: #fe4242;
                    }
                }

                .address-container {
                    display: flex;
                    flex-direction: column;

                    .address-form-group {
                        display: flex;
                        width: 100%;
                        margin-bottom: 1.6rem;

                        input {
                            display: none;

                            &:checked+label {
                                border: 1px solid #fe4242;

                                .title {
                                    color: #fe4242;
                                }
                            }
                        }

                        label {
                            border: 1px solid rgba($color: #0c0d1e, $alpha: 0.1);
                            border-radius: 8px;
                            padding: 1.2rem;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 1.2rem;
                            line-height: 170%;
                            width: 100%;
                            cursor: pointer;

                            .title {
                                font-style: normal;
                                font-weight: 600;
                                font-size: 1.4rem;
                                line-height: 170%;
                                color: rgba($color: #0c0d1e, $alpha: 0.75);
                            }

                            .address {
                                color: rgba($color: #0c0d1e, $alpha: 0.5);
                                padding-bottom: 0.8rem;
                            }

                            .mobile {
                                color: rgba($color: #0c0d1e, $alpha: 0.75);
                            }
                        }
                    }
                }
            }
        }
    }
}

.address-notification {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 8.2rem 2.4rem 2.4rem 2.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 999999999999999999;
    max-width: 40.8rem;
    min-width: 29.6rem;

    @media (max-width: 600px) {
        padding: 6rem 3rem 3rem 3rem;
        max-width: 51rem;
        min-width: 37rem;
    }

    .img-container {
        width: 7.2rem;
        height: 7.2rem;
        margin-bottom: 1.6rem;

        @media (max-width: 600px) {
            width: 9rem;
            height: 9rem;
            margin-bottom: 1rem;
        }

        svg {
            width: 7.2rem;
            height: 7.2rem;
            object-fit: cover;

            @media (max-width: 600px) {
                width: 9rem;
                height: 9rem;
            }
        }
    }

    .title {
        font-style: normal;
        font-weight: 500;
        font-size: 2.4rem;
        line-height: 170%;
        display: flex;
        align-items: center;
        text-align: center;
        color: #05061e;
        margin-bottom: 6px;

        @media (max-width: 600px) {
            font-size: 2.5rem;
        }
    }

    .subtitle {
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 170%;
        text-align: center;
        color: rgba($color: #05061e, $alpha: 0.5);

        @media (max-width: 600px) {
            font-size: 1.75rem;
        }

        .bold-text {
            font-weight: 500;
        }
    }

    .warning {
        font-style: normal;
        font-weight: normal;
        font-size: 1.4rem;
        line-height: 170%;
        text-align: center;
        color: #fe4242;

        .mobile:after {
            display: inline-block;
            content: "";
            width: 3px;
            height: 3px;
            background: #fe4242;
            border-radius: 50%;
            margin: 0 1.2rem;
            vertical-align: middle;
        }
    }

    .addInfo,
    .addInfo-mobile {
        display: inline-block;
        width: 36rem;
        height: 5rem;
        border: none;
        outline: none;
        cursor: pointer;
        background: #fe4242;
        border-radius: 8px;
        font-style: normal;
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 170%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        text-align: center;
        margin-top: 3.2rem;

        @media (max-width: 600px) {
            width: 30rem;
        }
    }

    .add-to-cart {
        width: 100%;
        height: 4.8rem;
        background: #fe6f42;
        border-radius: 4px;
        font-style: normal;
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 170%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #ffffff;
        margin-top: 3.2rem;

        @media (max-width: 600px) {
            height: 6rem;
            font-size: 1.75rem;
            margin-top: 3rem;
        }
    }

    .cross-close {
        border: none;
        outline: none;
        cursor: pointer;
        background: none;
        position: absolute;
        top: 24px;
        right: 24px;
        width: 2.4rem;
        height: 2.4rem;

        @media (max-width: 600px) {
            width: 2.5rem;
            height: 2.5rem;
        }

        img {
            width: 2.4rem;
            height: 2.4rem;
            object-fit: cover;

            @media (max-width: 600px) {
                width: 2.5rem;
                height: 2.5rem;
            }
        }
    }
}

.size-table {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 2.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 999999999999999999;
    max-width: 76rem;
    min-width: 29.6rem;

    &__img {
        max-width: 100%;
        max-height: 80vh;
    }

    @media (max-width: 767px) {
        min-width: 90vw;

        &__img {
            max-height: 90vh;
        }
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba($color: #05061e, $alpha: 0.75);
    width: 100vw;
    height: 100vh;
    z-index: 999999999999999;
}

.overlay.search {
    top: 60px;

    .search-input_container {
        background: #fff;
        z-index: 999;
        height: 7.2rem;
        padding: 1.2rem;

        @media (max-width: 600px) {
            height: 9rem;
            padding: 1.5rem;
        }
    }
}

.mobile-address {
    width: 100%;
    display: none;
    grid-column: 1/-1;
    padding: 0 2rem;
    margin-top: 8px;
    flex-direction: column;

    @media (max-width: 768px) {
        display: flex;
    }

    .header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.2rem;

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 2rem;
            line-height: 170%;
            color: rgba($color: #0c0d1e, $alpha: 0.75);

            @media (max-width: 600px) {
                font-size: 1.75rem;
            }
        }

        button {
            border: none;
            outline: none;
            cursor: pointer;
            background: none;
            font-style: normal;
            font-weight: normal;
            font-size: 1.2rem;
            line-height: 170%;
            text-align: center;
            color: #fe4242;

            @media (max-width: 600px) {
                font-size: 1.5rem;
            }
        }
    }

    .address-container {
        display: flex;
        flex-direction: column;

        .address-form-group {
            display: flex;
            width: 100%;
            margin-bottom: 1.6rem;

            input {
                display: none;

                &:checked+label {
                    border: 1px solid #fe4242;

                    .title {
                        color: #fe4242;
                    }
                }
            }

            label {
                border: 1px solid rgba($color: #0c0d1e, $alpha: 0.1);
                border-radius: 8px;
                padding: 1.2rem;
                font-style: normal;
                font-weight: normal;
                font-size: 1.2rem;
                line-height: 170%;
                width: 100%;
                cursor: pointer;
                background: #fff;

                @media (max-width: 600px) {
                    padding: 1.5rem;
                    height: auto;
                }

                .title {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 1.4rem;
                    line-height: 170%;
                    color: rgba($color: #0c0d1e, $alpha: 0.75);

                    @media (max-width: 600px) {
                        font-size: 1.75rem;
                        line-height: 170%;
                    }
                }

                .address {
                    color: rgba($color: #0c0d1e, $alpha: 0.5);
                    padding-bottom: 0.8rem;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    width: 100%;

                    @media (max-width: 600px) {
                        font-size: 1.5rem;
                        padding-bottom: 1rem;
                        line-height: 170%;
                    }
                }

                .mobile {
                    color: rgba($color: #0c0d1e, $alpha: 0.75);

                    @media (max-width: 600px) {
                        font-size: 1.5rem;
                        line-height: 170%;
                    }
                }
            }
        }
    }
}

.popupfast {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: #fff;
    z-index: 9999998;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 768px) {
        background: #fff;
        top: 30px;
        left: 0;
        height: 100vh;
        overflow-y: scroll;
    }
}

.popup_inner.fast {
    h1.title {
        color: rgba($color: #0c0d1e, $alpha: 0.75);
    }

    h3.subtitle {
        color: rgba($color: #0c0d1e, $alpha: 0.4);
    }

    form {
        button.confirm-button {
            color: #ffffff;
            background: #00d67c;
        }
    }
}

.empty-address {
    background-color: #f3f3f4;
    border-radius: 8px;
    padding: 1.8rem 0 2.2rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 600px) {
        padding: 2rem 0 2rem 0;
    }

    &.red-border {
        border: 1px solid #fe4242;
    }

    p {
        font-style: normal;
        font-weight: normal;
        font-size: 1.2rem;
        line-height: 170%;
        text-align: center;
        color: rgba($color: #0c0d1e, $alpha: 0.5);
        margin-bottom: 4px;

        @media (max-width: 600px) {
            font-size: 1.5rem;
        }
    }

    button {
        border: none;
        outline: none;
        background: none;
        cursor: pointer;
        font-style: normal;
        font-weight: normal;
        font-size: 1.2rem;
        line-height: 170%;
        text-align: center;
        color: #fe4242;

        @media (max-width: 600px) {
            font-size: 1.5rem;
        }
    }

    &.white {
        background: #fff;
    }

    &.white.red-border {
        border: 1px solid #fe4242;
    }
}

.white {
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    width: 100%;
    height: 100%;
    z-index: 9999999999;
}

.popup.address {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999999;
}

button {
    border: none;
    outline: none;
    background: transparent;
}

.custom-banner {
    grid-column: 1/-1;
    width: 100%;
    height: 16rem;
    border-radius: 0.8rem;
    position: relative;
    margin-bottom: 4rem;
    margin-top: 3.2rem;

    img {
        width: 100%;
        height: 16rem;
        border-radius: 0.8rem;
        object-fit: cover;
    }

    .title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 600;
        font-size: 2.4rem;
        line-height: 170%;
        color: rgba($color: #ffffff, $alpha: 0.75);
        text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    }
}

.about-page {
    width: 112.8rem;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 0 2.4rem;
    margin: 0 auto;
    padding-bottom: 15rem;

    @media (max-width: 1128px) {
        width: 100%;
    }
}

.reusable-content {
    grid-column: 3/11;

    .title {
        font-style: normal;
        font-weight: 500;
        font-size: 2rem;
        line-height: 170%;
        color: rgba($color: #0c0d1e, $alpha: 0.75);
        margin-bottom: 1.6rem;
    }

    .subtitle {
        font-style: normal;
        font-weight: normal;
        font-size: 1.4rem;
        line-height: 170%;
        color: rgba($color: #0c0d1e, $alpha: 0.5);
        margin-bottom: 3.2rem;
    }
}

.usage-page {
    width: 112.8rem;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 0 2.4rem;
    margin: 0 auto;
    padding-bottom: 15rem;

    @media (max-width: 1128px) {
        width: 100%;
    }
}

.question-faq {
    width: 100%;
    margin-bottom: 1.6rem;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 5.7rem;
        padding: 0 1.6rem;
        border-radius: 0.8rem;
        border: 1px solid transparent;
        transition: all 0.3s;
        cursor: pointer;

        &:hover {
            background: rgba(12, 13, 30, 0.03);
        }

        &.open {
            border: 1px solid rgba($color: #0c0d1e, $alpha: 0.1);

            &:hover {
                background: transparent;
            }
        }

        h4 {
            font-style: normal;
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 170%;
            color: rgba($color: #0c0d1e, $alpha: 0.75);
            margin-bottom: 0;
        }
    }

    p {
        font-style: normal;
        font-weight: normal;
        font-size: 1.4rem;
        line-height: 170%;
        color: rgba($color: #0c0d1e, $alpha: 0.5);
        padding: 1.2rem 1.6rem 1.6rem 1.6rem;
        background: rgba(12, 13, 30, 0.03);
        border-bottom-left-radius: 0.8rem;
        border-bottom-right-radius: 0.8rem;
    }
}

.btn-faq {
    background: transparent;
    border-color: transparent;
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    align-self: center;
    transform: rotate(180deg);
    transition: all 0.3s;
    outline: none;

    svg {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.help-page {
    width: 112.8rem;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 0 2.4rem;
    margin: 0 auto;
    padding-bottom: 15rem;

    @media (max-width: 1128px) {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .help-page_side {
        grid-column: 3/5;

        @media (max-width: 1128px) {
            padding: 0 16px;
        }

        .tab-list {
            .tab-title {
                display: flex;
                align-items: center;
                font-style: normal;
                font-weight: 500;
                font-size: 1.6rem;
                line-height: 170%;
                color: rgba($color: #0c0d1e, $alpha: 0.5);
                padding: 1.6rem 0;
                border-bottom: 1px solid rgba($color: #0c0d1e, $alpha: 0.1);
                cursor: pointer;

                &:first-child {
                    padding-bottom: 1.6rem;
                    padding-top: 0;
                }

                &:last-child {
                    border-bottom: none;
                }

                &:before {
                    content: "";
                    width: 0;
                    height: 1px;
                    background: #fe4242;
                    margin-right: 0;
                    display: block;
                    transition: all 0.3s;
                }

                &:hover {
                    transition: all 0.3s;
                    color: #fe4242;

                    &.tab-title:before {
                        width: 1.6rem;
                        margin-right: 0.8rem;
                        display: block;
                        transform: translateX(0);
                    }
                }

                &.tab-title.tab-title--active:before {
                    color: #fe4242;
                    display: block;
                    transform: translateX(0);
                    width: 1.6rem;
                    margin-right: 0.8rem;
                }

                &.tab-title--active {
                    color: #fe4242;
                }
            }
        }
    }

    .help-page_body {
        @media (max-width: 1128px) {
            padding: 0 16px;
        }

        grid-column: 5/11;
    }
}

.Toastify {
    position: fixed;
    z-index: 1000000000000;

    &__toast {
        @media (max-width: 480px) {
            top: 20px !important;
            width: 90%;

            &-container {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            }
        }
    }
}

.contact-page {
    width: 112.8rem;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 0 2.4rem;
    margin: 0 auto;
    padding-top: 3.2rem;
    padding-bottom: 15rem;

    @media (max-width: 1128px) {
        width: 100%;
        padding: 3.2rem 2rem 15rem 2rem;
    }

    .contact-page_form {
        grid-column: 1/7;

        @media (max-width: 768px) {
            grid-column: 1/-1;
        }

        .contact-form_heading {
            font-style: normal;
            font-weight: 600;
            font-size: 2.4rem;
            line-height: 170%;
            color: rgba($color: #0c0d1e, $alpha: 0.75);
            margin-bottom: 3.2rem;
        }

        .form-group {
            display: flex;

            @media (max-width: 768px) {
                flex-direction: column;
            }

            .form-control {
                &:first-child {
                    margin-right: 2.4rem;
                }

                label {
                    color: rgba($color: #0c0d1e, $alpha: 0.75);
                }

                small {
                    color: #fe4242;
                    margin-bottom: 1rem;
                }
            }
        }

        .text-area {
            grid-column: 1 / 3;

            @media (max-width: 600px) {
                grid-column: 1/-1;
            }

            label {
                color: rgba($color: #0c0d1e, $alpha: 0.75);
            }

            textarea {
                font-family: "DM Sans", sans-serif;
                width: 100%;
                height: 9.6rem;
                resize: none;
                border-radius: 1.2rem;
                outline: none;
                border: 1px solid rgba($color: #0c0d1e, $alpha: 0.1);
                padding-top: 1.7rem;
                padding-left: 1.4rem;
                font-style: normal;
                font-weight: 500;
                font-size: 1.4rem;
                line-height: 170%;
                color: rgba($color: #0c0d1e, $alpha: 0.75);
                transition: border 0.3s;
                color: #0c0d1e;
                font-style: normal;
                font-weight: 500;
                font-size: 1.4rem;

                @media (max-width: 600px) {
                    height: 12rem;
                    font-size: 1.75rem;
                }

                &::placeholder {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1.4rem;
                    line-height: 150%;
                    color: rgba($color: #0c0d1e, $alpha: 0.3);

                    @media (max-width: 600px) {
                        font-size: 1.75rem;
                    }
                }

                &:focus {
                    border: 1px solid rgba($color: #fe4242, $alpha: 0.5);
                }
            }

            small {
                color: #fe4242;
                margin-bottom: 1rem;
            }
        }

        .submit-btn {
            width: 26.4rem;

            @media (max-width: 768px) {
                width: 100%;
            }

            @media (max-width: 600px) {
                font-size: 1.75rem;
                height: 6rem;
            }
        }
    }

    .contact-image {
        grid-column: 7/-1;
        width: 100%;
        height: 100%;
        border-radius: 0.8rem;

        @media (max-width: 768px) {
            display: none;
        }

        img {
            border-radius: 0.8rem;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}


.setting-form {
    width: 100%;
    height: auto;
    background: #fff;
    border-radius: 0.8rem;
    padding-bottom: 4.8rem;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
        padding: 0 1.2rem;
    }

    @media (max-width: 600px) {
        padding: 0 1.5rem;
    }

    .form-container {
        display: grid;
        grid-template-columns: repeat(9, 1fr);
        grid-gap: 0 2.4rem;

        @media (max-width: 768px) {
            flex-direction: column;
        }
    }

    .submit-btn {
        width: 100%;
        margin-top: unset;

        @media (max-width: 768px) {
            width: 100%;
            height: 6rem;
        }

        @media (max-width: 600px) {
            height: 6rem;
            font-size: 1.75rem;
            margin-top: 1.5rem;
        }
    }

    .contact-heading {
        font-style: normal;
        font-weight: 500;
        font-size: 2rem;
        line-height: 170%;
        display: flex;
        align-items: center;
        color: #05061e;
        margin-bottom: 3.2rem;

        @media (max-width: 768px) {
            margin-bottom: 3rem;
            font-style: normal;
            font-weight: 500;
            font-size: 2rem;
            line-height: 170%;
            color: rgba($color: #05061e, $alpha: 0.75);
        }
    }

    .email-section {
        grid-column: 1/7;
        width: 100%;

        @media (max-width: 768px) {
            padding-right: 0;
            width: 100%;
            grid-column: 1/-1;
            margin-bottom: 4.8rem;
        }

        @media (max-width: 600px) {
            margin-bottom: 6rem;
        }

        .submit-btn {
            margin-top: 1.2rem;
        }

        .form-group {
            display: flex;

            &.second {
                @media (max-width: 768px) {
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        .form-control {
            &:first-child {
                margin-right: 2.4rem;
            }

            .number-input {
                display: flex;
                border: 1px solid rgba($color: #05061e, $alpha: 0.07);
                border-radius: 4px;

                input {
                    border: none;

                    &:focus {
                        border: 1px solid rgba($color: #FE6F42, $alpha: 0.5);
                    }
                }
            }

            small {
                margin-bottom: 0;

                @media (max-width: 768px) {
                    margin-bottom: unset;
                }
            }

        }
    }

    .password-section {
        //padding-top: 5.9rem;
        grid-column: 7/-1;
        width: 100%;
        background: rgba(5, 6, 30, 0.03);
        border: 1px solid rgba(5, 6, 30, 0.07);
        padding: 2rem 2rem 2.4rem 2rem;

        @media (max-width: 768px) {
            width: 100%;
            grid-column: 1/-1;
        }

        .form-control {
            width: 100%;

            @media (max-width: 768px) {
                width: 100%;
            }

            small {
                margin-bottom: 0;
                font-size: 12px;
                display: flex;
                align-items: center;

                @media (max-width: 768px) {
                    margin-bottom: unset;
                }

                svg {
                    margin-right: 4px;
                }
            }

            small.small-err {
                color: red;
            }

            &.newPassword {
                @media (max-width: 768px) {
                    margin-bottom: 3.2rem;
                }
            }

            input.invalid {
                border: 1px solid #fe4242;
            }
        }

        .save-password-btn {
            height: 4.8rem;
            width: 100%;
            background: #05061e;
            border-radius: 4px;
            font-style: normal;
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            text-align: center;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            margin-top: 3.2rem;

            @media (max-width: 768px) {
                height: 6rem;
                font-size: 1.75rem;
            }

            @media (max-width: 600px) {
                margin-top: 3rem;
            }

            svg {
                position: absolute;
                top: 50%;
                left: 16px;
                transform: translateY(-50%);

                width: 2rem;
                height: 2rem;
                object-fit: cover;
            }
        }
    }
}



.product-card-carousel {
    grid-column: 1/-1;
    padding-bottom: 10rem;
    position: relative;
    width: 112.8rem;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 0 2.4rem;
    margin: 0 auto;
    //border-top: 1px solid rgba($color: #0c0d1e, $alpha: 0.1);

    @media only screen and (max-width: 1128px) {
        width: 100%;
        padding: 0 2.4rem;
    }

    @media only screen and (max-width: 768px) {
        padding-right: 0;
        padding-left: 1.2rem;
    }

    @media only screen and (max-width: 600px) {
        padding-right: 0;
        padding-left: 1.5rem;
    }


    .carousel-header {
        grid-column: 1/-1;
        height: 10.8rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media only screen and (max-width: 1128px) {
            padding-right: 0;
        }

        @media only screen and (max-width: 768px) {
            padding-right: 1.2rem;
        }

        @media only screen and (max-width: 600px) {
            padding-right: 1.5rem;
        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 2.4rem;
            line-height: 170%;
            display: flex;
            align-items: center;
            color: #05061E;

            @media only screen and (max-width: 600px) {
                font-size: 2rem;
            }
        }
    }
}

#card-carousel {
    width: 100%;
    grid-column: 1/-1;

    @media only screen and (max-width: 640px) {
        padding: 0 0;
    }

    .swiper-container {
        height: auto;
        position: unset;
    }

    .swiper-wrapper {
        width: 100%;

        .swiper-slide {
            width: 100%;
            background: #fff;
            display: flex;
            align-items: flex-start;

            .product-card-parent {
                .product-card {
                    .product-img {}

                    .product-card-details {

                        .product-name {
                            height: 4.3rem;
                            width: auto;
                            white-space: normal;
                            overflow: hidden !important;
                            text-overflow: ellipsis;

                            @media only screen and (max-width: 600px) {
                                height: 6.5rem;
                            }
                        }
                    }
                }
            }
        }
    }

    .swiper-button-next {
        position: absolute;
        top: 50px;
        height: 10px;
        width: auto;
    }

    .swiper-button-prev {
        position: absolute;
        top: 50px;
        right: -80%;
        height: 10px;
        width: auto;
    }

    .swiper-container-horizontal {
        width: 100%;
    }


    .swiper-button-next:after {
        color: red;
        font-size: 30px;
    }

    .swiper-button-prev:after {
        color: red;
        font-size: 30px;
    }

    .product-card-details h4 {
        white-space: normal;

        @media only screen and (max-width: 768px) {
            font-size: 1.5rem;
        }
    }
}

.arr-container {
    button {
        outline: none;
        border: none;
        cursor: pointer;
        background: none;
        width: 2.4rem;
        height: 2.4rem;
        margin-left: 2.4rem;

        @media only screen and (max-width: 600px) {
            width: 2.5rem;
            height: 2.5rem;
        }

        svg {
            width: 2.4rem;
            height: 2.4rem;

            @media only screen and (max-width: 600px) {
                width: 2.5rem;
                height: 2.5rem;
            }
        }

        &.swiper-button-disabled {
            svg {
                g {
                    opacity: 0.3;
                }
            }
        }
    }

}



.dataMap {
    .gm-ui-hover-effect {
        display: none !important;
    }

    .gm-style-iw-ch {
        display: none !important;
    }

    .gm-style-iw-d {
        padding: 0;
    }

    .gm-style-iw {
        padding: 0;
    }

    .infoWindow {
        width: 200px;
        padding: 10px 10px 0 10px;
    }

    .buyTitle {
        font-size: 13px;
    }

    .buyPoint {
        color: #f27a1a;
        margin-top: 4px;
    }

    .mapCloseWindowBtn {
        position: absolute;
        right: 7px;
        top: 0px;
        font-size: 15px;
        cursor: pointer;
    }

    .distanceMapInfo {
        padding: 10px;
    }
}



.toastr-container {
    z-index: 99999;
    box-sizing: border-box;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    pointer-events: auto;
    position: fixed;
    top: 0;
    right: 0;
    padding: 20px;
}

.toastr-container .toastr {
    width: 100%;
    max-width: 300px;
    position: relative;
    z-index: 999999;
    margin-bottom: 6px;
}

.toastr-container .toastr .toastrElement {
    color: #fff;
    padding: 10px 10px 10px 40px;
    box-shadow: 0 0 12px #999;
    opacity: 0.8;
    transition: all 0.3s ease;
    cursor: pointer;
    border-radius: 3px;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: 8px;
}

.toastr-container .toastr .toastrElement.error {
    background-color: #bd362f;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=);
}

.toastr-container .toastr .toastrElement.success {
    background-color: #51a351;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==);
}

.toastr-container .toastr .toastrElement:hover {
    box-shadow: 0 0 12px #000;
    opacity: 1;
}

#payment-app {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 150px;

    @media (max-width: 1200px) {
        padding: 0 20px;
    }

    @media (max-width: 576px) {
        margin-bottom: 20px;
        padding: 0;
    }
}

#payment-app .ty-font-price {
    font-family: source_sans_proregular, sans-serif;
}

#payment-app .ty-p-width {
    width: 1200px;
}

#payment-app .ty-p-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

#payment-app .ty-p-text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

#payment-app .ty-no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#payment-app .p-header {
    width: 1500px;
    display: flex;
    justify-content: space-between;
    margin: 25px 0;
    align-items: center;

    @media(max-width:1500px) {
        width: 100%;
        margin-bottom: 0;
    }

    @media(max-width:576px){
        padding: 0 20px;
    }
}

#payment-app .p-header img {
    width: 146px;
    height: 60px;
}

#payment-app .p-header .p-header-ssl {
    display: flex;
    align-items: center;
}

#payment-app .p-header .p-header-ssl .i-ssl-secured {
    font-size: 30px;
    padding-right: 15px;
    border-right: solid 1px #999;
}

#payment-app .p-header .p-header-ssl .i-ssl-secured span::before {
    color: #0bc15c;
}

#payment-app .p-header .p-header-ssl .i-ssl-secured span.path3::before {
    color: #fefefe;
}

#payment-app .p-header .p-header-ssl .p-header-ssl-desc {
    font-size: 18px;
    color: #999;
    margin-left: 15px;
}

#payment-app .p-header.isMilla {
    margin: 40px 0 25px;
}

#payment-app .p-header.isMilla img {
    width: 182px;
    height: 20px;
}

#payment-app #p-layout {
    width: 1500px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1545px) {
        width: 100%;
        margin-top: 30px;
        padding: 0 20px;
    }

    @media (max-width: 1160px) {
        flex-direction: column;
        width: 100%;
    }

    @media(max-width:575px){
        padding: 0;
    }
}


#payment-app #p-layout.loading {
    transition: 0.3s ease-in;
    opacity: 0.3;
    pointer-events: none;
    transform: none;
}


.rightPanel{
    width: 400px;

    @media(max-width:1161px){
        width: 100%;
    }
}

#payment-app #p-layout .p-layout-content {
    width: calc(100% - 374px);

    @media (max-width: 1900px) {
        width: calc(100% - 374px);
    }

    @media (max-width: 1160px) {
        width: 100%;
    }
}

#payment-app #p-layout .p-layout-content .p-layout-tab-content {
    display: flex;
    flex-direction: row;
    min-height: 200px;
    width: 100%;
    position: relative;

    @media (max-width: 576px) {
        display: flex;
        flex-direction: column;
    }
}

#payment-app #p-layout .p-layout-content .p-layout-tab-content.tab-0 {
    left: 0;
}

#payment-app #p-layout .p-layout-content .p-layout-tab-content.tab-1 {
    left: -100%;
}

#payment-app #p-layout .p-layout-content .p-layout-tab-content.tab-2 {
    left: -100%;
}

#payment-app .p-tabs {
    width: 930px;
    height: 80px;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 6px;
    // border: solid 1px #e2e2e2;
    // background-color: #fafafa;
    cursor: pointer;

    @media (max-width: 1900px) {
        width: 100%;
    }
}

#payment-app .p-tabs>section:first-child {
    display: flex;
    height: 100%;
}

#payment-app .p-tabs>section:first-child .p-tab-separator {
    width: 1px;
    height: 100%;
    background-color: #e2e2e2;
}

#payment-app .p-tabs .p-active-tab {
    position: relative;
    bottom: 6px;
    height: 6px;
    transition: 0.3s linear;
    background-color: #f27a1a;
    width: 50%;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

#payment-app .p-tabs .p-active-tab.tab-0 {
    left: 0;
}

#payment-app .p-tabs .p-active-tab.tab-1 {
    left: 50%;
}

#payment-app .p-tabs .p-active-tab.tab-2 {
    width: 100%;
}

#payment-app .p-tabs .p-addresses-tab,
#payment-app .p-tabs .p-payment-tab {
    width: 50%;
    height: 100%;
    box-sizing: border-box;
    border-bottom: 4px solid #e2e2e2;
    padding: 0 20px;
    position: relative;
}

#payment-app .p-tabs .p-addresses-tab .p-tab-head,
#payment-app .p-tabs .p-payment-tab .p-tab-head {
    display: flex;
    align-items: center;
}

#payment-app .p-tabs .p-addresses-tab .p-tab-title,
#payment-app .p-tabs .p-payment-tab .p-tab-title {
    font-size: 22px;
    color: #666;
    font-weight: 600;
}

#payment-app .p-tabs .p-addresses-tab .p-tab-change,
#payment-app .p-tabs .p-payment-tab .p-tab-change {
    position: absolute;
    right: 20px;
    color: #333;
    text-decoration: underline;
    font-size: 14px;
}

#payment-app .p-tabs .p-addresses-tab .p-tab-checkmark-wrapper,
#payment-app .p-tabs .p-payment-tab .p-tab-checkmark-wrapper {
    background: #0bc15c;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin: 0 0 2px 10px;
    animation: scale_and_rotate90 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
}

#payment-app .p-tabs .p-addresses-tab .p-tab-checkmark-wrapper .i-checkmark,
#payment-app .p-tabs .p-payment-tab .p-tab-checkmark-wrapper .i-checkmark {
    color: #fff;
    font-size: 10px;
}

#payment-app .p-tabs .p-addresses-tab.active,
#payment-app .p-tabs .p-payment-tab.active {
    background: #fff1e6;
    border: 1px solid #cbcbcb;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

#payment-app .p-tabs .p-addresses-tab.active .p-tab-title,
#payment-app .p-tabs .p-payment-tab.active .p-tab-title {
    color: #f27a1a;
}

#payment-app .p-tabs .p-payment-tab .p-pay-with-card-header,
#payment-app .p-tabs .p-payment-tab .p-pay-with-wallet-header {
    animation: opacity 0.5s ease-in;
}

#payment-app .p-tabs .p-payment-tab .p-pay-with-card-header p,
#payment-app .p-tabs .p-payment-tab .p-pay-with-wallet-header p {
    color: #666;
    margin-top: 5px;
}

#payment-app .p-tabs .p-payment-tab .p-pay-with-card-header p strong,
#payment-app .p-tabs .p-payment-tab .p-pay-with-wallet-header p strong {
    color: #333;
    font-weight: 600;
}

#payment-app .p-tabs .p-addresses-tab .p-tab-standard-shipping {
    color: #666;
    font-size: 12px;
    animation: opacity 0.5s ease-in;
}

#payment-app .p-tabs .p-addresses-tab .p-tab-standard-shipping p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

#payment-app .p-tabs .p-addresses-tab .p-tab-standard-shipping .p-invoice-address-title {
    font-weight: 600;
}

#payment-app .p-tabs .p-addresses-tab .p-tab-standard-shipping .p-address-name {
    font-size: 14px;
    margin: 3px 0;
}

#payment-app .p-tabs .p-addresses-tab .p-tab-collection-point {
    font-weight: 600;
    font-size: 16px;
    color: #333;
}


#payment-app .collectionPointInfo{
    @media(max-width:576px){
        padding:15px 10px;
    }
}

#payment-app .p-tabs .p-retail-fs-tab {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-bottom: 4px solid #e2e2e2;
    padding: 18px 20px;
    position: relative;
    background-color: #fff;
}

#payment-app .p-tabs .p-retail-fs-tab .p-tab-head {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}

#payment-app .p-tabs .p-retail-fs-tab .p-tab-title {
    font-size: 22px;
    color: #f27a1a;
    font-weight: 600;
}

#payment-app .p-tabs .p-retail-fs-tab .p-retail-fs-tab-pre-approve-monthly-payment-header {
    margin-bottom: 12px;
    animation: opacity 0.5s ease-in;
}

#payment-app .p-tabs .p-retail-fs-tab .p-retail-fs-tab-pre-approve-monthly-payment-header p {
    color: #333;
    margin-top: 5px;
    font-size: 12px;
}

#payment-app .p-tabs .p-retail-fs-tab .p-retail-fs-tab-pre-approve-monthly-payment-header p strong {
    color: #333;
    font-weight: 600;
}

#payment-app .p-tabs .p-retail-fs-tab .p-retail-fs-tab-pre-approve-monthly-payment-description {
    display: flex;
    align-items: center;
}

#payment-app .p-tabs .p-retail-fs-tab .p-retail-fs-tab-pre-approve-monthly-payment-description .icon-container {
    display: flex;
    color: #f27a1a;
    font-size: 10px;
    margin-right: 4px;
    transform: rotate(180deg);
}

#payment-app .p-tabs .p-retail-fs-tab .p-retail-fs-tab-pre-approve-monthly-payment-description p {
    color: #666;
    font-size: 12px;
}

#payment-app .p-tabs .p-retail-fs-tab .p-tab-change {
    position: absolute;
    right: 20px;
    color: #333;
    text-decoration: underline;
    font-size: 14px;
}

#payment-app .p-tabs .p-retail-fs-tab .p-tab-checkmark-wrapper {
    background: #0bc15c;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin: 0 0 2px 10px;
    animation: scale_and_rotate90 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
}

#payment-app .p-tabs .p-retail-fs-tab .p-tab-checkmark-wrapper .i-checkmark {
    color: #fff;
    font-size: 10px;
}

#payment-app .p-summary {
    width: 281px;
    display: flex;
    flex-direction: column;
}

#payment-app .p-summary .p-checkout-summary {
    margin: 0 0 15px 0;
    padding: 15px;
    border-radius: 6px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #e6e6e6;
    background-color: #fff;
    color: #333;
    font-size: 14px;
}

#payment-app .p-summary .p-checkout-summary.loading ul>li {
    margin-bottom: 5px;
}

#payment-app .p-summary .p-checkout-summary.loading>p {
    display: flex;
    justify-content: space-between;
}

#payment-app .p-summary .p-checkout-summary .p-sum-az-cargo-price-per-merchant-info {
    font-size: 12px;
    line-height: 18px;
    color: #666;
    word-break: break-word;
    font-family: source_sans_proregular, sans-serif;
    -webkit-font-smoothing: antialiased;
}

#payment-app .p-summary .p-checkout-summary .p-sum-title {
    font-size: 22px;
    line-height: 28px;
    color: #333;
    margin-bottom: 16px;
}

#payment-app .p-summary .p-checkout-summary>ul {
    line-height: 28px;
}

#payment-app .p-summary .p-checkout-summary>ul>li>p>span {
    display: inline-block;
    max-width: 136px;
}

#payment-app .p-summary .p-checkout-summary>hr {
    margin: 10px 0;
    opacity: 0.3;
}

#payment-app .p-summary .p-checkout-summary .p-sum-total,
#payment-app .p-summary .p-checkout-summary ul>li>p {
    display: flex;
    justify-content: space-between;
}

#payment-app .p-summary .p-checkout-summary p.p-sum-cargo-price-info>span {
    width: 100%;
    max-width: unset;
    display: flex;
    justify-content: space-between;
}

#payment-app .p-summary .p-checkout-summary p.p-sum-cargo-price-info-az {
    flex-direction: column;
}

#payment-app .p-summary .p-checkout-summary p.p-sum-cargo-price-info-az>span {
    max-width: unset;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

#payment-app .p-summary .p-checkout-summary .p-sum-total {
    align-items: center;
    font-size: 16px;
    line-height: 20px;
}

#payment-app .p-summary .p-checkout-summary .p-sum-total>span:last-child {
    color: #f27a1a;
}

#payment-app .p-summary .p-checkout-summary .p-sum-total .discount-box-wrapper {
    display: inline-flex;
    flex-direction: column;
    padding: 0 5px 0;
    border-radius: 6px;
    background-color: #fff0e3;
}

#payment-app .p-summary .p-checkout-summary .p-sum-total .discount-box-wrapper .total-text {
    font-size: 16px;
    text-align: center;
    color: #333;
    font-family: source_sans_proregular, sans-serif;
    line-height: 20px;
}

#payment-app .p-summary .p-checkout-summary .p-sum-total .discount-box-wrapper .discount-box-text {
    font-size: 10px;
    max-height: 12px;
    font-weight: 600;
    text-align: center;
    color: #333;
}

#payment-app .p-summary .p-checkout-summary .p-sum-total .discount-box-wrapper .discount-box-amount {
    color: #f27a1a;
    font-size: 12px;
    text-align: center;
}

#payment-app .p-summary .p-checkout-summary .p-sum-discount {
    line-height: 1.2;
    margin: 5px 0;
}

#payment-app .p-summary .p-checkout-summary .p-sum-discount span:last-child {
    display: flex;
    align-items: center;
    color: #f27a1a;
}

#payment-app .p-summary .p-checkout-summary .p-cobranded-card-description {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    margin-top: 8px;
    background: #f5f5f5;
    border-radius: 4px;
    column-gap: 8px;
}

#payment-app .p-summary .p-checkout-summary .p-cobranded-card-description>i {
    color: #f27a1a;
    font-size: 15px;
}

#payment-app .p-summary .p-checkout-summary .p-cobranded-card-description>p {
    font-family: source_sans_proregular, sans-serif;
    font-size: 12px;
    line-height: 15px;
    color: #333;
}

#payment-app .p-summary .p-checkout-summary .p-cobranded-card-description>p>span {
    color: #f27a1a;
}

#payment-app .p-summary .p-contracts-approve {
    border-radius: 6px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #e6e6e6;
    padding: 8px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
}

#payment-app .p-summary .p-contracts-approve section {
    display: flex;
    margin-top: 10px;
    padding: 0 8px;
}

#payment-app .p-summary .p-contracts-approve section:first-child {
    margin-top: 0;
}

#payment-app .p-summary .p-contracts-approve section.sellable-for-optional-tckn {
    background-color: #fef4eb;
    border-radius: 4px;
    padding: 8px;
}

#payment-app .p-summary .p-contracts-approve section.sellable-for-optional-tckn>p span {
    color: #666;
}

#payment-app .p-summary .p-contracts-approve .tooltip-content {
    top: 40px !important;
}

#payment-app .p-summary .p-contracts-approve .p-approve-required-text {
    display: inline-block;
    width: 200px;
    padding: 10px;
    color: #d21313;
}

#payment-app .p-summary .p-contracts-approve p {
    max-width: 190px;
    color: #999;
    font-size: 12px;
}

#payment-app .p-summary .p-contracts-approve p.insurance span:first-child {
    display: inline-block;
    margin-bottom: 10px;
}

#payment-app .p-summary .p-contracts-approve p strong {
    text-decoration: underline;
    color: #333;
    font-weight: 600;
}

#payment-app .p-summary .p-contracts-approve p strong:hover {
    cursor: pointer;
    color: #f27a1a;
}

#payment-app .p-summary .approve-button-wrapper {
    position: relative;
}

#payment-app .p-summary .approve-button-wrapper .approve-button-tooltip {
    display: flex;
    position: absolute;
    box-sizing: border-box;
    align-items: center;
    background: #fff;
    border-radius: 8px;
    box-shadow: 3px 8px 15px 0 rgba(0, 0, 0, 0.1);
    width: 390px;
    padding: 15px;
    right: 260px;
    top: -15px;
    z-index: 9999;
}

#payment-app .p-summary .approve-button-wrapper .approve-button-tooltip::before {
    content: "";
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #fff;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(10px, 30px);
}

#payment-app .p-summary .approve-button-wrapper .approve-button-tooltip .approve-button-tooltip-text {
    font-size: 16px;
    line-height: 22px;
    color: #333;
}

#payment-app .p-summary .approve-button-wrapper .approve-button-tooltip .approve-button-tooltip-text strong {}

#payment-app .p-summary .cargo-bundle-wrapper {
    position: relative;
    display: flex;
    left: -8px;
    padding: 8px;
    width: 100%;
    border-radius: 4px;
    line-height: 1.2;
    background-color: #effbf5;
}

#payment-app .p-summary .cargo-bundle-wrapper .cargo-bundle-info .usage-info {
    -webkit-font-smoothing: antialiased;
    font-size: 10px;
    margin-bottom: 2px;
    color: #333;
}

#payment-app .p-summary .cargo-bundle-wrapper .cargo-bundle-info .remaining-count {
    font-family: source_sans_proregular, sans-serif;
    font-size: 10px;
    color: #666;
}

#payment-app .p-summary .cargo-bundle-wrapper .cargo-bundle-info .remaining-count .bold {
    -webkit-font-smoothing: antialiased;
}

#payment-app .p-summary .cargo-bundle-wrapper .cargo-bundle-icon {
    display: flex;
    align-items: center;
    margin-right: 8px;
    font-size: 14px;
    color: #0bc15c;
}

#payment-app .p-summary .cargo-bundle-wrapper.cargo-bundle-unused {
    background-color: #fff9eb;
}

#payment-app .p-summary .cargo-bundle-wrapper.cargo-bundle-unused .cargo-bundle-icon {
    color: #8f6400;
}

#payment-app .p-addresses-wrapper {
    width: 100%;
    visibility: hidden;
    margin-top: 20px;
    position: relative;
    height: 100%;
    transform: translateX(-100%);

    @media(max-width:575px) {
        margin-top: 0;
    }
}

#payment-app .p-addresses-wrapper .p-address-type-select {
    display: flex;
}

#payment-app .p-addresses-wrapper .p-address-type-select .pudoonboarding-wrapper {
    background-color: #fff;
    height: 100px;
    position: absolute;
    top: 80px;
    z-index: 3;
}

#payment-app .p-addresses-wrapper .p-address-type-select .pudo-onboarding-arrow {
    width: 0;
    height: 0;
    margin-left: 100px;
    margin-top: 5px;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-bottom: 30px solid #fff;
    z-index: 3;
    position: absolute;
}

#payment-app .p-addresses-wrapper .p-address-type-select .p-onboarding-content {
    z-index: 3;
    position: absolute;
    background: #fff;
    display: block;
    border-radius: 6px;
}

#payment-app .p-addresses-wrapper .p-address-type-select .p-onboarding-content .pr-rnr-ttp .collection-point-on-boarding {
    display: flex;
    padding: 20px;
    color: #333;
}

#payment-app .p-addresses-wrapper .p-address-type-select .p-onboarding-content .pr-rnr-ttp .collection-point-on-boarding div {
    display: block;
}

#payment-app .p-addresses-wrapper .p-address-type-select .p-onboarding-content .pr-rnr-ttp .collection-point-on-boarding .i-package-hand {
    font-size: 71px;
}

#payment-app .p-addresses-wrapper .p-address-type-select .p-onboarding-content .pr-rnr-ttp .collection-point-on-boarding .collection-point-on-boarding-content {
    margin-left: 20px;
    text-align: left;
}

#payment-app .p-addresses-wrapper .p-address-type-select .p-onboarding-content .pr-rnr-ttp .collection-point-on-boarding .collection-point-on-boarding-content h1 {
    width: 100%;
    white-space: normal;
    line-height: 1.1;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
}

#payment-app .p-addresses-wrapper .p-address-type-select .p-onboarding-content .pr-rnr-ttp .collection-point-on-boarding .collection-point-on-boarding-content .collection-point-on-boarding-content-list {
    line-height: 1.2;
    padding-left: 10px;
    font-weight: 600;
    font-size: 15px;
}

#payment-app .p-addresses-wrapper .p-address-type-select .p-onboarding-content .pr-rnr-ttp .collection-point-on-boarding .collection-point-on-boarding-content .collection-point-on-boarding-content-list li::before {
    content: "\2022";
    color: #f27a1a;
    font-weight: 700;
    display: inline-block;
    width: 1em;
}

#payment-app .p-addresses-wrapper .p-address-type-select .p-onboarding-content .pr-rnr-ttp .collection-point-on-boarding .collection-point-on-boarding-content p {
    white-space: normal;
    font-size: 12px;
    font-weight: 500;
    width: 330px;
    margin-top: 8px;
    color: #999;
}

#payment-app .p-addresses-wrapper .p-address-type-select .p-onboarding-content .pr-rnr-ttp .collection-point-on-boarding-buttons {
    margin: 0 20px 20px;
    display: flex;
}

#payment-app .p-addresses-wrapper .p-address-type-select .p-onboarding-content .pr-rnr-ttp .collection-point-on-boarding-buttons button {
    width: 215px;
    height: 32px;
    border: 1px solid #f27a1a;
    border-radius: 3px;
    box-sizing: border-box;
    font-family: source_sans_proregular, sans-serif;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.5s ease;
}

#payment-app .p-addresses-wrapper .p-address-type-select .p-onboarding-content .pr-rnr-ttp .collection-point-on-boarding-buttons .on-boarding-turn-address-selection {
    border-color: #999;
    color: #666;
    background-color: #fff;
    margin-right: 10px;
}

#payment-app .p-addresses-wrapper .p-address-type-select .p-onboarding-content .pr-rnr-ttp .collection-point-on-boarding-buttons .on-boarding-turn-address-selection:hover {
    color: #f27a1a;
    border-color: #f27a1a;
}

#payment-app .p-addresses-wrapper .p-address-type-select .p-onboarding-content .pr-rnr-ttp .collection-point-on-boarding-buttons .on-boarding-select-collection-point {
    background-color: #f27a1a;
    color: #fff;
}

#payment-app .p-addresses-wrapper .p-address-type-select .p-onboarding-content .pr-rnr-ttp .collection-point-on-boarding-buttons .on-boarding-select-collection-point:hover {
    background-color: #ff8b38;
    border-color: #ff8b38;
}

#payment-app .p-addresses-wrapper .p-address-type-select .p-ats-tab {
    display: flex;
    align-items: center;
    padding: 14px 30px;
    margin-right: 20px;
    border: solid 1px #e6e6e6;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    background-color: #fafafa;
    position: relative;
    bottom: -1px;
    z-index: 1;
    font-size: 16px;
    color: #333;
    font-weight: 600;
}

#payment-app .p-addresses-wrapper .p-address-type-select .p-ats-tab.pudo {
    padding: 14px 30px;
}

#payment-app .p-addresses-wrapper .p-address-type-select .p-ats-tab .home-delivery-fee-text {
    margin-top: 2px;
    font-weight: 400;
    color: #333;
    font-size: 12px;
    line-height: 15px;
}

#payment-app .p-addresses-wrapper .p-address-type-select .p-ats-tab .home-delivery-fee-text span {
    color: #f27a1a;
}

#payment-app .p-addresses-wrapper .p-address-type-select .p-ats-tab .pudo-address-tab-wrapper {
    display: flex;
    align-items: center;
}

#payment-app .p-addresses-wrapper .p-address-type-select .p-ats-tab .pudo-address-tab-wrapper>i {
    color: #f77d1a;
}

#payment-app .p-addresses-wrapper .p-address-type-select .p-ats-tab .pudo-address-tab-wrapper.without-campaign-text {
    line-height: 32px;
}

#payment-app .p-addresses-wrapper .p-address-type-select .p-ats-tab .pudo-address-tab-wrapper .pudo-address-tab-text-wrapper .pudo-address-tab-text-title {
    display: flex;
    align-items: center;
}

#payment-app .p-addresses-wrapper .p-address-type-select .p-ats-tab .pudo-address-tab-wrapper .pudo-address-tab-text-wrapper .pudo-address-tab-text-title>span {
    font-size: 16px;
    color: #333;
    -webkit-font-smoothing: antialiased;
}

#payment-app .p-addresses-wrapper .p-address-type-select .p-ats-tab .pudo-address-tab-wrapper .pudo-address-tab-text-wrapper .pudo-address-tab-text-title .campaign-text-for-paid-cargo-info-badge {
    color: #f27a1a;
    background-color: #fef4eb;
    font-size: 10px;
    border-radius: 3px;
    position: relative;
    bottom: 1.5px;
    display: inline-flex;
    align-items: center;
    height: 17px;
    margin-left: 6px;
    text-align: center;
    line-height: 17px;
    padding: 0 4px;
}

#payment-app .p-addresses-wrapper .p-address-type-select .p-ats-tab .pudo-address-tab-wrapper .pudo-address-tab-text-wrapper .pudo-address-tab-text-title .campaign-text-for-paid-cargo-info-badge>i {
    font-size: 5px;
    display: inline-block;
    margin-top: -2px;
    margin-right: 4px;
}

#payment-app .p-addresses-wrapper .p-address-type-select .p-ats-tab .pudo-address-tab-wrapper .pudo-address-tab-text-wrapper .collection-point-tab-campaign {
    font-size: 12px;
    font-family: source_sans_proregular, sans-serif;
    font-weight: 400;
}

#payment-app .p-addresses-wrapper .p-address-type-select .p-ats-tab .pudo-address-tab-wrapper .pudo-address-tab-text-wrapper .collection-point-tab-campaign span {
    color: #f27a1a;
}

#payment-app .p-addresses-wrapper .p-address-type-select .p-ats-tab .pudo-address-tab-wrapper .pudo-address-tab-text-wrapper .collection-point-tab-campaign.pudo-tab-selected {
    -webkit-font-smoothing: antialiased;

    font-size: 12px;
    line-height: 15px;
    color: #0bc15c;
}

#payment-app .p-addresses-wrapper .p-address-type-select .p-ats-tab .pudo-address-tab-wrapper .pudo-address-tab-text-wrapper .campaign-text-for-paid-cargo-info {
    color: #333;
    font-size: 11px;
    align-self: center;
}

#payment-app .p-addresses-wrapper .p-address-type-select .p-ats-tab .pudo-address-tab-wrapper .pudo-address-tab-new {
    color: #fff;
    background-color: #ec1c36;
    font-size: 12px;
    border-radius: 3px;
    position: relative;
    bottom: 1.5px;
    display: inline-block;
    height: 14px;
    margin-left: 6px;
    text-align: center;
    line-height: 17px;
    padding: 0 4px;
}

#payment-app .p-addresses-wrapper .p-address-type-select .p-ats-tab .pudo-address-tab-wrapper strong {
    -webkit-font-smoothing: antialiased;
}

#payment-app .p-addresses-wrapper .p-address-type-select .p-ats-tab i {
    margin-right: 10px;
}

#payment-app .p-addresses-wrapper .p-address-type-select .p-ats-tab:hover {
    cursor: pointer;
}

#payment-app .p-addresses-wrapper .p-address-type-select .p-ats-tab.active {
    background-color: #fff;
    border-bottom: none;
}

#payment-app .p-addresses-wrapper .p-eligible-for-corporate-sales-box-wrapper {
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 20px;
    margin-bottom: 20px;
    box-sizing: border-box;
    border: solid 1px #e6e6e6;
    background-color: #fff;
}

#payment-app .p-addresses-wrapper .p-eligible-for-corporate-sales-box-wrapper .p-eligible-for-corporate-sales-info-box {
    display: flex;
}

#payment-app .p-addresses-wrapper .p-eligible-for-corporate-sales-box-wrapper .p-eligible-for-corporate-sales-info-box .i-warning1-fill {
    font-size: 18px;
    margin-right: 10px;
    margin-bottom: 2px;
}

#payment-app .p-addresses-wrapper .p-eligible-for-corporate-sales-box-wrapper .p-eligible-for-corporate-sales-info-box .i-warning1-fill .path1:before {
    color: #f27a1a;
}

#payment-app .p-addresses-wrapper .p-eligible-for-corporate-sales-box-wrapper .p-eligible-for-corporate-sales-info-box>span {
    color: #333;
    font-size: 14px;
}

#payment-app .p-addresses-wrapper .p-eligible-for-corporate-sales-box-wrapper .p-eligible-for-corporate-sales-add-address-button {
    display: flex;
    cursor: pointer;
}

#payment-app .p-addresses-wrapper .p-eligible-for-corporate-sales-box-wrapper .p-eligible-for-corporate-sales-add-address-button .i-plus-bold {
    color: #f27a1a;
    font-size: 16px;
}

#payment-app .p-addresses-wrapper .p-eligible-for-corporate-sales-box-wrapper .p-eligible-for-corporate-sales-add-address-button span {
    font-size: 14px;
    margin-left: 5px;
}

#payment-app .p-addresses-wrapper .p-address-box:hover .p-ab-warning-tooltip {
    opacity: 1;
}

#payment-app .p-addresses-wrapper .p-ab-warning-tooltip {
    position: absolute;
    opacity: 0;
    z-index: 5;
    background: #fff;
    border: 1px solid #e6e6e6;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.179892);
    border-radius: 8px;
    color: #333;
    bottom: 10px;
    left: 15px;
    padding: 15px;
    font-size: 12px;
    display: flex;
    align-items: center;
    width: 390px;
}

#payment-app .p-addresses-wrapper .p-ab-warning-tooltip .i-warning {
    font-size: 18px;
    color: #f27a1a;
    margin-right: 10px;
    transform: rotate(180deg);
}

#payment-app .p-addresses-wrapper .alert-enter {
    opacity: 0;
    transform: scale(0.9);
}

#payment-app .p-addresses-wrapper .alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 0.3s, transform 0.3s;
}

#payment-app .p-addresses-wrapper .alert-exit {
    opacity: 1;
}

#payment-app .p-addresses-wrapper .alert-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 0.3s, transform 0.3s;
}

#payment-app .p-addresses-wrapper .emergency-address-info-container {
    background-color: #fef4eb;
    border-radius: 8px;
    display: flex;
    padding: 15px 20px;
    margin: 7px 0 20px;
    min-height: 49px;
    width: 100%;
    box-sizing: border-box;
}

#payment-app .p-addresses-wrapper .emergency-address-info-container .emergency-address-info-desc-icon {
    height: 34px;
    margin-right: 8px;
    display: flex;
    align-items: center;
}

#payment-app .p-addresses-wrapper .emergency-address-info-container .emergency-address-info-desc-icon .i-warning1-fill {
    font-size: 18px;
}

#payment-app .p-addresses-wrapper .emergency-address-info-container .emergency-address-info-desc-icon .i-warning1-fill .path1:before {
    color: #f27a1a;
}

#payment-app .p-addresses-wrapper .emergency-address-info-container .emergency-address-info-desc-text {
    font-size: 14px;
    line-height: 17px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 400;
    color: #333;
    row-gap: 12px;
}

#payment-app .p-addresses-wrapper .az-shipment-info-box-wrapper {
    display: flex;
    align-items: center;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #feeaea;
    box-sizing: border-box;
    border-radius: 8px;
}

#payment-app .p-addresses-wrapper .az-shipment-info-box-wrapper>i {
    font-size: 16px;
    margin-right: 10px;
}

#payment-app .p-addresses-wrapper .az-shipment-info-box-wrapper>i>.path1::before {
    color: #b00;
}

#payment-app .p-addresses-wrapper .az-shipment-info-box-wrapper>span {
    font-family: source_sans_proregular, sans-serif;
    font-size: 14px;
    line-height: 18px;
    color: #b00;
}

#payment-app .p-addresses-wrapper .az-shipment-info-box-wrapper>span>strong {
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
}

#payment-app .p-addresses-wrapper.active {
    animation: 0.5s top_to_bottom_opacity ease-in;
    visibility: visible;
    transform: none;
}

#payment-app .p-addresses-wrapper.active .p-addresses .p-addresses-splitter {
    position: absolute;
    right: 20px;
    top: 20px;
}

#payment-app .p-addresses-wrapper.active .p-addresses .p-addresses-splitter .p-checkbox-wrapper {
    color: #333;
}

#payment-app .p-addresses-wrapper.active .p-addresses .p-invoice-addresses,
#payment-app .p-addresses-wrapper.active .p-addresses .p-shipping-addresses {
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
    box-sizing: border-box;
    align-self: flex-start;
}

#payment-app .p-addresses-wrapper.active .p-addresses .p-invoice-addresses .p-address-title,
#payment-app .p-addresses-wrapper.active .p-addresses .p-shipping-addresses .p-address-title {
    position: absolute;
    left: 20px;
    top: 20px;
    color: #333;
    font-size: 20px;
    font-weight: 600;
}

#payment-app .p-addresses-wrapper.active .p-addresses .p-invoice-addresses {
    border-left: 1px solid #e6e6e6;
    display: none;
}

#payment-app .p-addresses-wrapper.active .p-addresses .p-invoice-addresses .p-address-title {
    position: absolute;
    left: calc(50% + 18px);
}

#payment-app .p-addresses-wrapper.active .p-addresses .p-invoice-addresses.show {
    display: flex;
    justify-content: flex-end;
}

#payment-app .p-addresses-wrapper .p-address-type-select+.p-addresses {
    border-top-left-radius: 0;
}

#payment-app .p-addresses-wrapper .p-addresses {
    border-radius: 6px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    box-sizing: border-box;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #e6e6e6;
    transition: 0.3s ease-in;
    background-color: #fff;
}

#payment-app .p-addresses-wrapper .p-addresses .p-address-box {
    width: 427px;
    position: relative;
}

#payment-app .p-addresses-wrapper .p-addresses .p-address-box.disabled .p-ab-content,
#payment-app .p-addresses-wrapper .p-addresses .p-address-box.disabled .p-ab-head {
    opacity: 0.5;
}

#payment-app .p-addresses-wrapper .p-addresses .p-address-box.disabled .p-ab-content .i-user-orange:before,
#payment-app .p-addresses-wrapper .p-addresses .p-address-box.disabled .p-ab-head .i-user-orange:before {
    color: #333;
}

#payment-app .p-addresses-wrapper .p-addresses .p-address-box.selected {
    cursor: pointer;
}

#payment-app .p-addresses-wrapper .p-addresses .p-address-box.selected .p-ab-content {
    transition: 0.3s ease-in;
    border-color: #f27a1a;
    box-shadow: 0 0 0 1px #f27a1a;
    background-color: #fff2e9;
}

#payment-app .p-addresses-wrapper .p-addresses .p-address-box:hover:not(.selected) {
    cursor: pointer;
}

#payment-app .p-addresses-wrapper .p-addresses .p-address-box:hover:not(.selected) .p-ab-content {
    transition: 0.3s ease-in;
    border: solid 1px #d6d6d6;
    background-color: #fff;
}

#payment-app .p-addresses-wrapper .p-addresses .p-address-box .p-ab-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
}

#payment-app .p-addresses-wrapper .p-addresses .p-address-box .p-ab-head>span {
    transition: 0.3s ease-in;
    text-decoration: underline;
    font-weight: 600;
    color: #333;
    font-size: 12px;
    text-wrap: nowrap;
}

#payment-app .p-addresses-wrapper .p-addresses .p-address-box .p-ab-head>span:hover {
    color: #f27a1a;
    cursor: pointer;
}

#payment-app .p-addresses-wrapper .p-addresses .p-address-box .p-ab-content {
    margin: 5px 0 20px 0;
    min-height: 110px;
    padding: 10px 15px;
    border-radius: 6px;
    border: solid 1px #e6e6e6;
    box-sizing: border-box;
    background-color: #fafafa;
    transition: 0.3s ease-in;
    position: relative;
}

#payment-app .p-addresses-wrapper .p-addresses .p-address-box .p-ab-content .p-ab-content-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: #333;
    font-weight: 600;
}

#payment-app .p-addresses-wrapper .p-addresses .p-address-box .p-ab-content .p-ab-content-head span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    max-width: 200px;
}

#payment-app .p-addresses-wrapper .p-addresses .p-address-box .p-ab-content .p-ab-content-head i {
    margin-right: 5px;
    padding-bottom: 3px;
}

#payment-app .p-addresses-wrapper .p-addresses .p-address-box .p-ab-content .p-ab-content-head i.i-phone-icon {
    color: #333;
}

#payment-app .p-addresses-wrapper .p-addresses .p-address-box .p-ab-content .p-ab-content-address {
    font-size: 14px;
    color: #333;
    margin-top: 5px;
    line-height: 24px;
}

#payment-app .p-addresses-wrapper .p-addresses .p-address-box .p-ab-content .p-ab-content-address p {
    overflow: hidden;
    font-weight: bold;
    font-size: 20px;
    max-width: 250px;
}

#payment-app .p-addresses-wrapper .p-addresses .p-address-box .p-ab-content .p-ab-commercial-address-badge {
    position: absolute;
    background-color: #e6e6e6;
    padding: 0 5px;
    border-radius: 4px;
    color: #999;
    font-size: 11px;
    bottom: 10px;
    right: 10px;
}

#payment-app .p-addresses-wrapper .p-addresses .p-address-box .p-ab-content .p-ab-az-shipment-home-delivery-badge {
    position: absolute;
    bottom: 10px;
    right: 13px;
    line-height: 13px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    font-size: 10px;
    color: #0bc15c;
}

#payment-app .p-addresses-wrapper .p-addresses .p-add-address-box {
    width: 427px;
    border-radius: 6px;
    border: solid 1px #e6e6e6;
    background-color: #fafafa;
    flex-direction: column;
    margin-bottom: 20px;
    transition: 0.3s ease-in;
    box-sizing: border-box;
    margin-top: 35px;
}

#payment-app .p-addresses-wrapper .p-addresses .p-add-address-box:hover {
    cursor: pointer;
    transition: 0.3s ease-in;
    border: solid 1px #e6e6e6;
    background-color: #fff;
}

#payment-app .p-addresses-wrapper .p-addresses .p-add-address-box i {
    color: #f27a1a;
    font-size: 18px;
    margin-bottom: 5px;
}

#payment-app .p-addresses-wrapper .p-addresses .p-add-address-box span {
    &.plus {
        transform: scale(1.5);
    }

    color: #fe6f42;
    font-size: 20px;
    font-weight: 600;
}

#payment-app .p-addresses-wrapper .p-addresses .p-invoice-addresses,
#payment-app .p-addresses-wrapper .p-addresses .p-shipping-addresses {
    display: none;
}

#payment-app .p-addresses-wrapper .p-addresses .p-invoice-addresses {
    display: none;
}

#payment-app .p-payment {
    width: calc(100% / 2);
    height: 100%;
    min-height: 500px;
    visibility: hidden;
    margin-top: 20px;
    position: relative;
    box-sizing: border-box;
    display: none;
    transform: translateX(-100%);
    transition: opacity 0.3s ease-in;
}

#payment-app .p-payment .p-pay-with-card {
    width: 100%;
    transition: 0.3s ease-in;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #e6e6e6;
    box-sizing: border-box;
    border-radius: 6px;
    position: relative;
    display: flex;
    flex-direction: column;
}

#payment-app .p-payment .p-pay-with-card .p-installment-options-wrapper {
    width: 100%;
    margin-top: 20px;
    border-radius: 6px;
    border: solid 1px #e5e5e5;
}

#payment-app .p-payment .p-pay-with-card .p-installment-options-wrapper table {
    width: 100%;
}

#payment-app .p-payment .p-pay-with-card .p-installment-options-wrapper table tr.selected .p-radio-button,
#payment-app .p-payment .p-pay-with-card .p-installment-options-wrapper table tr.selected td {
    color: #f27a1a;
}

#payment-app .p-payment .p-pay-with-card .p-installment-options-wrapper table tr:not(:last-child) {
    border-bottom: 1px solid #e5e5e5;
}

#payment-app .p-payment .p-pay-with-card .p-installment-options-wrapper table tr th {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 14px 20px;
    background-color: #fafafa;
    font-size: 14px;
    font-weight: 600;
    color: #333;
    border-bottom: 1px solid #e5e5e5;
}

#payment-app .p-payment .p-pay-with-card .p-installment-options-wrapper table tr td:first-child,
#payment-app .p-payment .p-pay-with-card .p-installment-options-wrapper table tr th:first-child {
    border-right: 1px solid #e5e5e5;
}

#payment-app .p-payment .p-pay-with-card .p-installment-options-wrapper table tr td {
    padding: 5px 20px;
    font-size: 14px;
    color: #333;
}

#payment-app .p-payment .p-pay-with-card .p-installment-options-wrapper table tr td .no-installment-cost-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0c9741;
    color: #fff;
    font-family: source_sans_proregular, sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 10px;
    border-radius: 2px;
    height: 18px;
    padding: 5px;
    margin-left: 8px;
    box-sizing: border-box;
}

#payment-app .p-payment .p-pay-with-card .p-installment-postponing-wrapper {
    box-sizing: border-box;
    height: 50px;
    padding: 15px;
    margin-top: 20px;
    border-radius: 8px;
    background-color: #fef4eb;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#payment-app .p-payment .p-pay-with-card .p-installment-postponing-wrapper .p-installment-postponing-text {
    display: flex;
    align-items: center;
    color: #333;
}

#payment-app .p-payment .p-pay-with-card .p-installment-postponing-wrapper .colored {
    color: #f27a1a;
}

#payment-app .p-payment .p-pay-with-card .p-installment-postponing-wrapper .bold {
    font-weight: 600;
}

#payment-app .p-payment .p-pay-with-card .p-installment-postponing-wrapper .i-info1 {
    font-size: 14px;
    color: #999;
    cursor: pointer;
}

#payment-app .p-payment .p-pay-with-card.loading {
    opacity: 0.3;
    pointer-events: none;
    visibility: hidden;
    display: none;
}

#payment-app .p-payment .p-pay-with-card .p-pwc-head {
    padding: 20px;
    height: 65px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

#payment-app .p-payment .p-pay-with-card .p-pwc-head.showing-content {
    border-bottom: 1px solid #e6e6e6;
}

#payment-app .p-payment .p-pay-with-card .p-pwc-head p {
    display: flex;
    flex-direction: column;
}

#payment-app .p-payment .p-pay-with-card .p-pwc-head p .p-title {
    font-size: 20px;
    font-weight: 600;
    color: #333;
}

#payment-app .p-payment .p-pay-with-card .p-pwc-head p .p-desc {
    font-size: 14px;
    color: #333;
    font-weight: 400;
}

#payment-app .p-payment .p-pay-with-card .p-pwc-head p .p-desc.select {
    position: relative;
    animation: 0.3s bottom_to_top_opacity_scale;
}

#payment-app .p-payment .p-pay-with-card .p-pwc-content.ie.hide {
    height: 0 !important;
}

#payment-app .p-payment .p-pay-with-card .p-pwc-content:not(.ie) {
    max-height: 600px;
    transition: max-height 0.3s ease-in;
}

#payment-app .p-payment .p-pay-with-card .p-pwc-content:not(.ie).hide {
    max-height: 0;
    transition: max-height 0.3s ease-out;
}

#payment-app .p-payment .p-pay-with-card .p-pwc-content {
    position: relative;
    padding: 20px;
    display: flex;
    box-sizing: border-box;
    opacity: 1;
}

#payment-app .p-payment .p-pay-with-card .p-pwc-content.hide {
    opacity: 0;
    padding: 0;
}

#payment-app .p-payment .p-pay-with-card .p-pwc-content.hide .payment-gateway-connector {
    opacity: 0;
}

#payment-app .p-payment .p-pay-with-card .p-pwc-content .payment-gateway-connector {
    width: 50%;
    padding-right: 20px;
    opacity: 1;
    border-right: 1px solid #e2e2e2;
    transition: 1s ease-in;
}

#payment-app .p-payment .p-pay-with-card .p-pwc-content .payment-gateway-connector .payment-gateway-connector-wrapper {
    transition: 0.3s ease-in;
    width: 100%;
    position: relative;
    display: flex;
}

#payment-app .p-payment .p-pay-with-card .p-pwc-content .payment-gateway-connector .payment-gateway-connector-wrapper .payment-fragment-wrapper {
    width: calc(100% / 2);
}

#payment-app .p-payment .p-pay-with-card .p-pwc-content .payment-gateway-connector .payment-gateway-connector-wrapper .payment-fragment-wrapper #paymentGatewayConnector #payment-fragment {
    width: 100% !important;
    min-height: 200px;
}

#payment-app .p-payment .p-pay-with-card .p-pwc-content .payment-gateway-connector .payment-gateway-connector-wrapper .payment-fragment-wrapper .p-invalid-card-number-wrapper {
    border: 1px solid;
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 5px 10px;
    border-radius: 6px;
    margin-top: 10px;
    color: #d21313;
}

#payment-app .p-payment .p-pay-with-card .p-pwc-content .payment-gateway-connector .payment-gateway-connector-wrapper .payment-fragment-wrapper .p-invalid-card-number-wrapper i {
    margin-right: 5px;
    margin-bottom: 2px;
}

#payment-app .p-payment .p-pay-with-card .p-pwc-content .payment-gateway-connector .payment-gateway-connector-wrapper.showCards {
    left: -100%;
}

#payment-app .p-payment .p-pay-with-card .p-pwc-content .payment-gateway-connector .payment-gateway-connector-wrapper.showCards #paymentGatewayConnector,
#payment-app .p-payment .p-pay-with-card .p-pwc-content .payment-gateway-connector .payment-gateway-connector-wrapper.showCards .p-invalid-card-number-wrapper {
    visibility: hidden;
}

#payment-app .p-payment .p-pay-with-card .p-pwc-content .payment-gateway-connector .payment-gateway-connector-wrapper.showCards .saved-cards-wrapper {
    animation: opacity 0.5s ease-in;
    display: block;
    visibility: visible;
}

#payment-app .p-payment .p-pay-with-card .p-pwc-content .payment-gateway-connector .payment-gateway-connector-wrapper.showCards .saved-cards-wrapper .saved-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: scroll;
    max-height: 400px;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

#payment-app .p-payment .p-pay-with-card .p-pwc-content .payment-gateway-connector .payment-gateway-connector-wrapper.showCards .saved-cards-wrapper .saved-cards::-webkit-scrollbar {
    display: none;
}

#payment-app .p-payment .p-pay-with-card .p-pwc-content .payment-gateway-connector .payment-gateway-connector-wrapper.showCards .saved-cards-wrapper .saved-cards.has-cobranded-card {
    max-height: 290px;
}

#payment-app .p-payment .p-pay-with-card .p-pwc-content .payment-gateway-connector .payment-gateway-connector-wrapper .saved-cards-wrapper {
    visibility: hidden;
    display: none;
    width: calc(100% / 2);
    padding: 0 1px;
}

#payment-app .p-payment .p-pay-with-card .p-pwc-content .payment-gateway-connector .pgc-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
}

#payment-app .p-payment .p-pay-with-card .p-pwc-content .payment-gateway-connector .pgc-head .pgc-title {
    font-size: 20px;
    color: #333;
}

#payment-app .p-payment .p-pay-with-card .p-pwc-content .payment-gateway-connector .pgc-head .pgc-option-change {
    font-size: 14px;
    color: #666;
    text-decoration: underline;
}

#payment-app .p-payment .p-pay-with-card .p-pwc-content .payment-gateway-connector .pgc-head .pgc-option-change:hover {
    cursor: pointer;
    color: #f27a1a;
}

#payment-app .p-payment .p-pay-with-card .p-pwc-content .p-saved-cards-options-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

#payment-app .p-payment .p-pay-with-card .p-pwc-content .p-card-options-wrapper {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    width: 50%;
    z-index: 1;
}

#payment-app .p-payment .p-pay-with-card .p-pwc-content .p-card-options-wrapper .p-card-options-title {
    font-size: 20px;
    color: #333;
}

#payment-app .p-payment .p-pay-with-card .p-pwc-content .p-card-options-wrapper .p-card-options-desc {
    font-size: 14px;
    color: #333;
}

#payment-app .p-payment .p-pay-with-card .p-pwc-content .p-card-options-wrapper .p-card-options {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    border: solid 1px #e5e5e5;
    background-color: #f6f6f6;
    width: 100%;
    height: 130px;
    margin-top: 20px;
}

#payment-app .p-payment .p-pay-with-card .p-pwc-content .p-card-options-wrapper .p-card-options p {
    color: #666;
    font-size: 13px;
}

#payment-app .p-payment .p-pay-with-wallet {
    display: flex;
    flex-direction: column;
}

#payment-app .p-payment .p-pay-with-wallet.show {
    width: 100%;
    border-radius: 6px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #e6e6e6;
    background-color: #fff;
    box-sizing: border-box;
    transition: 0.3s ease-in;
    margin-bottom: 15px;
}

#payment-app .p-payment .p-pay-with-wallet .p-onboarding-children>.p-pww-head {
    background: #fff;
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-head {
    padding: 20px;
    box-sizing: border-box;
    height: 85px;
    display: flex;
    align-items: center;
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-head.with-optional-rebate {
    height: 125px;
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-head.showing-content {
    border-bottom: 1px solid #e6e6e6;
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-head .p-wallet-total-balance {
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 14px;
    color: #999;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-head .p-wallet-total-balance i {
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 17px;
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-head .p-optional-rebate {
    display: flex;
    align-items: center;
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-head .p-optional-rebate p {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #333;
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-head .p-optional-rebate p span {
    font-weight: 600;
    margin-right: 3px;
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-head .p-optional-rebate .p-optional-rebate-forced {
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-head .p-optional-rebate .p-optional-rebate-forced span {
    color: #f27a1a;
    margin: 0 0 0 3px;
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-head p {
    display: flex;
    flex-direction: column;
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-head p .p-title {
    font-size: 20px;
    font-weight: 600;
    color: #333;
    display: flex;
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-head p .p-desc {
    font-weight: 400;
    font-size: 14px;
    color: #333;
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-head p .p-desc.select {
    position: relative;
    animation: 0.3s bottom_to_top_opacity_scale;
}

#payment-app .p-payment .p-pay-with-wallet .wallet-onboarding-arrow {
    width: 0;
    height: 0;
    margin-left: 150px;
    margin-top: 10px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
    z-index: 2;
    position: relative;
}

#payment-app .p-payment .p-pay-with-wallet .p-onboarding-content {
    z-index: 2;
    position: absolute;
    background: #fff;
    display: block;
    border-radius: 6px;
}

#payment-app .p-payment .p-pay-with-wallet .p-onboarding-content .wallet-onboarding {
    display: block;
}

#payment-app .p-payment .p-pay-with-wallet .p-onboarding-content .wallet-onboarding .wallet-onboarding-content {
    display: flex;
    padding: 20px;
    flex-direction: column;
}

#payment-app .p-payment .p-pay-with-wallet .p-onboarding-content .wallet-onboarding .wallet-onboarding-content .wallet-header-text {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.4px;
    color: #f27a1a;
}

#payment-app .p-payment .p-pay-with-wallet .p-onboarding-content .wallet-onboarding .wallet-onboarding-content .wallet-description-text {
    font-size: 14px;
    letter-spacing: 0.35px;
    color: #333;
    padding-top: 8px;
}

#payment-app .p-payment .p-pay-with-wallet.loading {
    visibility: hidden;
    pointer-events: none;
    display: none;
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-content.ie.hide {
    height: 0 !important;
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-content:not(.ie) {
    max-height: 100vh;
    transition: max-height 0.3s ease-in;
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-content:not(.ie).hide {
    max-height: 0;
    transition: max-height 0.3s ease-out;
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-content {
    box-sizing: border-box;
    position: relative;
    opacity: 1;
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-content.hide {
    opacity: 0;
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-content.hide .wallet-gateway-connector-wrapper {
    opacity: 0;
    height: 0;
    padding: 0;
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-content .wallet-gateway-connector-wrapper {
    width: 100%;
    padding: 20px;
    height: 100%;
    opacity: 1;
    display: flex;
    position: relative;
    transition: opacity 1s ease-in;
    box-sizing: border-box;
    flex-direction: column;
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-content .wallet-gateway-connector-wrapper .wallet-gateway-connector {
    width: 200%;
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 7px;
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-content .wallet-gateway-connector-wrapper .wallet-gateway-connector.showCards {
    left: -100%;
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-content .wallet-gateway-connector-wrapper .wallet-gateway-connector.showCards #paymentWalletGatewayConnector,
#payment-app .p-payment .p-pay-with-wallet .p-pww-content .wallet-gateway-connector-wrapper .wallet-gateway-connector.showCards .p-invalid-card-number-wrapper {
    visibility: hidden;
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-content .wallet-gateway-connector-wrapper .wallet-gateway-connector.showCards .saved-cards {
    animation: opacity 0.5s ease-in;
    visibility: visible;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-content .wallet-gateway-connector-wrapper .wallet-gateway-connector.showCards .saved-cards::-webkit-scrollbar {
    display: none;
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-content .wallet-gateway-connector-wrapper .wallet-gateway-connector .wallet-fragment-wrapper {
    width: calc(100% / 2);
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-content .wallet-gateway-connector-wrapper .wallet-gateway-connector .wallet-fragment-wrapper #paymentWalletGatewayConnector {
    width: calc(46% + 15px);
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-content .wallet-gateway-connector-wrapper .wallet-gateway-connector .wallet-fragment-wrapper #paymentWalletGatewayConnector #wallet-fragment {
    width: 100% !important;
    min-height: 200px;
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-content .wallet-gateway-connector-wrapper .wallet-gateway-connector .wallet-fragment-wrapper .p-invalid-card-number-wrapper {
    width: calc(46% + 15px);
    border: 1px solid;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 5px 10px;
    border-radius: 6px;
    margin-top: 10px;
    color: #d21313;
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-content .wallet-gateway-connector-wrapper .wallet-gateway-connector .wallet-fragment-wrapper .p-invalid-card-number-wrapper i {
    margin-right: 5px;
    margin-bottom: 2px;
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-content .wallet-gateway-connector-wrapper .wallet-gateway-connector .saved-cards {
    visibility: hidden;
    display: none;
    width: calc(100% / 2);
    padding: 0 1px;
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-content .wallet-gateway-connector-wrapper .p-wgc-head {
    display: flex;
    margin-bottom: 13px;
    align-items: center;
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-content .wallet-gateway-connector-wrapper .p-wgc-head .p-wgc-title {
    font-size: 20px;
    color: #333;
    display: inline-block;
    margin-right: 20px;
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-content .wallet-gateway-connector-wrapper .p-wgc-head .p-wgc-option-change {
    font-size: 14px;
    color: #666;
    text-decoration: underline;
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-content .wallet-gateway-connector-wrapper .p-wgc-head .p-wgc-option-change:hover {
    cursor: pointer;
    color: #f27a1a;
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-content .wallet-gateway-connector-wrapper .p-wgc-card-owner-information-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 23px;
    color: #f27a1a;
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-content .wallet-gateway-connector-wrapper .p-wgc-card-owner-information-wrapper i {
    font-size: 14px;
}

#payment-app .p-payment .p-pay-with-wallet .p-pww-content .wallet-gateway-connector-wrapper .p-wgc-card-owner-information-wrapper span {
    margin-left: 7px;
    font-weight: 600;
    font-size: 14px;
}

#payment-app .p-payment .p-pay-with-retail-fs {
    display: flex;
    flex-direction: column;
}

#payment-app .p-payment .p-pay-with-retail-fs.show {
    width: 100%;
    border-radius: 6px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #e6e6e6;
    background-color: #fff;
    box-sizing: border-box;
    transition: 0.3s ease-in;
    margin-top: 20px;
    z-index: 1;
}

#payment-app .p-payment .p-pay-with-retail-fs.loading {
    opacity: 0.3;
    visibility: hidden;
    pointer-events: none;
    display: none;
}

#payment-app .p-payment .p-pay-with-retail-fs .p-rfs-content.ie.hide {
    height: 0 !important;
}

#payment-app .p-payment .p-pay-with-retail-fs .p-rfs-content:not(.ie) {
    max-height: 500px;
    transition: max-height 0.3s ease-in;
}

#payment-app .p-payment .p-pay-with-retail-fs .p-rfs-content:not(.ie).hide {
    max-height: 0;
    transition: max-height 0.3s ease-out;
}

#payment-app .p-payment .p-pay-with-retail-fs .p-rfs-header {
    padding: 20px;
    height: 65px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

#payment-app .p-payment .p-pay-with-retail-fs .p-rfs-header.showing-content {
    border-bottom: 1px solid #e6e6e6;
}

#payment-app .p-payment .p-pay-with-retail-fs .p-rfs-header .rfs-header-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

#payment-app .p-payment .p-pay-with-retail-fs .p-rfs-header .rfs-header-wrapper p {
    display: flex;
    flex-direction: column;
}

#payment-app .p-payment .p-pay-with-retail-fs .p-rfs-header .rfs-header-wrapper p .p-title {
    font-size: 20px;
    font-weight: 600;
    color: #333;
}

#payment-app .p-payment .p-pay-with-retail-fs .p-rfs-header .rfs-header-wrapper p .p-title .new-badge {
    position: relative;
    bottom: 2px;
    background: #dc2e2e;
    margin-left: 4px;
    color: #fff;
    font-weight: 700;
    font-size: 10px;
    padding: 3px 5px 1px 5px;
    border-radius: 3px;
}

#payment-app .p-payment .p-pay-with-retail-fs .p-rfs-header .rfs-header-wrapper p .p-desc {
    font-size: 14px;
    color: #333;
    font-weight: 400;
}

#payment-app .p-payment .p-pay-with-retail-fs .p-rfs-header .rfs-header-wrapper p .p-desc.select {
    position: relative;
    animation: 0.3s bottom_to_top_opacity_scale;
}

#payment-app .p-payment .p-pay-with-retail-fs .p-rfs-header .rfs-header-wrapper .rates-text {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #f27a1a;
}

#payment-app .p-payment .p-pay-with-retail-fs .p-rfs-content {
    position: relative;
    padding: 20px;
    display: flex;
    box-sizing: border-box;
    opacity: 1;
}

#payment-app .p-payment .p-pay-with-retail-fs .p-rfs-content.hide {
    opacity: 0;
    padding: 0;
}

#payment-app .p-payment .p-pay-with-retail-fs .p-rfs-content.hide .payment-gateway-connector {
    opacity: 0;
}

#payment-app .p-payment .p-pay-with-retail-fs .p-rfs-content .p-rfs-options-wrapper {
    display: flex;
    flex-direction: column;
    padding-right: 20px;
    border-right: 1px solid #e2e2e2;
    width: 50%;
    z-index: 1;
}

#payment-app .p-payment .p-pay-with-retail-fs .p-rfs-content .p-rfs-options-wrapper .masked-input-wrapper {
    margin-bottom: 20px;
}

#payment-app .p-payment .p-pay-with-retail-fs .p-rfs-content .p-rfs-options-wrapper .masked-input-wrapper .p-ty-textbox {
    display: flex;
    flex-direction: column;
    color: #333;
}

#payment-app .p-payment .p-pay-with-retail-fs .p-rfs-content .p-rfs-options-wrapper .masked-input-wrapper .p-ty-textbox label {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 8px;
}

#payment-app .p-payment .p-pay-with-retail-fs .p-rfs-content .p-rfs-options-wrapper .masked-input-wrapper .p-ty-textbox.error input {
    border: solid 1px #d21313;
    background-color: #fff9f9;
}

#payment-app .p-payment .p-pay-with-retail-fs .p-rfs-content .p-rfs-options-wrapper .masked-input-wrapper .p-ty-textbox.error p {
    color: #d21313;
}

#payment-app .p-payment .p-pay-with-retail-fs .p-rfs-content .p-rfs-options-wrapper .masked-input-wrapper .p-ty-textbox p {
    font-size: 12px;
    font-weight: 600;
    margin-top: 5px;
}

#payment-app .p-payment .p-pay-with-retail-fs .p-rfs-content .p-rfs-options-wrapper .masked-input-wrapper .p-ty-textbox input {
    background-color: #fbfbfb;
    padding: 8px 10px;
    font: inherit;
    color: inherit;
    box-sizing: border-box;
    width: 100%;
    transition: all 0.3s ease;
    outline: 0;
    border: solid 1px #e6e6e6;
    border-radius: 6px;
    height: 42px;
}

#payment-app .p-payment .p-pay-with-retail-fs .p-rfs-content .p-rfs-options-wrapper .masked-input-wrapper .p-ty-textbox input:focus:not(:disabled) {
    background-color: #fff;
    border: solid 1px #999;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

#payment-app .p-payment .p-pay-with-retail-fs .p-rfs-content .p-rfs-options-wrapper .customer-informations-wrapper .p-rfs-options-title {
    font-size: 18px;
    color: #333 !important;
    font-weight: 600;
}

#payment-app .p-payment .p-pay-with-retail-fs .p-rfs-content .p-rfs-options-wrapper .customer-informations-wrapper .p-rfs-options-desc {
    font-size: 14px;
    color: #666;
    line-height: 18px;
    font-weight: 400;
    margin-bottom: 20px;
}

#payment-app .p-payment .p-pay-with-retail-fs .p-rfs-content .payment-gateway-connector {
    width: 50%;
    padding-left: 20px;
    opacity: 1;
    transition: 1s ease-in;
    overflow: hidden;
}

#payment-app .p-payment .p-pay-with-retail-fs .p-rfs-content .payment-gateway-connector .p-rfs-bank-selection-wrapper {
    transition: 0.3s ease-in;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

#payment-app .p-payment .p-pay-with-retail-fs .p-rfs-content .payment-gateway-connector .p-rfs-bank-selection-wrapper .bank-selection-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#payment-app .p-payment .p-pay-with-retail-fs .p-rfs-content .payment-gateway-connector .p-rfs-bank-selection-wrapper .bank-selection-header input[type="checkbox"]+.p-checkbox-text:before {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
}

#payment-app .p-payment .p-pay-with-retail-fs .p-rfs-content .payment-gateway-connector .p-rfs-bank-selection-wrapper .bank-selection-header .bank-selection-header-title {
    font-size: 18px;
    color: #333;
    font-weight: 600;
}

#payment-app .p-payment .p-pay-with-retail-fs .p-rfs-content .payment-gateway-connector .p-rfs-bank-selection-wrapper .bank-selection-header .bank-selection-header-checkbox {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #333;
}

#payment-app .p-payment .p-pay-with-retail-fs .p-rfs-content .payment-gateway-connector .p-rfs-bank-selection-wrapper .bank-selection-desc {
    font-size: 14px;
    color: #666;
    line-height: 18px;
    font-weight: 400;
    margin-bottom: 20px;
}

#payment-app .p-payment .p-pay-with-retail-fs .p-rfs-content .payment-gateway-connector .p-rfs-bank-selection-wrapper .banks-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    margin-bottom: 20px;
    max-height: 290px;
    overflow: scroll;
}

#payment-app .p-payment .p-pay-with-retail-fs .p-rfs-content .payment-gateway-connector .p-rfs-bank-selection-wrapper .banks-wrapper input[type="checkbox"]+.p-checkbox-text:before {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
}

#payment-app .p-payment .modal-custom-close-icon {
    font-size: 12px;
    color: #979797;
}

#payment-app .p-payment .rfs-monthly-payments-modal-wrapper {
    width: 724px;
    padding: 20px 0;
    overflow: hidden;
}

#payment-app .p-payment .rfs-monthly-payments-modal-wrapper .rfs-monthly-payments-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 20px 0;
    border-bottom: 1px solid #e8e8e8;
    box-sizing: border-box;
}

#payment-app .p-payment .rfs-monthly-payments-modal-wrapper .rfs-monthly-payments-modal-header>i {
    font-size: 12px;
    color: #979797;
    cursor: pointer;
}

#payment-app .p-payment .rfs-monthly-payments-modal-wrapper .rfs-monthly-payments-modal-header>p {
    padding: 0 20px;
    font-size: 18px;
    line-height: 22px;
    color: #333;
}

#payment-app .p-payment .rfs-monthly-payments-modal-wrapper .rfs-monthly-payments-modal-body {
    padding: 0 20px;
}

#payment-app .p-payment .rfs-monthly-payments-modal-wrapper .rfs-monthly-payments-modal-body .rfs-monthly-payments-modal-description-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

#payment-app .p-payment .rfs-monthly-payments-modal-wrapper .rfs-monthly-payments-modal-body .rfs-monthly-payments-modal-description-wrapper>p {
    padding: 20px 8px;
    color: #333;
    font-size: 14px;
    line-height: 18px;
}

#payment-app .p-payment .rfs-monthly-payments-modal-wrapper .rfs-monthly-payments-modal-body .rfs-monthly-payments-modal-content-wrapper {
    display: grid;
    grid-gap: 20px;
    max-height: 290px;
    overflow: scroll;
}

#payment-app .p-payment .rfs-monthly-payments-modal-wrapper .rfs-monthly-payments-modal-body .rfs-monthly-payments-modal-content-wrapper .tooltip-icon-container {
    display: flex;
}

#payment-app .p-payment .rfs-monthly-payments-modal-wrapper .rfs-monthly-payments-modal-body .rfs-monthly-payments-modal-content-wrapper .tooltip-icon-container>i {
    color: #999;
    font-size: 12px;
    font-weight: 600;
    transform: rotate(180deg);
}

#payment-app .p-payment .p-rfs-error-modal-content {
    width: 409px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #e6e6e6;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #333;
}

#payment-app .p-payment .p-rfs-error-modal-content .p-rfs-error-icon {
    width: 84px;
    height: 84px;
    border-radius: 50px;
    background-color: #feeaea;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
}

#payment-app .p-payment .p-rfs-error-modal-content .p-rfs-error-icon i {
    display: flex;
    justify-content: center;
    align-items: center;
}

#payment-app .p-payment .p-rfs-error-modal-content .p-rfs-error-icon i:before {
    color: #b00;
    font-size: 24px;
}

#payment-app .p-payment .p-rfs-error-modal-content p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #666;
    text-align: center;
}

#payment-app .p-payment .modal-custom-close-icon {
    font-size: 12px;
    color: #979797;
}

#payment-app .p-payment .p-rfs-otp-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 460px;
    padding: 20px;
    box-sizing: border-box;
}

#payment-app .p-payment .p-rfs-otp-modal>form {
    width: 100%;
}

#payment-app .p-payment .p-rfs-otp-modal .p-otp-modal-icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #fff0e4;
    margin: 20px 0 10px;
}

#payment-app .p-payment .p-rfs-otp-modal .p-otp-modal-icon i {
    font-size: 44px;
    color: #f27a1a;
}

#payment-app .p-payment .p-rfs-otp-modal .p-otp-modal-desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    line-height: 30px;
    margin-bottom: 30px;
}

#payment-app .p-payment .p-rfs-otp-modal .p-otp-modal-desc .p-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    color: #333;
    max-width: 320px;
    margin-bottom: 5px;
}

#payment-app .p-payment .p-rfs-otp-modal .p-otp-modal-desc .p-desc {
    font-size: 20px;
    color: #666;
    font-weight: 400;
    line-height: 26px;
}

#payment-app .p-payment .p-rfs-otp-modal .p-otp-modal-input {
    margin-bottom: 30px;
}

#payment-app .p-payment .p-rfs-otp-modal .p-otp-modal-input .p-ty-textbox {
    display: flex;
    flex-direction: column;
    color: #333;
}

#payment-app .p-payment .p-rfs-otp-modal .p-otp-modal-input .p-ty-textbox label {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 10px;
}

#payment-app .p-payment .p-rfs-otp-modal .p-otp-modal-input .p-ty-textbox.error input {
    border: solid 1px #b00 !important;
    background-color: #fff9f9 !important;
}

#payment-app .p-payment .p-rfs-otp-modal .p-otp-modal-input .p-ty-textbox.error p {
    color: #b00 !important;
}

#payment-app .p-payment .p-rfs-otp-modal .p-otp-modal-input .p-ty-textbox p {
    font-size: 12px;
    font-weight: 600;
}

#payment-app .p-payment .p-rfs-otp-modal .p-otp-modal-input .p-ty-textbox input {
    background-color: #fbfbfb;
    padding: 8px 10px;
    font: inherit;
    color: inherit;
    box-sizing: border-box;
    width: 100%;
    transition: all 0.3s ease;
    outline: 0;
    border: solid 1px #e6e6e6;
    border-radius: 6px;
    height: 44px;
}

#payment-app .p-payment .p-rfs-otp-modal .p-otp-modal-input .p-ty-textbox input:focus:not(:disabled) {
    background-color: #fff;
    border: solid 1px #999;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

#payment-app .p-payment .p-rfs-otp-modal .p-otp-modal-input .p-ty-textbox .otp-info-message {
    color: #666;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
}

#payment-app .p-payment .p-rfs-otp-modal .p-otp-modal-input .p-otp-modal-input-counter {
    display: inline-block;
    color: #f27a1a;
    margin-left: 5px;
}

#payment-app .p-payment .p-rfs-otp-modal .p-otp-modal-input p {
    margin-top: 5px;
    font-size: 12px;
    color: #666;
}

#payment-app .p-payment .p-rfs-otp-modal .p-otp-modal-action {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

#payment-app .p-payment .p-rfs-otp-modal .p-otp-modal-action .ty-otp-verify-btn {
    height: 44px;
}

#payment-app .p-payment .p-rfs-otp-modal .p-otp-modal-action .ty-send-again-btn {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transition: 0.3s ease-in;
    border: none;
    background-color: #fefefe;
    color: #333;
    font-weight: 600;

    height: 44px;
    color: #666;
    border: solid 1px #e6e6e6;
}

#payment-app .p-payment .p-rfs-otp-modal .p-otp-modal-action .ty-send-again-btn:hover {
    cursor: pointer;
}

#payment-app .p-payment .p-rfs-otp-modal .p-otp-modal-action .ty-send-again-btn.disabled-send-again-btn {
    cursor: default;
    color: #999;
}

#payment-app .p-payment .p-rfs-otp-modal .p-otp-modal-action .ty-send-again-btn:hover:not(.disabled-send-again-btn) {
    border-color: #f27a1a;
    color: #f27a1a;
}

#payment-app .p-payment .p-rfs-otp-modal .p-otp-modal-action .disabled-approve-btn {
    color: #999;
    background-color: #ddd;
}

#payment-app .p-payment .p-pay-with-wallet .saved-cards {
    justify-content: flex-start !important;
}

#payment-app .p-payment .p-pay-with-wallet .saved-cards .p-saved-card-wrapper {
    flex: 0 23%;
}

#payment-app .p-payment .p-pay-with-wallet .saved-cards .p-saved-card-wrapper:not(:nth-child(4n)) {
    margin-right: 15px;
}

#payment-app .p-payment .p-pay-with-wallet .saved-cards .p-saved-card-wrapper.disabled .p-saved-card-content {
    opacity: 0.4;
}

#payment-app .p-payment .p-saved-card-wrapper {
    position: relative;
    flex: 0 48%;
}

#payment-app .p-payment .p-saved-card-wrapper .p-radio-button {
    cursor: pointer;
}

#payment-app .p-payment .p-saved-card-wrapper .p-radio-button span {
    display: inline-block;
    white-space: nowrap;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
}

#payment-app .p-payment .p-saved-card-wrapper.selected,
#payment-app .p-payment .p-saved-card-wrapper:hover:not(.disabled) {
    cursor: pointer;
}

#payment-app .p-payment .p-saved-card-wrapper.selected .p-saved-card,
#payment-app .p-payment .p-saved-card-wrapper:hover:not(.disabled) .p-saved-card {
    transition: 0.3s ease-in;
    border-color: #f27a1a;
    box-shadow: 0 0 0 1px #f27a1a;
}

#payment-app .p-payment .p-saved-card-wrapper.selected .p-radio-button,
#payment-app .p-payment .p-saved-card-wrapper:hover:not(.disabled) .p-radio-button {
    transition: 0.3s ease-in;
    color: #f27a1a;
}

#payment-app .p-payment .p-saved-card-wrapper:hover.disabled {
    cursor: pointer;
}

#payment-app .p-payment .p-saved-card-wrapper:hover.disabled .p-saved-card-info-tooltip {
    display: block;
    z-index: 999;
}

#payment-app .p-payment .p-saved-card-wrapper:hover.disabled .p-radio-button {
    cursor: default;
}

#payment-app .p-payment .p-saved-card-wrapper .p-saved-card {
    transition: 0.3s ease-in;
    margin: 10px 0;
    padding: 10px;
    min-height: 110px;
    border-radius: 6px;
    position: relative;
    border: solid 1px #e6e6e6;
    box-sizing: border-box;
    background-image: linear-gradient(to bottom, #fefdfd, #fcfbfa);
}

#payment-app .p-payment .p-saved-card-wrapper .p-saved-card .p-ty-textbox {
    width: 60px;
    position: absolute;
    bottom: 10px;
}

#payment-app .p-payment .p-saved-card-wrapper .p-saved-card .p-ty-textbox label {
    font-size: 12px;
    margin-bottom: 5px;
}

#payment-app .p-payment .p-saved-card-wrapper .p-saved-card .p-ty-textbox input {
    height: 34px;
}

#payment-app .p-payment .p-saved-card-wrapper .p-saved-card .p-saved-card-icons {
    display: flex;
    justify-content: space-between;
}

#payment-app .p-payment .p-saved-card-wrapper .p-saved-card .p-saved-card-icons i {
    width: 75px;
    height: 20px;
    background-size: contain;
}

#payment-app .p-payment .p-saved-card-wrapper .p-saved-card .p-saved-card-details {
    display: flex;
    flex-direction: column;
    text-align: right;
    position: absolute;
    bottom: 10px;
    right: 10px;
    line-height: 20px;
    color: #333;
    font-size: 12px;
    font-weight: 600;
}

#payment-app .p-payment .p-otp-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 460px;
    padding: 20px;
    box-sizing: border-box;
}

#payment-app .p-payment .p-otp-modal>form {
    width: 100%;
}

#payment-app .p-payment .p-otp-modal .p-otp-modal-icon {
    width: 84px;
    height: 84px;
    border-radius: 50%;
    background-color: #fff0e4;
    margin: 10px 0;
}

#payment-app .p-payment .p-otp-modal .p-otp-modal-icon i {
    font-size: 44px;
    color: #f27a1a;
}

#payment-app .p-payment .p-otp-modal .p-otp-modal-desc {
    text-align: center;
    margin: 10px 0 25px;
    line-height: 30px;
}

#payment-app .p-payment .p-otp-modal .p-otp-modal-desc .p-title {
    font-size: 22px;
    font-weight: 600;
    color: #f27a1a;
    max-width: 320px;
}

#payment-app .p-payment .p-otp-modal .p-otp-modal-desc .p-desc {
    font-size: 16px;
    color: #666;
}

#payment-app .p-payment .p-otp-modal .p-otp-modal-input {
    margin-bottom: 25px;
}

#payment-app .p-payment .p-otp-modal .p-otp-modal-input .p-otp-modal-input-counter {
    display: inline-block;
    color: #f27a1a;
    margin-left: 5px;
}

#payment-app .p-payment .p-otp-modal .p-otp-modal-input p {
    margin-top: 10px;
    font-size: 12px;
    color: #666;
}

#payment-app .p-payment .p-otp-modal .p-otp-modal-action {
    width: 100%;
}

#payment-app .p-payment .p-bddk-modal {
    width: 460px;
    padding: 20px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    flex-direction: column;
}

#payment-app .p-payment .p-bddk-modal .p-bm-ty-icon {
    width: 60px;
}

#payment-app .p-payment .p-bddk-modal .p-bm-head {
    width: 360px;
    text-align: center;
    margin: 20px 0;
}

#payment-app .p-payment .p-bddk-modal .p-bm-head .p-bm-head-title {
    max-height: 100px;
    margin: 20px 0;
}

#payment-app .p-payment .p-bddk-modal .p-bm-head p {
    font-size: 24px;
    color: #d21313;
}

#payment-app .p-payment .p-bddk-modal .p-bm-desc {
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    color: #333;
}

#payment-app .p-payment .p-bddk-modal .p-bm-desc .p-bm-desc-title {
    border-bottom: 1px solid #e6e6e6;
    padding: 10px;
}

#payment-app .p-payment .p-bddk-modal .p-bm-desc .p-bm-desc-info {
    padding: 10px;
    display: flex;
    align-items: center;
}

#payment-app .p-payment .p-bddk-modal .p-bm-desc .p-bm-desc-info .internet-info,
#payment-app .p-payment .p-bddk-modal .p-bm-desc .p-bm-desc-info .phone-info,
#payment-app .p-payment .p-bddk-modal .p-bm-desc .p-bm-desc-info .sms-info,
#payment-app .p-payment .p-bddk-modal .p-bm-desc .p-bm-desc-info .web-info {
    height: 44px;
    width: 50px;
    display: inline-block;
    margin-right: 10px;
}

#payment-app .p-payment .p-bddk-modal .p-bm-desc .p-bm-desc-info .sms-info {
    background: url(https://cdn.dsmcdn.com/web/production/sms-info.svg) center no-repeat;
}

#payment-app .p-payment .p-bddk-modal .p-bm-desc .p-bm-desc-info .phone-info {
    background: url(https://cdn.dsmcdn.com/web/production/phone-info.svg) center no-repeat;
}

#payment-app .p-payment .p-bddk-modal .p-bm-desc .p-bm-desc-info .internet-info {
    background: url(https://cdn.dsmcdn.com/web/production/internet-info.svg) center no-repeat;
}

#payment-app .p-payment .p-bddk-modal .p-bm-desc .p-bm-desc-info .web-info {
    background: url(https://cdn.dsmcdn.com/web/production/web-info.svg) center no-repeat;
}

#payment-app .p-payment .p-bddk-modal .p-bm-desc .p-bm-actions {
    width: 100%;
    margin-top: 20px;
}

#payment-app .p-payment .tooltip-wrapper {
    display: none;
    width: 330px;
    padding: 12px;

    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    color: #333;
    background-color: #fffff1;
    box-sizing: border-box;
    box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.3);
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    position: absolute;
    top: 95px;
    left: -60px;
    z-index: -1;
    text-align: center;
}

#payment-app .p-payment .tooltip-wrapper::before {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fffff1;
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
}

#payment-app .p-payment .installment-postponing-info-popup {
    background-color: #fff;
    width: 428px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    border-radius: 8px;
}

#payment-app .p-payment .installment-postponing-info-popup .i-regular-close {
    right: 20px;
    top: 20px;
    bottom: 22px;
    position: absolute;
    cursor: pointer;
    color: #999 !important;
}

#payment-app .p-payment .installment-postponing-info-popup__title-section {
    height: 54px;
    box-sizing: border-box;
    position: relative;
    padding: 20px 20px 12px 20px;
    border-bottom: 1px solid #e6e6e6;
}

#payment-app .p-payment .installment-postponing-info-popup__title {
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    color: #333;
}

#payment-app .p-payment .installment-postponing-info-popup__body-section {
    padding: 20px;
}

#payment-app .p-payment .installment-postponing-info-popup__bullet-list-item {
    display: flex;
}

#payment-app .p-payment .installment-postponing-info-popup__bullet-text {
    font-size: 12px;
    line-height: 15px;
    color: #666;
    margin-left: 4px;
    flex: 1;
}

#payment-app .p-payment .installment-postponing-info-popup__bullet-text>a {
    color: #4a90e2;
    font-weight: 600;
}

#payment-app .p-payment .installment-postponing-info-popup__bullet {
    height: 4px;
    flex: 0 0 4px;
    border-radius: 50%;
    background-color: #f27a1a;
    margin-top: 4px;
}

#payment-app .p-payment .installment-postponing-info-popup__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 2;
    display: none;
}

#payment-app .p-payment .installment-postponing-info-popup__overlay.active {
    display: block;
}

#payment-app .p-payment.select-loading {
    transition: 0.3s ease-in;
    opacity: 0.3;
    pointer-events: none;
    transform: none;
}

#payment-app .p-payment.active {
    animation: 0.5s top_to_bottom_opacity ease-in;
    visibility: visible;
    display: flex;
    flex-direction: column;
    transform: none;
}

#payment-app .p-save-card-modal-wrapper {
    width: 506px;
    overflow: hidden;
}

#payment-app .p-save-card-modal-wrapper .p-save-card-modal-header {
    display: flex;
    height: 40px;
    padding: 10px 20px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e8e8e8;
}

#payment-app .p-save-card-modal-wrapper .p-save-card-modal-header .p-save-card-modal-logo {
    flex-grow: 1;
    text-align: center;
}

#payment-app .p-save-card-modal-wrapper .p-save-card-modal-header .p-save-card-modal-logo img {
    height: 40px;
    margin-left: 52px;
}

#payment-app .p-save-card-modal-wrapper .p-save-card-modal-header .p-save-card-modal-logo.isMilla img {
    width: 182px;
    height: 20px;
    margin-left: 53px;
}

#payment-app .p-save-card-modal-wrapper .p-save-card-modal-header .p-scm-ssl {
    font-size: 24px;
    line-height: 20px;
}

#payment-app .p-save-card-modal-wrapper .p-save-card-modal-header .p-scm-ssl i span::before {
    color: #0bc15c;
}

#payment-app .p-save-card-modal-wrapper .p-save-card-modal-header .p-scm-ssl i span.path3::before {
    color: #fefefe;
}

#payment-app .p-save-card-modal-wrapper .p-save-card-modal {
    width: 200%;
    position: relative;
    display: flex;
    left: 0;
    transition: 0.3s ease-in;
}

#payment-app .p-save-card-modal-wrapper .p-save-card-modal.show-contract {
    transition: 0.3s ease-in;
    left: -100%;
}

#payment-app .p-save-card-modal-wrapper .p-save-card-modal .p-save-card-modal-contract {
    width: calc(100% / 2);
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#payment-app .p-save-card-modal-wrapper .p-save-card-modal .p-save-card-modal-contract .p-scm-contract {
    position: relative;
    height: 177px;
}

#payment-app .p-save-card-modal-wrapper .p-save-card-modal .p-save-card-modal-contract .p-scm-contract>div {
    height: 100%;
    overflow-y: scroll;
    word-break: break-word;
}

#payment-app .p-save-card-modal-wrapper .p-save-card-modal .p-save-card-modal-contract .p-scm-contract:after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px;
    background: #fff;
    background: linear-gradient(0deg, #fff, rgba(255, 255, 255, 0));
    pointer-events: none;
}

#payment-app .p-save-card-modal-wrapper .p-save-card-modal .p-save-card-modal-contract .ty-btn-medium {
    margin-top: 20px;
    height: 44px;
    font-size: 14px;
}

#payment-app .p-save-card-modal-wrapper .p-save-card-modal .p-save-card-modal-content {
    width: calc(100% / 2);
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    text-align: center;
}

#payment-app .p-save-card-modal-wrapper .p-save-card-modal .p-save-card-modal-content .p-scm-icon-wrapper {
    width: 53px;
    height: 53px;
    border: 3px solid #0bc15c;
    border-radius: 50%;
    margin: 10px auto;
}

#payment-app .p-save-card-modal-wrapper .p-save-card-modal .p-save-card-modal-content .p-scm-icon-wrapper i {
    color: #0bc15c;
    font-size: 28px;
}

#payment-app .p-save-card-modal-wrapper .p-save-card-modal .p-save-card-modal-content .p-scm-title {
    font-size: 20px;
    color: #0bc15c;
}

#payment-app .p-save-card-modal-wrapper .p-save-card-modal .p-save-card-modal-content .p-scm-desc-area {
    gap: 5px;
    display: flex;
    flex-direction: column;
    margin: 10px 0 20px 0;
}

#payment-app .p-save-card-modal-wrapper .p-save-card-modal .p-save-card-modal-content .p-scm-desc-area .p-scm-desc {
    width: 100%;
    font-size: 14px;
    color: #333;
}

#payment-app .p-save-card-modal-wrapper .p-save-card-modal .p-save-card-modal-content .p-scm-desc-area .p-scm-desc strong {}

#payment-app .p-save-card-modal-wrapper .p-save-card-modal .p-save-card-modal-content .p-scm-desc-area .p-scm-desc .p-scm-desc-contract {
    text-decoration: underline;
}

#payment-app .p-save-card-modal-wrapper .p-save-card-modal .p-save-card-modal-content .p-scm-desc-area .p-scm-desc .p-scm-desc-contract:hover {
    color: #f27a1a;
    cursor: pointer;
}

#payment-app .p-save-card-modal-wrapper .p-save-card-modal .p-save-card-modal-content .p-save-card-modal-actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

#payment-app .p-save-card-modal-wrapper .p-save-card-modal .p-save-card-modal-content .p-save-card-modal-actions .ty-passive-btn,
#payment-app .p-save-card-modal-wrapper .p-save-card-modal .p-save-card-modal-content .p-save-card-modal-actions .ty-secondary-btn {
    white-space: nowrap;
    font-size: 14px;
    height: 44px;
}

#payment-app .p-save-card-modal-wrapper .p-save-card-modal .p-save-card-modal-content .p-save-card-modal-actions .p-scm-approve-btn {
    width: 210px;
}

#payment-app .p-save-card-modal-wrapper .p-save-card-modal .p-save-card-modal-content .p-save-card-modal-actions .p-scm-continue-btn {
    width: 236px;
}

#payment-app .optional-3d-payment .optional-3d-payment-text {
    display: flex;
    align-items: center;
}

#payment-app .optional-3d-payment .optional-3d-payment-text i {
    color: #333;
    padding-right: 5px;
}

#payment-app .optional-3d-payment .optional-3d-payment-text p {
    color: #333;
}

#payment-app .optional-3d-payment .optional-3d-payment-text p strong {}

#payment-app .cobranded-card-point .cobranded-card-point-text {
    display: flex;
    align-items: center;
}

#payment-app .cobranded-card-point .cobranded-card-point-text>p {
    color: #333;

    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    line-height: 18px;
}

#payment-app .cobranded-card-point .cobranded-card-point-text>p>span {
    color: #f27a1a;
}

#payment-app .cobranded-card-point .cobranded-card-point-description {
    width: 100%;
    height: 35px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 10px;
    margin-top: 5px;
    background: #f5f5f5;
    border-radius: 4px;
    box-sizing: border-box;
}

#payment-app .cobranded-card-point .cobranded-card-point-description .cobranded-card-point-description-text {
    font-family: source_sans_proregular, sans-serif;
    font-size: 12px;
    line-height: 15px;
    color: #333;
}

#payment-app .cobranded-card-point .cobranded-card-point-description .cobranded-card-point-description-text>span.color {
    color: #f27a1a;
}

#payment-app .cobranded-card-point .cobranded-card-point-description .cobranded-card-point-description-text>span.bold {
    -webkit-font-smoothing: antialiased;
}

#payment-app .cobranded-card-point .cobranded-card-point-description .cobranded-card-point-description-button {
    display: block;
    width: 20px;
    height: 20px;
    border: 1px solid #e6e6e6;
    border-radius: 50%;
    background-color: #fff;
    box-sizing: border-box;
    color: #f27a1a;
    text-align: center;
    line-height: 20px;

    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    cursor: pointer;
}

#payment-app .p-cobranded-card-information-modal-wrapper {
    width: 460px;
    overflow: hidden;
}

#payment-app .p-cobranded-card-information-modal-wrapper .p-cobranded-card-information-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 20px;
    border-bottom: 1px solid #e8e8e8;
    box-sizing: border-box;
}

#payment-app .p-cobranded-card-information-modal-wrapper .p-cobranded-card-information-modal-header>i {
    font-size: 12px;
    color: #979797;
    cursor: pointer;
}

#payment-app .p-cobranded-card-information-modal-wrapper .p-cobranded-card-information-modal-header>p {
    -webkit-font-smoothing: antialiased;
    font-size: 18px;
    line-height: 21px;
    color: #333;
}

#payment-app .p-cobranded-card-information-modal-wrapper .p-cobranded-card-information-modal-content {
    padding: 20px;
    color: #333;
    font-size: 14px;
    line-height: 18px;
}

#payment-app .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
}

#payment-app .left-container {
    display: flex;
    padding-right: 10px;
}

#payment-app .left-container .title {
    font-size: 14px;
    font-weight: 600;
    color: #333;
    margin-bottom: 2px;
}

#payment-app .left-container .sub-title {
    font-size: 12px;
    font-weight: 600;
    color: #f27a1a;
}

#payment-app .right-container {
    padding-right: 10px;
}

#payment-app .right-container i {
    color: #f27a1a;
    font-size: 6px;
    font-weight: 700;
}

#payment-app .icon-wrapper {
    max-width: 36px;
    max-height: 36px;
    margin-right: 10px;
    border-radius: 50%;
}

#payment-app .pre-approved-table-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

#payment-app .pre-approved-table-wrapper .pre-approved-table {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    gap: 15px;
}

#payment-app .pre-approved-table-wrapper .pre-approved-table .table-payment-type-title {
    padding: 10px 0;
}

#payment-app .p-rfs-pre-approve-payments {
    width: calc(100% / 2);
    visibility: hidden;
    margin-top: 20px;
    position: relative;
    height: 100%;
    display: none;
    transform: translateX(-100%);
    transition: opacity 0.3s ease-in;
}

#payment-app .p-rfs-pre-approve-payments .tooltip-icon-container {
    display: flex;
}

#payment-app .p-rfs-pre-approve-payments .tooltip-icon-container>i {
    color: #999;
    font-size: 12px;
    font-weight: 600;
    transform: rotate(180deg);
}

#payment-app .p-rfs-pre-approve-payments.active {
    animation: 0.5s top_to_bottom_opacity ease-in;
    visibility: visible;
    transform: none;
    display: flex;
    flex-direction: column;
}

#payment-app .p-rfs-pre-approve-payments.active .return-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

#payment-app .p-rfs-pre-approve-payments.active .return-button-container>button {
    width: 35%;
}

#payment-app .p-contracts-modal {
    width: 500px;
    padding: 20px;
}

#payment-app .p-contracts-modal .p-contracts-modal-info {
    margin: 20px 0 10px;
    font-size: 14px;
    color: #999;
    text-align: center;
}

#payment-app .p-contracts-modal>h1 {
    font-size: 22px;
    font-weight: 600;
    color: #333;
}

#payment-app .p-contracts-modal section {
    margin-top: 20px;
}

#payment-app .p-contracts-modal section>h5 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
}

#payment-app .p-contracts-modal section .p-contract-wrapper {
    border-radius: 6px;
    background-color: #fafafa;
    padding: 20px;
    height: 160px;
    color: #999;
}

#payment-app .p-contracts-modal section .p-contract-wrapper>p {
    max-height: 150px;
    padding: 0 20px;
    overflow-y: scroll;
}

#payment-app .p-contracts-modal section .p-contract-wrapper>p::-webkit-scrollbar {
    background: #d8d8d8;
    width: 7px;
    border-radius: 3px;
}

#payment-app .p-contracts-modal section .p-contract-wrapper>p::-webkit-scrollbar-thumb {
    background: #7a7a7a;
    border-radius: 3px;
}

#payment-app .p-contracts-modal section .p-contract-wrapper.gray-text li,
#payment-app .p-contracts-modal section .p-contract-wrapper.gray-text p,
#payment-app .p-contracts-modal section .p-contract-wrapper.gray-text span {
    color: #999 !important;
}

#payment-app .p-contracts {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #e6e6e6;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 6px;
    margin-top: 20px;
    animation: 0.5s top_to_bottom_opacity ease-in;
    position: relative;
}

#payment-app .p-contracts>h1 {
    font-size: 22px;
    font-weight: 600;
    color: #333;
}

#payment-app .p-contracts section {
    margin-top: 20px;
}

#payment-app .p-contracts section>h5 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
}

#payment-app .p-contracts section .p-contract-wrapper {
    border-radius: 6px;
    background-color: #fafafa;
    padding: 20px;
    height: 160px;
    color: #999;
}

#payment-app .p-contracts section .p-contract-wrapper.claim-conditions>p span {
    color: #999 !important;
}

#payment-app .p-contracts section .p-contract-wrapper>p {
    max-height: 150px;
    padding: 0 20px;
    overflow-y: scroll;
}

#payment-app .p-contracts section .p-contract-wrapper>p::-webkit-scrollbar {
    background: #d8d8d8;
    width: 7px;
    border-radius: 3px;
}

#payment-app .p-contracts section .p-contract-wrapper>p::-webkit-scrollbar-thumb {
    background: #7a7a7a;
    border-radius: 3px;
}

#payment-app .privacy-statement-cl li,
#payment-app .privacy-statement-cl span,
#payment-app .privacy-statement-cl>p {
    color: #999 !important;
}

#payment-app .p-pudo {
    border-radius: 6px;
    position: relative;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    box-sizing: border-box;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #e6e6e6;
    transition: 0.3s ease-in;
    background-color: #fff;
    visibility: hidden;
    display: none;
}

#payment-app .p-pudo>div {
    width: 100%;
}

#payment-app .p-pudo.active {
    display: flex;
    visibility: visible;
}

#payment-app .p-pudo.hide {
    visibility: hidden;
}

#payment-app .p-address-type-select+.p-pudo {
    border-top-left-radius: 0;
}

#payment-app .group-deal-product-card {
    width: 50%;
    padding: 20px;
    display: flex;
    flex-direction: row;
}

#payment-app .group-deal-product-card .group-deal-product-card-image img {
    width: auto;
    cursor: pointer;
    object-fit: contain;
}

#payment-app .group-deal-product-card .group-deal-product-card-info {
    padding-left: 12px;
}

#payment-app .group-deal-product-card .group-deal-product-card-info .group-deal-product-card-name {
    white-space: nowrap;
    width: 360px;
    overflow: hidden;
    font-size: 14px;
    text-overflow: ellipsis;
    margin-bottom: 1px;
}

#payment-app .group-deal-product-card .group-deal-product-card-info .group-deal-product-card-name .group-deal-product-card-seller-name {
    -webkit-font-smoothing: antialiased;
    color: #333;
    margin-right: 5px;
}

#payment-app .group-deal-product-card .group-deal-product-card-info .group-deal-product-card-name .group-deal-product-card-description {
    font-family: source_sans_proregular, sans-serif;
    -webkit-font-smoothing: antialiased;
}

#payment-app .group-deal-product-card .group-deal-product-card-info .group-deal-product-card-review-rating-and-free-cargo {
    display: flex;
    margin-bottom: 8px;
    align-items: center;
}

#payment-app .group-deal-product-card .group-deal-product-card-info .group-deal-product-card-review-rating-and-free-cargo .group-deal-product-card-free-cargo {
    display: flex;
    align-items: center;
    margin-left: 12px;
}

#payment-app .group-deal-product-card .group-deal-product-card-info .group-deal-product-card-review-rating-and-free-cargo .group-deal-product-card-free-cargo i {
    margin-right: 5px;
    font-size: 12px;
    color: #999;
}

#payment-app .group-deal-product-card .group-deal-product-card-info .group-deal-product-card-review-rating-and-free-cargo .group-deal-product-card-free-cargo span {
    font-family: source_sans_proregular, sans-serif;
    color: #666;
    font-size: 12px;
}

#payment-app .group-deal-product-card .group-deal-product-card-info .group-deal-product-card-price-section {
    box-sizing: border-box;
    border: 1px solid #008040;
    border-radius: 4px;
    background: #fff;
    display: flex;
    flex-direction: column;
    width: 133px;
}

#payment-app .group-deal-product-card .group-deal-product-card-info .group-deal-product-card-price-section .group-deal-product-card-price-badge {
    background: #effbf5;
    margin: 4px 4px 3px;

    -webkit-font-smoothing: antialiased;
    font-size: 12px;
    color: #008040;
    border-radius: 2px;
    text-align: center;
}

#payment-app .group-deal-product-card .group-deal-product-card-info .group-deal-product-card-price-section .group-deal-product-card-prices {
    display: flex;
    justify-content: center;
    padding: 0 4px 4px;
    align-items: flex-end;
}

#payment-app .group-deal-product-card .group-deal-product-card-info .group-deal-product-card-price-section .group-deal-product-card-prices .group-deal-product-card-discounted-price {
    font-size: 14px;
    color: #878787;
    margin-right: 5px;
    text-decoration: line-through;
    line-height: 1;
}

#payment-app .group-deal-product-card .group-deal-product-card-info .group-deal-product-card-price-section .group-deal-product-card-prices .group-deal-product-card-group-deal-price {
    font-family: source_sans_proregular, sans-serif;
    font-size: 16px;
    color: #333;
    line-height: 1;
}

#payment-app .group-deal-box {
    width: 930px;
    height: 130px;
    box-sizing: border-box;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    background: #fff;
    margin-bottom: 20px;
    display: flex;
}

#payment-app .group-deal-journey {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#payment-app .group-deal-journey .group-deal-journey-stages {
    display: flex;
}

#payment-app .group-deal-journey .group-deal-journey-stages .group-deal-journey-icon-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#payment-app .group-deal-journey .group-deal-journey-stages .group-deal-journey-icon-wrapper:not(:last-child) {
    margin-right: 40px;
    position: relative;
}

#payment-app .group-deal-journey .group-deal-journey-stages .group-deal-journey-icon-wrapper:not(:last-child):before {
    content: "";
    position: absolute;
    right: -46px;
    top: 15px;
    width: 53px;
    height: 1px;
    border-top: 1px dashed #d1d1d1;
}

#payment-app .group-deal-journey .group-deal-journey-stages .group-deal-journey-icon-wrapper:last-child .group-deal-journey-icon i {
    width: 15px;
}

#payment-app .group-deal-journey .group-deal-journey-stages .group-deal-journey-icon-wrapper.active .group-deal-journey-icon {
    background-color: #008040;
}

#payment-app .group-deal-journey .group-deal-journey-stages .group-deal-journey-icon-wrapper.active .group-deal-journey-icon i {
    color: #fff;
}

#payment-app .group-deal-journey .group-deal-journey-stages .group-deal-journey-icon-wrapper.active .icon-text {
    -webkit-font-smoothing: antialiased;
    color: #008040;
}

#payment-app .group-deal-journey .group-deal-journey-stages .group-deal-journey-icon-wrapper .group-deal-journey-icon {
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: #effbf5;
    border-radius: 50%;
    margin-bottom: 12px;
    width: 32px;
    height: 32px;
}

#payment-app .group-deal-journey .group-deal-journey-stages .group-deal-journey-icon-wrapper .group-deal-journey-icon i {
    font-size: 16px;
    color: #008040;
    width: 16px;
    height: 16px;
}

#payment-app .group-deal-journey .group-deal-journey-stages .group-deal-journey-icon-wrapper .icon-text {
    font-family: source_sans_proregular, sans-serif;
    color: #333;
    font-size: 14px;
    white-space: nowrap;
    width: 90px;
    overflow: hidden;
    text-align: center;
}

#payment-app .p-photo-gallery-modal {
    width: 600px;
}

#payment-app .p-photo-slider .i-arrow-right {
    position: absolute;
    top: 50%;
    font-size: 37px;
    z-index: 1;
    color: #fff;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    cursor: pointer;
}

#payment-app .p-photo-slider .left-arrow {
    left: 20px;
    transform: rotate(180deg);
}

#payment-app .p-photo-slider .right-arrow {
    right: 20px;
}

#payment-app .p-photo-slider img {
    width: 100%;
    height: 680px;
    object-fit: contain;
    cursor: initial;
}

#payment-app .p-thumbnail-slider-wrapper {
    padding: 20px;
}

#payment-app .p-thumbnail-slider-wrapper .p-thumbnail-slider {
    display: flex;
    justify-content: center;
}

#payment-app .p-thumbnail-slider-wrapper .p-thumbnail-slider>div[data-arrow] {
    display: none;
}

#payment-app .p-thumbnail-slider-wrapper .p-thumbnail-slider>div:not([data-arrow]) {
    gap: 10px;
}

#payment-app .p-thumbnail-slider-wrapper .p-thumbnail-slider .p-thumbnail {
    width: 80px;
    height: 120px;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    cursor: pointer;
}

#payment-app .p-thumbnail-slider-wrapper .p-thumbnail-slider .p-thumbnail img {
    width: auto;
    height: 100%;
    border-radius: inherit;
}

#payment-app .p-thumbnail-slider-wrapper .p-thumbnail-slider .p-thumbnail.focused {
    border-color: #f27a1a;
}

#payment-app .milla-banner-payment {
    margin-bottom: 24px;
}

#payment-app .milla-banner-payment-image {
    width: 100%;
    cursor: pointer;
}

#payment-app .shipping-options-info-box-wrapper {
    display: flex;
    align-items: center;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #feeaea;
    box-sizing: border-box;
    border-radius: 8px;
}

#payment-app .shipping-options-info-box-wrapper>i {
    font-size: 16px;
    margin-right: 10px;
}

#payment-app .shipping-options-info-box-wrapper>i>.path1::before {
    color: #b00;
}

#payment-app .shipping-options-info-box-wrapper>span {
    font-family: source_sans_proregular, sans-serif;
    font-size: 14px;
    line-height: 18px;
    color: #b00;
}

#payment-app .shipping-options-info-box-wrapper>span>strong {
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
}

#payment-app .ty-btn {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transition: 0.3s ease-in;
    border: none;
    background-color: #fefefe;
    color: #333;
    font-weight: 600;
}

#payment-app .ty-btn:hover {
    cursor: pointer;
}

#payment-app .ty-btn-large {
    width: 44px;
    font-size: 18px;
}

#payment-app .ty-btn-medium {
    width: 40px;
    font-size: 16px;
}

#payment-app .ty-secondary-passive-btn {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transition: 0.3s ease-in;
    border: none;
    background-color: #fefefe;
    color: #333;
    font-weight: 600;

    border: solid 1px #e6e6e6;
}

#payment-app .ty-secondary-passive-btn:hover {
    cursor: pointer;
}

#payment-app .ty-secondary-passive-btn:hover {
    border-color: #f27a1a;
    color: #f27a1a;
}

#payment-app .ty-secondary-btn {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transition: 0.3s ease-in;
    border: none;
    background-color: #fefefe;
    color: #333;
    font-weight: 600;

    border: solid 1px #f27a1a;
    color: #f27a1a;
}

#payment-app .ty-secondary-btn:hover {
    cursor: pointer;
}

#payment-app .ty-secondary-btn:hover {
    color: #fff;
    background-color: #f27a1a;
}

#payment-app .ty-passive-btn {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transition: 0.3s ease-in;
    border: none;
    background-color: #fefefe;
    color: #333;
    font-weight: 600;

    border: solid 1px #e6e6e6;
    color: #666;
}

#payment-app .ty-passive-btn:hover {
    cursor: pointer;
}

#payment-app .ty-passive-btn:hover {
    color: #f27a1a;
    border: solid 1px #f27a1a;
    background-color: #fff;
}

#payment-app .ty-primary-btn {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transition: 0.3s ease-in;
    border: none;
    background-color: #fefefe;
    color: #333;
    font-weight: 600;

    background-color: #f27a1a;
    color: #fff;
}

#payment-app .ty-primary-btn:hover {
    cursor: pointer;
}

#payment-app .ty-primary-btn.ty-disabled {
    background-color: #999;
}

#payment-app .ty-primary-btn.ty-disabled-secondary {
    background-color: #999;
    cursor: default;
}

#payment-app .ty-primary-btn.ty-disabled-secondary:hover {
    background-color: #999;
}

#payment-app .ty-primary-btn.ty-disabled:hover {
    cursor: default;
}

#payment-app .ty-primary-btn:hover:not(.ty-disabled):not(.ty-disabled-secondary) {
    background-color: #ff8b38;
}

@keyframes scale_and_rotate90 {
    from {
        transform: scale(0.5) rotateY(90deg);
    }

    to {
        transform: scale(1);
    }
}

@keyframes right_to_left {
    0% {
        opacity: 0;
        right: -100px;
    }

    100% {
        opacity: 1;
        right: 0;
    }
}

@keyframes opacity {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes top_to_bottom_opacity {
    from {
        top: -15px;
        opacity: 0;
    }

    to {
        top: 0;
        opacity: 1;
    }
}

@keyframes bottom_to_top_opacity_scale {
    from {
        top: 15px;
        opacity: 0;
        transform: scale(0.9);
    }

    to {
        top: 0;
        opacity: 1;
        transform: scale(1);
    }
}

#payment-app .ty-p-modal {
    top: 0;
    left: 0;
    background-color: rgba(102, 102, 102, 0.4);
    z-index: 99999;
    overflow: scroll;
    align-items: center;
    width: 100%;
    height: 100%;
    position: fixed;
}

#payment-app .ty-p-modal .ty-p-modal-content {
    background: #fff;
    position: relative;
    margin: auto;
    border-radius: 8px;
}

#payment-app .ty-p-modal .ty-p-modal-content .p-warn-modal-primary {
    width: 409px;
    padding: 20px;
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #333;
}

#payment-app .ty-p-modal .ty-p-modal-content .p-warn-modal-primary.corporate-sale-warning .p-wm-primary-content {
    font-size: 16px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
}

#payment-app .ty-p-modal .ty-p-modal-content .p-warn-modal-primary.corporate-sale-warning .p-wm-primary-actions button {
    font-size: 16px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    width: 165px;
}

#payment-app .ty-p-modal .ty-p-modal-content .p-warn-modal-primary.corporate-sale-warning .p-wm-primary-actions> :first-child {
    margin-right: 10px;
    width: 230px;
}

#payment-app .ty-p-modal .ty-p-modal-content .p-warn-modal-primary.cargo-bundle-warning {
    width: 369px;
}

#payment-app .ty-p-modal .ty-p-modal-content .p-warn-modal-primary.cargo-bundle-warning .p-wm-primary-icon {
    background-color: #feeaea;
    margin: 12px 0 10px;
}

#payment-app .ty-p-modal .ty-p-modal-content .p-warn-modal-primary.cargo-bundle-warning .p-wm-primary-icon i {
    color: #b30000;
    font-size: 26px;
}

#payment-app .ty-p-modal .ty-p-modal-content .p-warn-modal-primary.cargo-bundle-warning .ty-primary-btn {
    height: 42px;
    font-size: 16px;
}

#payment-app .ty-p-modal .ty-p-modal-content .p-warn-modal-primary .p-wm-primary-icon {
    width: 64px;
    height: 64px;
    border-radius: 50px;
    background-color: #fff0e4;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
}

#payment-app .ty-p-modal .ty-p-modal-content .p-warn-modal-primary .p-wm-primary-icon i {
    color: #f27a1a;
    font-size: 24px;
}

#payment-app .ty-p-modal .ty-p-modal-content .p-warn-modal-primary .p-wm-primary-content {
    margin-bottom: 20px;
    font-size: 16px;
    font-family: source_sans_proregular, sans-serif;
    text-align: center;
    width: 100%;
}

#payment-app .ty-p-modal .ty-p-modal-content .p-warn-modal-primary .p-wm-primary-actions {
    display: flex;
    width: 100%;
}

#payment-app .ty-p-modal .ty-p-modal-content .p-wm-az-modal {
    width: 420px;
    padding: 20px;
    border-radius: 6px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #e6e6e6;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #333;
}

#payment-app .ty-p-modal .ty-p-modal-content .p-wm-az-modal .p-wm-az-icon {
    width: 84px;
    height: 84px;
    border-radius: 50px;
    background-color: #feeaea;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

#payment-app .ty-p-modal .ty-p-modal-content .p-wm-az-modal .p-wm-az-icon i {
    color: #b00;
    font-size: 34px;
}

#payment-app .ty-p-modal .ty-p-modal-content .p-wm-az-modal .p-wm-az-title {
    -webkit-font-smoothing: antialiased;
    font-size: 22px;
    text-align: center;
    color: #b00;
    margin: 10px 0;
}

#payment-app .ty-p-modal .ty-p-modal-content .p-wm-az-modal .p-wm-az-content {
    font-family: source_sans_proregular, sans-serif;
    margin-bottom: 40px;
    font-size: 16px;
    text-align: center;
    width: 342px;
    color: #666;
}

#payment-app .ty-p-modal .ty-p-modal-content .address-wm-az-shipment-modal-content {
    padding: 20px;
    width: 460px;
    min-height: 290px;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid #e6e6e6;
}

#payment-app .ty-p-modal .ty-p-modal-content .address-wm-az-shipment-modal-content .address-wm-az-shipment-modal-icon {
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff0e4;
    border-radius: 50%;
    margin: 10px auto 0;
}

#payment-app .ty-p-modal .ty-p-modal-content .address-wm-az-shipment-modal-content .address-wm-az-shipment-modal-icon>i {
    font-size: 33px;
    color: #f27a1a;
}

#payment-app .ty-p-modal .ty-p-modal-content .address-wm-az-shipment-modal-content .address-wm-az-shipment-modal-title {
    -webkit-font-smoothing: antialiased;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #333;
    margin-top: 10px;
}

#payment-app .ty-p-modal .ty-p-modal-content .address-wm-az-shipment-modal-content .address-wm-az-shipment-modal-description {
    display: block;
    font-family: source_sans_proregular, sans-serif;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #666;
    margin-top: 10px;
}

#payment-app .ty-p-modal .ty-p-modal-content .address-wm-az-shipment-modal-content .address-wm-az-shipment-modal-description>strong {
    -webkit-font-smoothing: antialiased;
    font-weight: 600;
}

#payment-app .ty-p-modal .ty-p-modal-content .address-wm-az-shipment-modal-content .address-wm-az-shipment-modal-subdescription {
    display: block;
    font-family: source_sans_proregular, sans-serif;
    font-size: 10px;
    line-height: 13px;
    text-align: center;
    color: #666;
    margin-top: 20px;
}

#payment-app .ty-p-modal .ty-p-modal-content .address-wm-az-shipment-modal-content .address-wm-az-shipment-modal-actions {
    display: flex;
    margin-top: 20px;
    justify-content: center;
}

#payment-app .ty-p-modal .ty-p-modal-content .address-wm-az-shipment-modal-content .address-wm-az-shipment-modal-actions>button {
    width: 195px;
    background: #f27a1a;
    border: 1px solid #f27a1a;
    border-radius: 4px;
    cursor: pointer;
    padding: 12px 0;
    transition: all 0.4s ease;
}

#payment-app .ty-p-modal .ty-p-modal-content .address-wm-az-shipment-modal-content .address-wm-az-shipment-modal-actions>button>p {
    color: #666;
    font-size: 16px;
    font-family: source_sans_proregular, sans-serif;
}

#payment-app .ty-p-modal .ty-p-modal-content .address-wm-az-shipment-modal-content .address-wm-az-shipment-modal-actions .address-wm-az-shipment-modal-change-country-button {
    background: #fff;
    border-color: #e6e6e6;
    margin-right: 15px;
}

#payment-app .ty-p-modal .ty-p-modal-content .address-wm-az-shipment-modal-content .address-wm-az-shipment-modal-actions .address-wm-az-shipment-modal-change-country-button>p {
    transition: all 0.4s ease;
}

#payment-app .ty-p-modal .ty-p-modal-content .address-wm-az-shipment-modal-content .address-wm-az-shipment-modal-actions .address-wm-az-shipment-modal-change-country-button:hover {
    border-color: #f27a1a;
    color: #f27a1a;
}

#payment-app .ty-p-modal .ty-p-modal-content .address-wm-az-shipment-modal-content .address-wm-az-shipment-modal-actions .address-wm-az-shipment-modal-change-country-button:hover>p {
    color: #f27a1a;
}

#payment-app .ty-p-modal .ty-p-modal-content .address-wm-az-shipment-modal-content .address-wm-az-shipment-modal-actions .address-wm-az-shipment-modal-edit-basket-button p {
    color: #fff;
}

#payment-app .ty-p-modal .ty-p-modal-content .address-wm-az-shipment-modal-content .address-wm-az-shipment-modal-actions .address-wm-az-shipment-modal-edit-basket-button:hover {
    background: #ff9642;
    border-color: #ff9642;
}

#payment-app .ty-p-modal .ty-p-modal-content .change-shipping-country-modal {
    width: 445px;
    min-height: 239px;
    box-sizing: border-box;
    overflow: hidden;
}

#payment-app .ty-p-modal .ty-p-modal-content .change-shipping-country-modal>a>svg {
    display: none;
}

#payment-app .ty-p-modal .ty-p-modal-content .change-shipping-country-modal .change-shipping-country-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #e2e2e2;
}

#payment-app .ty-p-modal .ty-p-modal-content .change-shipping-country-modal .change-shipping-country-modal-header>span {
    justify-content: space-between;
    align-items: center;

    -webkit-font-smoothing: antialiased;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #333;
}

#payment-app .ty-p-modal .ty-p-modal-content .change-shipping-country-modal .change-shipping-country-modal-header>i {
    font-size: 12px;
    cursor: pointer;
}

#payment-app .ty-p-modal .ty-p-modal-content .change-shipping-country-modal .change-shipping-country-modal-header>i::before {
    color: #333;
}

#payment-app .ty-p-modal .ty-p-modal-content .change-shipping-country-modal .change-shipping-country-modal-content {
    padding: 20px 0;
}

#payment-app .ty-p-modal .ty-p-modal-content .change-shipping-country-modal .change-shipping-country-modal-content>ul {
    height: 100%;
    max-height: 185px;
    overflow-y: auto;
    padding: 0 20px;
    box-sizing: border-box;
}

#payment-app .ty-p-modal .ty-p-modal-content .change-shipping-country-modal .change-shipping-country-modal-content>ul>li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 11px;
    cursor: pointer;
}

#payment-app .ty-p-modal .ty-p-modal-content .change-shipping-country-modal .change-shipping-country-modal-content>ul>li:not(:first-child) {
    margin-top: 12px;
}

#payment-app .ty-p-modal .ty-p-modal-content .change-shipping-country-modal .change-shipping-country-modal-content>ul>li.is-selected {
    background-color: #f5f5f5;
    border-color: #f5f5f5;
}

#payment-app .ty-p-modal .ty-p-modal-content .change-shipping-country-modal .change-shipping-country-modal-content>ul>li .country-card-item {
    display: flex;
    justify-content: center;
    flex-direction: column;
    row-gap: 3px;
}

#payment-app .ty-p-modal .ty-p-modal-content .change-shipping-country-modal .change-shipping-country-modal-content>ul>li .country-card-item .country-card-header {
    display: flex;
    align-items: center;
    column-gap: 5px;
}

#payment-app .ty-p-modal .ty-p-modal-content .change-shipping-country-modal .change-shipping-country-modal-content>ul>li .country-card-item .country-card-header>img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
}

#payment-app .ty-p-modal .ty-p-modal-content .change-shipping-country-modal .change-shipping-country-modal-content>ul>li .country-card-item .country-card-header>span {
    font-size: 14px;

    -webkit-font-smoothing: antialiased;
    font-weight: 600;
    color: #333;
}

#payment-app .ty-p-modal .ty-p-modal-content .change-shipping-country-modal .change-shipping-country-modal-content>ul>li .country-card-item>p {
    font-size: 12px;
    color: #999;
}

#payment-app .ty-p-modal .ty-p-modal-content .change-shipping-country-modal .change-shipping-country-modal-content>ul>li>i {
    font-size: 12px;
    color: #f27a1a;
}

#payment-app .ty-p-modal .ty-p-modal-content>a {
    cursor: pointer;
    position: absolute;
    text-decoration: none;
    right: 12px;
    top: 12px;
    z-index: 100;
}

#payment-app .ty-p-modal .ty-p-modal-content>a i {
    font-size: 12px;
    color: #333;
}

#payment-app .ty-p-modal .ty-p-modal-content>a:hover i {
    color: #f27a1a;
}

#payment-app .ty-modal-scale-enter .ty-p-modal-content {
    opacity: 0;
    transform: scale(0.1);
}

#payment-app .ty-modal-scale-enter-active .ty-p-modal-content {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 0.3s, transform 0.3s;
}

#payment-app .ty-modal-scale-exit .ty-p-modal-content {
    opacity: 1;
}

#payment-app .ty-modal-scale-exit-active .ty-p-modal-content {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 0.3s, transform 0.3s;
}

#payment-app .p-checkbox-wrapper {
    display: inline-block;
    margin: 5px 0;
    cursor: pointer;
    color: #999;
    transition: 0.2s ease-in;
}

#payment-app .p-checkbox-wrapper:hover {
    transition: 0.2s ease-in;
    color: #666;
}

#payment-app .p-checkbox-wrapper:hover input[type="checkbox"]+.p-checkbox-text:before {
    transition: 0.2s ease-in;
    border-color: #999;
}

#payment-app .p-checkbox-wrapper .p-checkbox-text {
    display: flex;
    align-items: center;
    font-size: 14px;
}

#payment-app .p-checkbox-wrapper input[type="checkbox"] {
    display: none;
}

#payment-app .p-checkbox-wrapper input[type="checkbox"]+.p-checkbox-text:before {
    transition: 0.2s ease-in;
    content: "";
    margin-right: 10px;
    font-weight: 400;
    border: 1px solid #e6e6e6;
    width: 18px;
    color: #e6e6e6;
    height: 18px;
    border-radius: 2px;
    background-color: #fbfbfb;
    outline: 0;
}

#payment-app .p-checkbox-wrapper input[type="checkbox"]:checked+.p-checkbox-text {
    color: #666;
}

#payment-app .p-checkbox-wrapper input[type="checkbox"]:checked+.p-checkbox-text:before {
    content: "\e911";
    font-family: icomoon !important;
    color: #fefefe;
    font-size: 9px;
    background-color: #f27a1a;
    line-height: 20px;
    text-align: center;
    border-color: #f27a1a;
    outline: 0;
}

#payment-app .p-checkbox-wrapper input[type="checkbox"]:disabled+.p-checkbox-text {
    color: #999;
}

#payment-app .p-checkbox-wrapper input[type="checkbox"]:disabled+.p-checkbox-text:before {
    content: "\2713";
    color: #e6e6e6;
}

#payment-app .p-radio-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
    color: #333;
    width: 100%;
}

#payment-app .p-radio-button i {
    margin-right: 10px;
    margin-bottom: 2px;
    font-size: 16px;
}

#payment-app .tooltip {
    position: relative;
    display: flex;
}

#payment-app .tooltip .tooltip-content {
    position: absolute;
    top: 30px;
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d5d5d5;
    z-index: 999;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transition: transform 0.3s ease, opacity 0.3s, visibility 0.3s 0.3s;
}

#payment-app .tooltip .tooltip-content:after {
    bottom: 0;
}

#payment-app .tooltip .tooltip-content.right {
    right: 0;
}

#payment-app .tooltip .tooltip-content.right:after {
    right: 5px;
}

#payment-app .tooltip .tooltip-content.left:after {
    left: 15px;
}

#payment-app .tooltip .tooltip-content:before {
    content: "";
    width: 100%;
    height: 10px;
    top: -10px;
    position: absolute;
    display: none;
}

#payment-app .tooltip .tooltip-content:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    z-index: -1;
    border-style: solid;
    border-width: 10px;
    border-color: #fff transparent transparent transparent;
    filter: drop-shadow(0 1px 0 #cad5e0);
    transform: translateX(-50%) rotate(180deg);
    transition: bottom 0.3s ease;
}

#payment-app .tooltip.is-visible .tooltip-content {
    opacity: 1;
    visibility: visible;
    transition: transform 0.3s ease, opacity 0.3s, visibility 0.3s 0s;
    pointer-events: auto;
}

#payment-app .tooltip.is-visible .tooltip-content:before {
    display: block;
}

#payment-app .tooltip.is-visible .tooltip-content:after {
    bottom: 100%;
}

@keyframes fade {
    0% {
        top: 0;
        opacity: 1;
    }

    100% {
        top: -1em;
        opacity: 0;
    }
}

#payment-app .ty-font-price {
    font-family: source_sans_proregular, sans-serif;
}

#payment-app .ty-p-width {
    width: 1200px;
}

#payment-app .ty-p-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

#payment-app .ty-p-text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

#payment-app .ty-no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#payment-app .expand-button {
    background-color: #fff;
    border: none;
    -webkit-tap-highlight-color: transparent;
}

#payment-app .expand-button-content {
    display: flex;
    align-items: center;
}

#payment-app .expand-button-content i {
    color: #f27a1a;
    font-size: 6px;
    font-weight: 700;
}

#payment-app .expand-button-text {
    padding: 10px 0;
    margin-right: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #f27a1a;
}

#payment-app .ty-tooltip {
    position: relative;
}

#payment-app .ty-tooltip .ty-tooltip-children {
    display: flex;
    justify-content: center;
}

#payment-app .ty-tooltip .ty-tooltip-content {
    position: absolute;
    background-color: #fff;
    z-index: 2;
    width: 212px;
    border-radius: 8px;
    filter: drop-shadow(0 1px 12px rgba(0, 0, 0, 0.25));
}

#payment-app .ty-tooltip .ty-tooltip-content .tooltip-content-container {
    padding: 6px 12px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
}

#payment-app .ty-tooltip .ty-tooltip-content:after,
#payment-app .ty-tooltip .ty-tooltip-content:before {
    left: 85%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

#payment-app .ty-tooltip .ty-tooltip-content:after {
    border-width: 10px;
    margin-left: -10px;
}

#payment-app .ty-tooltip .ty-tooltip-content:before {
    border-width: 11px;
    margin-left: -11px;
}

#payment-app .ty-tooltip .ty-tooltip-content.ty-tooltip-bottom:after {
    bottom: 100%;
    border-bottom-color: #fff;
}

#payment-app .ty-tooltip .ty-tooltip-content.ty-tooltip-bottom:before {
    bottom: 100%;
    border-bottom-color: #e6e6e6;
}

#payment-app .ty-tooltip .ty-tooltip-content.ty-tooltip-top:after {
    top: 100%;
    border-top-color: #fff;
}

#payment-app .ty-tooltip .ty-tooltip-content.ty-tooltip-top:before {
    top: 100%;
    border-top-color: #e6e6e6;
}

#payment-app .cell-header-color-primary {
    background-color: #f5f5f5;
}

#payment-app .cell-header-color-secondary {
    background-color: #fff;
}

#payment-app .table-wrapper {
    font-size: 12px;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
}

#payment-app .table-wrapper table {
    border-spacing: 0;
    width: 100%;
}

#payment-app .table-wrapper table td.border-bottom {
    border-bottom: 1px solid #e5e5e5;
}

#payment-app .table-wrapper table td.best-offer-header {
    background-color: #fef1e8;
    border-bottom-color: #f27a1a;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
}

#payment-app .best-offer-border {
    border-color: #f27a1a;
}

#payment-app .table-rows-wrapper table,
#payment-app .table-rows-wrapper td,
#payment-app .table-rows-wrapper th {
    border-collapse: collapse;
    border: 1px solid #e5e5e5;
}

#payment-app .table-rows-wrapper td,
#payment-app .table-rows-wrapper th {
    padding: 3px 0;
    text-align: center;
}

#payment-app .table-rows-wrapper td:last-child {
    border-right: none;
}

#payment-app .table-rows-wrapper td:first-child {
    border-top: none;
    border-left: none;
}

#payment-app .table-rows-wrapper th:first-child {
    border-left: none;
}

#payment-app .table-rows-wrapper tr:last-child td {
    border-bottom: none;
}

#payment-app .table-rows-wrapper table {
    border: none;
}

#payment-app .table-rows-wrapper-no-border table {
    width: 100%;
}

#payment-app .table-rows-wrapper-no-border td,
#payment-app .table-rows-wrapper-no-border th:first-child {
    width: 16px;
    padding-left: 15px;
    padding-right: 57px;
}

#payment-app .table-rows-wrapper-no-border th:last-child {
    padding-right: 15px;
    width: 120px;
}

#payment-app .table-rows-wrapper-no-border th {
    border: none;
}

#payment-app .table-rows-wrapper-no-border th {
    padding: 15px 0 8px 0;
    width: 176px;
}

#payment-app .table-rows-wrapper-no-border td,
#payment-app .table-rows-wrapper-no-border th {
    text-align: left;
    border-left: none;
}

#payment-app .table-rows-wrapper-no-border td {
    padding: 0 0 16px 0;
}

#payment-app .table-rows-wrapper-no-border td.border-top {
    padding: 5px 0;
    text-align: center;
    border-top: 1px solid #e5e5e5;
}

#payment-app .table-rows-wrapper-no-border tr:first-child td:first-child {
    padding: 0 15px;
}

#payment-app .table-rows-wrapper-no-border tr:first-child td {
    padding: 10px 15px 10px 15px;
    text-align: left;
}

#payment-app .table-rows-wrapper-no-border tbody:has(> tr.expand-tr-row) tr:nth-last-child(-n + 2) td {
    padding: 0 0 15px 0;
}

#payment-app .table-rows-wrapper-no-border tbody:has(> tr.expand-tr-row) tr:nth-last-child(-n + 2) td.radio-button {
    padding: 0 15px 15px 15px;
}

#payment-app .table-rows-wrapper-no-border tr[role="row"]:last-child td {
    padding: 0 0 15px 0;
}

#payment-app .table-rows-wrapper-no-border tr:last-child td:first-child {
    padding: 0 15px 15px 15px;
}

#payment-app .table-rows-wrapper-no-border tr td:first-child {
    padding: 0 15px 15px 15px;
}

#payment-app .table-rows-wrapper-no-border table {
    border: none;
}

#payment-app .table-rows-wrapper-no-border .expand-row {
    padding: 0 15px !important;
}

#payment-app .table-border-none {
    padding: 0 !important;
    border: none;
}

#payment-app .cell-header-color {
    font-size: 14px;
    font-weight: 700;
    color: #333;
}

#payment-app .cell-color {
    color: #666;
}

#payment-app .cell-position {
    position: relative;
}

#payment-app .radio-button {
    padding-left: 10px;
}

#payment-app .table-header {
    padding: 4px 0;
    color: #f27a1a;
}

#payment-app .table-header img {
    max-width: 160px;
    height: 23px;
    padding: 0;
}

#payment-app .table-header .best-offer {
    transform: translateX(-50%);
    left: 50%;
    top: -12px;
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2px 5px;
    width: fit-content;
    height: 18px;
    background: #fff;
    border: 1px solid #f27a1a;
    border-radius: 60px;
}

#payment-app .side-component-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

#payment-app .side-component-container>p {
    margin-right: 4px;
}

#payment-app .side-component-left-title {
    justify-content: flex-start;
}

#payment-app .modal-loading-content {
    font-family: source_sans_proregular, sans-serif;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    width: 500px;
    height: 257px;
    align-items: center;
    justify-content: center;
}

#payment-app .modal-loading-content .message {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    padding: 0 60px;
}

#payment-app .modal-loading-content .message span {
    color: #333;
    font-size: 16px;
    text-align: center;
    line-height: 20px;
    font-weight: 400;
}

#payment-app .modal-loading-content .message span:first-child {
    color: #f27a1a;
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 8px;
}

#payment-app .modal-loading-content .loading {
    display: inline-block;
    position: relative;
    width: 55px;
    height: 55px;
}

#payment-app .modal-loading-content .loading div {
    transform-origin: 27px 27px;
    animation: loading 1.2s linear infinite;
}

#payment-app .modal-loading-content .loading div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 23.125px;
    width: 2.5px;
    height: 11.25px;
    border: 1px;
    border-radius: 3px;
    background: #f27a1a;
}

#payment-app .modal-loading-content .loading div:nth-child(1) {
    transform: rotate(0);
    animation-delay: -1.1s;
}

#payment-app .modal-loading-content .loading div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}

#payment-app .modal-loading-content .loading div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}

#payment-app .modal-loading-content .loading div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}

#payment-app .modal-loading-content .loading div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}

#payment-app .modal-loading-content .loading div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}

#payment-app .modal-loading-content .loading div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}

#payment-app .modal-loading-content .loading div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}

#payment-app .modal-loading-content .loading div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}

#payment-app .modal-loading-content .loading div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}

#payment-app .modal-loading-content .loading div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}

#payment-app .modal-loading-content .loading div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}

@keyframes loading {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

#payment-app .p-rfs-error-modal-content {
    width: 409px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #e6e6e6;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #333;
}

#payment-app .p-rfs-error-modal-content .p-rfs-error-icon {
    width: 84px;
    height: 84px;
    border-radius: 50px;
    background-color: #feeaea;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px 0;
}

#payment-app .p-rfs-error-modal-content .p-rfs-error-icon i:before {
    color: #b00;
    font-size: 24px;
}

#payment-app .p-rfs-error-modal-content p {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    color: #666;
    text-align: center;
}

#payment-app .p-ty-textbox {
    display: flex;
    flex-direction: column;
    color: #333;
}

#payment-app .p-ty-textbox label {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 10px;
}

#payment-app .p-ty-textbox.error input {
    border: solid 1px #d21313 !important;
    background-color: #fff9f9 !important;
}

#payment-app .p-ty-textbox.error p {
    color: #d21313 !important;
}

#payment-app .p-ty-textbox p {
    font-size: 12px;
    font-weight: 600;
}

#payment-app .p-ty-textbox input {
    background-color: #fbfbfb;
    padding: 8px 10px;
    font: inherit;
    color: inherit;
    box-sizing: border-box;
    width: 100%;
    transition: all 0.3s ease;
    outline: 0;
    border: solid 1px #e6e6e6;
    border-radius: 6px;
    height: 42px;
}

#payment-app .p-ty-textbox input:focus:not(:disabled) {
    background-color: #fff;
    border: solid 1px #999;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

#payment-app .p-lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

#payment-app .p-lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

#payment-app .p-lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}

#payment-app .p-lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}

#payment-app .p-lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}

#payment-app .p-lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}

#payment-app .circle-loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

#payment-app .loader {
    display: block;
    position: relative;
    clear: both;
}

#payment-app .spinner {
    display: block;
    position: relative;
    width: 64px;
    height: 64px;
    margin: 0 auto;
}

#payment-app .spinner div {
    transform-origin: 32px 32px;
    animation: spin 1.2s linear infinite;
}

#payment-app .spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 29px;
    width: 5px;
    height: 14px;
    border-radius: 20%;
    background: #ddd;
}

#payment-app .spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}

#payment-app .spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}

#payment-app .spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}

#payment-app .spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}

#payment-app .spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}

#payment-app .spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}

#payment-app .spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}

#payment-app .spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}

#payment-app .spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}

#payment-app .spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}

#payment-app .spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}

#payment-app .spinner div:nth-child(1) {
    transform: rotate(0);
    animation-delay: -1.1s;
}

@keyframes spin {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

#payment-app .p-onboarding-wrapper.show .p-onboarding-overlay {
    display: block;
    position: fixed;
    z-index: 3;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    opacity: 0.7;
    background: grey;
}

#payment-app .p-onboarding-wrapper.show .p-onboarding-children {
    z-index: 3;
    position: relative;
}

#payment-app .img-wrapper {
    position: relative;
    overflow: hidden;
    width: 60px;
    height: 90px;
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
}

#payment-app .img-wrapper img {
    width: auto;
    height: 100%;
    object-fit: contain;
}

#payment-app .ratings {
    position: relative;
    display: flex;
    margin-top: 3px;
}

#payment-app .ratings .ratingCount {
    font-size: 10px;
    color: #999;
    line-height: 14px;
    margin-left: 4px;
}

#payment-app .ratings .star-w {
    position: relative;
}

#payment-app .ratings .star-w .star {
    width: 14px;
    height: 14px;
    transform: scale(0.8);
}

#payment-app .ratings .star-w .empty .star {
    background: url(https://cdn.dsmcdn.com/web/production/rating-and-review-small-star-grey.svg) no-repeat center;
}

#payment-app .ratings .star-w .full {
    position: absolute;
    left: 0;
    top: 0;
    white-space: nowrap;
    overflow: hidden;
}

#payment-app .ratings .star-w .full .star {
    background: url(https://cdn.dsmcdn.com/web/production/rating-and-review-small-star-orange.svg) no-repeat center;
}

#payment-app .summary-box-az-total-price {
    display: flex;
    align-items: center;
    margin-top: 8px;
}

#payment-app .summary-box-az-total-price .price,
#payment-app .summary-box-az-total-price .summary-box-az-total-price-label {
    -webkit-font-smoothing: antialiased;
    font-size: 11px;
    font-weight: 600;
    line-height: 14px;
    color: #333;
}

#payment-app .summary-box-az-total-price .price {
    margin-left: 3px;
    color: #f27a1a;
}

#payment-app .summary-box-az-total-price .tooltip-wrapper {
    display: flex;
    align-items: center;
    margin-left: 6px;
    margin-bottom: 1px;
    color: #999;
    cursor: pointer;
    position: relative;
}

#payment-app .summary-box-az-total-price .tooltip-wrapper .tooltip {
    position: absolute;
    display: block;
    box-sizing: border-box;
    top: 12px;
    left: -124px;
    z-index: 10;
    min-width: min-content;
    width: 282px;
    color: #333;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    padding: 12px 16px 16px;
}

#payment-app .summary-box-az-total-price .tooltip-wrapper .tooltip::before {
    left: 198px;
}

#payment-app .summary-box-az-total-price .i-info,
#payment-app .summary-box-az-total-price .i-info1 {
    font-size: 10px;
}

#payment-app .summary-box-az-total-price .i-info {
    color: #f27a1a;
}

#payment-app .p-layout-loading-container {
    z-index: 99;
    height: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    opacity: 0;
}

#payment-app .p-layout-loading-container.show {
    transition: 0.3s ease-in;
    opacity: 1;
}

#payment-app .sticky-ie {
    position: fixed;
    top: 20px;
}

#payment-app .sticky-ie-end {
    position: static;
    align-self: flex-end;
}

#payment-app .sticky {
    top: 20px;
    position: -webkit-sticky;
    position: sticky;
    align-self: flex-start;
    z-index: 2;
}

body {
    background-color: #fefefe !important;
    overflow-x: hidden;
}

.ty-p-scroll-disabled-body {
    overflow: hidden !important;
    position: absolute !important;
    width: 100% !important;
}

.ty-show-onboarding #payment-app .p-payment.select-loading {
    opacity: 1 !important;
}

// maps sections

.availablePointCard {
    background-color: #ffffff;
    padding: 0 12px 12px 10px;
    margin-left: 10px;
    cursor: pointer;
}

.availablePointCard.easyReturnEnabled {
    margin-left: 0;
}

.availablePointCard:first-child .availablePointRow {
    border: none;
}

.availablePointCard.selected,
.availablePointCard.hovered {
    background-color: #fafafa;
    border-radius: 6px;
}

.availablePointCard.selected .collectionPointName,
.availablePointCard.hovered .collectionPointName {
    font-weight: 600;
    display: flex;
    align-items: baseline;
}

.availablePointCard.selected .availablePointRow,
.availablePointCard.hovered .availablePointRow,
.availablePointCard.selected+.availablePointCard .availablePointRow,
.availablePointCard.hovered+.availablePointCard .availablePointRow {
    border-top-color: transparent;
}

.availablePointCard.selected {
    background-color: #fdebdd !important;
}

.availablePointCard .availablePointRow {
    display: flex;
    border-top: 1px solid #e6e6e6;
    padding-top: 12px;
}

.availablePointCard .availablePointRow .radioButton {
    margin-right: 8px;
    font-size: 18px;
    cursor: pointer;
}

.availablePointCard .availablePointRow .collectionPointName {
    cursor: pointer;
}

.availablePointCard .availablePointRow .collectionPointName span {
    font-size: 14px;
    color: #333333;
    font-weight: 600;
}

.availablePointCard .availablePointRow .collectionPointName i {
    font-size: 13px;
    margin-left: 8px;
}

.availablePointCard .availablePointRow .collectionPointName i:before {
    color: #999999;
}

.availablePointCard .availablePointRow .pointInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 5px;
}

.availablePointCard .availablePointRow .pointInfo .pointType {
    display: flex;
    color: #333;
    -ms-word-break: keep-all;
    align-items: center;
    column-gap: 4px;
}

.availablePointCard .availablePointRow .pointInfo .pointType .i-locker-badge {
    color: #f27a1a;
    font-size: 10px;
}

.availablePointCard .availablePointRow .pointInfo .pointType .i-locker-badge.isRecommendedPoint {
    font-size: 10px;
}

.availablePointCard .availablePointRow .pointInfo .pointType .i-delivery-point {
    color: #f77d1a;
    font-size: 14px;
}

.availablePointCard .availablePointRow .pointInfo .pointType .i-delivery-point.isRecommendedPoint {
    font-size: 14px;
}

.availablePointCard .availablePointRow .pointInfo .pointType .i-checkmark {
    font-size: 9px;
    color: #0bc15c;
}

.availablePointCard .availablePointRow .pointInfo .pointType .isRecommendedPoint {
    color: #ffc000;
}

.availablePointCard .availablePointRow .pointInfo .pointType span.path1,
.availablePointCard .availablePointRow .pointInfo .pointType span.path2 {
    margin: 0;
}

.availablePointCard .availablePointRow .pointInfo .pointType .collectionPointType {
    font-size: 12px;
}

.availablePointCard .availablePointRow .pointInfo .pointType .publicStatusText {
    border-radius: 4px;
    background-color: #ffd4d4;
    color: #d21313;
    padding: 0 3px;
    margin-left: 5px;
    font-size: 10px;
}

.availablePointCard .availablePointRow .pointInfo .pointType.selected span {
    color: #0bc15c !important;
}

.availablePointCard .availablePointRow .pointCommonWorkingDaysInfo {
    color: #999;
    font-size: 12px;
}

.availablePointCard .availablePointRow .pointCommonWorkingDaysInfo b {
    font-weight: 600;
}

.availablePointCard .availablePointRow .pointContent {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.availablePointCard .availablePointRow .i-location-pin-2 {
    color: #f27a1a;
    font-size: 11px;
    margin-right: 6px;
}

.availablePointCard .availablePointRow .pointActions {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.availablePointCard .availablePointRow .pointActions .i-clock {
    color: #f27a1a;
    font-size: 13px;
    margin-right: 6px;
    height: 15px;
}

.availablePointCard .availablePointRow .pointActions span {
    font-size: 12px;
    line-height: 2;
    color: #333333;
}

.availablePointCard .availablePointRow .pointActions button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    color: #333;
    font-family: source_sans_proregular, sans-serif;
    font-size: 12px;
    border: 1px solid #e6e6e6;
    border-radius: 3px;
    cursor: pointer;
    width: 144px;
    height: 28px;
    line-height: 28px;
    box-sizing: border-box;
    margin-right: 10px;
    transition: all 0.2s ease;
}

.availablePointCard .availablePointRow .pointActions button:hover {
    color: #f27a1a;
    border-color: #f27a1a;
}

.availablePointCard .availablePointRow .pointActions button.workingDaysHandle {
    margin-right: 0;
}

.availablePointCard .availablePointRow .pointActions button.workingDaysHandle:hover~div {
    display: block;
}

.availablePointCard .availablePointRow .pointActions .workingDays {
    position: relative;
}

.availablePointCard .availablePointRow .pointActions .workingHours {
    display: none;
    background: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 2px;
    position: absolute;
    width: 168px;
    padding: 15px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
    box-sizing: border-box;
    z-index: 999;
}

.availablePointCard .availablePointRow .pointActions .workingHours.up_arrow {
    top: 38px;
    left: -12px;
}

.availablePointCard .availablePointRow .pointActions .workingHours.up_arrow::after,
.availablePointCard .availablePointRow .pointActions .workingHours.up_arrow::before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.availablePointCard .availablePointRow .pointActions .workingHours.up_arrow::after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 5px;
    margin-left: -5px;
}

.availablePointCard .availablePointRow .pointActions .workingHours.up_arrow::before {
    border-color: rgba(230, 230, 230, 0);
    border-bottom-color: #e6e6e6;
    border-width: 6px;
    margin-left: -6px;
}

.availablePointCard .availablePointRow .pointActions .workingHours.down_arrow {
    bottom: 38px;
    left: -12px;
}

.availablePointCard .availablePointRow .pointActions .workingHours.down_arrow::after,
.availablePointCard .availablePointRow .pointActions .workingHours.down_arrow::before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.availablePointCard .availablePointRow .pointActions .workingHours.down_arrow::after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #fff;
    border-width: 5px;
    margin-left: -5px;
}

.availablePointCard .availablePointRow .pointActions .workingHours.down_arrow::before {
    border-color: rgba(230, 230, 230, 0);
    border-top-color: #e6e6e6;
    border-width: 6px;
    margin-left: -6px;
}

.availablePointCard .availablePointRow .pointActions .workingHours .workingDay {
    display: flex;
    color: #333;
    font-size: 12px;
}

.availablePointCard .availablePointRow .pointActions .workingHours .workingDay span:first-child {
    font-weight: 600;
    width: 60px;
}

.availablePointCard .availablePointRow .pointActions .workingHours .workingDay span:last-child.closed {
    color: #999;
}

.availablePointCard .availablePointRow .pointActions .workingHours .workingDay span:last-child::before {
    content: ":";
    color: #333;
    margin-right: 10px;
}

.availablePointCard .availablePointRow .receiver {
    margin-left: 29px;
    margin-top: 10px;
    font-size: 14px;
    color: #333333;
}

.availablePointCard .availablePointRow .receiver label {
    font-weight: 600;
    line-height: 1.71;
}

.filterContainer {
    padding: 10px 20px;
}

.filterContainer.easyReturnEnabled {
    padding: 10px 0;
}

.filterContainer .clearDistrictFilter {
    position: absolute;
    right: 40px;
    margin-top: 15px;
    font-size: 12px;
    color: #666666;
    text-decoration: underline;
    cursor: pointer;
    z-index: 1;
}

.filterContainer .clearDistrictFilter:hover {
    color: #f27a1a;
}

.filterContainer .locationFilterContainer {
    display: flex;
    position: relative;
    justify-content: space-between;
    padding: 16px 20px 0 20px;
    border-radius: 5px;
    border-color: transparent;
    background-color: #fafafa;
}

.filterContainer .locationFilterContainer .ty-text {
    width: 100%;
}

.filterContainer .locationFilterContainer label {}

.filterContainer .locationFilterContainer .ty-select {
    padding: 10px !important;
    border-radius: 6px;
}

.filterContainer .locationFilterContainer.is-az-shipment {
    column-gap: 22px;
}

.filterContainer .locationFilterContainer.is-az-shipment .ty-input-w {
    width: 100% !important;
}

.filterContainer .locationFilterContainer .az-info {
    background-color: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.filterContainer .locationFilterContainer .az-info span {
    font-size: 10px;
    color: #333;
    padding-right: 10px;
}

.filterContainer .locationFilterContainer .az-info i {
    color: #f27a1a;
    font-size: 21px;
    margin: 0 10px 0 10px;
}

.filterContainer .locationFilterContainer>.ty-input-w {
    width: 264px !important;
}

.collectionPointsContainer .pudoBanner {
    font-size: 18px;
    color: #000;
    margin: 20px 0 0 20px;
}

.collectionPointsContainer .pudoBanner span {
    color: #f27a1a;
}

.unavailablePointContainer {
    text-align: center;
    padding: 20px;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-flow: column;
    align-items: center;
}

.unavailablePointContainer .i-unavailable-point {
    color: #f27a1a;
    font-size: 44px;
    padding: 15px 20px;
    background: #fff0e4;
    border-radius: 100%;
    margin-bottom: 10px;
    display: inline-block;
}

.unavailablePointContainer h2 {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.22;
    color: #333333;
    margin-bottom: 5px;
}

.unavailablePointContainer p {
    font-size: 12px;
    color: #666666;
    max-width: 100%;
}

.unavailablePointContainer .clearFilter {
    font-size: 12px;
    color: #f27a1a;
    text-decoration: underline;
    cursor: pointer;
}

.availablePointsContainer .locker-collectionPoint-information-boxs {
    width: auto;
    display: flex;
}

.availablePointsContainer .locker-collectionPoint-information-boxs .receiver-box {
    margin: 10px 20px 20px 0;
    width: 100%;
}

.availablePointsContainer .locker-collectionPoint-information-boxs .receiver-box .change-receiver-information {
    padding: 4px 12px;
}

.availablePointsContainer .locker-collectionPoint-information-boxs .receiver-box .receiver-desc {
    display: flex;
    flex-direction: column;
    max-width: 200px;
}

.availablePointsContainer .locker-collectionPoint-information-boxs .receiver-box .receiver-desc .receiver-information {
    margin-left: 0;
}

.availablePointsContainer .locker-banner {
    display: flex;
    align-items: center;
    background-color: #fff9d7;
    border-radius: 8px;
    height: 55px;
    box-sizing: border-box;
    margin: 10px 20px 20px;
    padding: 15px 15px 16px 18px;
    width: 100%;
}

.availablePointsContainer .locker-banner i {
    font-size: 30px;
    width: 60px;
    display: inline-block;
}

.availablePointsContainer .locker-banner span.locker-text {
    font-size: 14px;
    color: #333;
    padding: 20px 0 20px 10px;
}

.availablePointsContainer .collectionPointsContent {
    display: flex;
    height: 520px;
    margin-bottom: 30px;
    margin-right: 20px;


    @media(max-width:575px) {
        margin: 0;
    }
}

.availablePointsContainer .collectionPointsContent.easyReturnEnabled {
    margin-right: 0;
}

.availablePointsContainer .collectionPointsContent .collectionPoints {
    position: relative;
    width: 380px;
    overflow-x: hidden;
    margin-right: 10px;
}

.availablePointsContainer .collectionPointsContent .collectionPoints::-webkit-scrollbar {
    width: 7px;
}

.availablePointsContainer .collectionPointsContent .collectionPoints::-webkit-scrollbar-thumb {
    background: #7a7a7a;
    border-radius: 100px;
    height: 20%;
}

.availablePointsContainer .collectionPointsContent .collectionPoints::-webkit-scrollbar-thumb:hover {
    background: #999999;
}

.availablePointsContainer .collectionPointsContent .collectionPoints::-webkit-scrollbar-track {
    background: #d8d8d8;
    border-radius: 100px;
}

.availablePointsContainer .collectionPointsContent .collectionPoints .availablePoints {
    overflow: hidden;
    margin-right: 10px;
}

.availablePointsContainer .collectionPointDetail {
    display: flex;
    width: 100%;
    flex-flow: column;
    overflow: hidden;
    border-radius: 8px;
}

.receiverChangeModal {
    padding: 50px 25px 40px 25px;
    width: 330px;
    text-align: center;
}

.receiverChangeModal .whoToDeliver {
    font-size: 18px;
    font-weight: 600;
    color: #333333;
    margin: 10px 0;
}

.receiverChangeModal .whoToDeliverDesc {
    font-size: 14px;
    color: #666666;
}

.receiverChangeModal .receiverChangeContainer {
    padding: 20px 20px 0 20px;
    text-align: left;
}

.receiverChangeModal .receiverChangeContainer .ty-mgb-1 {
    margin-bottom: 7px;
}

.receiverChangeModal .receiverChangeContainer .ty-select {
    padding: 10px !important;
    height: 34px !important;
    width: 100% !important;
}

.receiverChangeModal .receiverChangeContainer .ty-select .ty-font-sm {
    line-height: 18px;
}

.receiverChangeModal .receiverChangeContainer .ty-button {
    height: 44px;
    font-size: 16px;
    margin-bottom: 0;
}

.receiverChangeModal .i-package-hand {
    font-size: 71px;
}

.ty-modal-content {
    border-radius: 3px;
}

.ty-scroll-disabled-body {
    overflow: hidden !important;
    position: absolute !important;
}

.ty-select-options {
    max-height: 193px !important;
}

.ty-select {
    background-color: #ffffff;
    margin-right: 20px;
}

.ty-select .ty-text {
    font-size: 13px;
    color: #333333;
}

.ty-arrow {
    transform: scale(1.5);
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 360px;
}

.loader {
    display: block;
    position: relative;
    clear: both;
}

.spinner {
    display: block;
    position: relative;
    width: 64px;
    height: 64px;
    margin: 0 auto;
}

.spinner div {
    transform-origin: 32px 32px;
    animation: spin 1.2s linear infinite;
}

.spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 29px;
    width: 5px;
    height: 14px;
    border-radius: 20%;
    background: #eee;
}

.spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}

.spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}

.spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}

.spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}

.spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}

.spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}

.spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}

.spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}

.spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}

.spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}

.spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}

.spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}

@keyframes spin {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.mapContainer {
    height: 100%;
    position: relative;

    @media(max-width:575px) {
        .dataMap {
            height: 500px !important;
        }

    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.mapContainer .zoomOutWarning {
    position: absolute;
    top: 32px;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 1;
    display: flex;
    flex-direction: column;
    width: 499px;
    margin-left: 28px;
    margin-right: 28px;
    border-radius: 6px;
    font-weight: bold;
}

.mapContainer .zoomOutWarning .zoomOutWarningTitle {
    margin: 23px 0 5px 20px;
    font-size: 16px;
    color: #f27a1a;
}

.mapContainer .zoomOutWarning .zoomOutWarningTitle .i-warning {
    font-weight: bold;
    position: relative;
    top: 1px;
    margin-right: 3px;
}

.mapContainer .zoomOutWarning .zoomOutWarningDescription {
    margin: 0 0 23px 20px;
    font-size: 14px;
    color: #333333;
}

.mapContainer>div>div {
    border-radius: 3px;
}

.markerIcon {
    display: inline-block;
    filter: drop-shadow(0 0 0.15rem rgba(0, 0, 0, 0.2));
    will-change: transform;
    background-repeat: no-repeat;
    transition: -webkit-transform 0.5s cubic-bezier(0.485, 1.65, 0.545, 0.835) 0s;
    transform-origin: bottom;
    position: relative;
    left: -16px;
    top: -46px;
}

.markerIcon.i-standard-pudo-point,
.markerIcon.i-locker-pudo-point {
    font-size: 46px;
}

.markerIcon.i-standard-pudo-point-selected,
.markerIcon.i-locker-pudo-point-selected,
.markerIcon.i-standard-pudo-point-selected-recommendation,
.markerIcon.i-locker-pudo-point-selected-recommendation {
    font-size: 66px;
    left: -16px;
    top: -66px;
}

.markerIcon.i-standard-pudo-point-recommendation,
.markerIcon.i-locker-pudo-point-recommendation {
    font-size: 50px;
    left: -16px;
    top: -50px;
}

.markerIcon.disabled {
    opacity: 0.5;
}

.markerIcon.i-point-marker-hover,
.markerIcon.i-locker-marker-hover,
.markerIcon.hover {
    transform: scale(1.1);
}

.markerIcon.i-point-marker-active,
.markerIcon.i-locker-marker-active,
.markerIcon.active {
    font-size: 66px;
    left: -16px;
    top: -66px;
}

.markerTooltipContent {
    width: 160px;
    padding: 10px;
    font-size: 14px;
    background-color: #ffffff;
    color: #333333;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    line-height: 1.5;
    font-family: source_sans_proregular, sans-serif;
}

.markerTooltipContent .pointTypeName {
    font-size: 12px;
    color: #f27a1a;
}

.activeMarker {
    z-index: 1;
}

.collectionPointInfo {
    background-color: #fff;
    font-family: source_sans_proregular, sans-serif;
    padding-top: 15px;
    height: 300px;
}

.collectionPointInfo.expand {
    -webkit-animation-name: animate_expand;
    -webkit-animation-duration: 1s;
    animation-name: animate_expand;
    animation-duration: 1s;
}

.collectionPointInfo.collapse {
    -webkit-animation-name: animate_collapse;
    -webkit-animation-duration: 1s;
    animation-name: animate_collapse;
    animation-duration: 1s;
    height: 60px;
}

.collectionPointInfo.collapse .selectedPointInfo {
    display: none !important;
}

.collectionPointInfo .toggleInfo {
    display: flex;
    justify-content: center;
    position: relative;
    height: 0;
    bottom: 30px;
}

.collectionPointInfo .toggleInfo .circledIcon {
    cursor: pointer;
    width: 28px;
    height: 28px;
    border-radius: 16px;
    background-color: #ffffff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    justify-content: center;
    align-items: center;
    display: flex;
}

.collectionPointInfo .toggleInfo .circledIcon i {
    transition: all 0.6s ease-in-out;
    color: #999999;
    font-size: 12px;
}

.collectionPointInfo .toggleInfo .circledIcon i.plus {
    transform: rotate(45deg);
}

.collectionPointInfo h1 {
    font-size: 20px;
    font-weight: 600;
    line-height: 0.9;
    color: #333333;
}

.collectionPointInfo .pointAddress {
    font-size: 14px;
    color: #333333;
    margin-top: 6px;
}

.collectionPointInfo .pointAddress a {
    display: inline-block;
    margin-left: 5px;
}

.collectionPointInfo .pointAddress .i-external-url {
    color: #9a9a9a;
    font-weight: bold;
    font-size: 13px;
    margin-left: 2px;
}

.collectionPointInfo .selectedPointInfo {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    position: relative;
}

.collectionPointInfo .selectedPointInfo label {
    color: #333333;
    display: block;

    -webkit-font-smoothing: antialiased;
    margin-bottom: 10px;
    font-size: 12px;
}

.collectionPointInfo .selectedPointInfo .selectedPointInfoTitle {
    display: flex;
    align-items: center;
}

.collectionPointInfo .selectedPointInfo .selectedPointInfoTitle .publicStatusText {
    border-radius: 4px;
    background-color: #ffd4d4;
    color: #d21313;
    font-size: 12px;

    -webkit-font-smoothing: antialiased;
    padding: 0 3px;
    margin: 0 8px 10px;
}

.collectionPointInfo .selectedPointInfo .workingTimesInfo {
    display: flex;
}

.collectionPointInfo .selectedPointInfo .workingTimesInfo .selectedPointImage {
    position: relative;
    overflow: hidden;
    margin-right: 20px;
    width: 84px;
    height: 112px;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
}

.collectionPointInfo .selectedPointInfo .workingTimesInfo .selectedPointImage.hidden {
    display: none;
}

.collectionPointInfo .selectedPointInfo .workingTimesInfo .selectedPointImage img {
    border-radius: 6px;
    width: 100%;
    justify-content: center;
    align-self: center;
}

.collectionPointInfo .collectionPointTypeDesc {
    margin: 12px 0;
    display: flex;
    align-items: center;
}

.collectionPointInfo .collectionPointTypeDesc .i-locker {
    font-size: 14px;
    margin-right: 4px;
}

.collectionPointInfo .collectionPointTypeDesc .i-delivery-point {
    color: #f77d1a;
    font-size: 10px;
    margin-right: 6px;
}

.collectionPointInfo .collectionPointTypeDesc span {
    font-size: 11px;
    font-weight: 600;
    color: #333333;
}

.collectionPointInfo .collectionPointTypeDesc span.locker {
    color: #f2ba1a;
}

.collectionPointInfo .collectionPointTypeDesc span.branch {
    color: #f27a1a;
}

.collectionPointInfo .receiverInfoWrapper {
    max-width: 220px;
}

.collectionPointInfo .receiverInfoWrapper .receiverInfoContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.collectionPointInfo .receiverInfoWrapper .receiverInfoContainer .receiverInfo {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: #333333;
    line-height: 1.7;
}

.collectionPointInfo .receiverInfoWrapper .receiverInfoContainer .changeReceiver {
    font-size: 12px;
    color: #f27a1a;
    cursor: pointer;
    margin-left: 20px;
    padding: 4px 12px;
    border-radius: 4px;
    border: solid 1px #f27a1a;

    -webkit-font-smoothing: antialiased;
}

.collectionPointInfo .selectionContainer .collectionPointSelection {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.collectionPointInfo .selectionContainer .collectionPointSelection .collectionPointName {
    display: flex;
    align-items: center;
    column-gap: 4px;
}

.collectionPointInfo .selectionContainer .collectionPointSelection .collectionPointName .i-locker-badge {
    font-size: 12px;
    color: #f77d1a;
}

.collectionPointInfo .selectionContainer .collectionPointSelection .collectionPointName .i-delivery-point {
    font-size: 16px;
    color: #f77d1a;
}

.collectionPointInfo .selectionContainer .collectionPointSelection .collectionPointName .isRecommendedPoint {
    color: #ffc000;
}

.collectionPointInfo .selectionContainer .collectionPointSelection .collectionPointName h1 {
    display: inline-block;
    font-size: 14px;

    -webkit-font-smoothing: antialiased;
}

.collectionPointInfo .selectionContainer .collectionPointSelection .collectionPointName a:hover h1 {
    text-decoration: underline;
}

.collectionPointInfo .selectionContainer .selectPointButton {
    color: #0bc15c;
}

.collectionPointInfo .selectionContainer .selectPointButton .i-checkmark {
    font-size: 11px;
    margin-right: 4px;
}

.collectionPointInfo .selectionContainer .selectPointButton span {
    font-size: 14px;
    font-weight: 600;
}

.collectionPointInfo .workingDays {
    display: flex;
}

.collectionPointInfo .workingDays>div:first-child {
    margin-right: 30px;
}

.collectionPointInfo .workingDays .workingDay {
    display: flex;
    justify-content: space-between;
    padding: 0 0 7px 0;
    font-size: 12px;
}

.collectionPointInfo .workingDays .workingDay .dayName {
    color: #999999;
    margin-right: 16px;
}

.collectionPointInfo .workingDays .workingDay .hours {
    line-height: 1.17;
    color: #333333;
}

@keyframes animate_expand {
    from {
        height: 60px;
    }

    to {
        height: 300px;
    }
}

@keyframes animate_collapse {
    from {
        height: 300px;
    }

    to {
        height: 60px;
    }
}

.filterInformationHeader {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin: 0 20px;
}

.filterInformationHeader.easyReturnEnabled {
    margin: 0 0 0 34px;
}

.filterInformationHeader .filterInfo {
    font-size: 14px;
    color: #333333;
    line-height: 1.8;
    font-family: source_sans_proregular, sans-serif;
}

.filterInformationHeader .filterInfo b {}

.filterInformationHeader .pointsHelp {
    position: relative;
}

.filterInformationHeader .pointsHelp button {
    background-color: transparent;
    color: #666;
    border: none;

    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    text-decoration: underline;
    padding: 0;
    margin: 0;
    position: relative;
    cursor: pointer;
    outline: none;
    z-index: 999;
}

.filterInformationHeader .pointsHelp button:hover {
    color: #f27a1a;
}

.filterInformationHeader .pointsHelp button.active {
    color: #fff;
}

.filterInformationHeader .pointsHelp button.active:hover {
    color: #fff;
}

.filterInformationHeader .pointsHelp .pointsHelpOverlay {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 998;
}

.filterInformationHeader .pointsHelp .pointsHelpOverlay.pointsHelpOverlayVisible {
    display: block;
}

.filterInformationHeader .pointsHelp .ty-modal {
    border: none;
}

.filterInformationHeader .pointsHelp .ty-modal-header {
    font-weight: bold;
}

.filterInformationHeader .pointsHelp .collection-point-info-content {
    padding: 20px;
    line-height: 1.8;
    color: #333;
}

.filterInformationHeader .pointsHelp .collection-point-info-content .collection-point-info-img {
    width: 568px;
    height: 114px;
    margin-bottom: 20px;
}

.filterInformationHeader .pointsHelp .collection-point-info-content .collection-point-info-text-important {
    font-size: 16px;
    color: #f27a1a;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
}

.filterInformationHeader .pointsHelp .collection-point-info-content .collection-point-text-line-bold li {
    display: flex;
    margin-bottom: 10px;
}

.filterInformationHeader .pointsHelp .collection-point-info-content .collection-point-text-line-bold b {
    -webkit-font-smoothing: antialiased;
}

.filterInformationHeader .pointsHelp .collection-point-info-content .collection-point-legal-text-line {
    margin-top: 10px;
    font-size: 12px;
    color: #999999;
    font-family: source_sans_proregular, sans-serif;
}

.filterInformationHeader .pointsHelp .collection-point-info-content ul {
    max-width: 564px;
}

.filterInformationHeader .pointsHelp .collection-point-info-content ul b {
    font-weight: 600 !important;
}

.filterInformationHeader .pointsHelp .collection-point-info-content ul i {
    color: #f27a1a;
    margin-right: 5px;
    font-size: 16px;
    vertical-align: middle;
}

.filterInformationHeader .pointsHelp .collection-point-info-content ul i::before {
    color: #f27a1a;
}

.filterInformationHeader .pointsHelp .collection-point-info-popup-green-description {
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px 0px 10px 5px;
    background-color: #f2ffec;
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.filterInformationHeader .pointsHelp .collection-point-info-popup-green-description span {
    font-size: 12px;
    color: #333;
    padding-left: 5px;
    line-height: 15px;

    -webkit-font-smoothing: antialiased;
}

.filterInformationHeader .pointsHelp .collection-point-info-popup-green-description .i-leaf {
    font-size: 17px;
    color: #0bc15c;
    padding-left: 10px;
}

.filterInformationHeader .easyReturnInfoContainer {
    width: 600px;
}

.filterInformationHeader .easyReturnInfoContainer .title {
    -webkit-font-smoothing: antialiased;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 6px;
}

.filterInformationHeader .easyReturnInfoContainer li {
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
}

.filterInformationHeader .easyReturnInfoContainer li::before {
    content: "\2022";
    color: #f27a1a;
    display: inline-block;
    margin-right: 8px;
}

.mapContainer .markerTooltip {
    position: relative;
    display: inline-block;
}

.mapContainer .markerTooltip.markerTooltipWide {
    width: 40px;
}

.mapContainer .markerTooltip:hover .markerTooltipContentContainer {
    visibility: visible;
}

.mapContainer .markerTooltip .markerTooltipContentContainer {
    position: absolute;
    visibility: hidden;
    z-index: 1;
    left: -90px;
    bottom: 130px;
    height: fit-content;
    background-color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    filter: drop-shadow(0 0 0.35rem rgba(0, 0, 0, 0.3));
}

.mapContainer .markerTooltip .markerTooltipContentContainer:hover {
    visibility: visible;
}

.mapContainer .markerTooltip .markerTooltipContentContainer:after,
.mapContainer .markerTooltip .markerTooltipContentContainer:before {
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.mapContainer .markerTooltip .markerTooltipContentContainer:after {
    border-width: 10px;
    margin-left: -10px;
}

.mapContainer .markerTooltip .markerTooltipContentContainer:before {
    border-width: 11px;
    margin-left: -11px;
}

.mapContainer .markerTooltip .markerTooltipContentContainer.tooltipUpper {
    left: -90px;
    bottom: 110px;
}

.mapContainer .markerTooltip .markerTooltipContentContainer.tooltipUpper:before {
    top: 100%;
    border-top-color: #ffffff;
}

.mapContainer .markerTooltip .markerTooltipContentContainer.tooltipLower {
    left: -86px;
    top: 19px;
}

.mapContainer .markerTooltip .markerTooltipContentContainer.tooltipLower:before {
    bottom: 100%;
    border-bottom-color: #ffffff;
}

.cancel-modal-wrapper {
    width: 420px;
    height: auto;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cancel-modal-wrapper .cancel-modal-container {
    width: 380px;
    height: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
}

.cancel-modal-wrapper .cancel-modal-container .coupon-background {
    width: 180px;
    height: 97px;
    background-size: cover;
    margin-top: 30px;
    background: no-repeat 0 0;
}

.cancel-modal-wrapper .cancel-modal-container .cancel-modal-text {
    margin: 10px 0 20px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    width: 244px;
    color: #333333;
    line-height: 26px;
}

.cancel-modal-wrapper .cancel-modal-container .text-collection-point-button {
    height: 22px;
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
}

.cancel-modal-wrapper .cancel-modal-container .text-delivery-address-button {
    height: 22px;
    font-size: 16px;
    color: #666666;
}

.cancel-modal-wrapper .cancel-modal-container .text-delivery-address-button:hover {
    text-decoration: underline;
}

.cancel-modal-wrapper .cancel-modal-container button:last-child {
    background-color: white !important;
    border: none !important;
    margin-top: 10px;
}

.image-preview-modal-wrapper {
    width: 550px;
    height: auto;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-preview-modal-wrapper img {
    width: 100%;
    height: auto;
}

.receiver-box {
    display: flex;
    align-items: center;
    border-radius: 8px;
    height: 55px;
    margin: 10px 20px 20px 23px;
    padding: 15px 15px 16px 18px;
    box-sizing: border-box;
    background-color: #fff1e6;
    justify-content: space-between;
}

.receiver-box .receiver-box-title {
    display: flex;
    align-items: center;
}

.receiver-box .receiver-box-title i {
    font-size: 22px;
}

.receiver-box .receiver-box-title .receiver-desc {
    font-size: 14px;
    color: #333;
    padding: 20px 0 20px 15px;
    max-width: 500px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-flex;
}

.receiver-box .receiver-box-title .receiver-desc .receiver-information {
    margin-left: 6px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.receiver-box .change-receiver-information {
    border-radius: 4px;
    border: solid 1px #f27a1a;
    padding: 4px 55px;
    background-color: #fff1e6;
    color: #f27a1a;
    font-size: 12px;
    font-weight: 600;

    cursor: pointer;
}

.receiver-box .change-receiver-information:hover {
    background-color: #f27a1a;
    color: white;
}

.receiver-box .receiver-info {
    padding-left: 10px;
}

.non-pudo-eligible-city-modal-wrapper {
    width: 460px;
    height: auto;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 20px;
    flex-direction: column;
}

.non-pudo-eligible-city-modal-wrapper .non-pudo-eligible-city-header {
    width: 90px;
    height: 90px;
    border-radius: 45px;
    background-color: #fff4ec;
    justify-content: center;
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.non-pudo-eligible-city-modal-wrapper .non-pudo-eligible-city-header i {
    font-size: 48px;
    color: #f27a1a;
    border-radius: 1px;
}

.non-pudo-eligible-city-modal-wrapper .non-pudo-eligible-city-modal-body .text {
    margin: 16px 0 32px;
    font-size: 16px;
    text-align: center;
    color: #666;
    line-height: 20px;
}

.non-pudo-eligible-city-modal-wrapper .non-pudo-eligible-city-modal-body .collection-point-button {
    -webkit-font-smoothing: antialiased;

    line-height: 22px;
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
}

.non-pudo-eligible-city-modal-wrapper .non-pudo-eligible-city-modal-body .delivery-address-button {
    -webkit-font-smoothing: antialiased;

    line-height: 22px;
    font-size: 18px;
    font-weight: 600;
    color: #333333;
}

.non-pudo-eligible-city-modal-wrapper .non-pudo-eligible-city-modal-body .ty-notr {
    margin-top: 10px;
    border-color: #e6e6e6;
}

#checkout-gw-collection-points .ty-bordered,
#checkout-gw-collection-points .ty-modal-content {
    border-radius: 6px !important;
}

.availablePoints {
    padding-bottom: 5px;
    border-bottom: 1px solid #cbcbcb;
    margin-right: 0 !important;
}

.availablePointRow {
    padding-left: 16px;

    &:not(:last-child) {
        margin-bottom: 20px;
    }

    label {
        position: relative;
    }
}

.pointContent {
    padding-left: 5px;
}

.radioButton {
    position: absolute;

    input {
        appearance: none;
        position: relative;

        &:after {
            content: "";
            position: absolute;
            width: 16px;
            height: 16px;
            left: -20px;
            top: -17px;
            border: 1px solid #f27a1a;
            border-radius: 100%;
        }

        &:checked {
            &:after {
                background-color: #f27a1a;
                border: 2px solid white;
            }
        }
    }
}

.collectionPoints {
    border: 1px solid #cbcbcb;
    border-left: none;
}

.collectionPointsContent {
    margin-top: 20px;
}

.activeBack {
    background-color: #fff1e6;
}

.filterOrder {
    padding: 15px 20px;
    display: flex;

    &__flex {
        width: 307px;
        height: 50px;

        &:last-child {
            margin-left: 20px;
        }

        select {
            width: 100%;
            height: 100%;
            border: 1px solid #cbcbcb;
            color: #7f7f7f;
            outline: none;
        }

        input {
            width: 100%;
            height: 100%;
            border: 1px solid #cbcbcb !important;
            padding: 10px;
            color: #7f7f7f;

            outline: none;
        }
    }
}

.collectionPoints {
    height: 551px;
    border-bottom: 0 !important;
}

.auto-group-8zbk-6x6 {
    align-items: center;
    background-color: #fff8f5;
    border: solid 0.1rem #fe6f42;
    border-radius: 0.5rem;
    box-sizing: border-box;
    display: flex;
    flex-shrink: 0;
    padding: 1.4rem 1.7rem 1.5rem 1.8rem;
    width: 100%;
    margin-top: 20px;

    .comment-check-1-uuY {
        height: 2.0001rem;
        margin: 0.1rem 2rem 0rem 0rem;
        object-fit: contain;
        vertical-align: top;
        width: 2.0002rem;
    }

    .auto-group-rwmt-qYJ {
        display: flex;
        flex-direction: column;
        height: 100%;

        .ad-Ppi {
            color: #fe6f42;
            font-size: 17px;
            font-weight: 600;
            line-height: 1.2125;
            margin-bottom: 0.6rem;
            white-space: nowrap;
        }

        .sifari-olunduqdan-sonra-mhsullar-4-7-gn-rzind-dnisiz-atdrlr-LV4 {
            color: #fe6f42;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.2125;
            margin-left: 0.1rem;
        }
    }
}

.deliveryToHome {
    display: flex;
    justify-content: flex-end;
    font-size: 9px;
    color: #0bc05c;
    font-weight: 600;
}

.p-addresses-tab,
.p-payment-tab {
    display: flex;
}

#payment-app .sticky {
    @media (max-width: 1160px) {
        margin-top: 20px;
        margin-left: 0 !important;

        .p-summary {
            width: 539px;
        }
    }

    @media (max-width: 576px) {
        width: 100% !important;

        .p-summary {
            width: 100%;
        }
    }
}

.collectionPointInfo {
    @media (max-width: 576px) {
        // display: none;
    }
}

.collectionPoints {
    @media (max-width: 576px) {
        display: none;
    }
}

.collectionPointsContent {
    @media (max-width: 576px) {
        margin: 20px;
        height: 342px;
    }
}

.p-tabs {
    @media (max-width: 576px) {
        display: none;
    }
}

.p-address-type-select {
    @media (max-width: 576px) {
        display: none !important;
    }
}

.buttonSubmitBox {
    display: none;

    @media (max-width: 576px) {
        margin-top: 15px;
        padding: 15px;
        display: flex;
        justify-content: space-between;
    }

    button {
        display: flex;
        align-items: center;
        justify-content: center;

        &:first-child {
            /* Rectangle 24 */
            width: calc(50% - 20px);
            box-sizing: border-box;
            height: 35px;
            left: 12px;
            top: 675px;
            color: #fe6f42;
            background: #ffffff;
            border: 1px solid #fe6f42;
            border-radius: 5px;
        }

        &:nth-child(2) {
            width: calc(50% - 20px);
            box-sizing: border-box;
            height: 35px;
            left: 205px;
            top: 675px;
            color: #fe6f42;
            background: #ffffff;
            border: 1px solid #fe6f42;
            border-radius: 5px;
        }
    }
}

.p-ab-content-address p {
    @media (max-width: 576px) {
        font-size: 15px !important;
        line-height: normal;
        margin-bottom: 14px;
    }
}

.p-add-address-box span {
    @media (max-width: 576px) {
        padding: 5px 0 !important;
        font-size: 14px !important;
    }
}

.sticky2 {
    position: relative !important;
    display: block !important;

    .ty-font-price {
        color: #f27a1a;
        font-size: 18px;
        font-weight: 500;
    }
}


#carousel-custom {
    @media(max-width: 575px) {
        height: 380px !important;

        .swiper {
            height: 100% !important;

            .swiper-slide {
                height: 380px !important;

                >div {
                    height: 100% !important;

                    >img {
                        height: 100% !important;
                        object-fit: contain !important;
                        object-position: top !important;
                    }
                }
            }
        }
    }
}